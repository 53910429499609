import * as React from 'react';
import './CustomTableStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ITableData } from 'Containers/Reports/columnsData';
import TableHeader from './TableHeader';
import { currencyUtils } from 'utils';

import { getInnerCellContent, handleRowClick, addRowClasses } from './TableUtils';
import { withRouter } from '../../helpers/HOCs/withRouter';

interface IProps {
    dataSource: any;
    columns: ITableData[];
    sortHandler?: Function;
    currencyId?: number;
    reportKey?: string;
    clickableRow?: boolean;
    gmtOffset?: boolean;
    context?: any;
    actionsMenu?: any;
    navigate: any;
    onRowSelect?: Function;
}

interface IState {
    keyToSort: string;
    sortState: string | null;
    previousKey: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let INTERVAL_ID: any;

class SimplifiedCustomTable extends React.Component<IProps & WithTranslation, IState> {
    state: IState = SimplifiedCustomTable.getInitialState(this.props);

    divRef: React.RefObject<HTMLTableSectionElement> = React.createRef<HTMLTableSectionElement>();

    static getInitialState = (props: IProps) => {
        let keyToSort = '';
        let sortState = '';

        switch (props.reportKey) {
            case 'casino':
                keyToSort = 'from';
                sortState = 'desc';
                break;

            case 'games':
                keyToSort = 'gameName';
                sortState = 'asc';
                break;

            case 'players':
                keyToSort = 'login';
                sortState = 'asc';
                break;

            case 'playerProfile':
                keyToSort = 'endTime';
                sortState = 'desc';
                break;

            default:
                break;
        }

        return {
            keyToSort,
            sortState,
            previousKey: keyToSort,
            dataStartPoint: 0,
        };
    };

    getData = () => {
        const { dataSource, columns, clickableRow, navigate } = this.props;
        const result: JSX.Element[] = [];

        dataSource &&
            dataSource.length &&
            dataSource.forEach((row: any, i: number) => {
                const currentRow: JSX.Element[] = [];

                columns.forEach((column: ITableData, j: number) => {
                    const {stickyPosition, alignment, key, dataIndex} = column;

                    currentRow.push(
                        <td key={`${i}-${j}`} className={addRowClasses(stickyPosition)}>
                            <span className={`app-table__span alignment-${alignment}`}>
                                {getInnerCellContent(this.props, row, dataIndex, i, key)}
                            </span>
                        </td>,
                    );
                });

                result.push(
                    <tr
                        key={`row-${i}`}
                        onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                            handleRowClick(this.props, row, e, navigate);
                        }}
                        className={`app-table__row${row.blocked ? ' blocked' : ''}${
                            clickableRow ? ' app-table__row__clickable' : ''
                        }`}
                    >
                        {currentRow}
                    </tr>,
                );
            });

        return result;
    };

    sortStateHandler = (key: string) => {
        const { sortHandler } = this.props;
        const { previousKey } = this.state;
        let { sortState } = this.state;

        sortState = key === previousKey ? (sortState === 'desc' ? 'asc' : 'desc') : 'desc';

        sortHandler &&
            this.setState(
                () => ({
                    keyToSort: key,
                    sortState,
                    previousKey: key,
                }),
                () => sortHandler(key, sortState),
            );
    };

    fixStickyColumns = () => {
        const { current } = this.divRef;

        if (current) {
            const stickyColumnsWidth: any = {};

            for (const cell in current.rows[0].cells) {
                const currentCell = current.rows[0].cells[cell];
                if (currentCell.className?.includes('sticky')) {
                    stickyColumnsWidth[cell] = currentCell.offsetWidth;
                }
            }

            for (const row in current.rows) {
                if (
                    Object.prototype.hasOwnProperty.call(current.rows, row) &&
                    current.rows[row] &&
                    current.rows[row].cells
                ) {
                    let currentOffset = 0;
                    for (const column in stickyColumnsWidth) {
                        const columnTitle: any = document.querySelector(`.sticky-title_${column}`);
                        current.rows[row].cells[Number(column)].style.position = 'sticky';
                        current.rows[row].cells[Number(column)].style.left = `${currentOffset}px`;
                        if (columnTitle) {
                            columnTitle.style.position = 'sticky';
                            columnTitle.style.left = `${currentOffset}px`;
                        }

                        currentOffset = currentOffset + stickyColumnsWidth[column];
                    }
                }
            }
        } else {
            INTERVAL_ID = setTimeout(this.fixStickyColumns, 500);
        }
    };

    componentDidMount(): void {
        if (this.props.reportKey === 'casino' || this.props.reportKey === 'playerProfile') {
            INTERVAL_ID = setTimeout(this.fixStickyColumns, 500);

            window.addEventListener('resize', this.fixStickyColumns);
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.fixStickyColumns);
    };

    render = () => {
        const { columns, sortHandler, currencyId } = this.props;
        const { sortState, keyToSort } = this.state;
        const tableCurrencyId = currencyId || currencyUtils.getActiveCurrencyData().id;

        return (
            <>
                <div className={'app-table__wrapper'}>
                    <table className="app-table">
                        <TableHeader
                            columns={columns}
                            currencyId={tableCurrencyId}
                            sortStateHandler={sortHandler && this.sortStateHandler}
                            keyToSort={keyToSort}
                            sortState={sortState}
                        />
                        <tbody ref={this.divRef}>{this.getData()}</tbody>
                    </table>
                </div>
            </>
        );
    };
}

export default withTranslation()(withRouter(SimplifiedCustomTable));
