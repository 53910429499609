export const BrokenRoundsTypes = {
    BROKEN_ROUNDS_REQUEST: 'BROKEN_ROUNDS_REQUEST',
    BROKEN_ROUNDS_SUCCESS: 'BROKEN_ROUNDS_SUCCESS',
    BROKEN_ROUNDS_FAILED: 'BROKEN_ROUNDS_FAILED',
    RESOLVE_SESSION_REQUEST: 'RESOLVE_SESSION_REQUEST',
    RESOLVE_SESSION_SUCCESS: 'RESOLVE_SESSION_SUCCESS',
    RESOLVE_SESSION_FAILED: 'RESOLVE_SESSION_FAILED',
    RETRY_SESSION_REQUEST: 'RETRY_SESSION_REQUEST',
    RETRY_SESSION_SUCCESS: 'RETRY_SESSION_SUCCESS',
    RETRY_SESSION_FAILED: 'RETRY_SESSION_FAILED',
    BLOCK_SESSION_REQUEST: 'BLOCK_SESSION_REQUEST',
    BLOCK_SESSION_SUCCESS: 'BLOCK_SESSION_SUCCESS',
    BLOCK_SESSION_FAILED: 'BLOCK_SESSION_FAILED',
    STATUS_HISTORY_REQUEST: 'STATUS_HISTORY_REQUEST',
    STATUS_HISTORY_SUCCESS: 'STATUS_HISTORY_SUCCESS',
    STATUS_HISTORY_FAILED: 'STATUS_HISTORY_FAILED',
};

export const getBrokenRounds = (payload?: any) => ({
    type: BrokenRoundsTypes.BROKEN_ROUNDS_REQUEST,
    payload,
});
export const getBrokenRoundsSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.BROKEN_ROUNDS_SUCCESS,
    payload,
});
export const resolveSession = (payload?: any) => ({
    type: BrokenRoundsTypes.RESOLVE_SESSION_REQUEST,
    payload,
});
export const resolveSessionSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.RESOLVE_SESSION_SUCCESS,
    payload,
});
export const retrySession = (payload?: any) => ({
    type: BrokenRoundsTypes.RETRY_SESSION_REQUEST,
    payload,
});
export const retrySessionSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.RETRY_SESSION_SUCCESS,
    payload,
});
export const blockSession = (payload?: any) => ({
    type: BrokenRoundsTypes.BLOCK_SESSION_REQUEST,
    payload,
});
export const blockSessionSuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.BLOCK_SESSION_SUCCESS,
    payload,
});
export const getStatusHistory = (payload?: any) => ({
    type: BrokenRoundsTypes.STATUS_HISTORY_REQUEST,
    payload,
});
export const getStatusHistorySuccess = (payload?: any) => ({
    type: BrokenRoundsTypes.STATUS_HISTORY_SUCCESS,
    payload,
});
