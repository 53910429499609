import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import {
    blockSessionSuccess,
    BrokenRoundsTypes,
    getBrokenRoundsSuccess,
    getStatusHistorySuccess,
    resolveSessionSuccess,
    retrySessionSuccess,
} from '../../actions/configProvider/broken-rounds-actions';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const brokenRoundsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(BrokenRoundsTypes.BROKEN_ROUNDS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.post(
                    `${SERVER_URL}/session/broken-rounds/search`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map((response) => getBrokenRoundsSuccess(response.data)),
                catchError((error) => of(apiError(error, BrokenRoundsTypes.BROKEN_ROUNDS_REQUEST))),
            );
        }),
    );

export const resolveSessionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(BrokenRoundsTypes.RESOLVE_SESSION_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.post(
                    `${SERVER_URL}/session/unblock`,
                    JSON.stringify({ sessionId: action.payload }),
                    { headers },
                ),
            ).pipe(
                map((response) => resolveSessionSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, BrokenRoundsTypes.RESOLVE_SESSION_REQUEST)),
                ),
            );
        }),
    );

export const retrySessionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(BrokenRoundsTypes.RETRY_SESSION_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.post(
                    `${SERVER_URL}/session/broken-rounds/retry`,
                    JSON.stringify({ roundUuid: action.payload }),
                    { headers },
                ),
            ).pipe(
                map((response) => retrySessionSuccess(response.data)),
                catchError((error) => of(apiError(error, BrokenRoundsTypes.RETRY_SESSION_REQUEST))),
            );
        }),
    );

export const blockSessionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(BrokenRoundsTypes.BLOCK_SESSION_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/session/broken-rounds/${action.payload}/on-hold`,
                    JSON.stringify({ sessionId: action.payload }),
                    { headers },
                ),
            ).pipe(
                map((response) => blockSessionSuccess(response.data)),
                catchError((error) => of(apiError(error, BrokenRoundsTypes.BLOCK_SESSION_REQUEST))),
            );
        }),
    );

export const statusHistoryEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(BrokenRoundsTypes.STATUS_HISTORY_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.get(
                    `${SERVER_URL}/session/broken-rounds/status-history?roundUuid=${action.payload}`,
                    { headers },
                ),
            ).pipe(
                map((response) => getStatusHistorySuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, BrokenRoundsTypes.STATUS_HISTORY_REQUEST)),
                ),
            );
        }),
    );
