import { IAction } from 'helpers/interfaces';
import { ChecksumReportTypes } from '../../actions/backoffice/checksum-report-actions';

interface IState {
    data?: [] | null;
    totalNumberOfElements: number | null;
}

export const initialState: IState = {
    data: null,
    totalNumberOfElements: null,
};

const checksumReducer = (state: IState = initialState, actions: IAction) => {
    switch (actions.type) {
        case ChecksumReportTypes.CHECKSUM_REPORT_SUCCESS: {
            return {
                ...state,
                data: actions.payload.gameFileCheckHistories,
                totalNumberOfElements: actions.payload.metadata.totalNumberOfElements,
            };
        }

        default:
            return state;
    }
};

export default checksumReducer;
