import * as React from 'react';
import './SearchResultStyles.scss';
import PlayerCard from './PlayerCard';
import { ISearchPlayersData } from 'helpers/interfaces';

interface IProps {
    players: ISearchPlayersData[];
}

class SearchResult extends React.Component<IProps> {
    render = () => {
        const { players } = this.props;

        return (
            <div className="search-result__wrapper">
                {players.map((player: ISearchPlayersData) => (
                    <PlayerCard key={player.login} playerData={player} />
                ))}
            </div>
        );
    };
}

export default SearchResult;
