import { IAction } from 'helpers/interfaces';
import { BrokenRoundsTypes } from '../../actions/configProvider/broken-rounds-actions';
import { Messages } from '../../../utils';
import i18next from 'i18next';

export interface IBrokenRoundsData {
    data?: any;
    statusHistory?: any;
}

export const brokenRoundsData: IBrokenRoundsData = {
    data: null,
    statusHistory: null,
};

const brokenRoundsReducer = (state = brokenRoundsData, actions: IAction) => {
    switch (actions.type) {
        case BrokenRoundsTypes.BROKEN_ROUNDS_REQUEST: {
            return brokenRoundsData;
        }

        case BrokenRoundsTypes.BROKEN_ROUNDS_SUCCESS: {
            const { content, _metadata } = actions.payload;

            return {
                ...state,
                data: content,
                metadata: _metadata,
            };
        }

        case BrokenRoundsTypes.RESOLVE_SESSION_SUCCESS: {
            Messages.success(i18next.t('session_was_successfully_resolved'));

            return state;
        }

        case BrokenRoundsTypes.RESOLVE_SESSION_FAILED: {
            Messages.error(i18next.t('session_was_not_resolved'));

            return state;
        }

        case BrokenRoundsTypes.STATUS_HISTORY_SUCCESS: {
            const { statusHistoryRecords } = actions.payload;

            return {
                ...state,
                statusHistory: statusHistoryRecords,
            };
        }

        default:
            return state;
    }
};

export default brokenRoundsReducer;
