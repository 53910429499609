import { localStorageHelper } from 'utils';
import { IAction } from 'helpers/interfaces';
import { SearchTypes } from 'redux/actions/backoffice/search-actions';
import { UserTypes } from 'redux/actions/user-actions';

interface ISearchData {
    isSearching: boolean;
    searchResult?: any;
    error?: any;
}

export const defaultSearchState: ISearchData = {
    isSearching: false,
};

const searchReducer = (state = defaultSearchState, actions: IAction) => {
    switch (actions.type) {
        case SearchTypes.SEARCH_PLAYERS_REQUEST: {
            const { searchString } = actions.payload;

            return {
                ...defaultSearchState,
                searchString,
                isSearching: true,
            };
        }

        case SearchTypes.SEARCH_PLAYERS_SUCCESS: {
            const { response } = actions.payload;

            return {
                ...state,
                isSearching: false,
                searchResult: response,
            };
        }

        case SearchTypes.SEARCH_PLAYERS_FAILED: {
            return {
                ...defaultSearchState,
                error: true,
            };
        }

        case SearchTypes.REMOVE_SEARCH: {
            localStorageHelper
                .setChainedValue('user.search.currentSearchString', '')
                .setChainedValue('user.search.searchResult', []);

            return defaultSearchState;
        }

        case UserTypes.LOGOUT: {
            return defaultSearchState;
        }

        default:
            return state;
    }
};

export default searchReducer;
