import * as React from 'react';
import './ErrorStyles.scss';
import { Empty } from 'antd';

interface IProps {
    error: string;
}

export const ErrorComponent = (props: IProps) => (
    <Empty description={props.error} />
);
