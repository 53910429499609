import { createSelector } from 'reselect';
import { dataFormatterUtils } from '../../../utils';

const getUnfinishedRounds = (state: any) => state.unfinishedRoundsReducer.data;
const getTotalNumberOfElements = (state: any) =>
    state.unfinishedRoundsReducer.totalNumberOfElements;
const getLoadingStateData = (state: any) =>
    state.unfinishedRoundsReducer.loading;

export const getUnfinishedRoundsData = createSelector(
    getUnfinishedRounds,
    (state) => state && dataFormatterUtils.getFormattedDataForCP(state),
);
export const getTotalNumberOfElementsData = createSelector(
    getTotalNumberOfElements,
    (state) => state,
);
export const getLoadingState = createSelector(
    getLoadingStateData,
    (state) => state,
);
