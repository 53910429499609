import * as React from 'react';
import './SearchResultStyles.scss';
import { Link } from 'react-router-dom';
import { getRandomBetween } from 'utils';
import { ISearchPlayersData } from 'helpers/interfaces';
import { UserOutlined } from '@ant-design/icons/lib';

interface IProps {
    playerData: ISearchPlayersData;
}

interface IState {
    shouldDisplayExtraLine: boolean;
}

class PlayerCard extends React.Component<IProps, IState> {
    state: IState = {
        shouldDisplayExtraLine: false,
    };

    getBackgroundColor = () => {
        const colorsMap = [
            '#CF0920',
            '#FF5457',
            '#FF3F34',
            '#EF5777',
            '#F53B57',
            '#FFC047',
            '#FFA801',
            '#FFD32A',
            '#0BE881',
            '#05C46B',
            '#34E7E4',
            '#00D8D6',
            '#4BCFFA',
            '#0FBCF9',
            '#575FCF',
            '#3C40C6',
        ];

        return colorsMap[getRandomBetween(colorsMap.length - 1)];
    };

    componentDidMount = () => {
        if (this.props.playerData.login.length > 20) {
            this.setState(() => ({ shouldDisplayExtraLine: true }));
        }
    };

    render = () => {
        const { shouldDisplayExtraLine } = this.state;
        const { playerData } = this.props;
        const { id, login } = playerData;

        return (
            <Link className="player-card" to={`/casino/players/${id}/${login}`}>
                <UserOutlined
                    style={{ backgroundColor: `${this.getBackgroundColor()}` }}
                    className="player-card__icon"
                />
                <p className={`${shouldDisplayExtraLine ? 'small-font' : ''}`}>{login}</p>
            </Link>
        );
    };
}

export default PlayerCard;
