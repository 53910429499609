import { createSelector } from 'reselect';

const getAllGamesByIntegratorData = (state: any) => state.gamesReducer.games;
const getDefaultConfigData = (state: any) => state.gamesReducer.currentGameDefaultConfig;
const isNewGameAdding = (state: any) => state.gamesReducer.adding;
const isDefaultConfigUpdated = (state: any) => state.gamesReducer.isDefaultConfigUpdated;
const isGameConfigUpdated = (state: any) => state.gamesReducer.isGameConfigUpdated;

export const getDefaultConfig = createSelector(getDefaultConfigData, (state) => state);
export const isGameAdding = createSelector(isNewGameAdding, (state) => state);
export const getDefaultConfigUpdateStatus = createSelector(
    isDefaultConfigUpdated,
    (state) => state,
);
export const getGameConfigUpdateStatus = createSelector(isGameConfigUpdated, (state) => state);
export const getAllGamesByIntegrator = createSelector(
    getAllGamesByIntegratorData,
    (state) => state,
);
