import { ofType } from 'redux-observable';
import { from, Observable, forkJoin } from 'rxjs';
import {
    playersReportSuccess,
    PlayersReportTypes,
} from '../../actions/backoffice/players-report-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
    currencyUtils,
    dateFormatter,
    getLoginRequestHeader,
    localStorageHelper,
} from '../../../utils';
import { of } from 'rxjs';
import { IAction, IStateObservable } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const playersReportEpic = (action$: Observable<IAction>, state$: IStateObservable) =>
    action$.pipe(
        ofType(PlayersReportTypes.PLAYERS_REPORT_REQUEST),
        mergeMap((action) => {
            const { fromDate, toDate, tableParams } = action.payload;
            const { pagination, sortOrder, sortField } = tableParams;

            const requestBody = {
                includeTestPlayers:
                    localStorageHelper.getChainedValue('user.preferences.displayTestData') ||
                    state$.value.userReducer.shouldDisplayTestData,
                playerCurrencyIds: currencyUtils.getSelectedCurrenciesIdsData(),
                start: dateFormatter.getFormattedStartDate(fromDate),
                end: dateFormatter.getFormattedEndDate(toDate),
                currencyBreakdown: localStorageHelper.getChainedValue(
                    'user.reports.filters.currenciesBreakdown',
                )
                    ? 'currency'
                    : 'none',
                currentPage: pagination.current,
                pageSize: pagination.pageSize,
                sortOrder: sortOrder === 'ascend' ? 'ASCENDING' : 'DESCENDING',
                sortBy: sortField,
            };

            const headers = getLoginRequestHeader();

            const pageableRequest = from(
                axios.post(`${SERVER_URL}/reports/revenue/by-player/pageable`, requestBody, {
                    headers,
                }),
            );

            const grandTotalRequest = from(
                axios.post(`${SERVER_URL}/reports/revenue/by-player/grand-total`, requestBody, {
                    headers,
                }),
            );

            return forkJoin([pageableRequest, grandTotalRequest]).pipe(
                map(([pageableResponse, grandTotalResponse]) => {
                    const pageableData = pageableResponse.data;
                    const grandTotalData = grandTotalResponse.data;

                    return playersReportSuccess({ pageableData, grandTotalData });
                }),
                catchError((error) =>
                    of(apiError(error, PlayersReportTypes.PLAYERS_REPORT_REQUEST)),
                ),
            );
        }),
    );
