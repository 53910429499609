export const GeneralConfigProviderTypes = {
    GET_ALL_GAMES_REQUEST: 'GET_ALL_GAMES_REQUEST',
    GET_ALL_GAMES_SUCCESS: 'GET_ALL_GAMES_SUCCESS',
    GET_ALL_GAMES_FAILED: 'GET_ALL_GAMES_FAILED',
    GET_DEFAULT_GAME_CONFIGS_REQUEST: 'GET_DEFAULT_GAME_CONFIGS_REQUEST',
    GET_DEFAULT_GAME_CONFIGS_SUCCESS: 'GET_DEFAULT_GAME_CONFIGS_SUCCESS',
    GET_DEFAULT_GAME_CONFIGS_FAILED: 'GET_DEFAULT_GAME_CONFIGS_FAILED',
    GET_DEFAULT_CONFIGURED_CURRENCIES_REQUEST: 'GET_DEFAULT_CONFIGURED_CURRENCIES_REQUEST',
    GET_DEFAULT_CONFIGURED_CURRENCIES_SUCCESS: 'GET_DEFAULT_CONFIGURED_CURRENCIES_SUCCESS',
    GET_DEFAULT_CONFIGURED_CURRENCIES_FAILED: 'GET_DEFAULT_CONFIGURED_CURRENCIES_FAILED',
};

export const getAllGames = (payload?: any) => ({
    type: GeneralConfigProviderTypes.GET_ALL_GAMES_REQUEST,
    payload,
});
export const getAllGamesSuccess = (payload?: any) => ({
    type: GeneralConfigProviderTypes.GET_ALL_GAMES_SUCCESS,
    payload,
});
export const getDefaultGamesConfigurationsSuccess = (payload?: any) => ({
    type: GeneralConfigProviderTypes.GET_DEFAULT_GAME_CONFIGS_SUCCESS,
    payload,
});
export const getDefaultConfiguredCurrencies = (payload?: any) => ({
    type: GeneralConfigProviderTypes.GET_DEFAULT_CONFIGURED_CURRENCIES_REQUEST,
    payload,
});
export const getDefaultConfiguredCurrenciesSuccess = (payload?: any) => ({
    type: GeneralConfigProviderTypes.GET_DEFAULT_CONFIGURED_CURRENCIES_SUCCESS,
    payload,
});
