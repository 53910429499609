import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import {
    addCurrencyDefaultConfigSuccess,
    CurrencyTypes,
    getCurrencyConfigurationSuccess,
    getGameConfiguredCurrenciesSuccess,
    getGameUnconfiguredCurrenciesSuccess,
    updateCurrencyConfigurationSuccess,
} from '../../actions/configProvider/currencies-actions';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const getGameConfiguredCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(CurrencyTypes.GET_GAME_CONFIGURED_CURRENCIES_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(`${SERVER_URL}/configuration/currencies/game-configured`, {
                    headers,
                }),
            ).pipe(
                map((response) => getGameConfiguredCurrenciesSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, CurrencyTypes.GET_GAME_CONFIGURED_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );

export const getGameUnconfiguredCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(CurrencyTypes.GET_GAME_UNCONFIGURED_CURRENCIES_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(`${SERVER_URL}/configuration/currencies/game-unconfigured`, {
                    headers,
                }),
            ).pipe(
                map((response) => getGameUnconfiguredCurrenciesSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, CurrencyTypes.GET_GAME_UNCONFIGURED_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );

export const addDefaultCurrencyConfigEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(CurrencyTypes.ADD_CURRENCY_DEFAULT_CONFIG_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.post(
                    `${SERVER_URL}/configuration/default-game-configuration/currency`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map((response) => addCurrencyDefaultConfigSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, CurrencyTypes.ADD_CURRENCY_DEFAULT_CONFIG_REQUEST)),
                ),
            );
        }),
    );

export const getCurrencyConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(CurrencyTypes.GET_CURRENCY_CONFIGURATION_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/default-game-configuration?currencyIds=${action.payload}`,
                    { headers },
                ),
            ).pipe(
                map((response) => getCurrencyConfigurationSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, CurrencyTypes.GET_CURRENCY_CONFIGURATION_REQUEST)),
                ),
            );
        }),
    );

export const updateCurrencyConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(CurrencyTypes.UPDATE_CURRENCY_CONFIGURATION_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/default-game-configuration/currency`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map((response) => updateCurrencyConfigurationSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, CurrencyTypes.UPDATE_CURRENCY_CONFIGURATION_REQUEST)),
                ),
            );
        }),
    );
