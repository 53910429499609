export const PlayerReportTypes = {
    PLAYER_REPORT_REQUEST: 'PLAYER_REPORT_REQUEST',
    PLAYER_REPORT_SUCCESS: 'PLAYER_REPORT_SUCCESS',
    PLAYER_REPORT_FAILED: 'PLAYER_REPORT_FAILED',
    PLAYER_REPORT_SORT: 'PLAYER_REPORT_SORT',
    PROMOTION_DATA_REQUEST: 'PROMOTION_DATA_REQUEST',
    PROMOTION_DATA_SUCCESS: 'PROMOTION_DATA_SUCCESS',
    PROMOTION_DATA_FAILED: 'PROMOTION_DATA_FAILED',
    PLAYER_CAMPAIGNS_DATA_REQUEST: 'PLAYER_CAMPAIGNS_DATA_REQUEST',
    PLAYER_CAMPAIGNS_DATA_SUCCESS: 'PLAYER_CAMPAIGNS_DATA_SUCCESS',
    PLAYER_CAMPAIGNS_DATA_FAILED: 'PLAYER_CAMPAIGNS_DATA_FAILED',
};

export const getPlayerReport = (payload?: any) => ({
    type: PlayerReportTypes.PLAYER_REPORT_REQUEST,
    payload,
});
export const playerReportSuccess = (payload?: any) => ({
    type: PlayerReportTypes.PLAYER_REPORT_SUCCESS,
    payload,
});
export const playerReportSort = (payload?: any) => ({
    type: PlayerReportTypes.PLAYER_REPORT_SORT,
    payload,
});
export const getPromotionData = (payload?: any) => ({
    type: PlayerReportTypes.PROMOTION_DATA_REQUEST,
    payload,
});
export const promotionDataSuccess = (payload?: any) => ({
    type: PlayerReportTypes.PROMOTION_DATA_SUCCESS,
    payload,
});
export const getPlayerPromotionsData = (payload?: any) => ({
    type: PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_REQUEST,
    payload,
});
export const getPlayerPromotionsDataSuccess = (payload?: any) => ({
    type: PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_SUCCESS,
    payload,
});
