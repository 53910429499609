import React, { useState, useRef, useEffect } from 'react';
import { Select } from 'antd';
import * as i18next from 'i18next';
import { IFilters, IGameItemData } from 'helpers/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGamesByUser } from '../../../redux/actions/user-actions';
import { getGamesByUser } from '../../../redux/selectors/user-selectors';

const { Option } = Select;

interface IProps {
    t: i18next.TFunction;
    onChangeCallback: Function;
    filters: IFilters;
}

const GamesSelector: React.FC<IProps> = ({ t, onChangeCallback, filters }) => {
    const [open, setOpen] = useState(false);
    const selectRef = useRef<any>(null);
    const dispatch = useDispatch();
    const games: any[] = useSelector(getGamesByUser);

    const handleChange = (games: string[]) => {
        onChangeCallback('games', games);
        if (selectRef.current) {
            selectRef.current.blur();
        }
        setOpen(false);
    };

    const getGamesOptions = () =>
        games.map((game: IGameItemData) => {
            const gameData = `${game.name} (id: ${game.id})`;

            return (
                <Option key={gameData} value={gameData}>
                    {gameData}
                </Option>
            );
        });

    const handleFocus = () => setOpen(true);

    const handleBlur = () => setOpen(false);

    useEffect(() => {
        dispatch(getAllGamesByUser());
    }, [dispatch]);

    return (
        <div className="filter-item">
            <div className="filter-title">{t('games')}</div>
            <div className="filter-content games">
                <Select
                    defaultValue={filters.games}
                    ref={selectRef}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    open={open}
                    bordered={false}
                    className="games-selector"
                    showSearch
                    placeholder={t('select_games')}
                    mode="multiple"
                    onChange={handleChange}
                    notFoundContent={t('no_data_search')}
                >
                    {getGamesOptions()}
                </Select>
            </div>
        </div>
    );
};

export default GamesSelector;
