import React from 'react';
import { Select } from 'antd';
import * as i18next from 'i18next';
import { IFilters } from 'helpers/interfaces';

const { Option } = Select;

interface IProps {
    t: i18next.TFunction;
    onChangeCallback: Function;
    filters: IFilters;
}

const DEVICE_OPTIONS = ['all', 'desktop', 'mobile', 'tablet'];

export class DeviceTypeSelector extends React.Component<IProps> {
    handleChange = (deviceType: string) =>
        this.props.onChangeCallback('deviceType', deviceType);

    getDeviceOptions = () =>
        DEVICE_OPTIONS.map((key: string) => (
            <Option key={key} value={key}>
                {this.props.t(key)}
            </Option>
        ));

    render = () => {
        const { t, filters } = this.props;

        return (
            <div className="filter-item device-type">
                <div className="filter-title">{t('channel')}</div>
                <div className="filter-content">
                    <Select
                        value={t(filters.deviceType)}
                        onChange={this.handleChange}
                    >
                        {this.getDeviceOptions()}
                    </Select>
                </div>
            </div>
        );
    };
}
