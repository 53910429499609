import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader, localStorageHelper } from '../../../utils';
import { of } from 'rxjs';
import {
    GeneralConfigProviderTypes,
    getAllGamesSuccess,
    getDefaultConfiguredCurrenciesSuccess,
    getDefaultGamesConfigurationsSuccess,
} from '../../actions/configProvider/general-actions';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const allGamesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GeneralConfigProviderTypes.GET_ALL_GAMES_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(axios.get(`${SERVER_URL}/configuration/game`, { headers })).pipe(
                map(({ data }) => {
                    localStorageHelper.setChainedValue('user.allGames', data);
                    return getAllGamesSuccess({
                        response: data,
                        source: 'GET_ALL_GAMES_REQUEST',
                    });
                }),
                catchError((error) =>
                    of(apiError(error, GeneralConfigProviderTypes.GET_ALL_GAMES_REQUEST)),
                ),
            );
        }),
    );

export const allDefaultGameConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GeneralConfigProviderTypes.GET_DEFAULT_GAME_CONFIGS_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(`${SERVER_URL}/configuration/default-game-configuration`, {
                    headers,
                }),
            ).pipe(
                map(({ data }) => {
                    localStorageHelper.setChainedValue('user.defaultGameConfigurations', data);
                    return getDefaultGamesConfigurationsSuccess({
                        response: data,
                        source: GeneralConfigProviderTypes.GET_DEFAULT_GAME_CONFIGS_REQUEST,
                    });
                }),
                catchError((error) =>
                    of(
                        apiError(
                            error,
                            GeneralConfigProviderTypes.GET_DEFAULT_GAME_CONFIGS_REQUEST,
                        ),
                    ),
                ),
            );
        }),
    );

export const getDefaultConfiguredCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GeneralConfigProviderTypes.GET_DEFAULT_CONFIGURED_CURRENCIES_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(`${SERVER_URL}/configuration/currencies/game-configured`, {
                    headers,
                }),
            ).pipe(
                map(({ data }) => getDefaultConfiguredCurrenciesSuccess(data)),
                catchError((error) =>
                    of(
                        apiError(
                            error,
                            GeneralConfigProviderTypes.GET_DEFAULT_CONFIGURED_CURRENCIES_REQUEST,
                        ),
                    ),
                ),
            );
        }),
    );
