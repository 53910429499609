import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import './commonMobileStyles.scss';
import TopGamesMobileComponent from 'mobile/TopGamesMobileComponent';
import {
    CalendarMobileComponent,
    periodKeys,
} from 'mobile/CalendarMobileComponent';
import KPIComponents from './KPIComponent';
import { localStorageHelper } from 'utils';
import { Button } from 'antd';
import { logout } from 'redux/actions/user-actions';

interface IProps {
    logout: Function;
    t: i18next.TFunction;
}

interface IState {
    period: periodKeys;
}

class DashboardMobileComponent extends React.Component<
    IProps & WithTranslation,
    IState
> {
    state: IState = {
        period:
            localStorageHelper.getChainedValue('user.mobile.period') || 'today',
    };

    onChangeDates = (period: periodKeys) => {
        this.setState(
            () => ({ period }),
            () =>
                localStorageHelper.setChainedValue(
                    'user.mobile.period',
                    period,
                ),
        );
    };

    render = () => {
        const { period } = this.state;
        const { t, logout } = this.props;

        return (
            <div className="dashboard">
                <h1 id="dashboardTitle">{t('dashboard')}</h1>
                <div className="dashboard_items">
                    <CalendarMobileComponent
                        t={t}
                        period={period}
                        onChange={this.onChangeDates}
                    />
                    <KPIComponents period={period} />
                    <TopGamesMobileComponent period={period} />
                </div>
                <div className="footer">
                    <div className="footer_logo" />
                    <Button
                        id="logoutBtn"
                        className="footer_logout"
                        type="primary"
                        shape="round"
                        onClick={() => logout()}
                    >
                        {t('log_out')}
                    </Button>
                </div>
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(
    null,
    mapDispatchToProps,
)(withTranslation()(DashboardMobileComponent));
