import dotProp from 'dot-prop-immutable';
import { IAction } from 'helpers/interfaces';
import { IUser } from 'Containers/Users/UsersComponent';
import { AdminActionsTypes } from 'redux/actions/backoffice/casino-admin-actions';

interface IUserManagement {
    users?: IUser[];
    isUserCreated?: boolean | null;
    isUserBlocked?: boolean | null;
    isUserUnblocked?: boolean | null;
    isUserRoleChanged?: boolean | null;
    isUserNameChanged?: boolean | null;
    isUserPasswordChanged?: boolean | null;

    editingUserEmail?: string;
    newUserRole?: string;
    newUserName?: string;
}

export const casinoAdminState: IUserManagement = {
    isUserCreated: null,
    isUserBlocked: null,
    isUserUnblocked: null,
    isUserRoleChanged: null,
    isUserNameChanged: null,
    isUserPasswordChanged: null,
    editingUserEmail: '',
    newUserRole: '',
    newUserName: '',
};

const casinoAdminReducer = (state = casinoAdminState, actions: IAction) => {
    const { users, editingUserEmail, newUserRole, newUserName } = state;

    switch (actions.type) {
        case AdminActionsTypes.GET_USERS_REQUEST:
            return { ...casinoAdminState };

        case AdminActionsTypes.GET_USERS_SUCCESS:
            return dotProp.set(state, 'users', actions.payload);

        case AdminActionsTypes.CREATE_USER_REQUEST:
            return { ...state, ...casinoAdminState };

        case AdminActionsTypes.CREATE_USER_SUCCESS: {
            users && users.push(actions.payload);

            return { ...state, isUserCreated: true };
        }

        case AdminActionsTypes.CREATE_USER_FAILED: {
            return {
                ...state,
                ...casinoAdminState,
                isUserCreated: false,
            };
        }

        case AdminActionsTypes.BLOCK_USER_REQUEST:
            return dotProp.set(
                { ...state, ...casinoAdminState },
                'editingUserEmail',
                actions.payload.email,
            );

        case AdminActionsTypes.BLOCK_USER_SUCCESS:
            users &&
                users.forEach((user) => {
                    if (user.email === editingUserEmail) {
                        user.blocked = true;
                    }
                });

            return dotProp.set(state, 'isUserBlocked', true);

        case AdminActionsTypes.BLOCK_USER_FAILED: {
            return {
                ...state,
                ...casinoAdminState,
                isUserBlocked: false,
            };
        }

        case AdminActionsTypes.UNBLOCK_USER_REQUEST:
            return dotProp.set(
                { ...state, ...casinoAdminState },
                'editingUserEmail',
                actions.payload.email,
            );

        case AdminActionsTypes.UNBLOCK_USER_SUCCESS:
            users &&
                users.forEach((user) => {
                    if (user.email === editingUserEmail) {
                        user.blocked = false;
                    }
                });

            return dotProp.set(state, 'isUserUnblocked', true);

        case AdminActionsTypes.UNBLOCK_USER_FAILED: {
            return {
                ...state,
                ...casinoAdminState,
                isUserUnblocked: false,
            };
        }

        case AdminActionsTypes.CHANGE_ROLE_REQUEST: {
            const { email, role } = actions.payload;

            return {
                ...state,
                ...casinoAdminState,
                editingUserEmail: email,
                newUserRole: role,
            };
        }

        case AdminActionsTypes.CHANGE_ROLE_SUCCESS:
            newUserRole &&
                users &&
                users.forEach((user) => {
                    if (user.email === editingUserEmail) {
                        user.role = newUserRole;
                    }
                });

            return dotProp.set(state, 'isUserRoleChanged', true);

        case AdminActionsTypes.CHANGE_ROLE_FAILED: {
            return {
                ...state,
                ...casinoAdminState,
                isUserRoleChanged: false,
            };
        }

        case AdminActionsTypes.CHANGE_PASSWORD_BY_ADMIN_REQUEST:
            return {
                ...state,
                ...casinoAdminState,
                editingUserEmail: actions.payload.email,
            };

        case AdminActionsTypes.CHANGE_PASSWORD_BY_ADMIN_SUCCESS:
            return dotProp.set(state, 'isUserPasswordChanged', true);

        case AdminActionsTypes.CHANGE_PASSWORD_BY_ADMIN_FAILED: {
            return {
                ...state,
                ...casinoAdminState,
                isUserPasswordChanged: false,
            };
        }

        case AdminActionsTypes.CHANGE_NAME_BY_ADMIN_REQUEST: {
            const { email, name } = actions.payload;

            return {
                ...state,
                ...casinoAdminState,
                editingUserEmail: email,
                newUserName: name,
            };
        }

        case AdminActionsTypes.CHANGE_NAME_BY_ADMIN_SUCCESS:
            newUserName &&
                users &&
                users.forEach((user) => {
                    if (user.email === editingUserEmail) {
                        user.name = newUserName;
                    }
                });

            return dotProp.set(state, 'isUserNameChanged', true);

        case AdminActionsTypes.CHANGE_NAME_BY_ADMIN_FAILED: {
            return {
                ...state,
                ...casinoAdminState,
                isUserNameChanged: false,
            };
        }

        default:
            return state;
    }
};

export default casinoAdminReducer;
