import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { getPromotion } from 'redux/selectors/backoffice/player-report-selectors';
import { getPromotionData } from '../../redux/actions/backoffice/player-report-actions';
import { currencyUtils, dataFormatterUtils } from '../../utils';
import { Loader } from '../../Components/Loader/Loader';
import { ContentComponent } from '../../Components/ContentComponent/ContentComponent';
import CustomTable from '../../Components/Table/CustomTable';
import { promotionPageData } from '../Reports/columnsData';
import { Link } from 'react-router-dom';
import { Context } from '../../App';
import '../../Containers/PlayerProfile/promotion.scss';
import { withRouter } from '../../helpers/HOCs/withRouter';

interface IPromotionData {
    bet: number;
    expire: string;
    gameId: number;
    grantedFreeRounds: number;
    promotionId: string;
    remainedFreeRounds: number;
    win: number;
}

interface IProps {
    getPromotionData: Function;
    promotionData: IPromotionData[];
    gameSessionId: any;
    startId: any;
    promotionId: any;
    playerId: any;
    params: any;
}

interface IPromotionParams {
    playerId: string;
    promotionId: string;
    gameSessionId: string;
    currencyId: string;
    login: string;
}

class PromotionPage extends React.Component<IProps & WithTranslation> {
    constructor(props: IProps & WithTranslation) {
        super(props);

        const { queryParams } = this.props.params;
        const [playerId, promotionId, gameSessionId, currencyId, login] = queryParams
            .split('promotion-')[1]
            .split('+');

        this.promotionParams = {
            playerId,
            promotionId,
            gameSessionId,
            currencyId,
            login,
        };
    }

    private readonly promotionParams: IPromotionParams;

    getData = (data: any) => {
        const { t } = this.props;
        const { remainedFreeRounds, grantedFreeRounds, bet, expire } = data[data.length - 1];
        const { currencyId } = this.promotionParams;
        const notExpired = expire ? new Date(expire).getTime() > new Date().getTime() : true;
        const status = remainedFreeRounds > 0 && notExpired ? t('unfinished') : t('finished');
        const roundsLeft =
            grantedFreeRounds === '0'
                ? [remainedFreeRounds, Number(data.length) + Number(remainedFreeRounds)]
                : [remainedFreeRounds, grantedFreeRounds];
        const gameName = data
            .map((round: any) => round.gameId.toString())
            .filter((value: any, index: any, array: any) => array.indexOf(value) === index);
        const totalWin = data
            .map((item: any) => parseFloat(item.win))
            .reduce((a: any, b: any) => Number(a) + Number(b));

        return (
            <ul className="promotion_data">
                <li>
                    <span>{t('status')}:</span>
                    <span>{t(status)}</span>
                </li>
                <li>
                    <span>{t('remainedFreeRounds')}:</span>
                    <span>{roundsLeft[0] + '/' + roundsLeft[1]}</span>
                </li>
                <li>
                    <span>{t('game_name')}:</span>
                    <span>
                        {gameName.map((name: string, i: number) => (i > 0 ? `, ${name}` : name))}
                    </span>
                </li>
                {expire ? (
                    <li>
                        <span>{t('bet')}:</span>
                        <span>
                            {bet + ' ' + currencyUtils.getCurrencyDataById(Number(currencyId)).code}
                        </span>
                    </li>
                ) : null}
                {expire ? (
                    <li>
                        <span>{t('expire')}:</span>
                        <span>{expire}</span>
                    </li>
                ) : null}
                <li>
                    <span>{t('total_win')}:</span>
                    <span>
                        {totalWin +
                            ' ' +
                            currencyUtils.getCurrencyDataById(Number(currencyId)).code}
                    </span>
                </li>
            </ul>
        );
    };

    getPromotionData = () => {
        const { playerId, promotionId, gameSessionId } = this.promotionParams;

        this.props.getPromotionData({ playerId, gameSessionId, promotionId });
    };

    getSupplementedData = () => {
        const { currencyId } = this.promotionParams;
        const supplementedPromotionData = this.props.promotionData.map(
            (data: IPromotionData, index: number) => ({
                ...data,
                currencyId,
                round: index + 1,
            }),
        );

        return dataFormatterUtils.getFormattedData(supplementedPromotionData, false);
    };

    componentDidMount = () => this.getPromotionData();

    render = () => {
        const { promotionData, t } = this.props;
        const { playerId, promotionId, login, currencyId } = this.promotionParams;

        return (
            <ContentComponent
                title={this.props.t('promotion') + ' ' + promotionId}
                customBreadcrumbs={
                    <Link to={`/casino/players/${playerId}/${login}`}>
                        {t('go_back_to_player') + ' ' + login}
                    </Link>
                }
                innerContent={
                    promotionData ? (
                        <>
                            {this.getData(this.getSupplementedData())}
                            <Context.Consumer>
                                {(context) => (
                                    <CustomTable
                                        gmtOffset
                                        reportKey={'promotion-page'}
                                        hidePagination
                                        currencyId={currencyId}
                                        dataSource={this.getSupplementedData()}
                                        columns={promotionPageData}
                                        context={context}
                                    />
                                )}
                            </Context.Consumer>
                        </>
                    ) : (
                        <Loader />
                    )
                }
                reportKey={'promotion-page'}
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    promotionData: getPromotion(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getPromotionData: (data: any) => dispatch(getPromotionData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(PromotionPage)));
