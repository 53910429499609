import React from 'react';
import TableFilter from '../Filters/TableFilter';
import './TableHeaderWrapper.scss';
import { currencyUtils, dateFormatter, getLoginRequestHeader, localStorageHelper } from 'utils';
import Calendar from '../Calendar/Calendar';
import { useTranslation } from 'react-i18next';
import FilterMarks from 'Components/Filters/FilterMarks';
import DateAggregationWrapper from 'Containers/DateAggregatorWrapper/DateAggregatorWrapper';
import { Button, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons/lib';
import { DownloadOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../../utils/config';
import { useFilters } from './useFilter';

const REPORT_KEY_TO_TYPE_MAP: Record<string, string> = {
    casino: 'player-group-revenue',
    games: 'game-revenue',
    players: 'player-revenue',
};

interface IProps {
    getReport: () => void;
    parentReportCallback: Function;
    fromDate: Date;
    toDate: Date;
    reportKey: string;
    titleComponent?: JSX.Element;
    isShowSelectTime?: boolean;
    isShowDateAggregation?: boolean;
    notShowFilters?: boolean;
    context: any;
    currenciesBreakdown?: any;
    dateOfLastRefresh?: Date;
}

export const fetchReport = async (url: string, requestBody: any) => {
    const config = {
        method: 'POST',
        headers: {
            ...getLoginRequestHeader('application/json'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    };

    const response = await fetch(url, config);
    const blob = await response.blob();
    if (response.status === 200) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.csv';
        a.click();
    }
};

const TableHeaderWrapper: React.FC<IProps> = ({
    getReport,
    parentReportCallback,
    fromDate,
    toDate,
    reportKey,
    titleComponent,
    isShowSelectTime,
    isShowDateAggregation,
    notShowFilters,
    context,
    dateOfLastRefresh,
}) => {
    const { t } = useTranslation();
    const {
        applyingFilters,
        appliedFilters,
        applyFilters,
        cancelFilters,
        resetFilters,
        onFiltersChangeCallback,
    } = useFilters(reportKey, getReport);

    const handleDownloadReport = async () => {
        const requestBody = {
            reportType: REPORT_KEY_TO_TYPE_MAP[reportKey],
            includeTestPlayers:
                localStorageHelper.getChainedValue('user.preferences.displayTestData') || false,
            playerCurrencyIds: currencyUtils.getSelectedCurrenciesIdsData(),
            start: dateFormatter.getFormattedStartDate(fromDate),
            end: dateFormatter.getFormattedEndDate(toDate),
            timeBreakdown:
                localStorageHelper.getChainedValue('user.reports.aggregationPeriod') || 'day',
            currencyBreakdown: applyingFilters.currenciesBreakdown ? 'currency' : 'none',
        };

        await fetchReport(`${SERVER_URL}/reports/revenue/csv-export`, requestBody);
    };

    const isReportDownloadable = !!REPORT_KEY_TO_TYPE_MAP[reportKey];

    return (
        <div className="table-tools">
            <div className="header-line">
                <div>
                    {titleComponent}
                    {dateOfLastRefresh && (
                        <span style={{ fontSize: '12px', color: '#999' }}>
                            Data updated{' '}
                            {dateFormatter.getFormattedDateOfLastRefresh(dateOfLastRefresh)}
                        </span>
                    )}
                </div>
                <div className="tools">
                    <Tooltip title={t('refresh')}>
                        <Button style={{ marginRight: '8px' }} onClick={getReport}>
                            <SyncOutlined />
                        </Button>
                    </Tooltip>
                    {isShowDateAggregation ? (
                        <DateAggregationWrapper
                            parentReportCallback={parentReportCallback}
                            isShowSelectTime={isShowSelectTime}
                            getReport={getReport}
                            dates={{ fromDate, toDate }}
                            context={context}
                        />
                    ) : (
                        <Calendar
                            parentReportCallback={parentReportCallback}
                            isShowSelectTime={isShowSelectTime}
                            defaultValue={{ fromDate, toDate }}
                            context={context}
                        />
                    )}
                    {isReportDownloadable && (
                        <Button style={{ marginRight: '8px' }} onClick={handleDownloadReport}>
                            <DownloadOutlined />
                            {t('export_to_csv')}
                        </Button>
                    )}
                    {!notShowFilters && (
                        <TableFilter
                            filters={applyingFilters}
                            applyFilters={applyFilters}
                            onFiltersChangeCallback={onFiltersChangeCallback}
                            cancelFilters={cancelFilters}
                            reportKey={reportKey}
                            context={context}
                        />
                    )}
                </div>
            </div>
            <FilterMarks
                filters={appliedFilters}
                onReset={resetFilters}
                onFiltersChangeCallback={onFiltersChangeCallback}
            />
        </div>
    );
};

export default TableHeaderWrapper;
