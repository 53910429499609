import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import {
    finishRoundsSuccess,
    getUnfinishedRoundsSuccess,
    UnfinishedRoundsTypes,
} from '../../actions/configProvider/unfinished-rounds-actions';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const unfinishedRoundsEpics = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UnfinishedRoundsTypes.UNFINISHED_ROUNDS_REQUEST),
        mergeMap((action) => {
            const {
                roundStartedFrom,
                roundStartedTo,
                pageNumber,
                pageSize,
                filterKey,
                filterString,
                period,
                sortBy,
            } = action.payload;
            const [parsedPeriod, parsedUnit] = period.split('_');

            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.get(`${SERVER_URL}/rounds/unfinished-rounds`, {
                    headers,
                    params: {
                        'paging.pageNumber': pageNumber,
                        'paging.pageSize': pageSize,
                        'paging.sortBy': sortBy,
                        [filterKey]: filterString,
                        'timePeriod.period': parsedUnit ? parsedPeriod : null,
                        'timePeriod.periodUnit': parsedUnit,
                        roundStartedFrom,
                        roundStartedTo,
                    },
                }),
            ).pipe(
                map(({ data }) => getUnfinishedRoundsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, UnfinishedRoundsTypes.UNFINISHED_ROUNDS_REQUEST)),
                ),
            );
        }),
    );

export const finishRoundsEpics = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(UnfinishedRoundsTypes.FINISH_ROUNDS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.post(
                    `${SERVER_URL}/rounds/bonus-rounds-simulation`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map(({ data }) => finishRoundsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, UnfinishedRoundsTypes.FINISH_ROUNDS_REQUEST)),
                ),
            );
        }),
    );
