import * as React from 'react';
import './commonStyles.scss';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import ReportsMenu from './ReportsMenu/ReportsMenu';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Content } from 'antd/lib/layout/layout';

class ReportsComponent extends React.Component<WithTranslation> {
    render = () => {
        const { t } = this.props;

        return (
            <Content>
                <ContentComponent
                    customBreadcrumbs={<div>{t('all')}</div>}
                    innerContent={<ReportsMenu />}
                    title={t('content_page_reports')}
                />
            </Content>
        );
    };
}

export default withTranslation()(ReportsComponent);
