export const ChecksumReportTypes = {
    CHECKSUM_REPORT_REQUEST: 'CHECKSUM_REPORT_REQUEST',
    CHECKSUM_REPORT_SUCCESS: 'CHECKSUM_REPORT_SUCCESS',
};

export const getChecksumReport = (payload?: any) => ({
    type: ChecksumReportTypes.CHECKSUM_REPORT_REQUEST,
    payload,
});
export const checksumReportSuccess = (payload?: any) => ({
    type: ChecksumReportTypes.CHECKSUM_REPORT_SUCCESS,
    payload,
});
