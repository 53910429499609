import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { localStorageHelper } from 'utils';
import { UpOutlined } from '@ant-design/icons/lib';

interface IProps {
    children: any;
    collapsible?: boolean;
    collapsingState?: 'collapsed' | 'expanded';
    titleKey?: any;
    customClass?: string;
}

interface IState {
    isCollapsed: boolean;
}

class ItemComponentWrapper extends React.Component<IProps & WithTranslation> {
    state: IState = {
        isCollapsed: this.props.collapsingState
            ? this.props.collapsingState === 'collapsed'
            : localStorageHelper.getChainedValue(
                  `user.collapsedItems.${this.props.titleKey}`,
              ) || false,
    };

    handleCollapsing = () => {
        this.setState({ isCollapsed: !this.state.isCollapsed }, () => {
            localStorageHelper.setChainedValue(
                `user.collapsedItems.${this.props.titleKey}`,
                this.state.isCollapsed,
            );
        });
    };

    render = () => {
        const { isCollapsed } = this.state;
        const { children, t, titleKey, collapsible, customClass } = this.props;

        return (
            <div
                className={`content-item__wrapper${
                    customClass ? ` ${customClass}` : ''
                }`}
            >
                <div
                    className={`content-item${isCollapsed ? ' collapsed' : ''}${
                        customClass ? ` ${customClass}` : ''
                    }`}
                    onClick={() => {
                        collapsible && isCollapsed && this.handleCollapsing();
                    }}
                >
                    {titleKey && (
                        <h2 className="content-item__title">{t(titleKey)}</h2>
                    )}
                    <div
                        className={`content-item__content${
                            customClass ? ` ${customClass}` : ''
                        }`}
                    >
                        {children}
                    </div>
                    {collapsible ? (
                        <div
                            className="content-item__collapsing-button"
                            onClick={this.handleCollapsing}
                        >
                            <UpOutlined rotate={isCollapsed ? 180 : 0} />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };
}

export default withTranslation()(ItemComponentWrapper);
