import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { SettingOutlined } from '@ant-design/icons/lib';

interface IProps {
    t: i18next.TFunction;
    isLoading: boolean;
}

class LoginLoader extends React.Component<IProps & WithTranslation> {
    render = () => {
        const { t, isLoading } = this.props;

        return (
            <div className={`login-page-loader${isLoading ? ' visible' : ''}`}>
                <p className="loading">{t('loading')}</p>
                <SettingOutlined spin />
            </div>
        );
    };
}

export default withTranslation()(LoginLoader);
