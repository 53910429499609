import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import './AddOperatorStyles.scss';
import { STEPS } from './index';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import { addBrandsRequest } from 'redux/actions/configProvider/add-operator-actions';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { Button, Form, Input, Switch } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons/lib';
import { FormInstance } from 'antd/lib/form';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import { Footer } from 'Components/Footer/Footer';

interface IAddBrandsFormProps {
    addBrands: Function;
    previousStep: Function;
    t: i18next.TFunction;
}

interface IBrandTemplate {
    name: string;
    url: string;
    login: string;
    password: string;
    key: string;
    inactivityTimerMinutes: string;
    freeRoundsEnabled: boolean;
    buyFeatureEnabled: boolean;
    geolocationEnabled: boolean;
    singleSessionModeEnabled: boolean;
    dynamicGameConfigurationEnabled: boolean;
}

interface IBrandItemProps {
    key: string;
    id: string;
    index: number;
    data: any;
    selfRemove: Function;
    onChangeBrandInputField: Function;
    onFreeRoundsSupportChange: Function;
    onBuyFeatureSupportChange: Function;
    onGeolocationSupportChange: Function;
    onSingleSessionSupportChange: Function;
    onDynamicGameConfigurationSupportChange: Function;
    amountOfSiblings: number;
    t: i18next.TFunction;
}

interface IState {
    brands: IBrandTemplate[];
    isDataValid: boolean;
}

type brandKeys = 'name' | 'url' | 'login' | 'password' | 'key';

const CLEAR_BRAND_TEMPLATE: IBrandTemplate = {
    name: '',
    url: '',
    login: '',
    password: '',
    inactivityTimerMinutes: '30',
    key: '0',
    freeRoundsEnabled: false,
    buyFeatureEnabled: false,
    geolocationEnabled: false,
    singleSessionModeEnabled: false,
    dynamicGameConfigurationEnabled: false,
};

export const BrandItem = (props: IBrandItemProps) => {
    const {
        index,
        data,
        selfRemove,
        onChangeBrandInputField,
        onFreeRoundsSupportChange,
        onBuyFeatureSupportChange,
        onGeolocationSupportChange,
        onSingleSessionSupportChange,
        onDynamicGameConfigurationSupportChange,
        amountOfSiblings,
        id,
        t,
    } = props;
    const shouldDisplayRemoveButton = !(amountOfSiblings === 1 && index === 0);

    return (
        <ItemComponentWrapper
            collapsible
            collapsingState={'expanded'}
            titleKey={data.name && data.name.trim() !== '' ? data.name : 'new_brand'}
            customClass="cp-brand-item"
        >
            {shouldDisplayRemoveButton ? (
                <div
                    className="cp-brand-item__remove-button"
                    onClick={() => {
                        selfRemove(index);
                    }}
                >
                    <DeleteOutlined />
                </div>
            ) : null}
            <Form.Item
                label={t('name')}
                className={'cp-brand-item__label'}
                name={`name-${id}`}
                rules={[
                    { required: true, message: t('enter_name') },
                    {
                        whitespace: true,
                        message: t('this_field_cannot_be_empty'),
                    },
                    { min: 2, message: t('name_is_too_short') },
                    { max: 64, message: t('name_is_too_long') },
                ]}
                initialValue={data.name}
            >
                <Input
                    size="large"
                    onChange={(e) => {
                        onChangeBrandInputField(index, 'name', e.target.value);
                    }}
                />
            </Form.Item>
            <Form.Item
                label={t('service_url')}
                className={'cp-brand-item__label'}
                name={`url-${id}`}
                rules={[
                    { required: true, message: t('enter_service_url') },
                    {
                        whitespace: true,
                        message: t('this_field_cannot_be_empty'),
                    },
                ]}
                initialValue={data.url}
            >
                <Input
                    size="large"
                    onChange={(e) => {
                        onChangeBrandInputField(index, 'url', e.target.value);
                    }}
                />
            </Form.Item>
            <Form.Item
                label={t('service_login')}
                className={'cp-brand-item__label'}
                name={`login-${id}`}
                rules={[
                    { required: true, message: t('enter_login') },
                    {
                        whitespace: true,
                        message: t('this_field_cannot_be_empty'),
                    },
                ]}
                initialValue={data.login}
            >
                <Input
                    size="large"
                    onChange={(e) => {
                        onChangeBrandInputField(index, 'login', e.target.value);
                    }}
                />
            </Form.Item>
            <Form.Item
                label={t('service_password')}
                className={'cp-brand-item__label'}
                name={`password-${id}`}
                rules={[
                    { required: true, message: t('enter_password') },
                    { min: 3, message: t('password_validation_error_3') },
                ]}
                initialValue={data.password}
            >
                <Input
                    size="large"
                    onChange={(e) => {
                        onChangeBrandInputField(index, 'password', e.target.value);
                    }}
                />
            </Form.Item>
            <Form.Item
                label={t('inactivityTimerMinutes')}
                className={'cp-brand-item__label'}
                name={`inactivityTimerMinutes-${id}`}
                rules={[
                    { min: 1, message: t('inactivity_timer_should_be_between_1_and_120') },
                    { max: 120, message: t('inactivity_timer_should_be_more_than_1') },
                ]}
                initialValue={data.inactivityTimerMinutes}
            >
                <Input
                    size="large"
                    type="number"
                    onChange={(e) => {
                        onChangeBrandInputField(index, 'inactivityTimerMinutes', e.target.value);
                    }}
                />
            </Form.Item>
            <Form.Item
                label={t('enable_free_rounds')}
                className={'cp-brand-item__label switcher'}
                name={`free_rounds-${id}`}
                initialValue={data.freeRoundsEnabled}
            >
                <Switch
                    onChange={(checked: boolean) => onFreeRoundsSupportChange(index, checked)}
                />
            </Form.Item>
            <Form.Item
                label={t('enable_buy_feature')}
                className={'cp-brand-item__label switcher'}
                name={`buy_feature-${id}`}
                initialValue={data.buyFeatureEnabled}
            >
                <Switch
                    onChange={(checked: boolean) => onBuyFeatureSupportChange(index, checked)}
                />
            </Form.Item>
            <Form.Item
                label={t('enable_geolocation')}
                className={'cp-brand-item__label switcher'}
                name={`geolocation-${id}`}
                initialValue={data.geolocationEnabled}
            >
                <Switch
                    onChange={(checked: boolean) => onGeolocationSupportChange(index, checked)}
                />
            </Form.Item>
            <Form.Item
                label={t('single_session_mode')}
                className={'cp-brand-item__label switcher'}
                name={`single_session_mode-${id}`}
                initialValue={data.singleSessionModeEnabled}
            >
                <Switch
                    onChange={(checked: boolean) => onSingleSessionSupportChange(index, checked)}
                />
            </Form.Item>
            <Form.Item
                label={t('dynamic_game_configuration')}
                className={'cp-brand-item__label switcher'}
                name={`dynamic_game_configuration-${id}`}
                initialValue={data.dynamicGameConfigurationEnabled}
            >
                <Switch
                    onChange={(checked: boolean) =>
                        onDynamicGameConfigurationSupportChange(index, checked)
                    }
                />
            </Form.Item>
        </ItemComponentWrapper>
    );
};

export class StepAddBrands extends React.Component<IAddBrandsFormProps & WithTranslation, IState> {
    form: React.RefObject<FormInstance> = React.createRef<FormInstance>();

    state: IState = {
        brands: [{ ...CLEAR_BRAND_TEMPLATE }],
        isDataValid: false,
    };

    getBack = () => this.props.previousStep();

    handleSubmit = () => {
        const { brands } = this.state;
        const fieldsTemplate = [
            'name',
            'url',
            'login',
            'password',
            'inactivityTimerMinutes',
            'free_rounds',
            'geolocation',
            'single_session_mode',
            'dynamic_game_configuration',
        ];
        const fieldsForValidation: any = [];

        brands.forEach((brand) => {
            fieldsTemplate.forEach((field) => fieldsForValidation.push(`${field}-${brand.key}`));
        });

        this.form.current
            ?.validateFields(fieldsForValidation)
            .then(() => {
                this.props.addBrands(brands);
            })
            .catch(() => {});
    };

    onChangeBrandInputField = (i: number, key: brandKeys, value: string) => {
        this.setState((prevState: IState) => {
            const { brands } = prevState;

            brands[i][key] = value;

            return { brands };
        });
    };

    onFreeRoundsSupportChange = (index: number, value: boolean) => {
        this.setState((prevState: IState) => {
            const { brands } = prevState;

            brands[index].freeRoundsEnabled = value;

            return { brands };
        });
    };

    onBuyFeatureSupportChange = (index: number, value: boolean) => {
        this.setState((prevState: IState) => {
            const { brands } = prevState;

            brands[index].buyFeatureEnabled = value;

            return { brands };
        });
    };

    onGeolocationSupportChange = (index: number, value: boolean) => {
        this.setState((prevState: IState) => {
            const { brands } = prevState;

            brands[index].geolocationEnabled = value;

            return { brands };
        });
    };

    onSingleSessionSupportChange = (index: number, value: boolean) => {
        this.setState((prevState: IState) => {
            const { brands } = prevState;

            brands[index].singleSessionModeEnabled = value;

            return { brands };
        });
    };

    onDynamicGameConfigurationSupportChange = (index: number, value: boolean) => {
        this.setState((prevState: IState) => {
            const { brands } = prevState;

            brands[index].dynamicGameConfigurationEnabled = value;

            return { brands };
        });
    };

    removeField = (i: number) => {
        if (this.state.brands.length > 1) {
            this.setState((prevState: IState) => {
                const { brands } = prevState;

                brands.splice(i, 1);

                return { brands };
            });
        }
    };

    addNewField = async () => {
        const { brands } = this.state;
        const fieldsTemplate = ['name', 'url', 'login', 'password'];
        const fieldsForValidation: any = [];

        brands.forEach((brand) => {
            fieldsTemplate.forEach((field) => fieldsForValidation.push(`${field}-${brand.key}`));
        });

        this.form.current
            ?.validateFields(fieldsForValidation)
            .then(() => {
                const newBrandTemplate = {
                    name: '',
                    url: '',
                    login: '',
                    password: '',
                    inactivityTimerMinutes: '30',
                    key: '',
                    freeRoundsEnabled: false,
                    buyFeatureEnabled: false,
                    geolocationEnabled: false,
                    singleSessionModeEnabled: false,
                    dynamicGameConfigurationEnabled: false,
                };
                const bigIntValue = 9999999;
                newBrandTemplate.key = Math.floor(
                    Math.random() * Math.floor(bigIntValue),
                ).toString();

                this.setState({ brands: [...brands, newBrandTemplate] });
            })
            .catch(() => {});
    };

    render = () => {
        const { brands } = this.state;
        const { t } = this.props;

        return (
            <>
                <div className="operator-step">
                    <h2 className="title">{t(STEPS[4])}</h2>
                    <Form ref={this.form}>
                        {brands.map((brand: IBrandTemplate, index: number) => (
                            <BrandItem
                                key={brand.key}
                                id={brand.key}
                                index={index}
                                data={brand}
                                selfRemove={this.removeField}
                                onChangeBrandInputField={this.onChangeBrandInputField}
                                onFreeRoundsSupportChange={this.onFreeRoundsSupportChange}
                                onBuyFeatureSupportChange={this.onBuyFeatureSupportChange}
                                onGeolocationSupportChange={this.onGeolocationSupportChange}
                                onSingleSessionSupportChange={this.onSingleSessionSupportChange}
                                onDynamicGameConfigurationSupportChange={
                                    this.onDynamicGameConfigurationSupportChange
                                }
                                amountOfSiblings={brands.length}
                                t={t}
                            />
                        ))}
                        <ItemComponentWrapper customClass="cp-brand__add-button-wrapper">
                            <Button onClick={this.addNewField}>
                                <PlusCircleOutlined /> {t('add_brand')}
                            </Button>
                        </ItemComponentWrapper>
                    </Form>
                </div>
                <Footer>
                    <SecondaryButton onClick={this.getBack}>{t('back')}</SecondaryButton>
                    <MainButton onClick={this.handleSubmit}>{t('finish')}</MainButton>
                </Footer>
            </>
        );
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addBrands: (data: any) => dispatch(addBrandsRequest(data)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(StepAddBrands));
