import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import './BrandStyles.scss';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import Stepper from 'Components/CustomStepper';
import {
    getOperatorBrandsRequest,
    getOperatorCurrenciesRequest,
    getOperatorGamesRequest,
    nextStep,
    previousStep,
    reset,
} from 'redux/actions/configProvider/manage-brand-actions';
import StepManageBrandInfo from 'ConfigurationProvider/ManageBrands/Steps/StepBrandInfo';
import StepManageBrandGames from 'ConfigurationProvider/ManageBrands/Steps/StepBrandGames';
import StepManageBrandCurrencies from 'ConfigurationProvider/ManageBrands/Steps/StepBrandCurrencies';
import StepManageBrandConfigurations from 'ConfigurationProvider/ManageBrands/Steps/StepBrandConfigurations';
import { BRAND_STEPS } from 'ConfigurationProvider/ManageBrands/AddBrand';
import { IBrandData, IOperatorGameData } from 'helpers/interfaces';
import { Link } from 'react-router-dom';
import { CPUtils } from 'utils';
import {
    getBrandConfigUpdateStatus,
    getCurrentManageBrandStep,
    getOperatorBrands,
    getOperatorCurrenciesIds,
    getOperatorGamesData,
} from 'redux/selectors/configProvider/manage-brand-selectors';
import { Loader } from '../../Components/Loader/Loader';
import { withRouter } from '../../helpers/HOCs/withRouter';
import { Navigate } from 'react-router-dom';

interface IProps {
    getOperatorBrands: Function;
    brands: IBrandData[];

    getOperatorGames: Function;
    operatorGamesData: IOperatorGameData[];

    getOperatorCurrencies: Function;
    operatorCurrencyIds: number[];

    step: number;
    previousStep: Function;
    nextStep: Function;
    reset: Function;

    t: i18next.TFunction;
    params: any;
    isBrandConfigUpdated: boolean;
}

interface IState {
    brandData: IBrandData;
}

class EditBrand extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        brandData: {
            operatorId: +this.props.params.operatorId,
            id: +window.location.hash.slice(1),
            name: '',
            serviceLogin: '',
            servicePassword: '',
            serviceUrl: '',
            gameIds: [],
            currencies: [],
            inactivityTimerMinutes: '30',
            freeRoundsEnabled: false,
            buyFeatureEnabled: false,
            dynamicGameConfigurationEnabled: false,
            geolocationEnabled: false,
            singleSessionModeEnabled: false,
            directFreeRoundsSupporting: false,
        },
    };

    getCurrentStepComponent = () => {
        const { step, operatorGamesData, operatorCurrencyIds, brands } = this.props;
        const { brandData } = this.state;
        const props = {
            operatorData: { brands, operatorGamesData, operatorCurrencyIds },
            brandData: { ...brandData, enabled: this.isBrandEnabled() },
            updateBrandData: this.updateBrandData,
            previousStep: this.previousStep,
            nextStep: this.nextStep,
            isExistingBrand: true,
        };
        const stepComponents = [
            <StepManageBrandInfo key={'step-0'} {...props} />,
            <StepManageBrandGames key={'step-1'} {...props} />,
            <StepManageBrandCurrencies key={'step-2'} {...props} />,
            <StepManageBrandConfigurations key={'step-3'} {...props} />,
        ];

        return stepComponents[step];
    };

    isBrandEnabled = () => {
        let enabled;

        this.props.brands.forEach((brand: IBrandData) => {
            if (brand.id === this.state.brandData.id) {
                enabled = brand.enabled;
            }
        });

        return enabled;
    };

    previousStep = () => this.props.previousStep();

    nextStep = () => this.props.nextStep();

    updateBrandData = (data: IBrandData) =>
        this.setState((state) => ({
            brandData: { ...state.brandData, ...data },
        }));

    componentDidMount = () => {
        const { operatorId } = this.state.brandData;

        this.props.getOperatorBrands(operatorId);
        this.props.getOperatorGames(operatorId);
        this.props.getOperatorCurrencies(operatorId);
    };

    componentWillUnmount = () => this.props.reset();

    render = () => {
        const { t, step, params, brands, isBrandConfigUpdated } = this.props;
        const operatorName = CPUtils.operatorIdToName(this.state.brandData.operatorId.toString());

        return isBrandConfigUpdated ? (
            <Navigate to={`/config-provider/operators`} />
        ) : (
            <ContentComponent
                withFooter
                header={
                    <HeaderTitleComponent
                        title={t('manage_brand')}
                        customBreadcrumbs={
                            <>
                                <Link to={`/config-provider/operators`}>{t('operators')}</Link>
                                <span className="breadcrumbs-separator">/</span>
                                <Link to={`/config-provider/operator/${params.operatorId}`}>
                                    {operatorName}
                                </Link>
                                {this.state.brandData.name ? (
                                    <>
                                        <span className="breadcrumbs-separator">/</span>
                                        <p>{this.state.brandData.name}</p>
                                    </>
                                ) : null}
                            </>
                        }
                    />
                }
                innerContent={
                    <>
                        <Stepper steps={BRAND_STEPS} currentStep={step} />
                        {brands ? this.getCurrentStepComponent() : <Loader />}
                    </>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    step: getCurrentManageBrandStep(state),
    brands: getOperatorBrands(state),
    operatorGamesData: getOperatorGamesData(state),
    operatorCurrencyIds: getOperatorCurrenciesIds(state),
    isBrandConfigUpdated: getBrandConfigUpdateStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperatorBrands: (data: number) => dispatch(getOperatorBrandsRequest(data)),
    getOperatorGames: (data: number) => dispatch(getOperatorGamesRequest(data)),
    getOperatorCurrencies: (data: number) => dispatch(getOperatorCurrenciesRequest(data)),
    previousStep: () => dispatch(previousStep()),
    nextStep: () => dispatch(nextStep()),
    reset: () => dispatch(reset()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(EditBrand)));
