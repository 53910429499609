import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyNameConverter } from '../../../utils/Currency/currencyNameConverter';
import { Link } from 'react-router-dom';

export const PlayersColumns = (activeCurrencyCode: string) => {
    const { t } = useTranslation();

    return [
        {
            title: t('externalPlayerId'),
            dataIndex: 'login',
            key: 'login',
            width: 185,
            fixed: true,
            sorter: true,
            render: (text: any, record: any) => (
                <Link className="login" to={record.link}>
                    {text}
                </Link>
            ),
        },
        {
            title: t('casino_report_from_date'),
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (text: any) => {
                return <span>{text}</span>;
            },
            width: 165,
            fixed: true,
        },
        {
            title: t('casino_report_to_date'),
            dataIndex: 'toDate',
            key: 'toDate',
            render: (text: any) => <span>{text}</span>,
            width: 165,
        },
        {
            title: t('rounds'),
            dataIndex: 'spins',
            key: 'spins',
            sorter: true,
            width: 165,
        },
        {
            title: t('currency_filter'),
            dataIndex: 'currencyId',
            key: 'currencyId',
            render: (text: any) =>
                text === 0 ? t('all_consolidated') : currencyNameConverter.idToCode(`${text}`),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'bet',
            key: 'bet',
            sorter: true,
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'win',
            key: 'win',
            sorter: true,
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'net',
            key: 'net',
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('rtp')}
                    <span>%</span>
                </span>
            ),
            dataIndex: 'rtp',
            key: 'rtp',
            width: 165,
        },
        {
            title: t('free_round_spins'),
            dataIndex: 'freeRoundsSpins',
            key: 'freeRoundsSpins',
            sorter: true,
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsBet',
            key: 'freeRoundsBet',
            sorter: true,
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsNet',
            key: 'freeRoundsNet',
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_rtp')}
                    <span>%</span>
                </span>
            ),
            dataIndex: 'freeRoundsRtp',
            key: 'freeRoundsRtp',
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsWin',
            key: 'freeRoundsWin',
            sorter: true,
            width: 175,
        },
        {
            title: t('buy_feature_spins'),
            dataIndex: 'buyFeatureSpins',
            key: 'buyFeatureSpins',
            sorter: true,
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureBet',
            key: 'buyFeatureBet',
            sorter: true,
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureNet',
            key: 'buyFeatureNet',
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_rtp')}
                    <span>%</span>
                </span>
            ),
            dataIndex: 'buyFeatureRtp',
            key: 'buyFeatureRtp',
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureWin',
            key: 'buyFeatureWin',
            sorter: true,
            width: 175,
        },
    ];
};
