export const AddOperatorsTypes = {
    ADD_OPERATOR_NAME_REQUEST: 'ADD_OPERATOR_NAME_REQUEST',
    ADD_OPERATOR_NAME_SUCCESS: 'ADD_OPERATOR_NAME_SUCCESS',
    ADD_OPERATOR_NAME_FAILED: 'ADD_OPERATOR_NAME_FAILED',
    ADD_OPERATOR_GAMES_REQUEST: 'ADD_OPERATOR_GAMES_REQUEST',
    ADD_OPERATOR_GAMES_SUCCESS: 'ADD_OPERATOR_GAMES_SUCCESS',
    ADD_OPERATOR_GAMES_FAILED: 'ADD_OPERATOR_GAMES_FAILED',
    ADD_OPERATOR_CURRENCIES_REQUEST: 'ADD_OPERATOR_CURRENCIES_REQUEST',
    ADD_OPERATOR_CURRENCIES_SUCCESS: 'ADD_OPERATOR_CURRENCIES_SUCCESS',
    ADD_OPERATOR_CURRENCIES_FAILED: 'ADD_OPERATOR_CURRENCIES_FAILED',
    ADD_GAME_CONFIGURATIONS_REQUEST: 'ADD_GAME_CONFIGURATIONS_REQUEST',
    ADD_GAME_CONFIGURATIONS_SUCCESS: 'ADD_GAME_CONFIGURATIONS_SUCCESS',
    ADD_GAME_CONFIGURATIONS_FAILED: 'ADD_GAME_CONFIGURATIONS_FAILED',
    ADD_BRANDS_REQUEST: 'ADD_BRANDS_REQUEST',
    ADD_BRANDS_SUCCESS: 'ADD_BRANDS_SUCCESS',
    ADD_BRANDS_FAILED: 'ADD_BRANDS_FAILED',
    BACK_STEP_ACTION: 'BACK_STEP_ACTION',
};

export const addOperatorNameRequest = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_OPERATOR_NAME_REQUEST,
    payload,
});
export const addOperatorNameSuccess = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_OPERATOR_NAME_SUCCESS,
    payload,
});
export const addOperatorGamesRequest = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_OPERATOR_GAMES_REQUEST,
    payload,
});
export const addOperatorGamesSuccess = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_OPERATOR_GAMES_SUCCESS,
    payload,
});
export const addOperatorCurrenciesRequest = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_OPERATOR_CURRENCIES_REQUEST,
    payload,
});
export const addOperatorCurrenciesSuccess = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_OPERATOR_CURRENCIES_SUCCESS,
    payload,
});
export const addGameConfigurationsRequest = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_GAME_CONFIGURATIONS_REQUEST,
    payload,
});
export const addGameConfigurationsSuccess = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_GAME_CONFIGURATIONS_SUCCESS,
    payload,
});
export const addBrandsRequest = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_BRANDS_REQUEST,
    payload,
});
export const addBrandsSuccess = (payload?: any) => ({
    type: AddOperatorsTypes.ADD_BRANDS_SUCCESS,
    payload,
});
export const previousStep = (payload?: any) => ({
    type: AddOperatorsTypes.BACK_STEP_ACTION,
    payload,
});
