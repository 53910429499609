import * as React from 'react';
import './CustomButtonStyles.scss';

interface IProps {
    children: JSX.Element | string;
    onClick?: Function;
    className?: string;
    disabled?: boolean;
    small?: boolean;
    type?: 'button' | 'submit' | 'reset';
}

export const MainButton = (props: IProps) => {
    const {children, onClick, className, disabled, small, type} = props;

    return (
        <button
            disabled={disabled}
            type={type || 'button'}
            onClick={() => onClick && onClick()}
            className={`custom-button${
                small ? ' custom-button__small' : ''
            } custom-button__main${
                disabled ? ' custom-button__main__disabled' : ''
            }${className ? ` ${className}` : ''}`}
        >
            {children}
        </button>
    );
};

export const SecondaryButton = (props: IProps) => {
    const {children, onClick, className, disabled, small, type} = props;

    return (
        <button
            disabled={disabled}
            type={type || 'button'}
            onClick={() => onClick && onClick()}
            className={`custom-button${
                small ? ' custom-button__small' : ''
            } custom-button__secondary${
                disabled ? ' custom-button__secondary__disabled' : ''
            }${className ? ` ${className}` : ''}`}
        >
            {children}
        </button>
    );
};
