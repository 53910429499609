import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { Loader } from 'Components/Loader/Loader';
import { IConfig } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { CloseOutlined } from '@ant-design/icons/lib';
import { Link, Navigate } from 'react-router-dom';
import { currencyUtils, Messages } from 'utils';
import TextArea from 'antd/lib/input/TextArea';
import i18next from 'i18next';
import {
    getCurrencyConfiguration,
    updateCurrencyConfiguration,
} from 'redux/actions/configProvider/currencies-actions';
import { Footer } from 'Components/Footer/Footer';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import {
    getConfigUpdatingStatus,
    getCurrencyConfig,
} from 'redux/selectors/configProvider/currency-selectors';
import { ChangeEvent } from 'react';
import { withRouter } from '../../../../helpers/HOCs/withRouter';

interface IProps {
    data: IConfig[];
    params: any;
    getCurrencyConfig: Function;
    updateCurrencyConfig: Function;
    navigate: any;
    isConfigUpdated: boolean;
}

interface IState {
    config: string | null;
    isValid: boolean;
}

class EditCurrencyConfiguration extends React.Component<IProps & WithTranslation> {
    state: IState = {
        config: null,
        isValid: true,
    };

    componentDidUpdate = (prevProps: Readonly<IProps & WithTranslation>, prevState: IState) => {
        if (this.props.data) {
            const config = this.getDefaultValue(this.props.data);

            if (!prevState.config && prevState.config !== config) {
                this.setState({ config });
            }
        }
    };

    saveChanges = () => {
        const { params } = this.props;
        const { currencyId } = params;
        const { config } = this.state;

        if (config && config.length > 0) {
            const body: any = {
                currencyId,
                gameConfig: {},
            };

            let isValid = true;

            try {
                const parsedConfigs = JSON.parse(`{${config}}`);

                for (const item in parsedConfigs) {
                    if (Object.prototype.hasOwnProperty.call(parsedConfigs, item)) {
                        body.gameConfig[item] = JSON.stringify(parsedConfigs[item]);
                    }
                }
            } catch (e) {
                Messages.error(this.props.t('json_not_valid'));
                isValid = false;

                this.setState({ isValid: false });
            }

            isValid && this.props.updateCurrencyConfig(body);
        } else {
            Messages.success(i18next.t('changes_successfully_saved'));
        }
    };

    editConfig = (value: string) => {
        this.setState({ config: value });
    };

    getDefaultValue = (data: IConfig[]) => {
        let value = '';

        data.forEach((config: IConfig, i) => {
            value += `"${config.gameId}": ${config.gameConfig}${i < data.length - 1 ? ',\n' : ''}`;
        });

        return value;
    };

    componentDidMount = () => {
        this.props.getCurrencyConfig(this.props.params.currencyId);
    };

    render = () => {
        const { isValid, config } = this.state;
        const { t, params, isConfigUpdated } = this.props;
        const currencyName = currencyUtils.getCurrencyDataById(params.currencyId).name;

        return isConfigUpdated ? (
            <Navigate to={`/config-provider/currencies`} />
        ) : (
            <ContentComponent
                header={
                    <>
                        <div className="header-line cp-header-line">
                            <HeaderTitleComponent
                                title={t('edit_default_currency_configuration')}
                                customBreadcrumbs={
                                    <>
                                        <Link to={`/config-provider/currencies`}>
                                            {t('currencies')}
                                        </Link>{' '}
                                        / {currencyName}
                                    </>
                                }
                            />
                            <Link to={`/config-provider/currencies`}>
                                <CloseOutlined className="close-button" />
                            </Link>
                        </div>
                    </>
                }
                innerContent={
                    config !== null ? (
                        <>
                            <div className={'game-default-config'}>
                                <TextArea
                                    style={{ backgroundColor: '#0B1022' }}
                                    defaultValue={config}
                                    rows={20}
                                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                        this.editConfig(e.target.value);
                                    }}
                                />
                                <p className="error">{!isValid && t('json_not_valid')}</p>
                            </div>
                            <Footer>
                                <Link to={`/config-provider/currencies`}>
                                    <SecondaryButton>{t('cancel')}</SecondaryButton>
                                </Link>
                                <MainButton onClick={this.saveChanges}>{t('save')}</MainButton>
                            </Footer>
                        </>
                    ) : (
                        <Loader />
                    )
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    data: getCurrencyConfig(state),
    isConfigUpdated: getConfigUpdatingStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getCurrencyConfig: (data: any) => dispatch(getCurrencyConfiguration(data)),
    updateCurrencyConfig: (data: any) => dispatch(updateCurrencyConfiguration(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(EditCurrencyConfiguration)));
