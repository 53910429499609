import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';
import { Button, Select } from 'antd';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import './MothlyBillingStyles.scss';
import { getLoginRequestHeader } from 'utils';
import { getServerError } from 'redux/selectors/backoffice/error-selectors';
import { SERVER_URL } from '../../../utils/config';
import { useState } from 'react';
import dayjs from 'dayjs';

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const { Option } = Select;
const INITIAL_YEAR = 2019;

interface IProps extends WithTranslation {
    error: string;
    download: Function;
}

interface IOption {
    name: string;
    number: string;
    disabled: boolean;
}

const MonthlyBillingComponent: React.FC<IProps> = ({ t, error }) => {
    const [selectedYear, setSelectedYear] = useState<string>(dayjs().format('YYYY'));
    const [selectedMonth, setSelectedMonth] = useState<string>(
        dayjs().subtract(1, 'months').format('M'),
    );
    const [includeNonBillableCurrencies, setIncludeNonBillableCurrencies] =
        useState<boolean>(false);

    const changeYear = (year: string): void => {
        const currentYear = dayjs().format('YYYY');
        if (year === currentYear) {
            setSelectedYear(year);
            setSelectedMonth(dayjs().subtract(1, 'months').format('M'));
        } else {
            setSelectedYear(year);
        }
    };

    const changeMonth = (month: string): void => setSelectedMonth(month);

    const downloadAll = () => {
        const config = {
            method: 'GET',
            headers: getLoginRequestHeader('application/octet-stream'),
        };

        fetch(
            `${SERVER_URL}/monthly-billing/download?currencyOfRequest=978&month=${selectedMonth}&timeZone=Etc%2FUTC&year=${selectedYear}&includeNonBillableCurrencies=${includeNonBillableCurrencies}`,
            config,
        ).then((response) => {
            response.blob().then((blob) => {
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'report.zip';
                    a.click();
                }
            });
        });
    };

    const getYearOptions = (): JSX.Element[] => {
        const options: JSX.Element[] = [];
        const currentYear = Number(dayjs().format('YYYY'));
        for (let year = INITIAL_YEAR; year <= currentYear; year++) {
            options.push(
                <option key={year} value={year.toString()}>
                    {year.toString()}
                </option>,
            );
        }
        return options;
    };

    const monthToDisplay: IOption[] = monthNames.map(
        (month, i): IOption => ({
            name: month,
            number: (i + 1).toString(),
            disabled: Number(selectedYear) === dayjs().year() && dayjs().month() + 1 <= i + 1,
        }),
    );

    return (
        <ContentComponent
            header={
                <div className="header-line cp-header-line monthly-billing">
                    <HeaderTitleComponent title={t('billing')} />
                    <div className="cp-header-buttons">
                        <div className="cp-header-buttons__button">
                            <Select
                                className="monthly-billing__selector"
                                value={selectedYear}
                                onChange={(value: string) => changeYear(value)}
                            >
                                {getYearOptions()}
                            </Select>
                            <Select
                                className="monthly-billing__selector"
                                key={Math.random().toString()}
                                defaultValue={selectedMonth}
                                onChange={(value: string) => changeMonth(value)}
                            >
                                {monthToDisplay.map(({ number, name, disabled }) => (
                                    <Option value={number} disabled={disabled} key={name}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="monthly-billing__selector"
                                defaultValue={false}
                                onChange={(value: boolean) =>
                                    setIncludeNonBillableCurrencies(value)
                                }
                            >
                                <Option value={true}>All Currencies</Option>
                                <Option value={false}>Only Billable Currencies</Option>
                            </Select>
                            <Button type="primary" shape="round" onClick={downloadAll}>
                                {t('download_all')}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            innerContent={error ? <ErrorComponent error={error} /> : <></>}
        />
    );
};

const mapStateToProps = (state: any) => ({
    error: getServerError(state),
});

export default connect(mapStateToProps, null)(withTranslation()(MonthlyBillingComponent));
