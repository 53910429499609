import * as React from 'react';
import './MainHeaderStyles.scss';
import { Dropdown, Button } from 'antd';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { componentShouldBeDisplayed, localStorageHelper, projectDetection } from 'utils';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import SearchComponent from 'Components/SearchComponent/SearchComponent';
import * as i18next from 'i18next';
import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons/lib';
import { logout } from 'redux/actions/user-actions';
import { MenuProps } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { DownCircleOutlined } from '@ant-design/icons';

interface IProps {
    t: i18next.TFunction;
    logout: Function;
}

class MainHeader extends React.Component<IProps & WithTranslation> {
    logout = () => this.props.logout();

    getUserMenuItems = (t: i18next.TFunction): MenuProps['items'] => [
        ...(componentShouldBeDisplayed('settingsLink')
            ? [
                  {
                      key: 'settings',
                      label: (
                          <Link
                              id="button_settings"
                              className="dropdown-item"
                              to={`/casino/settings`}
                          >
                              <SettingOutlined className="header-dropdown__icon" />
                              {t('settings')}
                          </Link>
                      ),
                  },
              ]
            : []),
        {
            key: 'logout',
            label: (
                <div
                    id="button_logout"
                    className="dropdown-item"
                    onClick={() => {
                        this.logout();
                    }}
                >
                    <LogoutOutlined className="header-dropdown__icon" />
                    {t('log_out')}
                </div>
            ),
        },
    ];

    getNameForDropdown = () => {
        const name = localStorageHelper.getChainedValue('user.name') || '';
        const maxNameLength = 100;

        if (name.length > maxNameLength) {
            return `${name.substring(0, maxNameLength)}...`;
        }

        return name;
    };

    render = () => {
        const { t } = this.props;

        return (
            <Header className="main-header">
                {projectDetection.isBO() && <SearchComponent />}
                <div className={'main-header__dropdown-container'}>
                    <Dropdown
                        menu={{ items: this.getUserMenuItems(t) }}
                        placement="bottomLeft"
                        trigger={['click']}
                    >
                        <Button title={localStorageHelper.getChainedValue('user.name') || ''}>
                            <UserOutlined />
                            {this.getNameForDropdown()}
                            <DownCircleOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </Header>
        );
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logout: () => dispatch(logout()),
});

export default connect(null, mapDispatchToProps)(withTranslation()(MainHeader));
