import { ofType, StateObservable } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
    casinoPerformanceReportSuccess,
    CasinoPerformanceReportTypes,
} from '../../actions/backoffice/casino-performance-report-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
    currencyUtils,
    dateFormatter,
    getLoginRequestHeader,
    localStorageHelper,
} from '../../../utils';
import { of } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const casinoPerformanceEpic = (action$: Observable<IAction>, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(CasinoPerformanceReportTypes.CASINO_PERFORMANCE_REPORT_REQUEST),
        mergeMap((action) => {
            const requestBody = {
                includeTestPlayers:
                    localStorageHelper.getChainedValue('user.preferences.displayTestData') ||
                    state$.value.userReducer.shouldDisplayTestData,
                playerCurrencyIds: currencyUtils.getSelectedCurrenciesIdsData(),
                start: dateFormatter.getFormattedStartDate(action.payload.fromDate),
                end: dateFormatter.getFormattedEndDate(action.payload.toDate),
                timeBreakdown:
                    localStorageHelper.getChainedValue('user.reports.aggregationPeriod') || 'day',
                currencyBreakdown: localStorageHelper.getChainedValue(
                    'user.reports.filters.currenciesBreakdown',
                )
                    ? 'currency'
                    : 'none',
            };

            return from(
                axios.post(`${SERVER_URL}/reports/revenue/by-player-group`, requestBody, {
                    headers: getLoginRequestHeader('application/json'),
                }),
            ).pipe(
                map((response) => casinoPerformanceReportSuccess(response.data)),
                catchError((error) =>
                    of(
                        apiError(
                            error,
                            CasinoPerformanceReportTypes.CASINO_PERFORMANCE_REPORT_REQUEST,
                        ),
                    ),
                ),
            );
        }),
    );
