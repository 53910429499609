import * as React from 'react';
import { connect } from 'react-redux';
import '../../commonCPStyles.scss';
import { withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { Button, Table } from 'antd';
import { ICurrencyData } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';
import { getGameConfiguredCurrenciesRequest } from 'redux/actions/configProvider/currencies-actions';
import {
    getConfiguredCurrencies,
    getCurrencies,
    isAddingDefaultCurrencyFormLoading,
} from 'redux/selectors/configProvider/currency-selectors';
import { useEffect, useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { RootState } from '../../../redux/store';

interface IProps {
    data: ICurrencyData[];
    getConfiguredCurrencies: () => void;
    loading: boolean;
    t: (key: string) => string;
}

const Currencies: React.FC<IProps> = ({ data, getConfiguredCurrencies, loading, t }) => {
    useEffect(() => {
        getConfiguredCurrencies();
    }, [getConfiguredCurrencies]);

    const getData = useMemo(
        () =>
            data
                ? data.map((currencyData) => ({
                      ...currencyData,
                      key: currencyData.id,
                  }))
                : [],
        [data],
    );

    const columns: ColumnsType<ICurrencyData> = [
        {
            title: t('serial_number_title'),
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            title: t('name'),
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: t('code'),
            dataIndex: 'code',
            sorter: (a, b) => a.code.localeCompare(b.code),
        },
        {
            title: t('id'),
            dataIndex: 'id',
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: t('subunit'),
            dataIndex: 'subunit',
        },
        {
            title: t('actions'),
            dataIndex: 'actions',
            render: (text, record) => (
                <Link
                    key={record.id}
                    to={`/config-provider/currencies/${record.id}/currency-configuration`}
                >
                    <EditOutlined /> {t('edit')}
                </Link>
            ),
        },
    ];

    return (
        <ContentComponent
            header={
                <div className="header-line cp-header-line">
                    <HeaderTitleComponent
                        title={t('currencies')}
                        customBreadcrumbs={<div>{t('all')}</div>}
                    />
                    <div className="cp-header-buttons">
                        <div className="cp-header-buttons__button">
                            <Link
                                id="cp-header-button__wrapper"
                                to={`/config-provider/currencies/add-default-config`}
                            >
                                <Button shape="round" danger>
                                    <PlusCircleOutlined /> {t('add_default_config')}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            }
            innerContent={
                <Table
                    pagination={{ defaultPageSize: 15 }}
                    dataSource={getData}
                    columns={columns}
                    loading={loading}
                    size={'middle'}
                    footer={() => <></>}
                />
            }
        />
    );
};

const mapStateToProps = (state: RootState) => ({
    data: getCurrencies(state),
    configuredCurrencies: getConfiguredCurrencies(state),
    loading: isAddingDefaultCurrencyFormLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getConfiguredCurrencies: () => dispatch(getGameConfiguredCurrenciesRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Currencies));
