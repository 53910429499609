import { ICurrencyData, IAction, IBrandData, IOperatorGameData } from 'helpers/interfaces';
import { ManageBrandTypes } from 'redux/actions/configProvider/manage-brand-actions';
import { Messages, sort } from 'utils';
import i18next from 'i18next';
import { errorHandler } from '../../../helpers/errorHandler';

interface IManageBrand {
    step: number;
    loading?: boolean;

    brands?: IBrandData[];
    operatorBrandsCP?: IBrandData[];
    sortedOperatorBrandsCP?: IBrandData[];
    operatorGamesData?: IOperatorGameData[];
    operatorCurrencyIds?: number[];
    isBrandConfigUpdated?: boolean;

    games?: IOperatorGameData[];
    currencies?: ICurrencyData[];
    gameConfig?: any;
    credentialsLoading?: boolean;
    freeRoundsCredentials?: any;
}

export const manageBrandInitialState: IManageBrand = {
    step: 0,
    freeRoundsCredentials: {},
};

const manageBrandReducer = (state: IManageBrand = manageBrandInitialState, actions: IAction) => {
    switch (actions.type) {
        case ManageBrandTypes.GET_OPERATOR_BRANDS_REQUEST:
        case ManageBrandTypes.GET_OPERATOR_GAMES_REQUEST:
        case ManageBrandTypes.GET_OPERATOR_CURRENCIES_REQUEST:
        case ManageBrandTypes.ADD_BRAND_INFO_REQUEST:
        case ManageBrandTypes.UPDATE_BRAND_INFO_REQUEST:
        case ManageBrandTypes.UPDATE_BRAND_STATUS_REQUEST:
        case ManageBrandTypes.GET_BRAND_GAMES_REQUEST:
        case ManageBrandTypes.UPDATE_BRAND_GAMES_REQUEST:
        case ManageBrandTypes.GET_BRAND_CURRENCIES_REQUEST:
        case ManageBrandTypes.UPDATE_BRAND_CURRENCIES_REQUEST:
        case ManageBrandTypes.GET_BRAND_CONFIGS_REQUEST:
        case ManageBrandTypes.LOGOUT_ALL_BRAND_SESSIONS_REQUEST:
        case ManageBrandTypes.UPDATE_BRAND_CONFIGS_REQUEST: {
            return { ...state, loading: true };
        }

        case ManageBrandTypes.GENERATE_FREE_ROUNDS_CREDENTIALS_REQUEST: {
            return { ...state, credentialsLoading: true };
        }

        case ManageBrandTypes.SAVE_FREE_ROUNDS_CREDENTIALS_REQUEST: {
            return { ...state };
        }

        case ManageBrandTypes.GET_OPERATOR_BRANDS_SUCCESS: {
            return {
                ...state,
                loading: false,
                brands: actions.payload,
                operatorBrandsCP: actions.payload,
            };
        }

        case ManageBrandTypes.SORT_OPERATOR_BRANDS: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedOperatorBrandsCP: sortState
                    ? sort(actions.payload, state.operatorBrandsCP)
                    : [],
            };
        }

        case ManageBrandTypes.GET_OPERATOR_GAMES_SUCCESS: {
            return {
                ...state,
                loading: false,
                operatorGamesData: actions.payload,
            };
        }

        case ManageBrandTypes.GET_OPERATOR_CURRENCIES_SUCCESS: {
            return {
                ...state,
                loading: false,
                operatorCurrencyIds: actions.payload,
            };
        }

        case ManageBrandTypes.ADD_BRAND_INFO_SUCCESS: {
            Messages.success(i18next.t('brand_admin_account_was_successfully_created'));

            return {
                ...state,
                loading: false,
                step: 1,
                brands: [actions.payload],
            };
        }

        case ManageBrandTypes.UPDATE_BRAND_INFO_SUCCESS: {
            const { brands } = state;

            brands &&
                brands.forEach((brand: IBrandData, index: number) => {
                    if (brand.id === actions.payload.id) {
                        Object.assign(brands[index], actions.payload);
                    }
                });

            Messages.success(i18next.t('brand_status_was_successfully_updated'));

            return { ...state, loading: false, step: 1 };
        }

        case ManageBrandTypes.UPDATE_BRAND_STATUS_SUCCESS: {
            const { brands } = state;

            Messages.success(i18next.t('brand_status_has_been_successfully_updated'));

            brands &&
                brands.forEach((brand: IBrandData, index: number) => {
                    if (brand.id === actions.payload.id) {
                        Object.assign(brands[index], actions.payload);
                    }
                });

            return { ...state, loading: false, step: 0 };
        }

        case ManageBrandTypes.GET_BRAND_GAMES_SUCCESS: {
            return { ...state, loading: false, games: actions.payload };
        }

        case ManageBrandTypes.UPDATE_BRAND_GAMES_SUCCESS: {
            return { ...state, loading: false, step: 2 };
        }

        case ManageBrandTypes.GET_BRAND_CURRENCIES_SUCCESS: {
            return { ...state, loading: false, currencies: actions.payload };
        }

        case ManageBrandTypes.UPDATE_BRAND_CURRENCIES_SUCCESS: {
            return { ...state, loading: false, step: 3 };
        }

        case ManageBrandTypes.GET_BRAND_CONFIGS_SUCCESS: {
            return { ...state, loading: false, gameConfig: actions.payload };
        }

        case ManageBrandTypes.UPDATE_BRAND_CONFIGS_SUCCESS: {
            return {
                ...state,
                loading: false,
                step: 0,
                isBrandConfigUpdated: true,
            };
        }

        case ManageBrandTypes.LOGOUT_ALL_BRAND_SESSIONS_SUCCESS: {
            Messages.success(i18next.t('sessions_have_been_successfully_suspended'));

            return { ...state, loading: false };
        }

        case ManageBrandTypes.GENERATE_FREE_ROUNDS_CREDENTIALS_SUCCESS: {
            return {
                ...state,
                credentialsLoading: false,
                freeRoundsCredentials: actions.payload,
            };
        }

        case ManageBrandTypes.SAVE_FREE_ROUNDS_CREDENTIALS_SUCCESS: {
            Messages.success(i18next.t('free_rounds_credentials_have_been_successfully_created'));
            Messages.success(
                i18next.t(
                    'copy_this_password_the_only_way_to_see_the_password_is_to_generate_a_new_one',
                ),
            );

            return { ...state };
        }

        case ManageBrandTypes.GET_OPERATOR_GAMES_FAILED:
        case ManageBrandTypes.GET_OPERATOR_CURRENCIES_FAILED:
        case ManageBrandTypes.GET_OPERATOR_BRANDS_FAILED:
        case ManageBrandTypes.ADD_BRAND_INFO_FAILED:
        case ManageBrandTypes.UPDATE_BRAND_INFO_FAILED:
        case ManageBrandTypes.UPDATE_BRAND_STATUS_FAILED:
        case ManageBrandTypes.GET_BRAND_GAMES_FAILED:
        case ManageBrandTypes.UPDATE_BRAND_GAMES_FAILED:
        case ManageBrandTypes.GET_BRAND_CURRENCIES_FAILED:
        case ManageBrandTypes.UPDATE_BRAND_CURRENCIES_FAILED:
        case ManageBrandTypes.GET_BRAND_CONFIGS_FAILED:
        case ManageBrandTypes.LOGOUT_ALL_BRAND_SESSIONS_FAILED:
        case ManageBrandTypes.UPDATE_BRAND_CONFIGS_FAILED: {
            return { ...state, loading: false };
        }

        case ManageBrandTypes.GENERATE_FREE_ROUNDS_CREDENTIALS_FAILED: {
            Messages.error(errorHandler.getErrorText(actions.payload));

            return { ...state, credentialsLoading: false };
        }

        case ManageBrandTypes.BACK_STEP_ACTION: {
            return {
                ...state,
                step: state.step > 0 ? state.step - 1 : 0,
            };
        }

        case ManageBrandTypes.NEXT_STEP_ACTION: {
            return {
                ...state,
                step: state.step + 1,
            };
        }

        case ManageBrandTypes.RESET_STEP_ACTION: {
            return manageBrandInitialState;
        }

        default:
            return state;
    }
};

export default manageBrandReducer;
