import * as React from 'react';
import './Overlay.scss';

export const Overlay = ({ ...props }) => {
    const { isVisible, transparent, switchState } = props;

    if (isVisible) {
        if (document.body.clientHeight > window.innerHeight) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '16px';
        }

        return (
            <div
                className={`overlay visible${
                    transparent ? ' transparent' : ''
                }`}
                onClick={() => switchState && switchState()}
            />
        );
    } else {
        if (document.body.clientHeight > window.innerHeight) {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0';
        }

        return null;
    }
};
