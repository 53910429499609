import { createSelector } from 'reselect';

const getSearchResultData = (state: any) => state.searchReducer.searchResult;
const getSearchStringData = (state: any) => state.searchReducer.searchString;
const getSearchStatusData = (state: any) => state.searchReducer.isSearching;
const errorPlayerSearch = (state: any) => state.searchReducer.error;

export const getSearchResult = createSelector(
    getSearchResultData,
    (state) => state,
);
export const getSearchString = createSelector(
    getSearchStringData,
    (state) => state,
);
export const getSearchStatus = createSelector(
    getSearchStatusData,
    (state) => state,
);
export const getSearchError = createSelector(
    errorPlayerSearch,
    (state) => state,
);
