import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
    addBrandsSuccess,
    addGameConfigurationsSuccess,
    addOperatorCurrenciesSuccess,
    addOperatorGamesSuccess,
    addOperatorNameSuccess,
    AddOperatorsTypes,
} from '../../actions/configProvider/add-operator-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader, localStorageHelper } from '../../../utils';
import { of } from 'rxjs';
import { IAction, IBrandData } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const addOperatorNameEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AddOperatorsTypes.ADD_OPERATOR_NAME_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.post(`${SERVER_URL}/configuration/operator`, JSON.stringify(action.payload), {
                    headers,
                }),
            ).pipe(
                map((response) => addOperatorNameSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, AddOperatorsTypes.ADD_OPERATOR_NAME_REQUEST)),
                ),
            );
        }),
    );

export const addOperatorGamesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AddOperatorsTypes.ADD_OPERATOR_GAMES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.put(
                    `${SERVER_URL}/configuration/operator/games`,
                    JSON.stringify(action.payload),
                    {
                        headers,
                    },
                ),
            ).pipe(
                map((response) => addOperatorGamesSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, AddOperatorsTypes.ADD_OPERATOR_GAMES_REQUEST)),
                ),
            );
        }),
    );

export const addOperatorCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AddOperatorsTypes.ADD_OPERATOR_CURRENCIES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.put(
                    `${SERVER_URL}/configuration/operator/currencies`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map((response) => addOperatorCurrenciesSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, AddOperatorsTypes.ADD_OPERATOR_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );

export const addOperatorConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AddOperatorsTypes.ADD_GAME_CONFIGURATIONS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/operator/games-configuration`,
                    JSON.stringify(action.payload.requestBody),
                    { headers },
                ),
            ).pipe(
                map((response) => addGameConfigurationsSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, AddOperatorsTypes.ADD_GAME_CONFIGURATIONS_REQUEST)),
                ),
            );
        }),
    );

export const addOperatorBrandsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AddOperatorsTypes.ADD_BRANDS_REQUEST),
        mergeMap((action) => {
            const operatorData = localStorageHelper.getChainedValue('user.newOperator');
            const body = action.payload.map((brand: IBrandData) => ({
                currencyIds: operatorData.currencyIds,
                gameIds: operatorData.gameIds,
                operatorId: operatorData.id,
                name: brand.name,
                serviceLogin: brand.login,
                servicePassword: brand.password,
                inactivityTimerMinutes: Number(brand.inactivityTimerMinutes),
                serviceUrl: brand.url,
                freeRoundsEnabled: brand.freeRoundsEnabled,
                buyFeatureEnabled: brand.buyFeatureEnabled,
                geolocationEnabled: brand.geolocationEnabled,
                dynamicGameConfigurationEnabled: brand.dynamicGameConfigurationEnabled,
                singleSessionModeEnabled: brand.singleSessionModeEnabled,
            }));
            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.post(`${SERVER_URL}/configuration/brand/batch`, JSON.stringify(body), {
                    headers,
                }),
            ).pipe(
                map((response) => addBrandsSuccess(response.data)),
                catchError((error) => of(apiError(error, AddOperatorsTypes.ADD_BRANDS_REQUEST))),
            );
        }),
    );
