import * as React from 'react';
import './ContentComponent.scss';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';

interface IProps {
    innerContent: JSX.Element;
    header?: JSX.Element;
    preHeader?: JSX.Element;
    title?: string;
    footer?: JSX.Element;
    customBreadcrumbs?: JSX.Element;
    reportKey?: string;
    withFooter?: boolean;
}

export const ContentComponent = (props: IProps) => {
    const {
        reportKey,
        innerContent,
        title,
        header,
        preHeader,
        footer,
        withFooter,
        customBreadcrumbs,
    } = props;

    return (
        <div
            className={`content${footer || withFooter ? ' with-footer' : ''}${
                reportKey === 'playerProfile' ? ' players-report' : ''
            }`}
        >
            <div className="content-header">
                {header || (
                    <HeaderTitleComponent title={title} customBreadcrumbs={customBreadcrumbs} />
                )}
                {preHeader}
            </div>
            {innerContent}
            {footer}
        </div>
    );
};
