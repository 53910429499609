import { ofType } from 'redux-observable';
import { IAction } from '../../../helpers/interfaces';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { currencyUtils, getLoginRequestHeader } from '../../../utils';
import { from, Observable, of } from 'rxjs';
import {
    createPromotionSuccess,
    createPromotionWithCSVSuccess,
    deleteCampaignSuccess,
    editPromotionSuccess,
    editPromotionWithCSVSuccess,
    getCampaignSuccess,
    getPromotionsSuccess,
    PromotionTypes,
    terminateCampaignSuccess,
    uploadCSVSuccess,
} from '../../actions/backoffice/promotions-actions';
import axios from 'axios';
import { apiError } from '../../actions/user-actions';
import { SERVER_URL } from '../../../utils/config';

export const getPromotionsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_PROMOTIONS_REQUEST),
        mergeMap((action) => {
            const { paginationData, sortConfig, filterString, filterKey, dateRange } =
                action.payload;

            const params = new URLSearchParams();
            params.append('paging.pageNumber', paginationData.pageNumber.toString());
            params.append('paging.pageSize', paginationData.pageSize.toString());
            params.append('paging.sortBy', sortConfig.sortKey);
            params.append('paging.order', sortConfig.sortOrder);

            if (filterString) {
                params.append(filterKey, filterString);
            }
            if (dateRange[0]) {
                params.append('startDateFrom', dateRange[0].format('YYYY-MM-DD'));
            }
            if (dateRange[1]) {
                params.append('startDateTo', dateRange[1].format('YYYY-MM-DD'));
            }

            const headers = getLoginRequestHeader('application/json');

            return from(
                axios.get(`${SERVER_URL}/api/v1/brand/current/campaign`, {
                    params: params,
                    headers: headers,
                }),
            ).pipe(
                map((response) => getPromotionsSuccess(response.data)),
                catchError((error) => of(apiError(error, PromotionTypes.GET_PROMOTIONS_REQUEST))),
            );
        }),
    );

export const createPromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.CREATE_PROMOTION_REQUEST),
        mergeMap((action) => {
            const { payload } = action;

            const requestBody: any = {
                name: payload.campaign_name,
                gameIds: payload.game_name,
                startDate: payload.start_at,
                endDate: payload.end_at,
                timezone: payload.timezone,
                bet: payload.bet,
                currencyCode: payload.currency,
                betNumber: payload.number_of_free_rounds,
                eligiblePlayersNumber: payload.players,
                countries: payload.countries,
                expiresInDays: payload.expires_in_days,
                strategy: 'FIRST_N_PLAYERS',
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.post(`${SERVER_URL}/api/v1/brand/current/campaign`, requestBody, {
                    headers: headers,
                }),
            );

            return fromPromise.pipe(
                map((response) => createPromotionSuccess(response.data)),
                catchError((error) => of(apiError(error, PromotionTypes.CREATE_PROMOTION_REQUEST))),
            );
        }),
    );

export const editPromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.EDIT_PROMOTION_REQUEST),
        mergeMap((action) => {
            const { values } = action.payload;

            const requestBody: any = {
                name: values.campaign_name,
                gameIds: values.game_name,
                startDate: values.start_at,
                endDate: values.end_at,
                timezone: values.timezone,
                bet: values.bet,
                currencyCode: values.currency,
                betNumber: values.number_of_free_rounds,
                eligiblePlayersNumber: values.isFile ? null : values.players,
                countries: values.countries,
                expiresInDays: values.expires_in_days,
                strategy: values.isFile ? 'PLAYER_LIST' : 'FIRST_N_PLAYERS',
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.put(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}`,
                    requestBody,
                    {
                        headers: headers,
                    },
                ),
            );

            return fromPromise.pipe(
                map((response) => editPromotionSuccess(response.data)),
                catchError((error) => of(apiError(error, PromotionTypes.EDIT_PROMOTION_REQUEST))),
            );
        }),
    );

export const createPromotionWithCSVEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST),
        mergeMap((action) => {
            const { payload } = action;

            const requestBody: any = {
                name: payload.campaign_name,
                gameIds: payload.game_name,
                startDate: payload.start_at,
                endDate: payload.end_at,
                timezone: payload.timezone,
                bet: payload.bet,
                currencyCode: payload.currency,
                betNumber: payload.number_of_free_rounds,
                countries: payload.countries,
                expiresInDays: payload.expires_in_days,
                strategy: 'PLAYER_LIST',
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.post(`${SERVER_URL}/api/v1/brand/current/campaign`, requestBody, { headers }),
            );

            return fromPromise.pipe(
                map((response) =>
                    createPromotionWithCSVSuccess({
                        ...response.data,
                        file: action.payload.file,
                    }),
                ),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST)),
                ),
            );
        }),
    );

export const editPromotionWithCSVEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.EDIT_PROMOTION_WITH_CSV_REQUEST),
        mergeMap((action) => {
            const { values } = action.payload;

            const requestBody: any = {
                name: values.campaign_name,
                gameIds: values.game_name,
                startDate: values.start_at,
                endDate: values.end_at,
                timezone: values.timezone,
                bet: values.bet,
                currencyCode: values.currency,
                betNumber: values.number_of_free_rounds,
                countries: values.countries,
                expiresInDays: values.expires_in_days,
                strategy: 'PLAYER_LIST',
            };

            const headers = getLoginRequestHeader('application/json');

            const fromPromise = from(
                axios.put(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}`,
                    requestBody,
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) =>
                    editPromotionWithCSVSuccess({
                        ...response.data,
                        id: action.payload.id,
                        file: action.payload.values.file,
                    }),
                ),
                catchError((error) =>
                    of(apiError(error, PromotionTypes.EDIT_PROMOTION_WITH_CSV_REQUEST)),
                ),
            );
        }),
    );

export const uploadPromotionCSVEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(
            PromotionTypes.CREATE_PROMOTION_WITH_CSV_SUCCESS,
            PromotionTypes.EDIT_PROMOTION_WITH_CSV_SUCCESS,
        ),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();

            const fromPromise = from(
                axios.post(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}/participant-file`,
                    action.payload.file,
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) => uploadCSVSuccess(response.data)),
                catchError((error) => of(apiError(error, PromotionTypes.UPLOAD_CSV_REQUEST))),
            );
        }),
    );

export const getCampaignEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.GET_CAMPAIGN_REQUEST),
        mergeMap(async (action) => {
            const {
                campaignId,
                paginationData,
                sortKey,
                sortOrder,
                playerIdSearchValue,
                playersStatus,
                currency,
            } = action.payload;

            const headers = getLoginRequestHeader('application/json');

            try {
                let finalCurrency = currency;

                const campaignDataResponse = await axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}`,
                    { headers },
                );

                if (currency === 'user') {
                    finalCurrency = currencyUtils.getActiveCurrencyData().code;
                } else if (currency === 'campaign') {
                    finalCurrency = campaignDataResponse.data.currencyCode;
                }

                const summaryRequest = axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/summary`,
                    { headers, params: { currency: finalCurrency } },
                );

                const shouldRequestCsv = campaignDataResponse.data.strategy === 'PLAYER_LIST';
                const csvRequest = shouldRequestCsv
                    ? axios.get(
                          `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant/csv`,
                          { headers },
                      )
                    : Promise.resolve({ data: null });

                const playersRequest = axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${campaignId}/participant`,
                    {
                        headers,
                        params: {
                            currency: finalCurrency,
                            'paging.pageNumber': paginationData.pageNumber,
                            'paging.pageSize': paginationData.pageSize,
                            'paging.sortBy': sortKey,
                            'paging.order': sortOrder,
                            ...(playerIdSearchValue ? { playerId: playerIdSearchValue } : {}),
                            ...(playersStatus !== 'all_statuses' ? { status: playersStatus } : {}),
                        },
                    },
                );

                const [summaryResponse, csvResponse, playersResponse] = await Promise.all([
                    summaryRequest,
                    csvRequest,
                    playersRequest,
                ]);

                return getCampaignSuccess({
                    campaignData: campaignDataResponse.data,
                    summary: summaryResponse.data,
                    players: playersResponse.data,
                    csv: csvResponse.data,
                });
            } catch (error) {
                return apiError(error, PromotionTypes.GET_CAMPAIGN_REQUEST);
            }
        }),
    );

export const deletePromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.DELETE_CAMPAIGN_REQUEST),
        mergeMap(async (action) => {
            const headers = getLoginRequestHeader('application/json');

            try {
                const response = await axios.delete(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload}`,
                    { headers },
                );
                return deleteCampaignSuccess(response.data);
            } catch (error) {
                return apiError(error, PromotionTypes.DELETE_CAMPAIGN_REQUEST);
            }
        }),
    );

export const terminatePromotionEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PromotionTypes.TERMINATE_CAMPAIGN_REQUEST),
        mergeMap(async (action) => {
            const requestBody = {
                enabled: false,
                force: action.payload.force,
            };

            const headers = getLoginRequestHeader('application/json');

            try {
                const response = await axios.put(
                    `${SERVER_URL}/api/v1/brand/current/campaign/${action.payload.id}/enabled`,
                    requestBody,
                    { headers },
                );
                return terminateCampaignSuccess(response.data);
            } catch (error) {
                return apiError(error, PromotionTypes.TERMINATE_CAMPAIGN_REQUEST);
            }
        }),
    );
