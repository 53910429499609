import * as React from 'react';
import { IS_KOALA } from '../../utils/config';

export const LeftPart = () => {
    const currentYear = new Date().getFullYear();
    const companyName = IS_KOALA ? 'Koala Games' : 'RubyPlay';

    return (
        <div className="login-page__container login-page__container__red">
            <div className="login-page__logo-container" />
            <div className="login-page__copyright">
                <p className="copyright__text">
                    © 2017-{currentYear} {companyName}
                </p>
            </div>
        </div>
    );
};
