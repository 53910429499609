import { localStorageHelper, Messages, sort } from 'utils';
import { errorHandler } from 'helpers/errorHandler';
import { IAction, ICurrencyData } from 'helpers/interfaces';
import { CurrencyTypes } from 'redux/actions/configProvider/currencies-actions';
import i18next from 'i18next';
import dotProp from 'dot-prop-immutable';

export interface ICurrencies {
    currenciesCP?: ICurrencyData[] | null;
    configuredCurrencies?: number[];
    unconfiguredCurrencies?: number[];
    loading?: boolean;
    currentCurrencyConfig?: null | any[];
    configUpdatingStatus?: boolean;
    isNewConfigAdded?: boolean;
}

const currencyReducer = (state: ICurrencies = {}, actions: IAction) => {
    switch (actions.type) {
        case CurrencyTypes.ADD_CURRENCY_DEFAULT_CONFIG_REQUEST:
        case CurrencyTypes.GET_GAME_UNCONFIGURED_CURRENCIES_REQUEST:
        case CurrencyTypes.GET_GAME_CONFIGURED_CURRENCIES_REQUEST: {
            return { ...state, loading: true, isNewConfigAdded: false };
        }

        case CurrencyTypes.GET_GAME_UNCONFIGURED_CURRENCIES_SUCCESS: {
            return {
                ...state,
                unconfiguredCurrencies: actions.payload,
                loading: false,
            };
        }

        case CurrencyTypes.GET_GAME_CONFIGURED_CURRENCIES_SUCCESS: {
            // Converting actions.payload to a Set for faster lookups
            const payloadSet = new Set(actions.payload);

            return {
                ...state,
                configuredCurrencies: actions.payload,
                currenciesCP: localStorageHelper
                    .getChainedValue('user.allCurrencies')
                    .filter((currency: ICurrencyData) => payloadSet.has(currency.id)),
                loading: false,
                configUpdatingStatus: false,
            };
        }

        case CurrencyTypes.SORT_ALL_CURRENCIES: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedCurrenciesCP: sortState ? sort(actions.payload, state.currenciesCP) : [],
            };
        }

        case CurrencyTypes.ADD_CURRENCY_DEFAULT_CONFIG_SUCCESS: {
            Messages.success(i18next.t('currency_configuration_saved'));

            return { ...state, configUpdatingStatus: true, loading: false, isNewConfigAdded: true };
        }

        case CurrencyTypes.GET_GAME_UNCONFIGURED_CURRENCIES_FAILED:
        case CurrencyTypes.GET_GAME_CONFIGURED_CURRENCIES_FAILED: {
            Messages.error(errorHandler.getErrorText(actions.payload));

            return { ...state, loading: false };
        }

        case CurrencyTypes.ADD_CURRENCY_DEFAULT_CONFIG_FAILED: {
            //TODO: add specific error for this case
            Messages.error(i18next.t('configuration_could_not_saved'));

            return { ...state, loading: false };
        }

        case CurrencyTypes.GET_CURRENCY_CONFIGURATION_SUCCESS: {
            return dotProp.set(state, 'currentCurrencyConfig', actions.payload);
        }

        case CurrencyTypes.UPDATE_CURRENCY_CONFIGURATION_SUCCESS: {
            Messages.success(i18next.t('currency_config_successfully_updated'));

            return { ...state, configUpdatingStatus: true, loading: false };
        }

        case CurrencyTypes.UPDATE_CURRENCY_CONFIGURATION_FAILED: {
            Messages.error(i18next.t('currency_config_was_not_updated'));

            return { ...state, loading: false };
        }

        default:
            return state;
    }
};

export default currencyReducer;
