import { IAction } from 'helpers/interfaces';
import { dataFormatterUtils, localStorageHelper, sort } from 'utils';
import { CasinoPerformanceReportTypes } from 'redux/actions/backoffice/casino-performance-report-actions';

export interface ICasinoPerformanceData {
    players: number;
    newPlayers: number;
    spins: number;
    bet: any;
    win: any;
    net: any;
    rtp: number | null;
    freeRoundsBet: number | null;
    freeRoundsNet: number | null;
    freeRoundsRtp: number | null;
    freeRoundsSpins: number | null;
    freeRoundsWin: number | null;
    from: Date | string;
    to: Date | string;
    currencyId?: any;
}

export interface ICasinoPerformance {
    performance?: ICasinoPerformanceData[];
    sortedCasino: ICasinoPerformanceData[];
    total?: any;
}

export const casinoPerformance: ICasinoPerformance = {
    sortedCasino: [],
};

const casinoPerformanceReducer = (state = casinoPerformance, actions: IAction) => {
    switch (actions.type) {
        case CasinoPerformanceReportTypes.CASINO_PERFORMANCE_REPORT_REQUEST: {
            const { fromDate, toDate } = actions.payload;

            localStorageHelper.setChainedValue('user.reports.dates', {
                fromDate,
                toDate,
            });

            return casinoPerformance;
        }

        case CasinoPerformanceReportTypes.CASINO_PERFORMANCE_REPORT_SUCCESS: {
            const { content, total } = actions.payload;

            return {
                performance: content,
                sortedCasino: [],
                total,
            };
        }

        case CasinoPerformanceReportTypes.CASINO_REPORT_SORT: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedCasino: sortState
                    ? sort(actions.payload, dataFormatterUtils.getFormattedData(state.performance))
                    : [],
            };
        }

        default:
            return state;
    }
};

export default casinoPerformanceReducer;
