import * as React from 'react';
import './TabsStyles.scss';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { ITab } from 'helpers/interfaces';
import ItemComponentWrapper from '../ContentComponent/ItemComponentWrapper';

interface IProps {
    tabs: ITab[];
    data?: any;
    t: i18next.TFunction;
}

interface IState {
    activeTab: string;
}

class CustomTabs extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        activeTab: CustomTabs.setInitialTab(this.props),
    };

    static setInitialTab = (props: IProps) => {
        const hash = window.location.hash.slice(1);
        const activeTab = props.tabs.filter((tab: ITab) => tab.name === hash)
            .length
            ? hash
            : props.tabs[0].name;

        window.location.hash = activeTab;

        return activeTab;
    };

    getTabs = () => {
        const { t, tabs } = this.props;
        const { activeTab } = this.state;
        const result: JSX.Element[] = [];

        tabs.forEach((tab: ITab) => {
            result.push(
                <div
                    key={tab.name}
                    className={`tab__button ${
                        activeTab === tab.name ? 'active' : ''
                    }`}
                    onClick={() => this.setActiveTab(tab)}
                >
                    {t(tab.name)}
                </div>,
            );
        });

        return result;
    };

    setActiveTab = (tab: ITab) => {
        window.location.hash = tab.name;

        this.setState({ activeTab: tab.name });
    };

    getActiveTabElement = () => {
        const { tabs, data } = this.props;
        const { activeTab } = this.state;

        return React.cloneElement(
            tabs.filter((tab: ITab) => tab.name === activeTab)[0].element,
            {
                data,
            },
        );
    };

    render = () => (
        <div className="tab">
            <ItemComponentWrapper customClass={'tabs'}>
                <div className="tab__buttons">{this.getTabs()}</div>
            </ItemComponentWrapper>
            <div className="tab__content-wrapper">
                {this.getActiveTabElement()}
            </div>
        </div>
    );
}

export default connect(null, null)(withTranslation()(CustomTabs));
