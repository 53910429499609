import * as React from 'react';
import { Form, Modal, Radio } from 'antd';
import './UsersComponent.scss';
import * as i18next from 'i18next';
import { FormInstance } from 'antd/lib/form';

interface IProps {
    role: string;
    visible: boolean;
    onCancel: Function;
    onSubmit: Function;
    t: i18next.TFunction;
}

export class ChangeUserRolePopupComponent extends React.Component<IProps> {
    form: React.RefObject<FormInstance> = React.createRef<FormInstance>();

    onCancel = () => {
        this.props.onCancel();
    };

    onSubmit = () => {
        const { current } = this.form;

        if (current) {
            current
                .validateFields()
                .then((values: any) => {
                    current.resetFields();
                    this.props.onSubmit(values);
                })
                .catch();
        }
    };

    render = () => {
        const { role, visible, t } = this.props;

        return (
            <Modal
                className="changeUserRole"
                open={visible}
                title={t('edit_user_role')}
                okText={t('save')}
                onCancel={this.onCancel}
                onOk={this.onSubmit}
            >
                <Form layout="vertical" ref={this.form}>
                    <Form.Item
                        label={t('role')}
                        className="role"
                        name="role"
                        initialValue={role.toLowerCase()}
                        rules={[{ required: true }]}
                    >
                        <Radio.Group>
                            <Radio value="admin">{t('admin')}</Radio>
                            <Radio value="manager">{t('manager')}</Radio>
                            <Radio value="user">{t('user')}</Radio>
                            <Radio value="account_manager">{t('account_manager')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };
}
