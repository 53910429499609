import * as React from 'react';
import UsersComponent from '../Containers/Users/UsersComponent';
import SettingsComponent from '../Containers/Settings';
import CasinoReportComponent from '../Containers/Reports/CasinoReport';
import PlayersReportComponent from '../Containers/Reports/PlayersReport';
import GamesReportComponent from '../Containers/Reports/GamesReport';
import MonthlyBillingComponent from '../Containers/Reports/MonthlyBillingReport';
import ReportsComponent from '../Containers/Reports/ReportsComponent';
import SearchResultContainer from '../Containers/SearchResult';
import PlayerProfileComponent from '../Containers/PlayerProfile';
import PromotionPage from '../Containers/PlayerProfile/PromotionPage';
import { PageNotFoundComponent } from '../Containers/Errors/PageNotFoundComponent';
import DashboardMobileComponent from '../mobile/DashboardMobileComponent';
import BrokenRoundsPage from '../Containers/BrokenRoundsPage/BrokenRoundsPage';
import Operators from '../ConfigurationProvider/Lists/Operators';
import Games from '../ConfigurationProvider/Lists/Games';
import Currencies from '../ConfigurationProvider/Lists/Currencies';
import OperatorGameConfigurations from '../ConfigurationProvider/Lists/Operators/OperatorGameConfigurations/OperatorGameConfigurations';
import AddOperator from '../ConfigurationProvider/AddOperator';
import AddGame from '../ConfigurationProvider/AddGame';
import AddBrand from '../ConfigurationProvider/ManageBrands/AddBrand';
import EditBrand from '../ConfigurationProvider/ManageBrands/EditBrand';
import ManageGames from '../ConfigurationProvider/Lists/Operators/ManageGames/ManageGames';
import ManageCurrencies from '../ConfigurationProvider/Lists/Operators/ManageСurrencies/ManageCurrencies';
import EditDefaultConfig from '../ConfigurationProvider/Lists/Games/EditDefaultConfig/EditDefaultConfig';
import EditCurrencyConfiguration from '../ConfigurationProvider/Lists/Currencies/EditCurrencyConfiguration/EditCurrencyConfiguration';
import AddDefaultGameConfig from '../ConfigurationProvider/Lists/Currencies/AddDefaultGameConfig';
import OperatorPage from '../ConfigurationProvider/OperatorPage';
import ChecksumReportComponent from '../Containers/Reports/ChecksumReport';
import RoundInfoPage from '../Containers/RoundInfoPage/RoundInfoPage';
import UnfinishedRoundsPage from '../ConfigurationProvider/UnfinishedRounds/UnfinishedRoundsPage';
import Promotions from '../Containers/Promotions/PromotionsReport';
import CampaignControl from '../Containers/Promotions/PromotionControlPage/CampaignControl';
import ToolsMenu from '../ConfigurationProvider/Home/ToolsMenu';

export const routes = [
    {
        path: `/casino/reports/*`,
        belonging: ['backOffice'],
        innerComponent: <ReportsComponent />,
    },
    {
        path: `/casino/dashboard`,
        belonging: ['backOffice'],
        innerComponent: <DashboardMobileComponent />,
    },
    {
        path: `/casino/users`,
        belonging: ['backOffice'],
        innerComponent: <UsersComponent />,
    },
    {
        path: `/casino/settings`,
        belonging: ['backOffice'],
        innerComponent: <SettingsComponent />,
    },
    {
        path: `/casino/reports/casino-performance`,
        belonging: ['backOffice'],
        innerComponent: <CasinoReportComponent />,
    },
    {
        path: `/casino/reports/players-performance`,
        belonging: ['backOffice'],
        innerComponent: <PlayersReportComponent />,
    },
    {
        path: `/casino/reports/games-performance`,
        belonging: ['backOffice'],
        innerComponent: <GamesReportComponent />,
    },
    {
        path: `/casino/reports/critical-checksum`,
        belonging: ['backOffice', 'configProvider'],
        innerComponent: <ChecksumReportComponent />,
    },
    {
        path: `/casino/reports/monthly-billing-report`,
        belonging: ['backOffice'],
        innerComponent: <MonthlyBillingComponent />,
    },
    // {
    //     path: `/casino/players/:playerId/:login/:queryParams`,
    //     belonging: ['backOffice'],
    //     innerComponent: <PlayerProfileComponent />,
    // },
    {
        path: `/casino/players/:playerId/:login`,
        belonging: ['backOffice'],
        innerComponent: <PlayerProfileComponent />,
    },
    {
        path: `/casino/promotion/:queryParams`,
        belonging: ['backOffice'],
        innerComponent: <PromotionPage />,
    },
    {
        path: `/casino/promotions`,
        belonging: ['backOffice'],
        innerComponent: <Promotions />,
    },
    {
        path: `/casino/promotions/:campaignId`,
        belonging: ['backOffice'],
        innerComponent: <CampaignControl />,
    },
    {
        path: `/casino/search-result/:player`,
        belonging: ['backOffice'],
        innerComponent: <SearchResultContainer />,
    },
    {
        path: `/config-provider/tools/`,
        belonging: ['configProvider'],
        innerComponent: <ToolsMenu />,
    },
    {
        path: `/config-provider/operators/`,
        belonging: ['configProvider'],
        innerComponent: <Operators />,
    },
    {
        path: `/config-provider/operators/add-operator`,
        belonging: ['configProvider'],
        innerComponent: <AddOperator />,
    },
    {
        path: `/config-provider/operator/:operatorId`,
        belonging: ['configProvider'],
        innerComponent: <OperatorPage />,
    },
    {
        path: `/config-provider/operators/:operatorId/add-brand`,
        belonging: ['configProvider'],
        innerComponent: <AddBrand />,
    },
    {
        path: `/config-provider/operators/:operatorId/edit-brand`,
        belonging: ['configProvider'],
        innerComponent: <EditBrand />,
    },
    {
        path: `/config-provider/games`,
        belonging: ['configProvider'],
        innerComponent: <Games />,
    },
    {
        path: `/config-provider/round-info`,
        belonging: ['configProvider'],
        innerComponent: <RoundInfoPage />,
    },
    {
        path: `/config-provider/games/:gameId/game-configuration`,
        belonging: ['configProvider'],
        innerComponent: <EditDefaultConfig />,
    },
    {
        path: `/config-provider/currencies/:currencyId/currency-configuration`,
        belonging: ['configProvider'],
        innerComponent: <EditCurrencyConfiguration />,
    },
    {
        path: `/config-provider/operators/:operatorId/game-configurations`,
        belonging: ['configProvider'],
        innerComponent: <OperatorGameConfigurations />,
    },
    {
        path: `/config-provider/operators/:operatorId/manage-games`,
        belonging: ['configProvider'],
        innerComponent: <ManageGames />,
    },
    {
        path: `/config-provider/operators/:operatorId/manage-currencies`,
        belonging: ['configProvider'],
        innerComponent: <ManageCurrencies />,
    },
    {
        path: `/config-provider/games/add-game`,
        belonging: ['configProvider'],
        innerComponent: <AddGame />,
    },
    {
        path: `/config-provider/currencies`,
        belonging: ['configProvider'],
        innerComponent: <Currencies />,
    },
    {
        path: `/config-provider/currencies/add-default-config`,
        belonging: ['configProvider'],
        innerComponent: <AddDefaultGameConfig />,
    },
    {
        path: `/config-provider/broken-rounds/*`,
        belonging: ['configProvider'],
        innerComponent: <BrokenRoundsPage />,
    },
    {
        path: `/config-provider/unfinished-rounds/*`,
        belonging: ['configProvider'],
        innerComponent: <UnfinishedRoundsPage />,
    },
    {
        path: '*',
        belonging: ['configProvider', 'backOffice'],
        innerComponent: <PageNotFoundComponent />,
    },
];
