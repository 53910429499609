import { IAction } from 'helpers/interfaces';
import { Messages } from '../../../utils';
import i18next from 'i18next';
import { RoundInfoTypes } from '../../actions/configProvider/round-info-actions';

interface IRoundInfoReducer {
    data?: any;
}

const roundInfoData: IRoundInfoReducer = {
    data: null,
};

const roundInfoReducer = (state = roundInfoData, actions: IAction) => {
    switch (actions.type) {
        case RoundInfoTypes.ROUND_INFO_REQUEST: {
            return roundInfoData;
        }

        case RoundInfoTypes.ROUND_INFO_SUCCESS: {
            return {
                ...state,
                data: actions.payload,
            };
        }

        case RoundInfoTypes.ROUND_INFO_FAILED: {
            Messages.error(i18next.t('round_not_found'));

            return state;
        }

        default:
            return state;
    }
};

export default roundInfoReducer;
