import { createSelector } from 'reselect';
import { dataFormatterUtils, sort } from 'utils';

const getCasinoPerformanceData = (state: any) =>
    state.casinoPerformanceReducer.performance;
const getCasinoTotalLineData = (state: any) =>
    state.casinoPerformanceReducer.total;
const getSortedCasinoData = (state: any) =>
    state.casinoPerformanceReducer.sortedCasino;

// Report with raw order of data (as it comes from server)
export const getRawCasinoPerformanceData = getCasinoPerformanceData;

export const getCasinoPerformance = createSelector(
    getCasinoPerformanceData,
    (state) =>
        state &&
        sort(
            {
                keyToSort: 'from',
                sortState: 'desc',
            },
            dataFormatterUtils.getFormattedData(state),
        ),
);
export const getCasinoTotalLine = createSelector(
    getCasinoTotalLineData,
    (state) => state,
);
export const getSortedCasino = createSelector(
    getSortedCasinoData,
    (state) => state,
);
