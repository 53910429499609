import * as React from 'react';
import './TotalLineStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { currencyUtils, dateFormatter, getAbbreviatedValue } from 'utils';
import ItemComponentWrapper from '../ContentComponent/ItemComponentWrapper';
import { ITableData } from 'Containers/Reports/columnsData';

interface IProps {
    data: any;
    columns: any;
    currencyId?: number;
}

class TotalLineComponent extends React.Component<IProps & WithTranslation> {
    setTooltipToTitle = (title: string, tooltip: string, index: number) => (
        <Tooltip title={tooltip} id={`tooltip_${index}`}>
            <span>{title}</span>
        </Tooltip>
    );

    getInnerCellContent = (data: any, column: ITableData) => {
        let content: JSX.Element;
        const currency = currencyUtils.getActiveCurrencyData();
        const currencyCode = currency.code;

        if (column.key === 'from' || column.key === 'to') {
            content = <>{dateFormatter.getFormattedForReportDate(data[column.key])}</>;
        } else if (['net', 'bet', 'win', 'totalWin', 'totalBet'].includes(column.key)) {
            content = (
                <>
                    {getAbbreviatedValue(data[column.key], currencyCode, true, true)}{' '}
                    <span className="total-cell__currency">{currencyCode}</span>
                </>
            );
        } else if (column.key === 'rtp') {
            content = (
                <>
                    {getAbbreviatedValue(data[column.key], currencyCode, false, true)}
                    <span className="total-cell__currency">%</span>
                </>
            );
        } else {
            content = <>{getAbbreviatedValue(data[column.key], currencyCode, false, true)}</>;
        }

        return content;
    };

    getTotalLineData = () => {
        const { data, columns, t } = this.props;

        if (Object.keys(data).length > 0) {
            return columns.map((column: any, j: number) => {
                if (Object.keys(data).includes(column.key) && !column.frData && !column.bfData) {
                    return (
                        <div key={`${column}-${j}`} className="grand-total__cell total-cell">
                            <p className="total-cell__title">
                                {this.setTooltipToTitle(
                                    `${t(`${column.title}`)}`,
                                    `${t(`${column.tooltip}`)}`,
                                    j + 1,
                                )}
                            </p>
                            <p className={'total-cell__content'}>
                                {this.getInnerCellContent(data, column)}
                            </p>
                        </div>
                    );
                }
            });
        }
    };

    render = () => {
        const { data, columns } = this.props;

        return (
            <>
                {columns && data ? (
                    <ItemComponentWrapper titleKey={'grand_total'}>
                        <div className="grand-total">{this.getTotalLineData()}</div>
                    </ItemComponentWrapper>
                ) : null}
            </>
        );
    };
}

export default withTranslation()(TotalLineComponent);
