import * as React from 'react';
import './LoginComponentStyles.scss';
import { LeftPart } from './LoginLeftPart';
import LoginForm from './LoginForm';
import LoginLoader from 'Components/Loader/LoginLoader';
import { connect } from 'react-redux';
import { IUserState } from 'redux/reducers/user/user-reducer';
import { userState } from 'redux/selectors/user-selectors';

interface IProps {
    userState: IUserState;
}

class LoginComponent extends React.Component<IProps> {
    render = () => {
        const { isLoading } = this.props.userState;

        return (
            <>
                <div className="login-page">
                    <LoginLoader isLoading={isLoading} />
                    <LeftPart />
                    <LoginForm />
                </div>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    userState: userState(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
