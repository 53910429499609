import * as React from 'react';
import * as i18next from 'i18next';
import { CalendarOutlined } from '@ant-design/icons/lib';
import { ChangeEvent } from 'react';
import dayjs from 'dayjs';

interface IProps {
    period: periodKeys;
    t: i18next.TFunction;
    onChange: Function;
}

export type periodKeys =
    | 'today'
    | 'yesterday'
    | 'week_to_date'
    | 'month_to_date'
    | 'last_7_days'
    | 'last_30_days'
    | 'last_180_days';

export const periods = {
    today: [dayjs(), dayjs()],
    yesterday: [dayjs().subtract(1, 'd'), dayjs().subtract(1, 'd')],
    week_to_date: [dayjs().startOf('week'), dayjs()],
    month_to_date: [dayjs().startOf('month'), dayjs()],
    last_7_days: [dayjs().subtract(7, 'd'), dayjs().subtract(1, 'd')],
    last_30_days: [dayjs().subtract(30, 'd'), dayjs().subtract(1, 'd')],
    last_180_days: [dayjs().subtract(180, 'd'), dayjs().subtract(1, 'd')],
};

export const CalendarMobileComponent = (props: IProps) => {
    const { t, period } = props;
    const periodOptions = Object.keys(periods) as periodKeys[];
    const onChange = (e: ChangeEvent<HTMLSelectElement>) => props.onChange(e.target.value);

    return (
        <div className="dashboard__item-wrapper">
            <div className="dashboard__item calendar">
                <select
                    className="selector"
                    value={period}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e)}
                >
                    {periodOptions.map((key: periodKeys) => (
                        <option key={key} value={key}>
                            {t(key)}
                        </option>
                    ))}
                </select>
                <div className="selected_key">
                    <CalendarOutlined />
                    {t(period)}
                </div>
            </div>
        </div>
    );
};
