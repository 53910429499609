import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SERVER_URL } from '../../../utils/config';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import {
    addBrandDataSuccess,
    generateFreeRoundsCredentialsSuccess,
    getBrandConfigsSuccess,
    getBrandCurrenciesSuccess,
    getBrandGamesSuccess,
    getOperatorBrandsSuccess,
    getOperatorCurrenciesSuccess,
    getOperatorGamesSuccess,
    ManageBrandTypes,
    saveFreeRoundsCredentialsSuccess,
    updateBrandConfigsSuccess,
    updateBrandCurrenciesSuccess,
    updateBrandDataSuccess,
    updateBrandGamesSuccess,
    updateBrandStatusSuccess,
} from '../../actions/configProvider/manage-brand-actions';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';

export const getOperatorBrandsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.GET_OPERATOR_BRANDS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/operator/brands?operatorId=${action.payload}`,
                    {
                        headers,
                    },
                ),
            ).pipe(
                map(({ data }) => getOperatorBrandsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.GET_OPERATOR_BRANDS_REQUEST)),
                ),
            );
        }),
    );

export const getOperatorGameIdsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.GET_OPERATOR_GAMES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/operator/games?operatorId=${action.payload}`,
                    {
                        headers,
                    },
                ),
            ).pipe(
                map(({ data }) => getOperatorGamesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.GET_OPERATOR_GAMES_REQUEST)),
                ),
            );
        }),
    );

export const getOperatorCurrencyIdsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.GET_OPERATOR_CURRENCIES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/operator/currencies?operatorId=${action.payload}`,
                    {
                        headers,
                    },
                ),
            ).pipe(
                map(({ data }) => getOperatorCurrenciesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.GET_OPERATOR_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );

export const addNewBrandEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.ADD_BRAND_INFO_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.post(`${SERVER_URL}/configuration/brand`, JSON.stringify(action.payload), {
                    headers,
                }),
            ).pipe(
                map(({ data }) => addBrandDataSuccess(data)),
                catchError((error) => of(apiError(error, ManageBrandTypes.ADD_BRAND_INFO_REQUEST))),
            );
        }),
    );

export const updateBrandDataEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.UPDATE_BRAND_INFO_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(`${SERVER_URL}/configuration/brand`, JSON.stringify(action.payload), {
                    headers,
                }),
            ).pipe(
                map(({ data }) => updateBrandDataSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.UPDATE_BRAND_INFO_REQUEST)),
                ),
            );
        }),
    );

export const updateBrandStatusEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.UPDATE_BRAND_STATUS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(`${SERVER_URL}/configuration/brand`, JSON.stringify(action.payload), {
                    headers,
                }),
            ).pipe(
                map(({ data }) => updateBrandStatusSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.UPDATE_BRAND_STATUS_REQUEST)),
                ),
            );
        }),
    );

export const getBrandGamesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.GET_BRAND_GAMES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(`${SERVER_URL}/configuration/brand/games?brandId=${action.payload}`, {
                    headers,
                }),
            ).pipe(
                map(({ data }) => getBrandGamesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.GET_BRAND_GAMES_REQUEST)),
                ),
            );
        }),
    );

export const updateBrandGamesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.UPDATE_BRAND_GAMES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/brand/games`,
                    JSON.stringify(action.payload),
                    {
                        headers,
                    },
                ),
            ).pipe(
                map(({ data }) => updateBrandGamesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.UPDATE_BRAND_GAMES_REQUEST)),
                ),
            );
        }),
    );

export const getBrandCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.GET_BRAND_CURRENCIES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/brand/currencies?brandId=${action.payload}`,
                    {
                        headers,
                    },
                ),
            ).pipe(
                map(({ data }) => getBrandCurrenciesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.GET_BRAND_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );

export const updateBrandCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.UPDATE_BRAND_CURRENCIES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/brand/currencies`,
                    JSON.stringify(action.payload),
                    {
                        headers,
                    },
                ),
            ).pipe(
                map(({ data }) => updateBrandCurrenciesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.UPDATE_BRAND_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );

export const getBrandConfigsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.GET_BRAND_CONFIGS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/brand/applied-game-configuration?brandId=${action.payload}`,
                    { headers },
                ),
            ).pipe(
                map(({ data }) => getBrandConfigsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.GET_BRAND_CONFIGS_REQUEST)),
                ),
            );
        }),
    );

export const updateBrandConfigsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.UPDATE_BRAND_CONFIGS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/brand/games-configuration`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map(({ data }) => updateBrandConfigsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, ManageBrandTypes.UPDATE_BRAND_CONFIGS_REQUEST)),
                ),
            );
        }),
    );

export const generateFreeRoundsCredentialsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.GENERATE_FREE_ROUNDS_CREDENTIALS_REQUEST),
        mergeMap(async (action) => {
            const headers = getLoginRequestHeader('application/json');

            try {
                const response = await axios.post(
                    `${SERVER_URL}/public-api/credentials-issuance`,
                    action.payload,
                    { headers },
                );
                return generateFreeRoundsCredentialsSuccess(response.data);
            } catch (error) {
                return apiError(error, ManageBrandTypes.GENERATE_FREE_ROUNDS_CREDENTIALS_REQUEST);
            }
        }),
    );

export const saveFreeRoundsCredentialsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ManageBrandTypes.SAVE_FREE_ROUNDS_CREDENTIALS_REQUEST),
        mergeMap(async (action) => {
            const headers = getLoginRequestHeader('application/json');

            try {
                const response = await axios.put(
                    `${SERVER_URL}/public-api/credentials-issuance/${action.payload}/confirmed`,
                    null,
                    { headers },
                );
                return saveFreeRoundsCredentialsSuccess(response.data);
            } catch (error) {
                return apiError(error, ManageBrandTypes.SAVE_FREE_ROUNDS_CREDENTIALS_REQUEST);
            }
        }),
    );
