import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { Button, Input } from 'antd';
import './LoginFormStyles.scss';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { EyeOutlined, InfoCircleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons/lib';
import { changeDefaultPassword, login } from 'redux/actions/user-actions';
import { defaultPasswordChanged, passwordChangeRequired } from 'redux/selectors/user-selectors';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import CryptoJS from 'crypto-js';

interface IProps {
    login: Function;
    passwordChangeRequired: boolean;
    defaultPasswordChanged: boolean;
    changePassword: Function;
}

interface InitialValues {
    email: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
    rememberMe: boolean;
}

interface LoginInputProps {
    name: string;
    errors: any;
    touched: any;
}

const LoginInputField = ({ name, errors, touched }: LoginInputProps) => {
    const [showText, setShowText] = useState(false);
    const isEmail = name === 'email';
    const inputType = isEmail || showText ? 'text' : 'password';
    const { t } = useTranslation();

    return (
        <>
            <Field name={name}>
                {({ field }: any) => (
                    <div className="input-container">
                        {isEmail ? <UserOutlined className="input-container__icon" /> : null}
                        {name === 'password' ? (
                            <LockOutlined className="input-container__icon" />
                        ) : null}
                        <Input
                            {...field}
                            className="input-container__input"
                            type={inputType}
                            placeholder={t('login_' + name)}
                        />
                        {isEmail ? null : showText ? (
                            <EyeInvisibleOutlined
                                className="input-container__icon__right"
                                onClick={() => setShowText((prevState) => !prevState)}
                            />
                        ) : (
                            <EyeOutlined
                                className="input-container__icon__right"
                                onClick={() => setShowText((prevState) => !prevState)}
                            />
                        )}
                    </div>
                )}
            </Field>
            {errors[name] && touched[name] ? (
                <p id={'login_error_message_empty_' + name} className="login-form__error-message">
                    {errors[name]}
                </p>
            ) : null}
        </>
    );
};

const LoginForm = ({ passwordChangeRequired, login, changePassword }: IProps) => {
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('invalid_email_address')).required(t('email_is_required')),
        password: !passwordChangeRequired
            ? Yup.string().required(t('password_is_required'))
            : Yup.string().notRequired(),
        newPassword: passwordChangeRequired
            ? Yup.string()
                  .min(8, t('pass_8_chars'))
                  .matches(/[A-Z]/, t('pass_capital'))
                  .matches(/[!@#$%^&*(),.?":{}|<>]/, t('pass_special'))
                  .matches(/[0-9]/, t('pass_number'))
                  .matches(/^\S*$/, t('pass_spaces'))
                  .required(t('new_password_is_required'))
            : Yup.string().notRequired(),
        confirmPassword: passwordChangeRequired
            ? Yup.string()
                  .required(t('password_confirmation_is_required'))
                  .oneOf([Yup.ref('newPassword')], t('passwords_must_match'))
            : Yup.string().notRequired(),
    });
    const [initialValues, setInitialValues] = useState<InitialValues>({
        email: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
        rememberMe: false,
    });
    const saveCredentials = (email: string, password: string) => {
        const keySize = 256 / 32;
        const key = CryptoJS.lib.WordArray.random(keySize).toString(CryptoJS.enc.Hex);
        const text = `${email}:${CryptoJS.AES.encrypt(password, key).toString()}:${key}`;
        const expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 1);
        document.cookie = `rp_remember_me=${text};expires=${expiryDate.toUTCString()};path=/;secure`;
    };
    const retrieveCredentials = () => {
        const cookie = document.cookie.match(`(^|;)\\s*${'rp_remember_me'}\\s*=\\s*([^;]+)`);

        if (cookie) {
            const cookieValue = cookie[2];
            const [email, encryptedPassword, encryptionKeyString] = cookieValue.split(':');
            const decryptedPassword = CryptoJS.AES.decrypt(
                encryptedPassword,
                encryptionKeyString,
            ).toString(CryptoJS.enc.Utf8);

            setInitialValues((prevState: InitialValues) => ({
                ...prevState,
                email,
                password: decryptedPassword,
                rememberMe: true,
            }));
        }
    };
    const removeCookie = () => {
        document.cookie = 'rp_remember_me=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;secure';
    };

    const onSubmit = (
        { email, password, newPassword, rememberMe }: InitialValues,
        { setFieldValue }: any,
    ) => {
        if (passwordChangeRequired) {
            changePassword({ email, oldPassword: password, newPassword });
            setFieldValue('password', '');
            setFieldValue('newPassword', '');
            setFieldValue('confirm', '');
        } else {
            if (rememberMe) {
                saveCredentials(email, password);
            } else {
                removeCookie();
            }
            login({ email, password });
        }
    };

    useEffect(() => {
        retrieveCredentials();
    }, []);

    return (
        <>
            <div className="login-page__container login-page__container__gray">
                <div className="login-page__form">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {({ errors, touched, submitForm, setFieldValue, values }) => (
                            <Form className="login-form">
                                <h2 className="login-form__title">{t('welcome_back')}</h2>
                                <LoginInputField {...{ errors, touched }} name={'email'} />

                                {passwordChangeRequired ? (
                                    <>
                                        <div className="login-form__newPass-message-wrapper">
                                            <InfoCircleOutlined />
                                            <p className="login-form__newPass-message">
                                                {t('change_default_password')}
                                            </p>
                                        </div>
                                        <LoginInputField
                                            {...{ errors, touched }}
                                            name={'newPassword'}
                                        />
                                        <LoginInputField
                                            {...{ errors, touched }}
                                            name={'confirmPassword'}
                                        />
                                    </>
                                ) : (
                                    <LoginInputField {...{ errors, touched }} name={'password'} />
                                )}
                                <label className="checkbox-container login-form__checkbox-container">
                                    <input
                                        type="checkbox"
                                        onChange={(e) =>
                                            setFieldValue('rememberMe', e.target.checked)
                                        }
                                        className="login-form__checkbox"
                                        checked={values.rememberMe}
                                    />
                                    <p className="checkbox-container__text">{t('remember_me')}</p>
                                    <span className="checkmark" />
                                </label>
                                <Button
                                    id="button_login"
                                    shape="round"
                                    type="primary"
                                    htmlType="button"
                                    onClick={submitForm}
                                >
                                    {t('login_submit')}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className="login-page__copyright__mobile">
                <p className="copyright__text">© 2017-2023 RubyPlay</p>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    passwordChangeRequired: passwordChangeRequired(state),
    defaultPasswordChanged: defaultPasswordChanged(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    login: (params: any) => dispatch(login(params)),
    changePassword: (params: any) => dispatch(changeDefaultPassword(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
