import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './UsersComponent.scss';
import { IUser } from 'Containers/Users/UsersComponent';
import {
    CheckCircleOutlined,
    DownOutlined,
    EditOutlined,
    LockOutlined,
    StopOutlined,
} from '@ant-design/icons/lib';
import { Button, Dropdown, Menu } from 'antd';

interface IUserActionsProps {
    user: IUser;
    onChangeName: () => void;
    onChangeRole: () => void;
    onChangePassword: () => void;
    onBlockUser: () => void;
    onUnblockUser: () => void;
}

const UserActionsComponent: React.FC<IUserActionsProps> = ({
    user,
    onChangeName,
    onChangeRole,
    onChangePassword,
    onBlockUser,
    onUnblockUser,
}) => {
    const { t } = useTranslation();
    const { blocked } = user;
    const changeStatusText = t(blocked ? 'activate_account' : 'block_account');
    const changeStatusIcon = blocked ? <CheckCircleOutlined /> : <StopOutlined />;

    const menu = (
        <Menu>
            <Menu.Item onClick={onChangeName}>
                <EditOutlined />
                {t('edit_user_name')}
            </Menu.Item>
            <Menu.Item onClick={onChangeRole}>
                <EditOutlined />
                {t('edit_user_role')}
            </Menu.Item>
            <Menu.Item onClick={onChangePassword}>
                <LockOutlined />
                {t('change_password')}
            </Menu.Item>
            <Menu.Item onClick={blocked ? onUnblockUser : onBlockUser}>
                {changeStatusIcon}
                {changeStatusText}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <Button>
                Actions <DownOutlined />
            </Button>
        </Dropdown>
    );
};

export default UserActionsComponent;
