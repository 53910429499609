import { IAction } from 'helpers/interfaces';
import { GeneralConfigProviderTypes } from 'redux/actions/configProvider/general-actions';

export interface IUserCPData {
    isAuthorised: boolean;
    authorities: string[];
    name?: string;
    email?: string;
    role?: string;
    defaultConfiguredCurrencies?: any[];
}

export const userCPInitialState: IUserCPData = {
    isAuthorised: false,
    authorities: [],
};

const generalConfigProviderReducer = (
    state: IUserCPData = userCPInitialState,
    actions: IAction,
) => {
    switch (actions.type) {
        case GeneralConfigProviderTypes.GET_DEFAULT_CONFIGURED_CURRENCIES_SUCCESS: {
            return { ...state, defaultConfiguredCurrencies: actions.payload };
        }

        case GeneralConfigProviderTypes.GET_DEFAULT_GAME_CONFIGS_FAILED:
        case GeneralConfigProviderTypes.GET_DEFAULT_CONFIGURED_CURRENCIES_FAILED: {
            return state;
        }

        default:
            return state;
    }
};

export default generalConfigProviderReducer;
