import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
    changeOperatorStatusSuccess,
    getAllOperatorsSuccess,
    getOperatorGameConfigurationsSuccess,
    OE_getOperatorCurrenciesSuccess,
    OE_getOperatorGamesSuccess,
    OperatorsTypes,
    refreshServersSuccess,
    updateOperatorCurrenciesSuccess,
    updateOperatorGameConfigurationsSuccess,
    updateOperatorGamesSuccess,
} from '../../actions/configProvider/operators-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader, localStorageHelper } from '../../../utils';
import { of } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const operatorsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.GET_ALL_OPERATORS_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(axios.get(`${SERVER_URL}/configuration/operator/all`, { headers })).pipe(
                map(({ data }) => {
                    localStorageHelper.setChainedValue('user.operators', data);
                    return getAllOperatorsSuccess(data);
                }),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.GET_ALL_OPERATORS_REQUEST)),
                ),
            );
        }),
    );

export const changeOperatorStatusEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.CHANGE_OPERATOR_STATUS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/operator/change-status?enabled=${action.payload.enabled}&operatorId=${action.payload.id}`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map(({ data }) => changeOperatorStatusSuccess(data)),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.CHANGE_OPERATOR_STATUS_REQUEST)),
                ),
            );
        }),
    );

export const refreshServersEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.REFRESH_SERVERS_REQUEST),
        mergeMap(() => {
            const headers = getLoginRequestHeader();
            return from(axios.get(`${SERVER_URL}/configuration/refresh`, { headers })).pipe(
                map(() => refreshServersSuccess()),
                catchError((error) => of(apiError(error, OperatorsTypes.REFRESH_SERVERS_REQUEST))),
            );
        }),
    );

export const getOperatorGamesConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.GET_OPERATOR_GAME_CONFIGS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/operator/games-configuration-or-null?operatorId=${action.payload}`,
                    { headers },
                ),
            ).pipe(
                map(({ data }) => getOperatorGameConfigurationsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.GET_OPERATOR_GAME_CONFIGS_REQUEST)),
                ),
            );
        }),
    );

export const updateOperatorConfigurationsEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.UPDATE_OPERATOR_GAME_CONFIGS_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/operator/games-configuration`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map(({ data }) => updateOperatorGameConfigurationsSuccess(data)),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.UPDATE_OPERATOR_GAME_CONFIGS_REQUEST)),
                ),
            );
        }),
    );

export const getOperatorGamesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.GET_OPERATORS_GAMES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/operator/games?operatorId=${action.payload}`,
                    { headers },
                ),
            ).pipe(
                map(({ data }) => OE_getOperatorGamesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.GET_OPERATORS_GAMES_REQUEST)),
                ),
            );
        }),
    );

export const updateOperatorGamesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.UPDATE_OPERATORS_GAMES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/operator/games`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map(({ data }) => updateOperatorGamesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.UPDATE_OPERATORS_GAMES_REQUEST)),
                ),
            );
        }),
    );

export const getOperatorCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.GET_OPERATORS_CURRENCIES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader();
            return from(
                axios.get(
                    `${SERVER_URL}/configuration/operator/currencies?operatorId=${action.payload}`,
                    { headers },
                ),
            ).pipe(
                map(({ data }) => OE_getOperatorCurrenciesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.GET_OPERATORS_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );

export const updateOperatorCurrenciesEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(OperatorsTypes.UPDATE_OPERATORS_CURRENCIES_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.put(
                    `${SERVER_URL}/configuration/operator/currencies`,
                    JSON.stringify(action.payload),
                    { headers },
                ),
            ).pipe(
                map(({ data }) => updateOperatorCurrenciesSuccess(data)),
                catchError((error) =>
                    of(apiError(error, OperatorsTypes.UPDATE_OPERATORS_CURRENCIES_REQUEST)),
                ),
            );
        }),
    );
