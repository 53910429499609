export const PlayersReportTypes = {
    PLAYERS_REPORT_REQUEST: 'PLAYERS_REPORT_REQUEST',
    PLAYERS_REPORT_SUCCESS: 'PLAYERS_REPORT_SUCCESS',
    PLAYERS_REPORT_FAILED: 'PLAYERS_REPORT_FAILED',
};

export const getPlayersReport = (payload?: any) => ({
    type: PlayersReportTypes.PLAYERS_REPORT_REQUEST,
    payload,
});
export const playersReportSuccess = (payload?: any) => ({
    type: PlayersReportTypes.PLAYERS_REPORT_SUCCESS,
    payload,
});
