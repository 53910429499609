import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import './AddOperatorStyles.scss';
import StepAddOperator from '../AddOperator/StepAddOperator';
import StepManageGames from '../AddOperator/StepManageGames';
import StepManageCurrencies from '../AddOperator/StepManageCurrencies';
import StepEditGameConfigurations from '../AddOperator/StepEditGameConfigurations';
import StepAddBrands from '../AddOperator/StepAddBrands';
import { Dispatch } from 'redux';
import * as i18next from 'i18next';
import { localStorageHelper } from 'utils';
import { previousStep } from 'redux/actions/configProvider/add-operator-actions';
import Stepper from 'Components/CustomStepper';
import { Loader } from 'Components/Loader/Loader';
import {
    getCurrentAddOperatorStep,
    isLoadingOnAddingOperator,
    isOperatorLoaded,
} from 'redux/selectors/configProvider/add-operator-selectors';
import { withRouter } from '../../helpers/HOCs/withRouter';

interface IProps {
    loading: boolean;
    loaded: boolean;
    step: number;
    previousStep: Function;
    t: i18next.TFunction;
    navigate: any;
}

interface IState {
    currentStep: number;
}

export const STEPS: string[] = [
    'add_operator_info',
    'manage_games',
    'manage_currencies',
    'edit_game_configurations',
    'add_brands',
];

class AddOperator extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        currentStep: localStorageHelper.getChainedValue('user.newOperator.step') || 0,
    };

    getCurrentStepComponent = () => {
        const props = { previousStep: this.previousStep };
        const stepComponents = [
            <StepAddOperator key={'step-0'} {...props} />,
            <StepManageGames key={'step-1'} {...props} />,
            <StepManageCurrencies key={'step-2'} {...props} />,
            <StepEditGameConfigurations key={'step-3'} {...props} />,
            <StepAddBrands key={'step-4'} {...props} />,
        ];

        return stepComponents[this.state.currentStep];
    };

    nextStep = () => this.setState((state) => ({ currentStep: state.currentStep + 1 }));

    previousStep = () => {
        this.setState((state) => ({ currentStep: state.currentStep - 1 }));
        this.props.previousStep();
    };

    componentDidUpdate = (prevProps: IProps) => {
        const { step, loaded, navigate } = this.props;

        if (step !== prevProps.step) {
            this.setState({ currentStep: step });
        }

        if (loaded) {
            navigate(`/config-provider/operators`);
        }
    };

    render = () => {
        const { t, loading } = this.props;

        return (
            <ContentComponent
                withFooter
                header={
                    <HeaderTitleComponent
                        title={t('new_operator')}
                        customBreadcrumbs={<div>{t('operators')}</div>}
                    />
                }
                innerContent={
                    <>
                        <Stepper steps={STEPS} currentStep={this.state.currentStep} />
                        {loading ? <Loader /> : this.getCurrentStepComponent()}
                    </>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    step: getCurrentAddOperatorStep(state),
    loading: isLoadingOnAddingOperator(state),
    loaded: isOperatorLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    previousStep: () => dispatch(previousStep()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(AddOperator)));
