export const PromotionTypes = {
    GET_PROMOTIONS_REQUEST: 'GET_PROMOTIONS_REQUEST',
    GET_PROMOTIONS_SUCCESS: 'GET_PROMOTIONS_SUCCESS',
    GET_PROMOTIONS_FAILED: 'GET_PROMOTIONS_FAILED',
    CREATE_PROMOTION_REQUEST: 'CREATE_PROMOTION_REQUEST',
    CREATE_PROMOTION_SUCCESS: 'CREATE_PROMOTION_SUCCESS',
    CREATE_PROMOTION_FAILED: 'CREATE_PROMOTION_FAILED',
    EDIT_PROMOTION_REQUEST: 'EDIT_PROMOTION_REQUEST',
    EDIT_PROMOTION_SUCCESS: 'EDIT_PROMOTION_SUCCESS',
    EDIT_PROMOTION_FAILED: 'EDIT_PROMOTION_FAILED',
    CREATE_PROMOTION_WITH_CSV_REQUEST: 'CREATE_PROMOTION_WITH_CSV_REQUEST',
    CREATE_PROMOTION_WITH_CSV_SUCCESS: 'CREATE_PROMOTION_WITH_CSV_SUCCESS',
    CREATE_PROMOTION_WITH_CSV_FAILED: 'CREATE_PROMOTION_WITH_CSV_FAILED',
    EDIT_PROMOTION_WITH_CSV_REQUEST: 'EDIT_PROMOTION_WITH_CSV_REQUEST',
    EDIT_PROMOTION_WITH_CSV_SUCCESS: 'EDIT_PROMOTION_WITH_CSV_SUCCESS',
    EDIT_PROMOTION_WITH_CSV_FAILED: 'EDIT_PROMOTION_WITH_CSV_FAILED',
    UPLOAD_CSV_REQUEST: 'UPLOAD_CSV_REQUEST',
    UPLOAD_CSV_SUCCESS: 'UPLOAD_CSV_SUCCESS',
    UPLOAD_CSV_FAILED: 'UPLOAD_CSV_FAILED',
    GET_CAMPAIGN_REQUEST: 'GET_CAMPAIGN_REQUEST',
    GET_CAMPAIGN_SUCCESS: 'GET_CAMPAIGN_SUCCESS',
    GET_CAMPAIGN_FAILED: 'GAT_CAMPAIGN_FAILED',
    DELETE_CAMPAIGN_REQUEST: 'DELETE_CAMPAIGN_REQUEST',
    DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
    DELETE_CAMPAIGN_FAILED: 'DELETE_CAMPAIGN_FAILED',
    TERMINATE_CAMPAIGN_REQUEST: 'TERMINATE_CAMPAIGN_REQUEST',
    TERMINATE_CAMPAIGN_SUCCESS: 'TERMINATE_CAMPAIGN_SUCCESS',
    TERMINATE_CAMPAIGN_FAILED: 'TERMINATE_CAMPAIGN_FAILED',
    CLEAR_CAMPAIGN_DATA_REQUEST: 'CLEAR_CAMPAIGN_DATA_REQUEST',
};

export const getPromotions = (payload?: any) => ({
    type: PromotionTypes.GET_PROMOTIONS_REQUEST,
    payload,
});
export const getPromotionsSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_PROMOTIONS_SUCCESS,
    payload,
});
export const createPromotion = (payload?: any) => ({
    type: PromotionTypes.CREATE_PROMOTION_REQUEST,
    payload,
});
export const createPromotionSuccess = (payload?: any) => ({
    type: PromotionTypes.CREATE_PROMOTION_SUCCESS,
    payload,
});
export const editPromotion = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_REQUEST,
    payload,
});
export const editPromotionSuccess = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_SUCCESS,
    payload,
});
export const createPromotionWithCSV = (payload?: any) => ({
    type: PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST,
    payload,
});
export const createPromotionWithCSVSuccess = (payload?: any) => ({
    type: PromotionTypes.CREATE_PROMOTION_WITH_CSV_SUCCESS,
    payload,
});
export const editPromotionWithCSV = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_WITH_CSV_REQUEST,
    payload,
});
export const editPromotionWithCSVSuccess = (payload?: any) => ({
    type: PromotionTypes.EDIT_PROMOTION_WITH_CSV_SUCCESS,
    payload,
});
export const uploadCSV = (payload?: any) => ({
    type: PromotionTypes.UPLOAD_CSV_REQUEST,
    payload,
});
export const uploadCSVSuccess = (payload?: any) => ({
    type: PromotionTypes.UPLOAD_CSV_SUCCESS,
    payload,
});
export const getCampaign = (payload?: any) => ({
    type: PromotionTypes.GET_CAMPAIGN_REQUEST,
    payload,
});
export const getCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.GET_CAMPAIGN_SUCCESS,
    payload,
});
export const deleteCampaign = (payload?: any) => ({
    type: PromotionTypes.DELETE_CAMPAIGN_REQUEST,
    payload,
});
export const clearCampaignData = (payload?: any) => ({
    type: PromotionTypes.CLEAR_CAMPAIGN_DATA_REQUEST,
    payload,
});
export const deleteCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.DELETE_CAMPAIGN_SUCCESS,
    payload,
});
export const terminateCampaign = (payload?: any) => ({
    type: PromotionTypes.TERMINATE_CAMPAIGN_REQUEST,
    payload,
});
export const terminateCampaignSuccess = (payload?: any) => ({
    type: PromotionTypes.TERMINATE_CAMPAIGN_SUCCESS,
    payload,
});
