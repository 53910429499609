import { createSelector } from 'reselect';
import { dataFormatterUtils } from 'utils';

const isPlayersReportLoadingData = (state: any) => state.playersReportReducer.isLoading;
const getPlayersData = (state: any) => state.playersReportReducer.players;
const getPlayersTotalLineData = (state: any) => state.playersReportReducer.total;
const getPlayersTotalItemsData = (state: any) => state.playersReportReducer.totalItems;

export const getPlayers = createSelector(
    getPlayersData,
    (state) => state && dataFormatterUtils.getFormattedData(state),
);
export const getPlayersTotalLine = createSelector(getPlayersTotalLineData, (state) => state);
export const isPlayersReportLoading = createSelector(isPlayersReportLoadingData, (state) => state);
export const getPlayersTotalItems = createSelector(getPlayersTotalItemsData, (state) => state);
