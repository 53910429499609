import { localStorageHelper } from '../../../utils';

import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';

interface PromotionLimits {
    maxCampaignDurationHours: number;
    maxCampaignEndDateDays: number;
    maxFreeSpinsPerPlayer: number;
    maxParticipantFileUploadLimitMb: number;
    maxParticipantFileUploadLimitRows: number;
    maxPlayersPerFirstNPlayersCampaign: number;
    maxPlayersPerPlayerListCampaign: number;
}

const defaultPromotionLimits: PromotionLimits = {
    maxCampaignDurationHours: 8784,
    maxCampaignEndDateDays: 366,
    maxFreeSpinsPerPlayer: 100,
    maxParticipantFileUploadLimitMb: 5,
    maxParticipantFileUploadLimitRows: 20000,
    maxPlayersPerFirstNPlayersCampaign: 3000,
    maxPlayersPerPlayerListCampaign: 20000,
};

const promotionLimits: any =
    localStorageHelper.getChainedValue('user.brandFeatures.promotionLimits') ||
    defaultPromotionLimits;

export const validationSchema = (t: TFunction, isEdit: boolean) =>
    Yup.object().shape({
        campaign_name: Yup.string()
            .trim('name_should_not_start_or_end_with_a_space')
            .strict(true)
            .required(t('campaign_name_is_required')),
        game_name: Yup.array()
            .of(Yup.string().required(t('game_name_is_required')))
            .min(1, t('please_select_at_least_one_option')),
        start_at: Yup.string()
            .required(t('start_date_is_required'))
            .test('is-valid-date', t('start_date_is_invalid'), (value) => !isNaN(Date.parse(value)))
            .test('is-future-date', t('start_date_must_be_in_the_future'), function (value) {
                const { timezone } = this.parent;
                const selectedDate = DateTime.fromISO(value, { zone: timezone });

                return selectedDate > DateTime.local().setZone(timezone);
            }),
        end_at: Yup.string()
            .required(t('end_date_is_required'))
            .test('is-valid-date', t('end_date_is_invalid'), (value) => !isNaN(Date.parse(value)))
            .test('is-greater', t('end_date_should_be_later_than_start_date'), function (value) {
                const { start_at, timezone } = this.parent;
                if (!start_at || !value) return true;
                const startDate = DateTime.fromISO(start_at, { zone: timezone });
                const endDate = DateTime.fromISO(value, { zone: timezone });
                return endDate > startDate.plus({ hour: 1 });
            })
            .test('within-max-end-date', t('end_date_exceeds_max_limit'), function (value) {
                if (!value) return true;
                const { start_at, timezone } = this.parent;
                const startDate = DateTime.fromISO(start_at, { zone: timezone });
                const endDate = DateTime.fromISO(value, { zone: timezone });

                return (
                    endDate <= startDate.plus({ hours: promotionLimits.maxCampaignDurationHours })
                );
            }),
        countries: Yup.array()
            .of(Yup.string().required(t('please_select_at_least_one_option')))
            .min(1, t('please_select_at_least_one_option')),
        isFile: Yup.boolean(),
        players: Yup.number().when('isFile', {
            is: false,
            then: () =>
                Yup.number()
                    .required(t('players_amount_is_required'))
                    .positive(t('players_should_be_positive_number'))
                    .max(
                        promotionLimits.maxPlayersPerFirstNPlayersCampaign,
                        t('players_exceed_limit'),
                    ),
            otherwise: () =>
                Yup.number()
                    .nullable()
                    .max(
                        promotionLimits.maxPlayersPerPlayerListCampaign,
                        t('players_exceed_limit'),
                    ),
        }),
        file: Yup.mixed().when('isFile', ([isFile], schema: Yup.MixedSchema) =>
            isFile && !isEdit ? schema.required('csv_file_is_required') : schema.nullable(),
        ),
        expires_in_days: Yup.number()
            .positive(t('expires_in_should_be_positive_number'))
            .max(promotionLimits.maxCampaignEndDateDays, t('expires_in_exceeds_max_days_limit')),
        bet: Yup.number()
            .required(t('bet_is_required_field'))
            .positive(t('bet_should_be_positive_number')),
        number_of_free_rounds: Yup.number()
            .required(t('number_of_free_rounds_is_required_field'))
            .positive(t('number_of_free_rounds_should_be_positive_number'))
            .max(promotionLimits.maxFreeSpinsPerPlayer, t('number_of_free_rounds_exceed_limit')),
    });
