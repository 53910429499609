import { dataFormatterUtils, dateFormatter, localStorageHelper, sort } from 'utils';
import { IAction } from 'helpers/interfaces';
import { GamesReportTypes } from 'redux/actions/backoffice/games-report-actions';

export interface IGameData {
    gameId: number;
    players: number;
    spins: number;
    bet: any;
    win: any;
    net: any;
    rtp: number;
    freeRoundsBet: number | null;
    freeRoundsNet: number | null;
    freeRoundsRtp: number | null;
    freeRoundsSpins: number | null;
    freeRoundsWin: number | null;
    gamesDeviceType: string;
    fromDate: string;
    toDate: string;
    gameName?: string;
    currencyId: number;
}

export interface IGame {
    games?: IGameData[];
    sortedGames: IGameData[];
    total?: any;
}

export const gamesInitialState: IGame = {
    sortedGames: [],
};

const gamesReportReducer = (state = gamesInitialState, actions: IAction) => {
    switch (actions.type) {
        case GamesReportTypes.GAMES_REPORT_REQUEST: {
            const { fromDate, toDate } = actions.payload;

            localStorageHelper.setChainedValue('user.reports.dates', {
                fromDate,
                toDate,
            });

            return gamesInitialState;
        }

        case GamesReportTypes.GAMES_REPORT_SUCCESS: {
            const { content, total } = actions.payload;
            const dates = localStorageHelper.getChainedValue('user.reports.dates');
            const fromDate = (dates && dates.fromDate) || dateFormatter.getDefaultDate();
            const toDate = (dates && dates.toDate) || dateFormatter.getDefaultDate(false);
            const deviceType =
                localStorageHelper.getChainedValue('user.reports.filters.deviceType') || 'all';

            content.forEach((game: IGameData) => {
                game.fromDate = dateFormatter.getFormattedForReportDate(fromDate);
                game.toDate = dateFormatter.getFormattedForReportDate(toDate);
                game.gamesDeviceType = deviceType;
            });

            return {
                games: content,
                total,
                sortedGames: [],
            };
        }

        case GamesReportTypes.GAMES_REPORT_SORT: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedGames: sortState
                    ? sort(actions.payload, dataFormatterUtils.getFormattedData(state.games))
                    : [],
            };
        }

        default:
            return state;
    }
};

export default gamesReportReducer;
