import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';
import { Switch } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import { IGameItemData, IOperatorGameData } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined } from '@ant-design/icons/lib';
import { Link, Navigate } from 'react-router-dom';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { localStorageHelper, CPUtils } from 'utils';
import {
    getOperatorGames,
    updateOperatorGames,
} from 'redux/actions/configProvider/operators-actions';
import { Footer } from 'Components/Footer/Footer';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import {
    getCurrentOperatorGames,
    getOperatorGamesUpdateStatus,
} from 'redux/selectors/configProvider/operators-selectors';
import { CP_OperatorManageGamesData, ITableData } from '../../../../Containers/Reports/columnsData';
import { withRouter } from '../../../../helpers/HOCs/withRouter';

interface IProps {
    data: IOperatorGameData[];
    error: string;
    params: any;
    getOperatorGames: Function;
    updateOperatorGames: Function;
    isOperatorGamesUpdated: boolean;
}

interface IState {
    games: IGameItemData[];
    isSubmitEnabled: boolean;
    keyToSort: string;
    sortState: 'asc' | 'desc';
    isModalVisible: boolean;
}

class ManageGames extends React.Component<IProps & WithTranslation> {
    state: IState = {
        games: [],
        isSubmitEnabled: false,
        keyToSort: 'status',
        sortState: 'asc',
        isModalVisible: false,
    };

    componentDidUpdate = (prevProps: Readonly<IProps & WithTranslation>, prevState: IState) => {
        if (this.props.data && !prevState.games.length) {
            let isSubmitEnabled = false;
            const allGames = localStorageHelper.getChainedValue('user.allGames');
            const games = allGames.map((gameInStorage: IGameItemData) => {
                let status = false;
                let enabledUnderOperator = false;

                this.props.data &&
                    this.props.data.forEach((gameInProps: IOperatorGameData) => {
                        if (gameInStorage.id.toString() === gameInProps.gameCode.split('_')[1]) {
                            if (!gameInProps.frozen) {
                                status = true;
                            }
                            enabledUnderOperator = true;
                            isSubmitEnabled = true;
                            gameInStorage.freeRoundsEnabled = gameInProps.freeRoundsEnabled;
                            gameInStorage.buyFeatureEnabled = gameInProps.buyFeatureEnabled;
                        }
                    });
                gameInStorage.enabledUnderOperator = enabledUnderOperator;
                gameInStorage.status = status;

                return gameInStorage;
            });

            this.setState({ games, isSubmitEnabled });
        }
    };

    sortStateHandler = (key: string) => {
        const { keyToSort, sortState } = this.state;

        if (key === keyToSort) {
            if (sortState === 'asc') {
                this.setState({ sortState: 'desc' });
            } else {
                this.setState({ sortState: 'asc' });
            }
        } else {
            this.setState({ keyToSort: key, sortState: 'asc' });
        }
    };

    getSortedData = (data: IGameItemData[]) => {
        const { keyToSort, sortState } = this.state;

        return data.sort((a: any, b: any) => {
            if (a[keyToSort]! > b[keyToSort]!) {
                return sortState === 'asc' ? 1 : -1;
            } else {
                if (b[keyToSort]! > a[keyToSort]!) {
                    return sortState === 'asc' ? -1 : 1;
                } else {
                    return 0;
                }
            }
        });
    };

    disableAll = () => {
        const { games } = this.state;

        games.forEach((game: IGameItemData, index: number) => (games[index].status = false));

        this.setState({ games, isSubmitEnabled: false });
    };

    enableAll = () => {
        const { games } = this.state;

        games.forEach((game: IGameItemData, index: number) => (games[index].status = true));

        this.setState({ games, isSubmitEnabled: true });
    };

    onChange = (checked: boolean, index: number) => {
        let isSubmitEnabled = false;
        const { games } = this.state;

        games[index].status = checked;

        games.forEach((game: IGameItemData) => {
            if (game.status) {
                isSubmitEnabled = true;
            }
        });

        this.setState({ games, isSubmitEnabled });
    };

    onChangeFreeRounds = (checked: boolean, index: number) => {
        let isSubmitEnabled = false;
        const { games } = this.state;

        games[index].freeRoundsEnabled = checked;

        games.forEach((game: IGameItemData) => {
            if (game.freeRoundsEnabled) {
                isSubmitEnabled = true;
            }
        });

        this.setState({ games, isSubmitEnabled });
    };

    onChangeBuyFeature = (checked: boolean, index: number) => {
        const { games } = this.state;

        games[index].buyFeatureEnabled = checked;

        this.setState({ games });
    };

    getTable = (originData: IGameItemData[]) => {
        const data = this.getSortedData(originData);
        const { keyToSort, sortState } = this.state;
        const { t } = this.props;

        return (
            <ItemComponentWrapper customClass="cp-table__wrapper">
                <table className="cp-table">
                    <thead className="cp-table__header">
                        <tr>
                            {CP_OperatorManageGamesData.map((column: ITableData) => (
                                <th key={column.key} className="cp-table-cell">
                                    <div className="cell-inner">
                                        <div className="cell-inner__content">{t(column.title)}</div>
                                        {[
                                            'id',
                                            'name',
                                            'freeRoundsEnabled',
                                            'buyFeatureEnabled',
                                            'status',
                                        ].includes(column.key) ? (
                                            <div
                                                className={`sorter${
                                                    keyToSort && keyToSort === column.key
                                                        ? ` ${sortState}`
                                                        : ''
                                                }`}
                                                onClick={() => this.sortStateHandler(column.key)}
                                            >
                                                <CaretUpOutlined />
                                                <CaretDownOutlined />
                                            </div>
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="cp-table__body">
                        {data.map((game: IGameItemData, index: number) => (
                            <tr key={game.name}>
                                <td className="cp-table__cell cp-table__cell__number">
                                    {index + 1}
                                </td>
                                <td className="cp-table__cell cp-table__cell__name">{game.name}</td>
                                <td className="cp-table__cell cp-table__cell__id">{game.id}</td>
                                <td className="cp-table__cell">
                                    <Switch
                                        checked={game.status}
                                        onChange={(checked: boolean) =>
                                            this.onChange(checked, index)
                                        }
                                    />
                                </td>
                                <td className="cp-table__cell">
                                    <Switch
                                        checked={game.freeRoundsEnabled}
                                        onChange={(checked: boolean) =>
                                            this.onChangeFreeRounds(checked, index)
                                        }
                                    />
                                </td>
                                <td className="cp-table__cell">
                                    <Switch
                                        checked={game.buyFeatureEnabled}
                                        onChange={(checked: boolean) =>
                                            this.onChangeBuyFeature(checked, index)
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ItemComponentWrapper>
        );
    };

    saveChanges = () => {
        const { operatorId } = this.props.params;
        const games: any[] = [];

        this.state.games.forEach((game: IGameItemData) => {
            if (game.enabledUnderOperator || game.status) {
                games.push({
                    id: game.id,
                    freeRoundsEnabled: game.freeRoundsEnabled,
                    buyFeatureEnabled: game.buyFeatureEnabled,
                    frozen: !game.status,
                });
            }
        });

        const body = {
            games,
            operatorId,
        };

        this.props.updateOperatorGames(body);
    };

    componentDidMount = () => {
        const { params, getOperatorGames } = this.props;

        getOperatorGames(params.operatorId);
    };

    render = () => {
        const { games, isSubmitEnabled } = this.state;
        const { t, error, params, isOperatorGamesUpdated } = this.props;
        const operatorName = CPUtils.operatorIdToName(params.operatorId);

        return isOperatorGamesUpdated ? (
            <Navigate to={`/config-provider/operators`} />
        ) : (
            <ContentComponent
                header={
                    <>
                        <div className="header-line cp-header-line">
                            <HeaderTitleComponent
                                title={t('manage_games')}
                                customBreadcrumbs={
                                    <>
                                        <Link to={`/config-provider/operators`}>
                                            {t('operators')}
                                        </Link>
                                        <span className="breadcrumbs-separator">/</span>
                                        <Link to={`/config-provider/operator/${params.operatorId}`}>
                                            {operatorName}
                                        </Link>
                                    </>
                                }
                            />
                            <Link to={`/config-provider/operators`}>
                                <CloseOutlined className="close-button" />
                            </Link>
                        </div>
                        <div className="cp-operator-upper-buttons">
                            <div className="btn white" onClick={this.enableAll}>
                                {t('enable_all')}
                            </div>
                            <div className="btn white" onClick={this.disableAll}>
                                {t('disable_all')}
                            </div>
                        </div>
                    </>
                }
                innerContent={
                    error ? (
                        <ErrorComponent error={error} />
                    ) : games && games.length ? (
                        this.getTable(games)
                    ) : (
                        <Loader />
                    )
                }
                footer={
                    <Footer>
                        <Link to={`/config-provider/operators`} className="cp-table-footer__item">
                            <SecondaryButton>{t('cancel')}</SecondaryButton>
                        </Link>
                        <MainButton
                            disabled={!isSubmitEnabled}
                            onClick={() => isSubmitEnabled && this.saveChanges()}
                        >
                            {t('save')}
                        </MainButton>
                    </Footer>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    data: getCurrentOperatorGames(state),
    isOperatorGamesUpdated: getOperatorGamesUpdateStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperatorGames: (data: any) => dispatch(getOperatorGames(data)),
    updateOperatorGames: (data: any) => dispatch(updateOperatorGames(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(ManageGames)));
