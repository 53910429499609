import React from 'react';
import { localStorageHelper } from 'utils';
import { Loader } from 'Components/Loader/Loader';
import { Overlay } from 'Components/Overlay/Overlay';
import * as i18next from 'i18next';
import { CloseCircleOutlined } from '@ant-design/icons/lib';
import {Dispatch} from 'redux';
import {getGameHistoryUrl} from 'redux/actions/backoffice/game-history-actions';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'helpers/HOCs/withRouter';
import {getHistoryUrl} from 'redux/selectors/backoffice/game-history-selectors';

interface IProps {
    roundId: string,
    getGameHistoryUrl: Function;
    url: string;
    onClose: () => void;
    t: i18next.TFunction;
    error: string;
}

class OPAGameHistory extends React.Component<IProps, {}> {
    iframe: any;

    componentDidMount = () => {
        this.props.getGameHistoryUrl({
            roundId: this.props.roundId,
            langCode: localStorageHelper.getChainedValue('user.preferences.gh_language',) || 'en'
        });
    };

    resizeFrame = () => {
        if (this.iframe) {
            setTimeout(() => this.resizeFrame(), 100);
        }
    };

    render = () => {
        const { url, onClose, t, error } = this.props;

        return (
            <>
                <div id="gameHistory">
                    {error ?
                        <div className="error">{error}</div>
                    :
                        <>
                            {url ?
                                <iframe
                                    title={t('players_report_game_history')}
                                    className={'ready'}
                                    src={url}
                                    ref={(elem) => (this.iframe = elem)}
                                />
                                :
                                <Loader/>}
                            <CloseCircleOutlined
                                className="closeBtn"
                                onClick={onClose}
                            />
                        </>
                    }
                </div>
                <Overlay isVisible switchState={onClose} />
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    url: getHistoryUrl(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getGameHistoryUrl: (data: any) => dispatch(getGameHistoryUrl(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(OPAGameHistory)));

