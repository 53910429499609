import React from 'react';
import { useTranslation } from 'react-i18next';

export const ChecksumColumns = () => {
    const { t } = useTranslation();

    return [
        {
            title: t('gameId'),
            dataIndex: 'fileId',
            key: 'fileId',
            width: 135,
            fixed: true,
        },
        {
            title: t('fileName'),
            dataIndex: 'fileNameVersion',
            key: 'fileNameVersion',
        },
        {
            title: t('status'),
            dataIndex: 'success',
            key: 'success',
            width: 165,
            render: (text: any) => (
                <span className={`account-status${text ? '' : ' blocked'}`}>
                    {t(text ? 'success' : 'failed')}
                </span>
            ),
        },
    ];
};
