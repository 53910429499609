import React from 'react';
import Tag from 'antd/lib/tag';
import * as i18next from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IFilters } from 'helpers/interfaces';
import { Tooltip } from 'antd';

interface IProps {
    filters: IFilters;
    onFiltersChangeCallback: Function;
    onReset: Function;
    t: i18next.TFunction;
}

interface IState {
    collapsed: boolean;
}

const AMOUNT_OF_VISIBLE_FILTERS = 6;

class FilterMarks extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        collapsed: false,
    };

    onCurrencyClick = (value: string) => {
        const { currencies } = this.props.filters;
        const currencyIndex = currencies.indexOf(value);

        currencyIndex >= 0 && currencies.splice(currencyIndex, 1);

        this.props.onFiltersChangeCallback('currencies', currencies, true);
    };

    onDeviceTypeClick = () =>
        this.props.onFiltersChangeCallback('deviceType', 'all', true);

    getCurrencyFilters = () => {
        const { currencies } = this.props.filters;
        const tags = [];
        const otherCurrencies: string[] = [];
        const filtersAmount = this.state.collapsed
            ? currencies.length
            : AMOUNT_OF_VISIBLE_FILTERS;

        currencies.forEach((currency: string, index: number) => {
            if (index < filtersAmount) {
                tags.push(
                    <Tag
                        className="filter-tag"
                        key={currency}
                        onClose={this.onCurrencyClick.bind(this, currency)}
                        closable
                    >
                        {currency.toUpperCase()}
                    </Tag>,
                );
            } else {
                otherCurrencies.push(currency);
            }
        });

        if (currencies.length - 1 >= filtersAmount) {
            tags.push(
                <Tooltip title={otherCurrencies.join(', ')}>
                    <Tag
                        className="filter-tag show-all"
                        key={'currency-others'}
                        onClick={this.showAllCurrencies}
                    >
                        {`+${otherCurrencies.length} others`}
                    </Tag>
                </Tooltip>,
            );
        }

        return tags;
    };

    showAllCurrencies = () => this.setState({ collapsed: true });

    getChannelFilter = () => {
        const { deviceType } = this.props.filters;

        return (
            deviceType !== 'all' && (
                <Tag
                    className="filter-tag"
                    key={deviceType}
                    onClose={this.onDeviceTypeClick}
                    closable
                >
                    {this.props.t(deviceType).toUpperCase()}
                </Tag>
            )
        );
    };

    onGamesClick = (value: string) => {
        const { games } = this.props.filters;
        const gameIndex = games.indexOf(value);

        gameIndex >= 0 && games.splice(gameIndex, 1);

        this.props.onFiltersChangeCallback('games', games, true);
    };

    showAllGames = () => this.setState({ collapsed: true });

    getGamesFilter = () => {
        const { games } = this.props.filters;
        const tags = [];
        const otherGames: string[] = [];
        const filtersAmount = this.state.collapsed
            ? games.length
            : AMOUNT_OF_VISIBLE_FILTERS;

        games.forEach((game: string, index: number) => {
            if (index < filtersAmount) {
                tags.push(
                    <Tag
                        className="filter-tag"
                        key={game}
                        onClose={this.onGamesClick.bind(this, game)}
                        closable
                    >
                        {game.toUpperCase()}
                    </Tag>,
                );
            } else {
                otherGames.push(game);
            }
        });

        if (games.length - 1 >= filtersAmount) {
            tags.push(
                <Tooltip key="tooltip" title={otherGames.join(', ')}>
                    <Tag
                        className="filter-tag show-all"
                        key={'games-others'}
                        onClick={this.showAllGames}
                    >
                        {`+${otherGames.length} others`}
                    </Tag>
                </Tooltip>,
            );
        }

        return tags;
    };

    resetFilters = () => {
        this.setState({ collapsed: false });
        this.props.onReset();
    };

    render = () => {
        const { filters, t } = this.props;
        const { currencies, deviceType, games } = filters;

        return (
            <div className="filters">
                {(currencies.length > 0 ||
                    deviceType !== 'all' ||
                    games.length > 0) && (
                    <>
                        <div className="filtered-by">
                            <span className="title-span">
                                {t('filtered-by')}
                            </span>
                        </div>
                        {this.getChannelFilter()}
                        {this.getCurrencyFilters()}
                        {this.getGamesFilter()}
                        <span
                            className="ant-tag filter-tag reset"
                            onClick={this.resetFilters}
                        >
                            {t('reset_currency').toUpperCase()}
                        </span>
                    </>
                )}
            </div>
        );
    };
}

export default withTranslation()(FilterMarks);
