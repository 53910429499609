import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TimeAggregationComponent from 'Components/TimeAggregationComponent/TimeAggregationComponent';
import Calendar from 'Components/Calendar/Calendar';
import { localStorageHelper } from 'utils';
import { IDates } from 'helpers/interfaces';

interface IProps {
    getReport: Function;
    dates: IDates;
    parentReportCallback: Function;
    isShowSelectTime?: boolean;
    context: any;
}

interface IState {
    daysSubtraction: number;
    timeAggregatorValue: string;
}

class DateAggregationWrapper extends React.Component<
    IProps & WithTranslation,
    IState
> {
    state: IState = {
        daysSubtraction: 0,
        timeAggregatorValue:
            localStorageHelper.getChainedValue(
                'user.reports.aggregationPeriod',
            ) || 'day',
    };

    handleDateRangeChange = (dates: IDates) => {
        this.getDaysSubtraction(dates);
        this.props.parentReportCallback(dates);
    };

    handleChangeAggregatorValue = (
        timeAggregatorValue: string,
        sendRequest?: boolean,
    ) => {
        localStorageHelper.setChainedValue(
            'user.reports.aggregationPeriod',
            timeAggregatorValue,
        );

        this.setState(
            () => ({ timeAggregatorValue }),
            () => !!sendRequest && this.props.getReport(),
        );
    };

    getDaysSubtraction = ({ fromDate, toDate }: IDates) => {
        const daysDifference =
            new Date(toDate).getTime() - new Date(fromDate).getTime();
        const oneDayInMs = 86400000;
        const daysSubtractionResult = Math.floor(daysDifference / oneDayInMs);
        const isNotEntirePeriod = this.state.timeAggregatorValue !== 'none';
        const isNotDay = this.state.timeAggregatorValue !== 'day';

        this.setState(
            () => ({ daysSubtraction: daysSubtractionResult }),
            () => {
                if (daysSubtractionResult < 7 && isNotEntirePeriod) {
                    this.handleChangeAggregatorValue('day');
                } else if (
                    isNotDay &&
                    isNotEntirePeriod &&
                    daysSubtractionResult < 28
                ) {
                    this.handleChangeAggregatorValue('week');
                }
            },
        );
    };

    componentDidMount = () => this.getDaysSubtraction(this.props.dates);

    render = () => {
        const { isShowSelectTime, dates, context } = this.props;
        const { daysSubtraction, timeAggregatorValue } = this.state;

        return (
            <>
                <TimeAggregationComponent
                    onChange={this.handleChangeAggregatorValue}
                    timeAggregatorValue={timeAggregatorValue}
                    daysSubtraction={daysSubtraction}
                />
                <Calendar
                    parentReportCallback={this.handleDateRangeChange}
                    isShowSelectTime={isShowSelectTime}
                    defaultValue={dates}
                    context={context}
                />
            </>
        );
    };
}

export default withTranslation()(DateAggregationWrapper);
