import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import './AddOperatorStyles.scss';
import { STEPS } from '../AddOperator/index';
import * as i18next from 'i18next';
import { ICurrencyData } from 'helpers/interfaces';
import { localStorageHelper, sortCPCurrenciesArray } from 'utils';
import { Switch } from 'antd';
import { Dispatch } from 'redux';
import { addOperatorCurrenciesRequest } from 'redux/actions/configProvider/add-operator-actions';
import { getGameConfiguredCurrenciesRequest } from 'redux/actions/configProvider/currencies-actions';
import { Footer } from 'Components/Footer/Footer';
import {
    MainButton,
    SecondaryButton,
} from 'Components/CustomButtons/CustomButtons';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons/lib';
import { getConfiguredCurrencies } from 'redux/selectors/configProvider/currency-selectors';

interface ICurrencyDataForRequest {
    id: number;
    frozen: boolean;
}

interface IProps {
    getConfiguredCurrencies: Function;
    configuredCurrencies: number[];
    previousStep: Function;
    addCurrencies: Function;
    t: i18next.TFunction;
}

interface IState {
    currencies: any;
    isAvailableOnTop: boolean;
    keyToSort: 'name' | 'code' | 'id';
    sortState: 'asc' | 'desc';
}

const COLUMNS: string[] = ['name', 'id', 'code', 'subunit', 'status'];

class StepManageCurrencies extends React.Component<
    IProps & WithTranslation,
    IState
> {
    state: IState = {
        currencies: {},
        isAvailableOnTop: true,
        keyToSort: 'code',
        sortState: 'asc',
    };

    disableAll = () => {
        const { currencies } = this.state;

        for (const currencyId in currencies) {
            if (Object.prototype.hasOwnProperty.call(currencies, currencyId)) {
                currencies[currencyId].status = false;
            }
        }

        this.setState({ currencies });
    };

    enableAll = () => {
        const { currencies } = this.state;

        for (const currencyId in currencies) {
            if (Object.prototype.hasOwnProperty.call(currencies, currencyId)) {
                currencies[currencyId].status = true;
            }
        }

        this.setState({ currencies });
    };

    onChange = (checked: boolean, currencyId: number) => {
        const { currencies } = this.state;

        currencies[currencyId].status = checked;

        this.setState({ currencies });
    };

    handleSubmit = () => {
        const operatorId = localStorageHelper.getChainedValue(
            'user.newOperator.id',
        );
        const { currencies } = this.state;
        const currenciesForRequest: ICurrencyDataForRequest[] = [];

        for (const currencyId in currencies) {
            if (Object.prototype.hasOwnProperty.call(currencies, currencyId)) {
                currencies[currencyId].status &&
                    currenciesForRequest.push({
                        frozen: false,
                        id: Number(currencyId),
                    });
            }
        }

        this.isContinueBtnEnabled() &&
            this.props.addCurrencies({
                currencies: currenciesForRequest,
                operatorId,
            });
    };

    isContinueBtnEnabled = () => {
        const { currencies } = this.state;
        let isButtonEnabled = false;

        for (const currencyId in currencies) {
            if (
                Object.prototype.hasOwnProperty.call(currencies, currencyId) &&
                currencies[currencyId].status
            ) {
                isButtonEnabled = true;
            }
        }

        return isButtonEnabled;
    };

    getBack = () => this.props.previousStep();

    sortStateHandler = (key: 'code' | 'id' | 'name') => {
        const { keyToSort, sortState } = this.state;

        if (key === keyToSort) {
            if (sortState === 'asc') {
                this.setState({ sortState: 'desc' });
            } else {
                this.setState({ sortState: 'asc' });
            }
        } else {
            this.setState({ keyToSort: key, sortState: 'asc' });
        }
    };

    handleAvailableOnTop = () => {
        this.setState({ isAvailableOnTop: !this.state.isAvailableOnTop });
    };

    getTable = () => {
        const { keyToSort, sortState, isAvailableOnTop, currencies } =
            this.state;
        const data = sortCPCurrenciesArray(
            Object.values(currencies),
            keyToSort,
            sortState,
            isAvailableOnTop,
        );

        return (
            <table className="cp-table">
                <thead>
                    <tr>
                        {COLUMNS.map((columnTitle: string) => (
                            <th key={columnTitle} className="cp-table-cell">
                                <div className="cell-inner">
                                    <div className="cell-inner__content">
                                        {this.props.t(columnTitle)}
                                    </div>
                                    {columnTitle === 'id' ||
                                    columnTitle === 'name' ||
                                    columnTitle === 'code' ? (
                                        <div
                                            className={`sorter${
                                                keyToSort &&
                                                keyToSort === columnTitle
                                                    ? ` ${sortState}`
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                this.sortStateHandler(
                                                    columnTitle,
                                                )
                                            }
                                        >
                                            <CaretUpOutlined />
                                            <CaretDownOutlined />
                                        </div>
                                    ) : null}
                                    {columnTitle === 'status' ? (
                                        <div
                                            className={`sorter${
                                                isAvailableOnTop ? ' desc' : ''
                                            }`}
                                            onClick={() =>
                                                this.handleAvailableOnTop()
                                            }
                                        >
                                            <CaretUpOutlined />
                                        </div>
                                    ) : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((currency: ICurrencyData) => (
                        <tr key={currency.name}>
                            <td>{currency.name}</td>
                            <td>{currency.id}</td>
                            <td>{currency.code}</td>
                            <td>{currency.subunit}</td>
                            <td>
                                <Switch
                                    checked={currency.status}
                                    onChange={(checked: boolean) =>
                                        this.onChange(checked, currency.id)
                                    }
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    componentDidUpdate = () => {
        if (
            this.props.configuredCurrencies?.length &&
            Object.keys(this.state.currencies).length === 0
        ) {
            const currencies = this.getConfiguredCurrenciesData();
            const storedCurrencies = localStorageHelper.getChainedValue(
                'user.newOperator.currencyIds',
            );

            for (const currencyId in currencies) {
                if (
                    Object.prototype.hasOwnProperty.call(currencies, currencyId)
                ) {
                    if (storedCurrencies && storedCurrencies.length) {
                        currencies[currencyId].status =
                            storedCurrencies.includes(Number(currencyId));
                    } else {
                        currencies[currencyId].status = false;
                    }
                }
            }

            this.setState({ currencies });
        }
    };

    getConfiguredCurrenciesData = () => {
        const { configuredCurrencies } = this.props;
        const allCurrencies =
            localStorageHelper.getChainedValue('user.allCurrencies');
        const data: any = {};

        allCurrencies.forEach((currencyData: ICurrencyData) => {
            if (configuredCurrencies.includes(currencyData.id)) {
                data[currencyData.id] = currencyData;
            }
        });

        return data;
    };

    componentDidMount = () => {
        this.props.getConfiguredCurrencies();
    };

    render = () => {
        const { t } = this.props;

        return (
            <>
                <div className="operator-step step-wrapper manage-currencies">
                    <h2 className="title">{t(STEPS[2])}</h2>
                    <div className="buttons">
                        <div className="btn grey" onClick={this.disableAll}>
                            {t('disable_all')}
                        </div>
                        <div className="btn grey" onClick={this.enableAll}>
                            {t('enable_all')}
                        </div>
                    </div>
                    {this.getTable()}
                </div>
                <Footer>
                    <SecondaryButton onClick={this.getBack}>
                        {t('back')}
                    </SecondaryButton>
                    <MainButton
                        disabled={!this.isContinueBtnEnabled()}
                        onClick={this.handleSubmit}
                    >
                        {t('continue_save')}
                    </MainButton>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    configuredCurrencies: getConfiguredCurrencies(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getConfiguredCurrencies: () =>
        dispatch(getGameConfiguredCurrenciesRequest()),
    addCurrencies: (data: any) => dispatch(addOperatorCurrenciesRequest(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(StepManageCurrencies));
