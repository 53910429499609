import { localStorageHelper } from './index';
import { locales } from './locales';
import i18next from 'i18next';
import common_en from '../lang/en.json';
import common_zh from '../lang/zh.json';

const storageLocale = localStorageHelper.getChainedValue(
    'user.preferences.locale',
);
const currentLocale = (locales as any)[storageLocale] || locales.en;

i18next.init({
    interpolation: { escapeValue: false },
    lng: currentLocale.resources,
    resources: {
        en: {
            translation: common_en,
        },
        zh: {
            translation: common_zh,
        },
    },
});
