import React from 'react';
import { Select } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './TimeAggregationStyles.scss';
import * as i18next from 'i18next';

const { OptGroup, Option } = Select;

interface IProps {
    onChange: Function;
    t: i18next.TFunction;
    daysSubtraction: number;
    timeAggregatorValue: string;
}

class TimeAggregationComponent extends React.Component<IProps & WithTranslation> {
    changeTimeAggregatorValue = (value: string) => {
        this.props.onChange(value, true);
    };

    render = () => {
        const { t, daysSubtraction, timeAggregatorValue } = this.props;

        return (
            <div className="time-aggregator">
                <Select
                    value={timeAggregatorValue}
                    onChange={this.changeTimeAggregatorValue}
                    popupClassName="time-aggregator-dropdown"
                >
                    <OptGroup label={t('group_by')}>
                        <Option value="day">{t('day')}</Option>
                        <Option value="week" disabled={daysSubtraction < 6}>
                            {t('week')}
                        </Option>
                        <Option value="month" disabled={daysSubtraction < 27}>
                            {t('month')}
                        </Option>
                        <Option value="none">{t('entire_period')}</Option>
                    </OptGroup>
                </Select>
            </div>
        );
    };
}

export default connect()(withTranslation()(TimeAggregationComponent));
