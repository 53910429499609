export const ManageBrandTypes = {
    GET_OPERATOR_BRANDS_REQUEST: 'GET_OPERATOR_BRANDS_REQUEST',
    GET_OPERATOR_BRANDS_SUCCESS: 'GET_OPERATOR_BRANDS_SUCCESS',
    GET_OPERATOR_BRANDS_FAILED: 'GET_OPERATOR_BRANDS_FAILED',

    GET_OPERATOR_GAMES_REQUEST: 'GET_OPERATOR_GAMES_REQUEST',
    GET_OPERATOR_GAMES_SUCCESS: 'GET_OPERATOR_GAMES_SUCCESS',
    GET_OPERATOR_GAMES_FAILED: 'GET_OPERATOR_GAMES_FAILED',

    GET_OPERATOR_CURRENCIES_REQUEST: 'GET_OPERATOR_CURRENCIES_REQUEST',
    GET_OPERATOR_CURRENCIES_SUCCESS: 'GET_OPERATOR_CURRENCIES_SUCCESS',
    GET_OPERATOR_CURRENCIES_FAILED: 'GET_OPERATOR_CURRENCIES_FAILED',

    ADD_BRAND_INFO_REQUEST: 'ADD_BRAND_INFO_REQUEST',
    ADD_BRAND_INFO_SUCCESS: 'ADD_BRAND_DATA_SUCCESS',
    ADD_BRAND_INFO_FAILED: 'ADD_BRAND_DATA_FAILED',

    UPDATE_BRAND_INFO_REQUEST: 'UPDATE_BRAND_INFO_REQUEST',
    UPDATE_BRAND_INFO_SUCCESS: 'UPDATE_BRAND_INFO_SUCCESS',
    UPDATE_BRAND_INFO_FAILED: 'UPDATE_BRAND_INFO_FAILED',

    UPDATE_BRAND_STATUS_REQUEST: 'UPDATE_BRAND_STATUS_REQUEST',
    UPDATE_BRAND_STATUS_SUCCESS: 'UPDATE_BRAND_STATUS_SUCCESS',
    UPDATE_BRAND_STATUS_FAILED: 'UPDATE_BRAND_STATUS_FAILED',

    GENERATE_FREE_ROUNDS_CREDENTIALS_REQUEST: 'GENERATE_FREE_ROUNDS_CREDENTIALS_REQUEST',
    GENERATE_FREE_ROUNDS_CREDENTIALS_SUCCESS: 'GENERATE_FREE_ROUNDS_CREDENTIALS_SUCCESS',
    GENERATE_FREE_ROUNDS_CREDENTIALS_FAILED: 'GENERATE_FREE_ROUNDS_CREDENTIALS_FAILED',

    SAVE_FREE_ROUNDS_CREDENTIALS_REQUEST: 'SAVE_FREE_ROUNDS_CREDENTIALS_REQUEST',
    SAVE_FREE_ROUNDS_CREDENTIALS_SUCCESS: 'SAVE_FREE_ROUNDS_CREDENTIALS_SUCCESS',
    SAVE_FREE_ROUNDS_CREDENTIALS_FAILED: 'SAVE_FREE_ROUNDS_CREDENTIALS_FAILED',

    GET_BRAND_GAMES_REQUEST: 'GET_BRAND_GAMES_REQUEST',
    GET_BRAND_GAMES_SUCCESS: 'GET_BRAND_GAMES_SUCCESS',
    GET_BRAND_GAMES_FAILED: 'GET_BRAND_GAMES_FAILED',
    UPDATE_BRAND_GAMES_REQUEST: 'UPDATE_BRAND_GAMES_REQUEST',
    UPDATE_BRAND_GAMES_SUCCESS: 'UPDATE_BRAND_GAMES_SUCCESS',
    UPDATE_BRAND_GAMES_FAILED: 'UPDATE_BRAND_GAMES_FAILED',

    GET_BRAND_CURRENCIES_REQUEST: 'GET_BRAND_CURRENCIES_REQUEST',
    GET_BRAND_CURRENCIES_SUCCESS: 'GET_BRAND_CURRENCIES_SUCCESS',
    GET_BRAND_CURRENCIES_FAILED: 'GET_BRAND_CURRENCIES_FAILED',
    UPDATE_BRAND_CURRENCIES_REQUEST: 'UPDATE_BRAND_CURRENCIES_REQUEST',
    UPDATE_BRAND_CURRENCIES_SUCCESS: 'UPDATE_BRAND_CURRENCIES_SUCCESS',
    UPDATE_BRAND_CURRENCIES_FAILED: 'UPDATE_BRAND_CURRENCIES_FAILED',

    GET_BRAND_CONFIGS_REQUEST: 'GET_BRAND_CONFIGS_REQUEST',
    GET_BRAND_CONFIGS_SUCCESS: 'GET_BRAND_CONFIGS_SUCCESS',
    GET_BRAND_CONFIGS_FAILED: 'GET_BRAND_CONFIGS_FAILED',
    UPDATE_BRAND_CONFIGS_REQUEST: 'UPDATE_BRAND_CONFIGS_REQUEST',
    UPDATE_BRAND_CONFIGS_SUCCESS: 'UPDATE_BRAND_CONFIGS_SUCCESS',
    UPDATE_BRAND_CONFIGS_FAILED: 'UPDATE_BRAND_CONFIGS_FAILED',

    LOGOUT_ALL_BRAND_SESSIONS_REQUEST: 'LOGOUT_ALL_BRAND_SESSIONS_REQUEST',
    LOGOUT_ALL_BRAND_SESSIONS_SUCCESS: 'LOGOUT_ALL_BRAND_SESSIONS_SUCCESS',
    LOGOUT_ALL_BRAND_SESSIONS_FAILED: 'LOGOUT_ALL_BRAND_SESSIONS_FAILED',

    BACK_STEP_ACTION: 'BACK_STEP_ACTION',
    NEXT_STEP_ACTION: 'NEXT_STEP_ACTION',
    RESET_STEP_ACTION: 'RESET_STEP_ACTION',

    SORT_OPERATOR_BRANDS: 'SORT_OPERATOR_BRANDS',
};

export const getOperatorBrandsRequest = (payload?: any) => ({
    type: ManageBrandTypes.GET_OPERATOR_BRANDS_REQUEST,
    payload,
});
export const getOperatorBrandsSuccess = (payload?: any) => ({
    type: ManageBrandTypes.GET_OPERATOR_BRANDS_SUCCESS,
    payload,
});
export const sortOperatorBrands = (payload?: any) => ({
    type: ManageBrandTypes.SORT_OPERATOR_BRANDS,
    payload,
});
export const getOperatorGamesRequest = (payload?: any) => ({
    type: ManageBrandTypes.GET_OPERATOR_GAMES_REQUEST,
    payload,
});
export const getOperatorGamesSuccess = (payload?: any) => ({
    type: ManageBrandTypes.GET_OPERATOR_GAMES_SUCCESS,
    payload,
});
export const getOperatorCurrenciesRequest = (payload?: any) => ({
    type: ManageBrandTypes.GET_OPERATOR_CURRENCIES_REQUEST,
    payload,
});
export const getOperatorCurrenciesSuccess = (payload?: any) => ({
    type: ManageBrandTypes.GET_OPERATOR_CURRENCIES_SUCCESS,
    payload,
});
export const addBrandDataRequest = (payload?: any) => ({
    type: ManageBrandTypes.ADD_BRAND_INFO_REQUEST,
    payload,
});
export const addBrandDataSuccess = (payload?: any) => ({
    type: ManageBrandTypes.ADD_BRAND_INFO_SUCCESS,
    payload,
});
export const updateBrandDataRequest = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_INFO_REQUEST,
    payload,
});
export const updateBrandDataSuccess = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_INFO_SUCCESS,
    payload,
});
export const updateBrandStatusRequest = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_STATUS_REQUEST,
    payload,
});
export const updateBrandStatusSuccess = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_STATUS_SUCCESS,
    payload,
});
export const getBrandGamesRequest = (payload?: any) => ({
    type: ManageBrandTypes.GET_BRAND_GAMES_REQUEST,
    payload,
});
export const getBrandGamesSuccess = (payload?: any) => ({
    type: ManageBrandTypes.GET_BRAND_GAMES_SUCCESS,
    payload,
});
export const updateBrandGamesRequest = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_GAMES_REQUEST,
    payload,
});
export const updateBrandGamesSuccess = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_GAMES_SUCCESS,
    payload,
});
export const getBrandCurrenciesRequest = (payload?: any) => ({
    type: ManageBrandTypes.GET_BRAND_CURRENCIES_REQUEST,
    payload,
});
export const getBrandCurrenciesSuccess = (payload?: any) => ({
    type: ManageBrandTypes.GET_BRAND_CURRENCIES_SUCCESS,
    payload,
});
export const updateBrandCurrenciesRequest = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_CURRENCIES_REQUEST,
    payload,
});
export const updateBrandCurrenciesSuccess = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_CURRENCIES_SUCCESS,
    payload,
});
export const getBrandConfigsRequest = (payload?: any) => ({
    type: ManageBrandTypes.GET_BRAND_CONFIGS_REQUEST,
    payload,
});
export const getBrandConfigsSuccess = (payload?: any) => ({
    type: ManageBrandTypes.GET_BRAND_CONFIGS_SUCCESS,
    payload,
});
export const updateBrandConfigsRequest = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_CONFIGS_REQUEST,
    payload,
});
export const updateBrandConfigsSuccess = (payload?: any) => ({
    type: ManageBrandTypes.UPDATE_BRAND_CONFIGS_SUCCESS,
    payload,
});
export const logoutAllBrandSessions = (payload?: any) => ({
    type: ManageBrandTypes.LOGOUT_ALL_BRAND_SESSIONS_REQUEST,
    payload,
});
export const logoutAllBrandSessionsSuccess = (payload?: any) => ({
    type: ManageBrandTypes.LOGOUT_ALL_BRAND_SESSIONS_SUCCESS,
    payload,
});
export const previousStep = (payload?: any) => ({
    type: ManageBrandTypes.BACK_STEP_ACTION,
    payload,
});
export const nextStep = (payload?: any) => ({
    type: ManageBrandTypes.NEXT_STEP_ACTION,
    payload,
});
export const reset = (payload?: any) => ({
    type: ManageBrandTypes.RESET_STEP_ACTION,
    payload,
});
export const generateFreeRoundsCredentialsRequest = (payload?: any) => ({
    type: ManageBrandTypes.GENERATE_FREE_ROUNDS_CREDENTIALS_REQUEST,
    payload,
});
export const generateFreeRoundsCredentialsSuccess = (payload?: any) => ({
    type: ManageBrandTypes.GENERATE_FREE_ROUNDS_CREDENTIALS_SUCCESS,
    payload,
});
export const saveFreeRoundsCredentialsRequest = (payload?: any) => ({
    type: ManageBrandTypes.SAVE_FREE_ROUNDS_CREDENTIALS_REQUEST,
    payload,
});
export const saveFreeRoundsCredentialsSuccess = (payload?: any) => ({
    type: ManageBrandTypes.SAVE_FREE_ROUNDS_CREDENTIALS_SUCCESS,
    payload,
});