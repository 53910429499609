import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import {
    getOperatorCurrencies,
    sortOperatorCurrencies,
} from 'redux/actions/configProvider/operators-actions';
import { Loader } from 'Components/Loader/Loader';
import {
    getOperatorCurrenciesCP,
    getSortedOperatorCurrenciesCP,
} from 'redux/selectors/configProvider/operators-selectors';
import CustomTable from '../../../Components/Table/CustomTable';
import { CP_OperatorCurrenciesData } from '../../../Containers/Reports/columnsData';
import { Empty } from 'antd';

interface IOperatorData {
    operatorId: number;
}

interface IProps {
    data?: IOperatorData;
    getOperatorCurrencies: Function;
    currencies: number[];
    sortedCurrencies: number[];
    t: i18next.TFunction;
    sortHandler: Function;
}

class OperatorCurrencies extends React.Component<IProps & WithTranslation> {
    componentDidMount = () =>
        this.props.getOperatorCurrencies(this.props.data?.operatorId);

    sortHandler = (key: string, sortState: string) =>
        this.props.sortHandler({ keyToSort: key, sortState });

    render = () => {
        const { currencies, sortedCurrencies, t } = this.props;
        const currenciesData =
            sortedCurrencies && sortedCurrencies.length
                ? sortedCurrencies
                : currencies
                ? currencies.length
                    ? currencies
                    : []
                : null;

        return currenciesData ? (
            currenciesData.length ? (
                <CustomTable
                    reportKey={'CP_OperatorCurrenciesData'}
                    dataSource={currenciesData}
                    columns={CP_OperatorCurrenciesData}
                    sortHandler={this.sortHandler}
                    withoutCollapsing
                    hidePagination
                />
            ) : (
                <Empty description={t('no_data')} />
            )
        ) : (
            <Loader />
        );
    };
}

const mapStateToProps = (state: any) => ({
    currencies: getOperatorCurrenciesCP(state),
    sortedCurrencies: getSortedOperatorCurrenciesCP(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperatorCurrencies: (data: any) => dispatch(getOperatorCurrencies(data)),
    sortHandler: (data: any) => dispatch(sortOperatorCurrencies(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(OperatorCurrencies));
