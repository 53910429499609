export const RoundInfoTypes = {
    ROUND_INFO_REQUEST: 'ROUND_INFO_REQUEST',
    ROUND_INFO_SUCCESS: 'ROUND_INFO_SUCCESS',
    ROUND_INFO_FAILED: 'ROUND_INFO_FAILED',
};

export const getRoundInfo = (payload?: any) => ({
    type: RoundInfoTypes.ROUND_INFO_REQUEST,
    payload,
});
export const getRoundInfoSuccess = (payload?: any) => ({
    type: RoundInfoTypes.ROUND_INFO_SUCCESS,
    payload,
});
