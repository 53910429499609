import { createSelector } from 'reselect';

const getCurrencyConfigData = (state: any) => state.currencyReducer.currentCurrencyConfig;
const getCurrenciesData = (state: any) => state.currencyReducer.currenciesCP;
const getSortedCurrenciesData = (state: any) => state.currencyReducer.sortedCurrenciesCP;
const getGameConfiguredCurrencies = (state: any) => state.currencyReducer.configuredCurrencies;
const getGameUnconfiguredCurrencies = (state: any) => state.currencyReducer.unconfiguredCurrencies;
const isDefaultCurrencyFormLoading = (state: any) => state.currencyReducer.loading;
const configUpdatingStatus = (state: any) => state.currencyReducer.configUpdatingStatus;
const isNewConfigAdded = (state: any) => state.currencyReducer.isNewConfigAdded;

export const getCurrencyConfig = createSelector(getCurrencyConfigData, (state) => state);
export const getCurrencies = createSelector(getCurrenciesData, (state) => state);
export const getSortedCurrencies = createSelector(getSortedCurrenciesData, (state) => state);
export const getConfiguredCurrencies = createSelector(
    getGameConfiguredCurrencies,
    (state) => state,
);
export const getUnconfiguredCurrencies = createSelector(
    getGameUnconfiguredCurrencies,
    (state) => state,
);
export const isAddingDefaultCurrencyFormLoading = createSelector(
    isDefaultCurrencyFormLoading,
    (state) => state,
);
export const getConfigUpdatingStatus = createSelector(configUpdatingStatus, (state) => state);
export const getIsNewConfigAdded = createSelector(isNewConfigAdded, (state) => state);
