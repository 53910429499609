export const OperatorsTypes = {
    GET_ALL_OPERATORS_REQUEST: 'GET_OPERATORS_REQUEST',
    GET_ALL_OPERATORS_SUCCESS: 'GET_OPERATORS_SUCCESS',
    GET_ALL_OPERATORS_FAILED: 'GET_OPERATORS_FAILED',
    CHANGE_OPERATOR_STATUS_REQUEST: 'CHANGE_OPERATOR_STATUS_REQUEST',
    CHANGE_OPERATOR_STATUS_SUCCESS: 'CHANGE_OPERATOR_STATUS_SUCCESS',
    CHANGE_OPERATOR_STATUS_FAILED: 'CHANGE_OPERATOR_STATUS_FAILED',
    REFRESH_SERVERS_REQUEST: 'REFRESH_SERVERS_REQUEST',
    REFRESH_SERVERS_SUCCESS: 'REFRESH_SERVERS_SUCCESS',
    REFRESH_SERVERS_FAILED: 'REFRESH_SERVERS_FAILED',
    GET_OPERATOR_GAME_CONFIGS_REQUEST: 'GET_OPERATOR_GAME_CONFIGS_REQUEST',
    GET_OPERATOR_GAME_CONFIGS_SUCCESS: 'GET_OPERATOR_GAME_CONFIGS_SUCCESS',
    GET_OPERATOR_GAME_CONFIGS_FAILED: 'GET_OPERATOR_GAME_CONFIGS_FAILED',
    UPDATE_OPERATOR_GAME_CONFIGS_REQUEST:
        'UPDATE_OPERATOR_GAME_CONFIGS_REQUEST',
    UPDATE_OPERATOR_GAME_CONFIGS_SUCCESS:
        'UPDATE_OPERATOR_GAME_CONFIGS_SUCCESS',
    UPDATE_OPERATOR_GAME_CONFIGS_FAILED: 'UPDATE_OPERATOR_GAME_CONFIGS_FAILED',
    GET_OPERATORS_GAMES_REQUEST: 'GET_OPERATORS_GAMES_REQUEST',
    GET_OPERATORS_GAMES_SUCCESS: 'GET_OPERATORS_GAMES_SUCCESS',
    GET_OPERATORS_GAMES_FAILED: 'GET_OPERATORS_GAMES_FAILED',
    UPDATE_OPERATORS_GAMES_REQUEST: 'UPDATE_OPERATORS_GAMES_REQUEST',
    UPDATE_OPERATORS_GAMES_SUCCESS: 'UPDATE_OPERATORS_GAMES_SUCCESS',
    UPDATE_OPERATORS_GAMES_FAILED: 'UPDATE_OPERATORS_GAMES_FAILED',
    GET_OPERATORS_CURRENCIES_REQUEST: 'GET_OPERATORS_CURRENCIES_REQUEST',
    GET_OPERATORS_CURRENCIES_SUCCESS: 'GET_OPERATORS_CURRENCIES_SUCCESS',
    GET_OPERATORS_CURRENCIES_FAILED: 'GET_OPERATORS_CURRENCIES_FAILED',
    UPDATE_OPERATORS_CURRENCIES_REQUEST: 'UPDATE_OPERATORS_CURRENCIES_REQUEST',
    UPDATE_OPERATORS_CURRENCIES_SUCCESS: 'UPDATE_OPERATORS_CURRENCIES_SUCCESS',
    UPDATE_OPERATORS_CURRENCIES_FAILED: 'UPDATE_OPERATORS_CURRENCIES_FAILED',
    SORT_ALL_OPERATORS: 'SORT_ALL_OPERATORS',
    SORT_OPERATORS_CURRENCIES: 'SORT_OPERATORS_CURRENCIES',
    SORT_OPERATORS_GAMES: 'SORT_OPERATORS_GAMES',
    LOGOUT_ALL_OPERATOR_SESSIONS_REQUEST:
        'LOGOUT_ALL_OPERATOR_SESSIONS_REQUEST',
    LOGOUT_ALL_OPERATOR_SESSIONS_SUCCESS:
        'LOGOUT_ALL_OPERATOR_SESSIONS_SUCCESS',
    LOGOUT_ALL_OPERATOR_SESSIONS_FAILED: 'LOGOUT_ALL_OPERATOR_SESSIONS_FAILED',
};

export const getAllOperators = (payload?: any) => ({
    type: OperatorsTypes.GET_ALL_OPERATORS_REQUEST,
    payload,
});
export const getAllOperatorsSuccess = (payload?: any) => ({
    type: OperatorsTypes.GET_ALL_OPERATORS_SUCCESS,
    payload,
});
export const sortAllOperators = (payload?: any) => ({
    type: OperatorsTypes.SORT_ALL_OPERATORS,
    payload,
});
export const changeOperatorStatus = (payload?: any) => ({
    type: OperatorsTypes.CHANGE_OPERATOR_STATUS_REQUEST,
    payload,
});
export const changeOperatorStatusSuccess = (payload?: any) => ({
    type: OperatorsTypes.CHANGE_OPERATOR_STATUS_SUCCESS,
    payload,
});
export const refreshServers = (payload?: any) => ({
    type: OperatorsTypes.REFRESH_SERVERS_REQUEST,
    payload,
});
export const refreshServersSuccess = (payload?: any) => ({
    type: OperatorsTypes.REFRESH_SERVERS_SUCCESS,
    payload,
});
export const getOperatorGameConfigurations = (payload?: any) => ({
    type: OperatorsTypes.GET_OPERATOR_GAME_CONFIGS_REQUEST,
    payload,
});
export const getOperatorGameConfigurationsSuccess = (payload?: any) => ({
    type: OperatorsTypes.GET_OPERATOR_GAME_CONFIGS_SUCCESS,
    payload,
});
export const updateOperatorGameConfigurations = (payload?: any) => ({
    type: OperatorsTypes.UPDATE_OPERATOR_GAME_CONFIGS_REQUEST,
    payload,
});
export const updateOperatorGameConfigurationsSuccess = (payload?: any) => ({
    type: OperatorsTypes.UPDATE_OPERATOR_GAME_CONFIGS_SUCCESS,
    payload,
});
export const getOperatorGames = (payload?: any) => ({
    type: OperatorsTypes.GET_OPERATORS_GAMES_REQUEST,
    payload,
});
export const OE_getOperatorGamesSuccess = (payload?: any) => ({
    type: OperatorsTypes.GET_OPERATORS_GAMES_SUCCESS,
    payload,
});
export const sortOperatorGames = (payload?: any) => ({
    type: OperatorsTypes.SORT_OPERATORS_GAMES,
    payload,
});
export const updateOperatorGames = (payload?: any) => ({
    type: OperatorsTypes.UPDATE_OPERATORS_GAMES_REQUEST,
    payload,
});
export const updateOperatorGamesSuccess = (payload?: any) => ({
    type: OperatorsTypes.UPDATE_OPERATORS_GAMES_SUCCESS,
    payload,
});
export const getOperatorCurrencies = (payload?: any) => ({
    type: OperatorsTypes.GET_OPERATORS_CURRENCIES_REQUEST,
    payload,
});
export const sortOperatorCurrencies = (payload?: any) => ({
    type: OperatorsTypes.SORT_OPERATORS_CURRENCIES,
    payload,
});
export const OE_getOperatorCurrenciesSuccess = (payload?: any) => ({
    type: OperatorsTypes.GET_OPERATORS_CURRENCIES_SUCCESS,
    payload,
});
export const updateOperatorCurrencies = (payload?: any) => ({
    type: OperatorsTypes.UPDATE_OPERATORS_CURRENCIES_REQUEST,
    payload,
});
export const updateOperatorCurrenciesSuccess = (payload?: any) => ({
    type: OperatorsTypes.UPDATE_OPERATORS_CURRENCIES_SUCCESS,
    payload,
});
export const logoutAllOperatorSessions = (payload?: any) => ({
    type: OperatorsTypes.LOGOUT_ALL_OPERATOR_SESSIONS_REQUEST,
    payload,
});
export const logoutAllOperatorSessionsSuccess = (payload?: any) => ({
    type: OperatorsTypes.LOGOUT_ALL_OPERATOR_SESSIONS_SUCCESS,
    payload,
});
