import { IUserState } from './user-reducer';
import { IAction } from '../../../helpers/interfaces';

export const addToPendingQueue = (state: IUserState, actions: IAction) => {
    const updatedPendingQueue = [...state.pendingQueue];
    updatedPendingQueue.push(actions.type);

    return { ...state, pendingQueue: updatedPendingQueue };
};

export const updatePendingQueue = (state: IUserState, actions: IAction) => {
    if (state.pendingQueue.includes(actions.payload.source)) {
        const updatedPendingQueue = state.pendingQueue.filter(
            (action: any) => action !== actions.payload.source,
        );

        return {
            ...state,
            pendingQueue: updatedPendingQueue,
            isAuthorised: updatedPendingQueue.length === 0,
        };
    } else return state;
};
