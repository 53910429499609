import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import './commonMobileStyles.scss';
import GrandTotalMobileComponent from './GrandTotalMobileComponent';
import { TrendsByKPIMobileComponent } from 'mobile/TrendsByKPIMobileComponent';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';
import { Empty } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import { MobileChartConstants } from 'mobile/MobileChartConstants';
import { periodKeys } from 'mobile/CalendarMobileComponent';
import { ICasinoPerformanceData } from 'redux/reducers/backoffice/casino-performance-report-reducer';
import { getCasinoPerformanceReport } from 'redux/actions/backoffice/casino-performance-report-actions';
import {
    getCasinoTotalLine,
    getRawCasinoPerformanceData,
} from 'redux/selectors/backoffice/casino-performance-report-selectors';
import { getServerError } from 'redux/selectors/backoffice/error-selectors';

interface IProps {
    period: periodKeys;
    t: i18next.TFunction;
    getCasinoPerformanceData: Function;
    data: ICasinoPerformanceData[];
    totalLine: any;
    error: string;
}

class KPIComponents extends React.Component<IProps & WithTranslation> {
    getReport = () => {
        const { period, getCasinoPerformanceData } = this.props;

        getCasinoPerformanceData(MobileChartConstants.getDates(period));
    };

    componentDidUpdate = (prevProps: IProps) => {
        if (this.props.period !== prevProps.period) {
            this.getReport();
        }
    };

    componentDidMount = () => this.getReport();

    getItemWrapper = (elem: JSX.Element) => (
        <div className="dashboard__item-wrapper">
            <div className="dashboard__item">{elem}</div>
        </div>
    );

    render = () => {
        const { t, data, totalLine, error } = this.props;

        return (
            <>
                {error ? (
                    <ErrorComponent error={error} />
                ) : data ? (
                    data.length ? (
                        <>
                            <GrandTotalMobileComponent data={totalLine} />
                            <TrendsByKPIMobileComponent data={data} />
                        </>
                    ) : (
                        this.getItemWrapper(
                            <Empty description={t('no_data')} />,
                        )
                    )
                ) : (
                    this.getItemWrapper(<Loader />)
                )}
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    data: getRawCasinoPerformanceData(state),
    error: getServerError(state),
    totalLine: getCasinoTotalLine(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getCasinoPerformanceData: (date: any) =>
        dispatch(getCasinoPerformanceReport(date)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(KPIComponents));
