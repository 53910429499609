import dotProp from 'dot-prop-immutable';
import { IAction } from 'helpers/interfaces';
import { GameHistoryTypes } from 'redux/actions/backoffice/game-history-actions';

export const gameHistoryState = {
    gameHistory: null,
    url: null
};

const gameHistoryReducer = (state = gameHistoryState, actions: IAction) => {
    switch (actions.type) {
        case GameHistoryTypes.GAME_HISTORY_REQUEST:
            return gameHistoryState;

        case GameHistoryTypes.GAME_HISTORY_SUCCESS:
            return dotProp.set(state, 'gameHistory', actions.payload);

        case GameHistoryTypes.GAME_HISTORY_URL_REQUEST:
            return gameHistoryState;

        case GameHistoryTypes.GAME_HISTORY_URL_SUCCESS:
            return dotProp.set(state, 'url', actions.payload.url);

        default:
            return state;
    }
};

export default gameHistoryReducer;
