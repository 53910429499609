import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons/lib';
import { currencyUtils, dataFormatterUtils, dateFormatter, getAbbreviatedValue } from 'utils';

interface IProps {
    data: any;
}

interface IState {
    activeKey: string;
    keyValue: any;
}

const defaultState = {
    activeKey: '',
    keyValue: '',
};

class GrandTotalMobileComponent extends React.Component<IProps & WithTranslation, IState> {
    state: IState = defaultState;

    getInnerCellContent = (key: string, value: any) => {
        const currencyCode = currencyUtils.getActiveCurrencyData().code;

        if (key === 'from' || key === 'to') {
            return <>{dateFormatter.getFormattedForReportDate(value)}</>;
        } else if (key === 'net' || key === 'bet' || key === 'win') {
            return (
                <>
                    {getAbbreviatedValue(value, currencyCode, true, false)}
                    <span className="mobile-total-item__currency">{currencyCode}</span>
                </>
            );
        } else if (key === 'rtp') {
            return (
                <>
                    {getAbbreviatedValue(value, currencyCode, false, false)}
                    <span className="mobile-total-item__currency">%</span>
                </>
            );
        } else {
            return <>{getAbbreviatedValue(value, currencyCode, false, false)}</>;
        }
    };

    getTotalLineData = () => {
        const result = [];
        const { data, t } = this.props;

        for (const elem in data) {
            if (Object.prototype.hasOwnProperty.call(data, elem)) {
                result.push(
                    <div
                        key={`total-${elem}`}
                        onClick={this.showTotalItemClick.bind(this, elem, data[elem])}
                        className="mobile-total-item mobile-grand-total__item"
                    >
                        <p className="mobile-total-item__title">{t(`${elem}`)}</p>
                        <p className={'mobile-total-item__content'}>
                            {this.getInnerCellContent(elem, data[elem])}
                        </p>
                    </div>,
                );
            }
        }

        return result;
    };

    addPopup = () => {
        const { activeKey, keyValue } = this.state;
        const { t } = this.props;
        const isMoneyValue = ['bet', 'win', 'net'].includes(activeKey);
        let value;
        let sign = '';

        if (isMoneyValue) {
            const currencyData = currencyUtils.getActiveCurrencyData();
            const { subunit, code } = currencyData;

            value = currencyUtils.getFormattedCurrencyWithComas(keyValue, subunit);
            sign = ' ' + code;
        } else if (activeKey === 'rtp') {
            value = dataFormatterUtils.getFormattedNumber(keyValue);
            sign += ' %';
        } else {
            value = dataFormatterUtils.getFormattedNumber(keyValue);
        }

        return (
            <div className="popup">
                <div className="overlay visible" onClick={this.closeTotalItemValue} />
                <div className="popup__value">
                    <CloseOutlined onClick={this.closeTotalItemValue} />
                    <div className="title">{t(activeKey)}</div>
                    <div className="value">
                        {value}
                        <span>{sign}</span>
                    </div>
                </div>
            </div>
        );
    };

    showTotalItemClick = (key: string, value: any) =>
        this.setState({ activeKey: key, keyValue: value });

    closeTotalItemValue = () => this.setState({ ...defaultState });

    render = () => (
        <>
            {this.state.activeKey && this.addPopup()}
            <div className="dashboard__item-wrapper mobile-grand-total">
                {this.getTotalLineData()}
            </div>
        </>
    );
}

export default connect(null, null)(withTranslation()(GrandTotalMobileComponent));
