export const GameHistoryTypes = {
    GAME_HISTORY_REQUEST: 'GAME_HISTORY_REQUEST',
    GAME_HISTORY_SUCCESS: 'GAME_HISTORY_SUCCESS',
    GAME_HISTORY_FAILED: 'GAME_HISTORY_FAILED',

    GAME_HISTORY_URL_REQUEST: 'GAME_HISTORY_URL_REQUEST',
    GAME_HISTORY_URL_SUCCESS: 'GAME_HISTORY_URL_SUCCESS',
    GAME_HISTORY_URL_FAILED: 'GAME_HISTORY_URL_FAILED',
};

export const getGameHistoryReport = (payload?: any) => ({
    type: GameHistoryTypes.GAME_HISTORY_REQUEST,
    payload,
});
export const gameHistorySuccess = (payload?: any) => ({
    type: GameHistoryTypes.GAME_HISTORY_SUCCESS,
    payload,
});

export const getGameHistoryUrl = (payload?: any) => ({
    type: GameHistoryTypes.GAME_HISTORY_URL_REQUEST,
    payload,
});
export const gameHistoryUrlSuccess = (payload?: any) => ({
    type: GameHistoryTypes.GAME_HISTORY_URL_SUCCESS,
    payload,
});