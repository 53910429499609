import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import './calendar.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IDates } from 'helpers/interfaces';
import { localStorageHelper } from 'utils';
import { CONSTANTS } from '../../utils/config';

interface IProps {
    defaultValue: IDates;
    parentReportCallback: Function;
    isShowSelectTime?: boolean;
    context: any;
}

interface IState {
    dates: any;
}

const { RangePicker } = DatePicker;

class Calendar extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        dates: null,
    };

    getPresets = (): {
        label: string;
        value: [dayjs.Dayjs, dayjs.Dayjs] | (() => [dayjs.Dayjs, dayjs.Dayjs]);
    }[] => {
        const { t } = this.props;

        const rangeIDs = [
            'today',
            'yesterday',
            'week_to_date',
            'month_to_date',
            'last_7_days',
            'last_30_days',
            'last_180_days',
        ];

        const values: ([dayjs.Dayjs, dayjs.Dayjs] | (() => [dayjs.Dayjs, dayjs.Dayjs]))[] = [
            [dayjs(), dayjs()],
            [dayjs().subtract(1, 'd'), dayjs().subtract(1, 'd')],
            [dayjs().startOf('week'), dayjs()],
            [dayjs().startOf('month'), dayjs()],
            [dayjs().subtract(7, 'd'), dayjs().subtract(1, 'd')],
            [dayjs().subtract(30, 'd'), dayjs().subtract(1, 'd')],
            [dayjs().subtract(180, 'd'), dayjs().subtract(1, 'd')],
        ];

        return rangeIDs.map((id, index) => {
            return {
                label: t(id),
                value: values[index],
            };
        });
    };

    handleOpenChange = (isOpen: boolean) => {
        this.props.context.changeOverlaysState();

        // in order to get updated dates from state we should put the report's request to the end of the current code stack
        // that's why we use such hack as setTimeout
        setTimeout(() => {
            const { dates } = this.state;

            if (!isOpen && dates) {
                const fromDate = dates[0];
                const toDate = dates[1];

                fromDate && toDate && this.props.parentReportCallback({ fromDate, toDate });
                this.setState({ dates: null });
            }
        }, 0);
    };

    // store in state chosen dates
    onCalendarChange = (dates: any) => this.setState({ dates });

    // click on Ok buttons means that user chosen the time in addition to date
    onOk = () => localStorageHelper.setChainedValue('user.reports.isToSendHours', true);

    render = () => {
        const { defaultValue, isShowSelectTime } = this.props;
        const { fromDate, toDate } = defaultValue;

        return (
            <>
                <RangePicker
                    // popupClassName={`${
                    //     isShowSelectTime ? 'withTime' : ''
                    // } custom-calendar__dropdown`}
                    defaultValue={[dayjs(fromDate), dayjs(toDate)]}
                    presets={this.getPresets()}
                    showTime={
                        isShowSelectTime
                            ? {
                                  defaultValue: [dayjs().startOf('d'), dayjs().startOf('d')],
                              }
                            : false
                    }
                    format={isShowSelectTime ? 'DD/MM/YYYY HH' : CONSTANTS.dateFormat}
                    separator="-"
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                    onOpenChange={this.handleOpenChange}
                    onCalendarChange={this.onCalendarChange}
                    onOk={this.onOk}
                    style={{ height: '32px', marginRight: '8px' }}
                />
            </>
        );
    };
}

export default withTranslation()(Calendar);
