import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { Loader } from 'Components/Loader/Loader';
import { IConfig } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import { CloseOutlined } from '@ant-design/icons/lib';
import { Link, Navigate } from 'react-router-dom';
import { Messages } from 'utils';
import TextArea from 'antd/lib/input/TextArea';
import {
    getDefaultGameConfig,
    updateDefaultGameConfig,
} from 'redux/actions/configProvider/games-actions';
import i18next from 'i18next';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import { Footer } from 'Components/Footer/Footer';
import {
    getAllGamesByIntegrator,
    getDefaultConfig,
    getDefaultConfigUpdateStatus,
} from 'redux/selectors/configProvider/games-selectors';
import { ChangeEvent } from 'react';
import { withRouter } from '../../../../helpers/HOCs/withRouter';

interface IProps {
    data: IConfig[];
    params: any;
    getDefaultGameConfig: Function;
    allGamesByIntegrator: any[];
    updateDefaultGameConfig: Function;
    navigate: any;
    isDefaultConfigUpdated: boolean;
}

interface IState {
    config: string | null;
    isValid: boolean;
}

class EditDefaultConfig extends React.Component<IProps & WithTranslation> {
    state: IState = {
        config: null,
        isValid: true,
    };

    componentDidUpdate = (prevProps: Readonly<IProps & WithTranslation>, prevState: IState) => {
        if (prevState.config === null && this.props.data) {
            this.setState({ config: this.getDefaultValue(this.props.data) });
        }
    };

    saveChanges = () => {
        const { navigate, params } = this.props;
        const { gameId } = params;
        const { config } = this.state;

        if (config && config.length > 0) {
            const body: any = {
                gameConfig: {},
                gameId,
            };

            let isValid = true;

            try {
                const parsedConfigs = JSON.parse(`{${config}}`);

                for (const item in parsedConfigs) {
                    if (Object.prototype.hasOwnProperty.call(parsedConfigs, item)) {
                        body.gameConfig[item] = JSON.stringify(parsedConfigs[item]);
                    }
                }
            } catch (e) {
                Messages.error(this.props.t('json_not_valid'));
                isValid = false;

                this.setState({ isValid: false });
            }

            isValid && this.props.updateDefaultGameConfig(body);
        } else {
            Messages.success(i18next.t('changes_successfully_saved'));
            navigate(`/config-provider/games`);
        }
    };

    editConfig = (value: string) => {
        this.setState({ config: value });
    };

    getDefaultValue = (data: IConfig[]) => {
        let value = '';

        data.forEach((config: IConfig, i) => {
            value += `"${config.currencyId}": ${config.gameConfig}${
                i < data.length - 1 ? ',\n' : ''
            }`;
        });

        return value;
    };

    componentDidMount = () => {
        const { params, getDefaultGameConfig } = this.props;

        getDefaultGameConfig(params.gameId);
    };

    render = () => {
        const { isValid, config } = this.state;
        const { t, params, isDefaultConfigUpdated, allGamesByIntegrator } = this.props;
        const gameName = allGamesByIntegrator.find(
            (game) => game.id.toString() === params.gameId,
        ).name;

        return isDefaultConfigUpdated ? (
            <Navigate to={`/config-provider/games`} />
        ) : (
            <ContentComponent
                header={
                    <>
                        <div className="header-line cp-header-line">
                            <HeaderTitleComponent
                                title={t('edit_default_game_configuration')}
                                customBreadcrumbs={
                                    <>
                                        <Link to={`/config-provider/games`}>{t('games')}</Link> /{' '}
                                        {gameName}
                                    </>
                                }
                            />
                            <Link to={`/config-provider/games`}>
                                <CloseOutlined className="close-button" />
                            </Link>
                        </div>
                    </>
                }
                innerContent={
                    config !== null ? (
                        <div className={'game-default-config'}>
                            <TextArea
                                style={{ backgroundColor: '#0B1022' }}
                                defaultValue={config}
                                rows={20}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                    this.editConfig(e.target.value);
                                }}
                            />
                            <p className="error">{!isValid && this.props.t('json_not_valid')}</p>
                        </div>
                    ) : (
                        <Loader />
                    )
                }
                footer={
                    <Footer>
                        <Link to={`/config-provider/games`}>
                            <SecondaryButton>{t('cancel')}</SecondaryButton>
                        </Link>
                        <MainButton onClick={this.saveChanges}>{t('save')}</MainButton>
                    </Footer>
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    data: getDefaultConfig(state),
    isDefaultConfigUpdated: getDefaultConfigUpdateStatus(state),
    allGamesByIntegrator: getAllGamesByIntegrator(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getDefaultGameConfig: (data: any) => dispatch(getDefaultGameConfig(data)),
    updateDefaultGameConfig: (data: any) => dispatch(updateDefaultGameConfig(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(withRouter(EditDefaultConfig)));
