import * as React from 'react';
import './SettingsComponentStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button, message, Select, Switch, Tooltip } from 'antd';
import * as i18next from 'i18next';
import { localStorageHelper, Messages } from 'utils';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CONSTANTS } from 'utils/config';
import { IChangePasswordData, ICurrencyData, ITimeZoneListItem } from 'helpers/interfaces';
import { locales } from 'utils/locales';
import { InfoCircleOutlined } from '@ant-design/icons/lib';
import { ChangePasswordPopupComponent } from './ChangePasswordPopupComponent';
import {
    changeName,
    changePassword,
    changeStateDisplayingTestData,
    updatePreferences,
} from 'redux/actions/user-actions';
import { ChangeUserNamePopupComponent } from 'Containers/Users/ChangeUserNamePopupComponent';
import {
    getPreferencesUpdateError,
    isNameChanged,
    isPasswordChanged,
    isPreferencesChanged,
} from 'redux/selectors/user-selectors';
import dayjs from 'dayjs';

interface IProps {
    t: i18next.TFunction;
    i18n: i18next.i18n;
    context: any;
    updatePreferences: Function;
    changeName: Function;
    changePassword: Function;
    changeStateDisplayingTestData: Function;

    isNameChanged: boolean;
    isPasswordChanged: boolean;
    isPreferencesChanged: boolean;

    error: string;
}

interface IState {
    isNameChangeVisible: boolean;
    isPasswordChangeVisible: boolean;

    isNameChanging: boolean;
    isPasswordChanging: boolean;
    isLanguageChanging: boolean;
    isGameHistoryLanguageChanging: boolean;
    isCurrencyChanging: boolean;
    isTimezoneChanging: boolean;
}

const defaultState: IState = {
    isNameChangeVisible: false,
    isPasswordChangeVisible: false,
    isNameChanging: false,
    isPasswordChanging: false,
    isLanguageChanging: false,
    isGameHistoryLanguageChanging: false,
    isCurrencyChanging: false,
    isTimezoneChanging: false,
};

type localesType = 'en' | 'zh';

class Settings extends React.Component<IProps & WithTranslation, IState> {
    state: IState = defaultState;

    static getDerivedStateFromProps = (props: IProps & WithTranslation, state: IState) => {
        const { t, i18n, context, isNameChanged, isPasswordChanged, isPreferencesChanged, error } =
            props;
        const {
            isNameChanging,
            isPasswordChanging,
            isLanguageChanging,
            isCurrencyChanging,
            isTimezoneChanging,
        } = state;
        const { showMessage, showError } = Settings;

        if (isPasswordChanging && isPasswordChanged !== null) {
            isPasswordChanged ? showMessage(t('password_updated')) : showError(t(error));
        } else if (isNameChanging && isNameChanged !== null) {
            isNameChanged ? showMessage(t('user_name_was_changed')) : showError(t(error));
        } else if (isLanguageChanging && isPreferencesChanged !== null) {
            if (isPreferencesChanged) {
                const lang =
                    (localStorageHelper.getChainedValue(
                        'user.preferences.locale',
                    ) as localesType) || 'en';
                const currentLocale = locales[lang];

                i18n.changeLanguage(lang);
                context.changeAntLocale(lang);
                dayjs.locale(currentLocale.moment);

                showMessage(t('language-updated'));
            } else {
                showError(t(error));
            }
            // } else if (isGameHistoryLanguageChanging && isPreferencesChanged !== null) {
            //     isPreferencesChanged ? showMessage(t('game-history-language-changed')) : showError(t(error));
        } else if (isCurrencyChanging && isPreferencesChanged !== null) {
            isPreferencesChanged ? showMessage(t('currency-updated')) : showError(t(error));
        } else if (isTimezoneChanging && isPreferencesChanged !== null) {
            if (isPreferencesChanged) {
                showMessage(t('timezone_updated'));
            } else {
                showError(t(error));
            }
        } else {
            return null;
        }

        return { ...defaultState };
    };

    static showMessage = (text: string) => {
        message && message.destroy();
        message.success(text, CONSTANTS.message_duration);
    };

    static showError = (text: string) => {
        message && message.destroy();
        message.error(text, CONSTANTS.message_duration);
    };

    updateLanguage = (lang: localesType) => {
        this.props.updatePreferences({ locale: lang });
        this.setState({
            isPasswordChangeVisible: false,
            isLanguageChanging: true,
        });
    };

    updateGameHistoryLanguage = (lang: localesType) => {
        localStorageHelper.setChainedValue('user.preferences.gh_language', lang);
        this.props.updatePreferences({ gh_language: lang });

        Messages.success(this.props.t('game_history_language_has_been_changed'));
    };

    updateCurrency = (value: string) => {
        this.props.updatePreferences({ currency: value });
        this.setState({
            isPasswordChangeVisible: false,
            isCurrencyChanging: true,
        });
    };

    updateTimezone = (value: string) => {
        const timeZone = localStorageHelper
            .getChainedValue('user.timezoneList')
            .find(
                (elem: ITimeZoneListItem) =>
                    value.slice(10).indexOf(elem.location.toUpperCase()) >= 0,
            ).id;

        this.props.updatePreferences({ timeZone });
        this.setState({
            isPasswordChangeVisible: false,
            isTimezoneChanging: true,
        });
    };

    handleChangeName = () => this.setState({ ...defaultState, isNameChangeVisible: true });

    handleChangeNameCancel = () => this.setState({ isNameChangeVisible: false });

    handleChangeNameSubmit = (values: any) => {
        const email = localStorageHelper.getChainedValue('user.email');

        this.props.changeName({ email, name: values.name });

        this.setState(() => ({
            isNameChangeVisible: false,
            isNameChanging: true,
        }));
    };

    handleChangePassword = () => this.setState({ ...defaultState, isPasswordChangeVisible: true });

    handleChangePasswordCancel = () => this.setState({ isPasswordChangeVisible: false });

    handleChangePasswordSubmit = (values: any) => {
        this.props.changePassword({
            oldPassword: values.current_password,
            newPassword: values.password,
        });

        this.setState(() => ({
            isPasswordChangeVisible: false,
            isPasswordChanging: true,
        }));
    };

    getDefaultTimezoneValue = () => {
        const defaultTimezone = localStorageHelper.getChainedValue('user.preferences.timeZone');
        const defaultTimezoneData = localStorageHelper
            .getChainedValue('user.timezoneList')
            .find((elem: ITimeZoneListItem) => elem.id === defaultTimezone);
        const { area, location, offset } = defaultTimezoneData;

        return `UTC${offset} ${area}/${location}`.toUpperCase();
    };

    handleDisplayingTestUsersData = (shouldDisplay: boolean) => {
        this.props.changeStateDisplayingTestData(shouldDisplay);

        const text = shouldDisplay ? 'test-users-included' : 'test-users-excluded';

        Settings.showMessage(this.props.t(text));
    };

    render = () => {
        const { t } = this.props;
        const { isPasswordChangeVisible, isNameChangeVisible } = this.state;
        const { Option } = Select;
        const defaultLocale = (
            localStorageHelper.getChainedValue('user.preferences.locale') || 'en'
        ).toUpperCase();
        const defaultGameHistoryLanguage = (
            localStorageHelper.getChainedValue('user.preferences.gh_language') || 'en'
        ).toUpperCase();
        const defaultTimezoneValue = this.getDefaultTimezoneValue();
        const defaultCurrency = localStorageHelper.getChainedValue('user.preferences.currency');
        const userLogin = localStorageHelper.getChainedValue('user.name');
        const shouldDisplayTestUserData = localStorageHelper.getChainedValue(
            'user.preferences.displayTestData',
        );
        const currencies = [
            {
                label: 'non-billable',
                options: localStorageHelper
                    .getChainedValue('user.currenciesByBrand')
                    .filter((elem: ICurrencyData) => !elem.billable)
                    .map((elem: ICurrencyData) => ({ label: elem.code, value: elem.code })),
            },
            {
                label: 'billable',
                options: localStorageHelper
                    .getChainedValue('user.allCurrencies')
                    .filter((elem: ICurrencyData) => elem.billable)
                    .map((elem: ICurrencyData) => ({ label: elem.code, value: elem.code })),
            },
        ];

        return (
            <div className="settings-list">
                <div className="settings-list__item">
                    <p className="settings-item__title">{t('bio').toUpperCase()}</p>
                    <div className="settings-item__inner settings-inner">
                        <p className="settings-item__property">{t('name')}</p>
                        <p className="settings-item__value">{userLogin}</p>
                        <Button
                            className="settings-item__value name"
                            onClick={this.handleChangeName}
                        >
                            {t('change_name')}
                        </Button>
                    </div>
                    <div className="settings-item__inner settings-inner">
                        <p className="settings-item__property">{t('password')}</p>
                        {isPasswordChangeVisible ? (
                            <ChangePasswordPopupComponent
                                onCancel={this.handleChangePasswordCancel}
                                onSubmit={this.handleChangePasswordSubmit}
                                t={t}
                            />
                        ) : (
                            <Button
                                className="settings-item__value"
                                onClick={this.handleChangePassword}
                            >
                                {t('change_password')}
                            </Button>
                        )}
                    </div>
                </div>
                <div id="timezoneSearch" className="settings-list__item">
                    <p className="settings-item__title">{t('date_time').toUpperCase()}</p>
                    <div className="settings-item__inner settings-inner date">
                        <p className="settings-inner__title">{t('timezone')}</p>
                        <Select
                            showSearch
                            defaultValue={defaultTimezoneValue}
                            onChange={this.updateTimezone}
                            notFoundContent={t('no_results')}
                        >
                            {localStorageHelper
                                .getChainedValue('user.timezoneList')
                                .map((elem: ITimeZoneListItem) => {
                                    const { area, location, offset } = elem;
                                    const value = `UTC${offset} ${area}/${location}`.toUpperCase();

                                    return (
                                        <Option key={value} value={value}>
                                            {value}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </div>
                </div>
                <div id="currencySearch" className="settings-list__item">
                    <p className="settings-item__title">{t('language_region').toUpperCase()}</p>
                    <div className="settings-item__inner settings-inner">
                        <p className="settings-inner__title">
                            {
                                <>
                                    {t('currency')}
                                    <Tooltip id="currencyTooltip" title={t('currency-hint')}>
                                        <InfoCircleOutlined id="currencyIcon" />
                                    </Tooltip>
                                </>
                            }
                        </p>
                        <Select
                            showSearch
                            defaultValue={defaultCurrency}
                            onChange={this.updateCurrency}
                            notFoundContent={t('no_results')}
                            options={currencies}
                        />
                    </div>
                    <div className="settings-item__inner settings-inner settings-inner__language">
                        <p className="settings-inner__title">{t('language')}</p>
                        <Select defaultValue={defaultLocale} onChange={this.updateLanguage}>
                            <Option value="en">EN</Option>
                            <Option value="zh">ZH</Option>
                        </Select>
                    </div>
                    <div className="settings-item__inner settings-inner settings-inner__gh-language">
                        <p className="settings-inner__title">{t('game_history_language')}</p>
                        <Select
                            defaultValue={defaultGameHistoryLanguage}
                            onChange={this.updateGameHistoryLanguage}
                        >
                            {CONSTANTS.game_history_languages.map((lang: string) => (
                                <Option key={lang} value={lang}>
                                    {lang.toUpperCase()}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div id="reportsPreferences" className="settings-list__item">
                    <p className="settings-item__title">{t('reports').toUpperCase()}</p>
                    <div className="settings-item__inner settings-inner">
                        <p className="settings-inner__title">{t('display_test_users_data')}</p>
                        <Switch
                            defaultChecked={shouldDisplayTestUserData}
                            onChange={this.handleDisplayingTestUsersData}
                        />
                    </div>
                </div>
                <ChangeUserNamePopupComponent
                    name={userLogin}
                    visible={isNameChangeVisible}
                    onCancel={this.handleChangeNameCancel}
                    onSubmit={this.handleChangeNameSubmit}
                    t={t}
                />
            </div>
        );
    };
}

const mapStateToProps = (state: any) => ({
    isNameChanged: isNameChanged(state),
    isPasswordChanged: isPasswordChanged(state),
    isPreferencesChanged: isPreferencesChanged(state),
    error: getPreferencesUpdateError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    changeName: (data: any) => dispatch(changeName(data)),
    changePassword: (data: IChangePasswordData) => dispatch(changePassword(data)),
    updatePreferences: (preferences: any) => dispatch(updatePreferences(preferences)),
    changeStateDisplayingTestData: (shouldDisplay: boolean) =>
        dispatch(changeStateDisplayingTestData(shouldDisplay)),
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Settings);
export default withTranslation()(ConnectedComponent);
