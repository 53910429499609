import { currencyUtils, getAbbreviatedValue, getAbbreviateNumber, localStorageHelper } from 'utils';
import { periods } from 'mobile/CalendarMobileComponent';
import { periodKeys } from 'mobile/CalendarMobileComponent';
import dayjs from 'dayjs';

export class MobileChartConstants {
    public static tooltipOptions: any = {
        trigger: 'axis',
        transitionDuration: 0,
        axisPointer: {
            type: 'cross',
            animation: false,
        },
        position: {
            top: 0,
            left: 0,
        },
        extraCssText: 'width: 100%; padding: 8px 16px 0 16px;',
        alwaysShowContent: true,
        backgroundColor: 'white',
        textStyle: {
            color: '#0B1022',
        },
        formatter(params: any) {
            const currencyCode = currencyUtils.getActiveCurrencyData().code;
            const values = {
                'Total bet': '',
                'Total payout': '',
                GGR: '',
            };

            for (let i = 0, l = params.length; i < l; i++) {
                const value = getAbbreviatedValue(params[i].value, currencyCode, true, false);

                switch (params[i].seriesName) {
                    case 'Total bet':
                        values['Total bet'] = value;
                        break;
                    case 'Total payout':
                        values['Total payout'] = value;
                        break;
                    case 'GGR':
                        values['GGR'] = value;
                        break;
                    default:
                        break;
                }
            }

            return `<div class='mobile-chart-tooltip'>
                        <div class='chart-tooltip__item tooltip-item'>
                            <p class='tooltip-item__content'>
                                ${values['Total bet'] || ''}
                                <span class='tooltip-item__currency'>${
                                    values['Total bet'] ? currencyCode : ''
                                }</span>
                            </p>
                        </div>
                        <div class='chart-tooltip__item tooltip-item'>
                            <p class='tooltip-item__content'>
                                ${values['Total payout'] || ''}
                                <span class='tooltip-item__currency'>${
                                    values['Total payout'] ? currencyCode : ''
                                }</span>
                            </p>
                        </div>
                        <div class='chart-tooltip__item tooltip-item'>
                            <p class='tooltip-item__content'>
                                ${values['GGR'] || ''}
                                <span class='tooltip-item__currency'>${
                                    values['GGR'] ? currencyCode : ''
                                }</span>
                            </p>
                        </div>
                    </div>`;
        },
        enterable: true,
        appendToBody: true,
    };

    public static commonOptions = {
        grid: {
            left: '10px',
            right: '10px',
            top: '60px',
            bottom: '40px',
            containLabel: true,
        },
        tooltip: MobileChartConstants.tooltipOptions,
    };

    public static commonAxisXStyles = {
        axisLabel: {
            show: false,
        },
        axisLine: {
            lineStyle: { color: '#caccd0' },
            show: false,
        },
    };

    public static commonAxisYStyles = {
        type: 'value',
        minInterval: 1,
        axisLine: {
            lineStyle: { color: '#caccd0' },
            show: false,
        },
        axisLabel: {
            formatter(value: number): string {
                return getAbbreviateNumber(value, true);
            },
            inside: true,
            margin: -3,
            verticalAlign: 'bottom',
            fontSize: 10,
        },
        axisPointer: {
            show: false,
        },
    };

    // we need to add user's timezone offset to each date (on desktop we don't do it because it does ant calendar)
    public static addUserUTCOffsetToDate = (date: any) => {
        const timeZone = localStorageHelper.getChainedValue('user.preferences.timeZone');
        const userUTCOffset = dayjs
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .utcOffset();
        const userPreferencesUTCOffset = dayjs.tz(timeZone).utcOffset();

        return date.add(userPreferencesUTCOffset - userUTCOffset, 'm');
    };

    public static getDates = (period: periodKeys) => {
        const fromDate = MobileChartConstants.addUserUTCOffsetToDate(periods[period][0].clone());
        const toDate = MobileChartConstants.addUserUTCOffsetToDate(periods[period][1].clone());

        return { fromDate, toDate };
    };
}
