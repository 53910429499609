export const GamesTypes = {
    ADD_GAME_REQUEST: 'ADD_GAME_REQUEST',
    ADD_GAME_SUCCESS: 'ADD_GAME_SUCCESS',
    ADD_GAME_FAILED: 'ADD_GAME_FAILED',
    ADD_GAME_DEFAULT_CONFIG_REQUEST: 'ADD_GAME_DEFAULT_CONFIG_REQUEST',
    ADD_GAME_DEFAULT_CONFIG_SUCCESS: 'ADD_GAME_DEFAULT_CONFIG_SUCCESS',
    ADD_GAME_DEFAULT_CONFIG_FAILED: 'ADD_GAME_DEFAULT_CONFIG_FAILED',
    GET_DEFAULT_GAME_CONFIGURATIONS_REQUEST:
        'GET_DEFAULT_GAME_CONFIGURATIONS_REQUEST',
    GET_DEFAULT_GAME_CONFIGURATIONS_SUCCESS:
        'GET_DEFAULT_GAME_CONFIGURATIONS_SUCCESS',
    GET_DEFAULT_GAME_CONFIGURATIONS_FAILED:
        'GET_DEFAULT_GAME_CONFIGURATIONS_FAILED',
    UPDATE_DEFAULT_GAME_CONFIGURATION_REQUEST:
        'UPDATE_DEFAULT_GAME_CONFIGURATION_REQUEST',
    UPDATE_DEFAULT_GAME_CONFIGURATIONS_SUCCESS:
        'UPDATE_DEFAULT_GAME_CONFIGURATIONS_SUCCESS',
    UPDATE_DEFAULT_GAME_CONFIGURATIONS_FAILED:
        'UPDATE_DEFAULT_GAME_CONFIGURATIONS_FAILED',
};

export const addGameRequest = (payload?: any) => ({
    type: GamesTypes.ADD_GAME_REQUEST,
    payload,
});
export const addGameSuccess = (payload?: any) => ({
    type: GamesTypes.ADD_GAME_SUCCESS,
    payload,
});
export const addGameFailed = (payload?: any) => ({
    type: GamesTypes.ADD_GAME_FAILED,
    payload,
});
export const addGameDefaultConfigRequest = (payload?: any) => ({
    type: GamesTypes.ADD_GAME_DEFAULT_CONFIG_REQUEST,
    payload,
});
export const addGameDefaultConfigSuccess = (payload?: any) => ({
    type: GamesTypes.ADD_GAME_DEFAULT_CONFIG_SUCCESS,
    payload,
});
export const getDefaultGameConfig = (payload?: any) => ({
    type: GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_REQUEST,
    payload,
});
export const getDefaultGameConfigSuccess = (payload?: any) => ({
    type: GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_SUCCESS,
    payload,
});
export const updateDefaultGameConfig = (payload?: any) => ({
    type: GamesTypes.UPDATE_DEFAULT_GAME_CONFIGURATION_REQUEST,
    payload,
});
export const updateDefaultGameConfigSuccess = (payload?: any) => ({
    type: GamesTypes.UPDATE_DEFAULT_GAME_CONFIGURATIONS_SUCCESS,
    payload,
});
