import { createSelector } from 'reselect';

const users = (state: any) => state.casinoAdminReducer.users;
const isCreated = (state: any) => state.casinoAdminReducer.isUserCreated;
const isBlocked = (state: any) => state.casinoAdminReducer.isUserBlocked;
const isUnblocked = (state: any) => state.casinoAdminReducer.isUserUnblocked;
const isRoleChanged = (state: any) =>
    state.casinoAdminReducer.isUserRoleChanged;
const isUserPasswordChanged = (state: any) =>
    state.casinoAdminReducer.isUserPasswordChanged;
const isUserNameChanged = (state: any) =>
    state.casinoAdminReducer.isUserNameChanged;

export const createdUsers = createSelector(users, (state) => state);
export const isUserCreated = createSelector(isCreated, (state) => state);
export const isUserBlocked = createSelector(isBlocked, (state) => state);
export const isUserUnblocked = createSelector(isUnblocked, (state) => state);
export const isUserRoleChanged = createSelector(
    isRoleChanged,
    (state) => state,
);
export const isPasswordChangedByAdmin = createSelector(
    isUserPasswordChanged,
    (state) => state,
);
export const isNameChangedByAdmin = createSelector(
    isUserNameChanged,
    (state) => state,
);
