export const UnfinishedRoundsTypes = {
    UNFINISHED_ROUNDS_REQUEST: 'UNFINISHED_ROUNDS_REQUEST',
    UNFINISHED_ROUNDS_SUCCESS: 'UNFINISHED_ROUNDS_SUCCESS',
    UNFINISHED_ROUNDS_FAILED: 'UNFINISHED_ROUNDS_FAILED',
    FINISH_ROUNDS_REQUEST: 'FINISH_ROUNDS_REQUEST',
    FINISH_ROUNDS_SUCCESS: 'FINISH_ROUNDS_SUCCESS',
    FINISH_ROUNDS_FAILED: 'FINISH_ROUNDS_FAILED',
};

export const getUnfinishedRounds = (payload?: any) => ({
    type: UnfinishedRoundsTypes.UNFINISHED_ROUNDS_REQUEST,
    payload,
});
export const getUnfinishedRoundsSuccess = (payload?: any) => ({
    type: UnfinishedRoundsTypes.UNFINISHED_ROUNDS_SUCCESS,
    payload,
});
export const finishRounds = (payload?: any) => ({
    type: UnfinishedRoundsTypes.FINISH_ROUNDS_REQUEST,
    payload,
});
export const finishRoundsSuccess = (payload?: any) => ({
    type: UnfinishedRoundsTypes.FINISH_ROUNDS_SUCCESS,
    payload,
});
