import React from 'react';
import { useTranslation } from 'react-i18next';
import { currencyUtils } from '../../../utils';
import { currencyNameConverter } from '../../../utils/Currency/currencyNameConverter';
import { DesktopOutlined, MobileOutlined, TabletOutlined } from '@ant-design/icons';

const stringToInteger = (numberString: any) => {
    if (numberString === null) {
        return -1;
    }

    const string = numberString.toString();
    const numberWithoutCommas = string.replace(/,/g, '');
    const number = parseFloat(numberWithoutCommas);

    if (isNaN(number)) {
        return 0;
    }

    return number;
};

function createSorter(property: string) {
    return (a: { [key: string]: any }, b: { [key: string]: any }): number => {
        return stringToInteger(a[property]) - stringToInteger(b[property]);
    };
}

export const GamesColumns = (activeCurrencyCode: string, isFiltered: boolean) => {
    const { t } = useTranslation();

    return [
        {
            title: t('games_report_game_name'),
            dataIndex: 'gameName',
            key: 'gameName',
            sorter: (a: any, b: any) => a.gameName.localeCompare(b.gameName),
            width: 185,
            fixed: true,
        },
        {
            title: t('casino_report_from_date'),
            dataIndex: 'fromDate',
            key: 'fromDate',
            render: (text: any) => <span>{text}</span>,
            sorter: (a: any, b: any) =>
                new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime(),
            width: 165,
            fixed: true,
        },
        {
            title: t('casino_report_to_date'),
            dataIndex: 'toDate',
            key: 'toDate',
            render: (text: any) => <span>{text}</span>,
            sorter: (a: any, b: any) => new Date(a.toDate).getTime() - new Date(b.toDate).getTime(),
            width: 165,
        },
        {
            title: t('players'),
            dataIndex: 'players',
            key: 'players',
            sorter: createSorter('players'),
            width: 165,
        },
        {
            title: t('rounds'),
            dataIndex: 'spins',
            key: 'spins',
            sorter: createSorter('spins'),
            width: 165,
        },
        {
            title: t('device_type'),
            dataIndex: 'gamesDeviceType',
            key: 'gamesDeviceType',
            render: (text: string) => {
                if (text === 'desktop') return <DesktopOutlined className="device-type" />;
                if (text === 'tablet') return <TabletOutlined className="device-type" />;
                else return <MobileOutlined className="device-type" />;
            },
            width: 90,
        },
        {
            title: t('currency_filter'),
            dataIndex: 'currencyId',
            key: 'currencyId',
            render: (text: any) =>
                text === 0
                    ? isFiltered
                        ? t('filtered')
                        : t('all_consolidated')
                    : currencyNameConverter.idToCode(`${text}`),
            width: 165,
            sorter: (a: any, b: any): any => {
                const aCurrencyCode =
                    currencyUtils.getCurrencyDataById(a.currencyId)?.code || 'all';
                const bCurrencyCode =
                    currencyUtils.getCurrencyDataById(b.currencyId)?.code || 'all';

                return aCurrencyCode.localeCompare(bCurrencyCode);
            },
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'bet',
            key: 'bet',
            sorter: createSorter('bet'),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'win',
            key: 'win',
            sorter: createSorter('win'),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'net',
            key: 'net',
            sorter: createSorter('net'),
            width: 165,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('rtp')}
                    <span>%</span>
                </span>
            ),
            dataIndex: 'rtp',
            key: 'rtp',
            sorter: createSorter('rtp'),
            width: 165,
        },
        {
            title: t('free_round_spins'),
            dataIndex: 'freeRoundsSpins',
            key: 'freeRoundsSpins',
            sorter: createSorter('freeRoundsSpins'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsBet',
            key: 'freeRoundsBet',
            sorter: createSorter('freeRoundsBet'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsNet',
            key: 'freeRoundsNet',
            sorter: createSorter('freeRoundsNet'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_rtp')}
                    <span>%</span>
                </span>
            ),
            dataIndex: 'freeRoundsRtp',
            key: 'freeRoundsRtp',
            sorter: createSorter('freeRoundsRtp'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('free_round_win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'freeRoundsWin',
            key: 'freeRoundsWin',
            sorter: createSorter('freeRoundsWin'),
            width: 175,
        },
        {
            title: t('buy_feature_spins'),
            dataIndex: 'buyFeatureSpins',
            key: 'buyFeatureSpins',
            sorter: createSorter('buyFeatureSpins'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_bet')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureBet',
            key: 'buyFeatureBet',
            sorter: createSorter('buyFeatureBet'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_net')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureNet',
            key: 'buyFeatureNet',
            sorter: createSorter('buyFeatureNet'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_rtp')}
                    <span>%</span>
                </span>
            ),
            dataIndex: 'buyFeatureRtp',
            key: 'buyFeatureRtp',
            sorter: createSorter('buyFeatureRtp'),
            width: 175,
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('buy_feature_win')}
                    <span>{activeCurrencyCode}</span>
                </span>
            ),
            dataIndex: 'buyFeatureWin',
            key: 'buyFeatureWin',
            sorter: createSorter('buyFeatureWin'),
            width: 175,
        },
    ];
};
