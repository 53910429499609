import * as React from 'react';
import './MainMenu.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IReportType } from 'helpers/interfaces';
import { Layout, Menu } from 'antd';
import { localStorageHelper, mediaQueryHooksHOC, projectDetection } from 'utils';
import { Map } from 'helpers/types/Map';
import * as i18next from 'i18next';
import logo from 'images/logo.png';
import koalaLogo from 'images/KOALA-logo.png';

import {
    DollarOutlined,
    FileTextOutlined,
    PlayCircleOutlined,
    ShopOutlined,
    TeamOutlined,
    DisconnectOutlined,
} from '@ant-design/icons/lib';
import { IUserState } from 'redux/reducers/user/user-reducer';
import { userState } from 'redux/selectors/user-selectors';
import { withRouter } from '../../helpers/HOCs/withRouter';
import {
    CheckSquareOutlined,
    FieldTimeOutlined,
    FileSearchOutlined,
    GiftOutlined,
} from '@ant-design/icons';
import { IS_KOALA } from '../../utils/config';

const { Sider } = Layout;
const MENU_WIDTH_FOR_TABLET = 180;
const MENU_WIDTH_FOR_DESKTOP = 256;

interface IProps {
    t: i18next.TFunction;
    logout: Function;
    userState: IUserState;
    mediaQuery: any;
    location: Location;
}

interface IState {
    minified: boolean;
}

const configProviderMainMenuItems = [
    {
        description: 'operators',
        link: `/config-provider/operators`,
        component: <ShopOutlined />,
        authority: 'CONFIGURE_INTEGRATION',
    },
    {
        description: 'games',
        link: `/config-provider/games`,
        component: <PlayCircleOutlined />,
        authority: 'CONFIGURE_INTEGRATION',
    },
    {
        description: 'currencies',
        link: `/config-provider/currencies`,
        component: <DollarOutlined />,
        authority: 'CONFIGURE_INTEGRATION',
    },
    {
        description: 'resolve_sessions',
        link: `/config-provider/broken-rounds`,
        component: <DisconnectOutlined />,
        authority: 'SESSION_MANAGEMENT',
    },
    {
        description: 'unfinished_rounds',
        link: `/config-provider/unfinished-rounds`,
        component: <FieldTimeOutlined />,
        authority: 'CONFIGURE_INTEGRATION',
    },
    {
        description: 'round_info',
        link: `/config-provider/round-info`,
        component: <FileSearchOutlined />,
        authority: 'TECHNICAL_REPORT',
    },
    {
        description: 'critical_checksum',
        link: `/casino/reports/critical-checksum`,
        component: <CheckSquareOutlined />,
        authority: 'CRITICAL_FILES_CHECK_HISTORY',
    },
];

type MenuItemType = {
    key: string;
    icon?: React.ReactNode;
    label: React.ReactNode;
    children?: MenuItemType[];
    disabled?: boolean;
    tooltip?: string;
};

class MainMenu extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        minified: false,
    };

    convertToComponents = () => {
        const { t } = this.props;
        const authorities = localStorageHelper.getChainedValue('user.authorities');
        const convertingMap: Map<IReportType> = {
            REPORT_PLAYER_GROUP_REVENUE: {
                description: 'casino_performance',
                link: `/casino/reports/casino-performance`,
                id: 'casino_performance_reports_item',
            },
            REPORT_PLAYER_REVENUE: {
                description: 'players_performance',
                link: `/casino/reports/players-performance`,
                id: 'players_performance_reports_item',
            },
            REPORT_GAME_REVENUE: {
                description: 'games_performance',
                link: `/casino/reports/games-performance`,
                id: 'games_performance_reports_item',
            },
            CRITICAL_FILES_CHECK_HISTORY: {
                description: 'critical_checksum',
                link: `/casino/reports/critical-checksum`,
                id: 'critical_checksum_reports_item',
            },
            REPORT_MONTHLY_BILLING: {
                description: 'monthly_billing_report',
                link: `/casino/reports/monthly-billing-report`,
                id: 'monthly_billing_reports_item',
            },
        };
        const result: MenuItemType[] = [];

        for (const reportType in authorities) {
            if (
                Object.prototype.hasOwnProperty.call(authorities, reportType) &&
                authorities[reportType]
            ) {
                const convertedItem = convertingMap[authorities[reportType]];
                if (convertedItem) {
                    const { description, link, id } = convertedItem;
                    result.push({
                        key: link,
                        label: (
                            <Link to={link} id={id}>
                                {t(description)}
                            </Link>
                        ),
                    });
                }
            }
        }

        return result;
    };

    getConfigProviderMenuItems = (): MenuItemType[] => {
        const authorities = localStorageHelper.getChainedValue('user.authorities');

        return configProviderMainMenuItems.map(({ description, link, component, authority }) => {
            const menuItem: MenuItemType = {
                key: link,
                icon: component,
                label: <span className="menu-item__title">{this.props.t(description)}</span>,
            };

            if (authorities.includes(authority)) {
                menuItem.label = <Link to={link}>{menuItem.label}</Link>;
            } else {
                menuItem.disabled = true;
                menuItem.tooltip = this.props.t('ask_for_access');
            }

            return menuItem;
        });
    };

    onMinified = (minified: boolean) => this.setState(() => ({ minified }));

    render = () => {
        const { t, userState, mediaQuery, location } = this.props;
        const authorities = localStorageHelper.getChainedValue('user.authorities');
        const shouldDisplayPromotions =
            authorities.includes('MANAGE_CAMPAIGNS') || authorities.includes('VIEW_CAMPAIGNS');
        const { pathname } = location;
        const { isBO, isCP } = projectDetection;
        const isAdmin =
            (userState && userState.role === 'ADMIN') ||
            localStorageHelper.getChainedValue('user.role') === 'ADMIN';
        const { isTablet } = mediaQuery;

        const menuItems: MenuItemType[] = [];
        const siderLogo = IS_KOALA ? (
            <img
                style={{
                    width: this.state.minified ? '32px' : '64px',
                    height: this.state.minified ? '32px' : '64px',
                    marginLeft: this.state.minified ? '23px' : '33px',
                }}
                src={koalaLogo}
                alt={'logo'}
            />
        ) : (
            <img
                style={{
                    width: this.state.minified ? '32px' : '124px',
                    marginLeft: this.state.minified ? '27px' : '0',
                }}
                src={logo}
                alt={'logo'}
            />
        );

        if (isBO()) {
            menuItems.push({
                key: `/casino/reports`,
                icon: <FileTextOutlined />,
                label: <span className="menu-item__title">{t('main_menu_reports')}</span>,
                children: this.convertToComponents(),
            });
        }

        if (isCP()) {
            menuItems.push(...this.getConfigProviderMenuItems());
        }

        if (isAdmin) {
            menuItems.push({
                key: `/casino/users`,
                icon: <TeamOutlined />,
                label: (
                    <Link to={`/casino/users`}>
                        <span className="menu-item__title">{t('users')}</span>
                    </Link>
                ),
            });
        }

        if (shouldDisplayPromotions) {
            menuItems.push({
                key: `/casino/promotions`,
                icon: <GiftOutlined />,
                label: (
                    <Link to={`/casino/promotions`}>
                        <span className="menu-item__title">{t('promotions')}</span>
                    </Link>
                ),
            });
        }

        return (
            <Sider
                style={{ maxHeight: '100vh', overflow: 'hidden' }}
                theme={'light'}
                collapsible
                collapsed={this.state.minified}
                onCollapse={this.onMinified}
                width={isTablet ? MENU_WIDTH_FOR_TABLET : MENU_WIDTH_FOR_DESKTOP}
            >
                <h2 className={`main-menu__title${this.state.minified ? ' minified' : ''}`}>
                    {this.state.minified ? 'BO' : 'Back Office'}
                </h2>
                <Menu
                    theme="light"
                    mode="inline"
                    defaultOpenKeys={[`/casino/reports`]}
                    selectedKeys={[`${pathname}`]}
                    items={menuItems}
                />
                <div className={`main-menu__logo${this.state.minified ? '' : ' visible'}`}>
                    {siderLogo}
                </div>
            </Sider>
        );
    };
}

const mapStateToProps = (state: any) => ({
    userState: userState(state),
});

export default connect(
    mapStateToProps,
    null,
)(withTranslation()(mediaQueryHooksHOC(withRouter(MainMenu))));
