import * as React from 'react';
import ReportsMenuItem from '../../Containers/Reports/ReportsMenuItem/ReportsMenuItem';
import { useTranslation } from 'react-i18next';
import { localStorageHelper } from 'utils';
import {
    CheckSquareOutlined,
    DisconnectOutlined,
    DollarOutlined,
    FieldTimeOutlined,
    FileSearchOutlined,
    PlayCircleOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import { useMemo } from 'react';
import { ContentComponent } from '../../Components/ContentComponent/ContentComponent';

interface IMenuItemProperties {
    icon: JSX.Element;
    description: string;
    link: string;
}

interface IConvertingMap {
    [key: string]: IMenuItemProperties | IMenuItemProperties[];
}

const ToolsMenu = () => {
    const { t } = useTranslation();
    const convertingMap: IConvertingMap = useMemo(
        () => ({
            CONFIGURE_INTEGRATION: [
                {
                    icon: <ShopOutlined />,
                    description: 'operators',
                    link: `/config-provider/operators`,
                },
                {
                    icon: <PlayCircleOutlined />,
                    description: 'games',
                    link: `/config-provider/games`,
                },
                {
                    icon: <DollarOutlined />,
                    description: 'currencies',
                    link: `/config-provider/currencies`,
                },
            ],
            TECHNICAL_REPORT: {
                icon: <FileSearchOutlined />,
                description: 'round_info',
                link: `/config-provider/round-info`,
            },
            CRITICAL_FILES_CHECK_HISTORY: {
                icon: <CheckSquareOutlined />,
                description: 'critical_checksum',
                link: `/config-provider/reports/critical-checksum`,
            },
            SESSION_MANAGEMENT: {
                icon: <DisconnectOutlined />,
                description: 'resolve_sessions',
                link: `/config-provider/broken-rounds`,
            },
            UNFINISHED_ROUNDS: {
                icon: <FieldTimeOutlined />,
                description: 'unfinished_rounds',
                link: `/config-provider/unfinished-rounds`,
            },
        }),
        [],
    );

    const convertToComponents = () => {
        const authorities: string[] = localStorageHelper.getChainedValue('user.authorities') || [];
        const result: JSX.Element[] = [];

        authorities.forEach((authority) => {
            if (Array.isArray(authority)) {
                authority.forEach((item) => {
                    const items = convertingMap[item as keyof IConvertingMap];
                    if (items) {
                        const menuItems = Array.isArray(items) ? items : [items];
                        menuItems.forEach((itemProperties) => {
                            result.push(
                                <ReportsMenuItem
                                    key={itemProperties.description}
                                    t={t}
                                    isEnabled={true}
                                    properties={itemProperties}
                                />,
                            );
                        });
                    }
                });
            } else {
                const itemProperties = convertingMap[authority];
                if (itemProperties) {
                    const menuItems = Array.isArray(itemProperties)
                        ? itemProperties
                        : [itemProperties];
                    menuItems.forEach((prop) => {
                        result.push(
                            <ReportsMenuItem
                                key={prop.description}
                                t={t}
                                isEnabled={true}
                                properties={prop}
                            />,
                        );
                    });
                }
            }
        });

        return result;
    };

    return (
        <ContentComponent
            customBreadcrumbs={<div>{t('all')}</div>}
            innerContent={<div className="plates-container">{convertToComponents()}</div>}
            title={t('pages')}
        />
    );
};

export default ToolsMenu;
