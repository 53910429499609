import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
    gameHistorySuccess,
    GameHistoryTypes,
    gameHistoryUrlSuccess,
} from '../../actions/backoffice/game-history-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from 'utils';
import { of } from 'rxjs';
import { IAction } from 'helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

const DEFAULT_GAME_HISTORY_LANGUAGE = 'en';
export const gameHistoryEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GameHistoryTypes.GAME_HISTORY_REQUEST),
        mergeMap((action) => {
            const url =
                `${SERVER_URL}/game-history/bet-session?playerId=${action.payload.playerId}` +
                `&gameSessionId=${action.payload.gameSessionId}&startId=${action.payload.startId}`;

            return from(axios.get(url, { headers: getLoginRequestHeader() })).pipe(
                map((response) => gameHistorySuccess(response.data)),
                catchError((error) => of(apiError(error, GameHistoryTypes.GAME_HISTORY_FAILED))),
            );
        }),
    );

export const getGameHistoryUrl = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(GameHistoryTypes.GAME_HISTORY_URL_REQUEST),
        mergeMap((action) => {
            const requestBody = {
                roundId: action.payload.roundId,
                langCode: action.payload.langCode || DEFAULT_GAME_HISTORY_LANGUAGE,
            };

            return from(
                axios.post(`${SERVER_URL}/game-history/link`, requestBody, {
                    headers: getLoginRequestHeader(),
                }),
            ).pipe(
                map((response) => {
                    return gameHistoryUrlSuccess(response.data);
                }),
                catchError((error) =>
                    of(apiError(error, GameHistoryTypes.GAME_HISTORY_URL_FAILED)),
                ),
            );
        }),
    );
