import * as React from 'react';
import CasinoMobileChart from './CasinoMobileChart';

interface IProps {
    data: any;
}

export const TrendsByKPIMobileComponent = (props: IProps) => (
    <div className="dashboard__item-wrapper">
        <div className="dashboard__item mobile-chart">
            <CasinoMobileChart data={props.data} />
        </div>
    </div>
);
