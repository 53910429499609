import * as React from 'react';
import './BreadcrumbComponentStyles.scss';

interface IProps {
    customBreadcrumbs?: JSX.Element;
}

export const BreadcrumbComponent = (props: IProps) => {
    const { customBreadcrumbs } = props;

    return <div className="breadcrumbs">{customBreadcrumbs}</div>;
};
