import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import {
    checksumReportSuccess,
    ChecksumReportTypes,
} from '../../actions/backoffice/checksum-report-actions';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const checksumReportEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(ChecksumReportTypes.CHECKSUM_REPORT_REQUEST),
        mergeMap((action) => {
            const requestBody = {
                pagingInfo: action.payload,
            };

            return from(
                axios.post(`${SERVER_URL}/critical-files/check-history`, requestBody, {
                    headers: getLoginRequestHeader('application/json'),
                }),
            ).pipe(
                map((response) => checksumReportSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, ChecksumReportTypes.CHECKSUM_REPORT_REQUEST)),
                ),
            );
        }),
    );
