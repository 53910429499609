import React, { useEffect } from 'react';
import './SearchResultStyles.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'Components/Loader/Loader';
import { Empty } from 'antd';
import {
    getSearchResult,
    getSearchStatus,
    getSearchError,
} from 'redux/selectors/backoffice/search-selectors';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';
import SearchResult from './SearchResult';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { searchPlayers } from '../../redux/actions/backoffice/search-actions';
import { useParams } from 'react-router-dom';

const SearchResultContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const searchResult = useSelector(getSearchResult);
    const isSearching = useSelector(getSearchStatus);
    const error = useSelector(getSearchError);
    const params = useParams();
    const { player } = params;

    const getContent = () => {
        if (error) {
            return <ErrorComponent error={t('server_error')} />;
        }

        if (searchResult && searchResult.length > 0 && player) {
            return <SearchResult players={searchResult} />;
        } else {
            if (!isSearching) {
                return <Empty description={t('search_c_find_anything')} />;
            } else {
                return <Loader />;
            }
        }
    };

    useEffect(() => {
        dispatch(searchPlayers(player));
    }, [player, dispatch]);

    return (
        <ContentComponent
            customBreadcrumbs={<div>{t('search')}</div>}
            innerContent={getContent()}
        />
    );
};

export default SearchResultContainer;
