import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './BrokenRoundsPageStyles.scss';
import { Empty, Modal, Pagination, DatePicker, Button, Space } from 'antd';
import { ContentComponent } from '../../Components/ContentComponent/ContentComponent';
import { Loader } from '../../Components/Loader/Loader';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
    blockSession,
    getBrokenRounds,
    resolveSession,
    retrySession,
} from '../../redux/actions/configProvider/broken-rounds-actions';
import {
    getBrokenRoundsData,
    getBrokenRoundsMetadata,
} from '../../redux/selectors/backoffice/broken-rounds-selector';
import SimplifiedCustomTable from '../../Components/Table/SimplifiedCustomTable';
import { brokenRoundsData } from '../Reports/columnsData';
import { MainButton, SecondaryButton } from '../../Components/CustomButtons/CustomButtons';
import { Overlay } from '../../Components/Overlay/Overlay';
import { IBrokenRoundData } from '../../helpers/interfaces';
import { HeaderTitleComponent } from '../../Components/ContentComponent/HeaderTitleComponent';
import { LoadingOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons/lib';
import { Input, Select } from 'antd';
import StatusHistory from './StatusHistory';

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

interface IBrokenRoundsPagingInfo {
    pageNumber: number;
    pageSize: number;
    sortBy: string;
    order: string;
}

interface IBrokenRoundsMetadata {
    pagingInfo: IBrokenRoundsPagingInfo;
    totalNumberOfElements: number;
}

interface IProps {
    data: IBrokenRoundData[];
    metadata: IBrokenRoundsMetadata;
    getBrokenRounds: Function;
    resolveSession: Function;
    retrySession: Function;
    blockSession: Function;
}

interface IBrokenRoundRequestBody {
    externalPlayerId: string | null;
    playerSession: string | null;
    roundUuid: string | null;
    playerId: number | null;
    sessionId: number | null;
    currencyCode: string | null;
    roundStatus: string | null;
    transactionUuid: string | null;
    roundStartedFrom: string | null;
    roundStartedTo: string | null;
    roundFailedFrom: string | null;
    roundFailedTo: string | null;
    brandName: string | null;
    pagingInfo: IBrokenRoundsPagingInfo;
}

const DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE = 20;
const PAGE_SIZE_ARRAY = ['10', '20', '50', '100'];

const initialBodyRequest: IBrokenRoundRequestBody = {
    externalPlayerId: null,
    pagingInfo: {
        pageNumber: 1,
        pageSize: DEFAULT_AMOUNT_OF_ITEMS_PER_PAGE,
        sortBy: 'id',
        order: 'DESCENDING',
    },
    playerId: null,
    playerSession: null,
    roundUuid: null,
    sessionId: null,
    roundStatus: null,
    currencyCode: null,
    transactionUuid: null,
    roundStartedFrom: null,
    roundStartedTo: null,
    roundFailedFrom: null,
    roundFailedTo: null,
    brandName: null,
};

const initialRoundInfo: IBrokenRoundData = {
    brandId: 0,
    brandName: '',
    failReason: '',
    failedRoundUuid: '',
    gameId: 0,
    gameName: '',
    id: 0,
    operatorId: 0,
    playerId: 0,
    playerSession: '',
    roundStatus: '',
    walletErrorCode: 0,
    walletErrorDescription: '',
    walletErrorDetails: '',
    roundUuid: '',
    sessionId: 0,
    startId: 0,
    transactionType: '',
    externalPlayerId: '',
    amount: 0,
    currencyCode: '',
    created: '',
    transactionUuid: '',
    roundStartedAt: '',
    roundFailedAt: '',
};

const BrokenRoundsPage = (props: IProps) => {
    const { t } = useTranslation();
    const { data, metadata, getBrokenRounds, resolveSession, retrySession, blockSession } = props;
    const [requestBody, setRequestBody] = useState({ ...initialBodyRequest });
    const [roundInfo, setRoundInfo] = useState({ ...initialRoundInfo });
    const [roundInfoPopupVisible, changeInfoPopupVisibility] = useState(false);
    const [loadingResolve, setLoadingResolveStatus] = useState(false);
    const [loadingRetry, setLoadingRetryStatus] = useState(false);
    const [searchKey, setSearchKey] = useState('roundUuid');
    const [searchValue, setSearchValue] = useState('');
    const [onSearchStatus, setOnSearchStatus] = useState(false);

    const showInfo = (roundData: IBrokenRoundData) => {
        setRoundInfo(roundData);
        changeInfoPopupVisibility(true);
    };

    const getSupplementedData = () => {
        data.forEach((round: any) => {
            round.callback = showInfo;
        });

        return data;
    };

    const getInfo = () => {
        const result = [];

        for (const key in roundInfo) {
            if (key !== 'callback') {
                result.push(
                    <div key={key} className={'broken-round-info__item'}>
                        <p className={'broken-round-info__title'}>
                            {key === 'id' ? t('broken_round_id') : t(key)}
                        </p>
                        <p className={'broken-round-info__value'}>
                            {roundInfo[key as keyof IBrokenRoundData]}
                        </p>
                    </div>,
                );
            }
        }

        result.push(<StatusHistory key={roundInfo.roundUuid} roundUuid={roundInfo.roundUuid} />);

        return result;
    };

    const onResolve = () => {
        setLoadingResolveStatus(true);
        resolveSession(roundInfo.sessionId);
        setTimeout(() => {
            setLoadingResolveStatus(false);
            changeInfoPopupVisibility(false);
            getBrokenRounds(requestBody);
        }, 1000);
    };

    const onPageChange = (pageNumber: number) => {
        const pagingInfo = { ...requestBody.pagingInfo };

        pagingInfo.pageNumber = pageNumber;
        setRequestBody({ ...requestBody, pagingInfo });

        getBrokenRounds({ ...requestBody, pagingInfo });
    };

    const onShowSizeChange = (pageNumber: number, size: number) => {
        const pagingInfo = requestBody.pagingInfo;

        pagingInfo.pageNumber = pageNumber;
        pagingInfo.pageSize = size;

        setRequestBody({ ...requestBody, pagingInfo });
        getBrokenRounds(requestBody);
    };

    const onSearchKeyChange = (key: string) => {
        setSearchKey(key);
    };

    const onSearch = (value: string) => {
        const regularExp = new RegExp('^[0-9]*$');

        if (searchKey === 'sessionId' && !regularExp.test(value)) {
            setSearchValue(t('input_a_number'));
            return;
        }

        if (searchValue.length > 0) {
            const body: any = {
                ...requestBody,
                pagingInfo: initialBodyRequest.pagingInfo,
                playerSession: null,
                roundUuid: null,
                sessionId: null,
                externalPlayerId: null,
                brandName: null,
            };

            body[searchKey] = value;

            setRequestBody(body);
            setOnSearchStatus(true);

            getBrokenRounds(body);
        }
    };

    const refreshReportAfterSearch = () => {
        const { roundFailedTo, roundStartedFrom, roundStatus } = requestBody;
        setSearchValue('');
        setRequestBody({
            ...initialBodyRequest,
            roundStartedFrom,
            roundFailedTo,
            roundStatus,
        });
        getBrokenRounds({
            ...initialBodyRequest,
            roundStartedFrom,
            roundFailedTo,
            roundStatus,
        });
        setOnSearchStatus(false);
    };

    const searchFieldSuffix = () => (
        <CloseCircleOutlined
            className={`broken-rounds-search__clear${searchValue.length > 0 ? '__visible' : ''}`}
            onClick={() => {
                if (searchValue.length > 0) {
                    refreshReportAfterSearch();
                }
            }}
        />
    );

    const searchOnChange = (e: any) => {
        if (onSearchStatus && e.target.value.length < 1) {
            refreshReportAfterSearch();
        } else {
            setSearchValue(e.target.value);
        }
    };

    const onDateChange = (dates: any) => {
        const formatString = 'YYYY-MM-DDTHH:mm:ss';
        const roundStartedFrom = dates ? dates[0].startOf('day').format(formatString) : null;
        const roundFailedTo = dates ? dates[1].endOf('day').format(formatString) : null;

        setRequestBody({ ...requestBody, roundStartedFrom, roundFailedTo });

        getBrokenRounds({ ...requestBody, roundStartedFrom, roundFailedTo });
    };

    const onStatusFilterChange = (value: string) => {
        const status = value === 'all' ? null : value;

        setRequestBody({
            ...requestBody,
            pagingInfo: initialBodyRequest.pagingInfo,
            roundStatus: status,
        });

        getBrokenRounds({
            ...requestBody,
            pagingInfo: initialBodyRequest.pagingInfo,
            roundStatus: status === 'all' ? null : status,
        });
    };

    const refreshAfterRetry = () => {
        setTimeout(() => {
            setLoadingRetryStatus(false);
            changeInfoPopupVisibility(false);
            getBrokenRounds(requestBody);
        }, 1000);
    };

    const onRetry = () => {
        setLoadingRetryStatus(true);
        retrySession(roundInfo.roundUuid);
        refreshAfterRetry();
    };

    const onBlock = () => {
        setLoadingRetryStatus(true);
        blockSession(roundInfo.roundUuid);
        refreshAfterRetry();
    };

    const reloadData = () => {
        getBrokenRounds(requestBody);
    };

    useEffect(() => {
        getBrokenRounds(requestBody);
    }, [getBrokenRounds, requestBody]);

    return (
        <ContentComponent
            header={
                <>
                    <HeaderTitleComponent
                        title={t('broken_rounds')}
                        customBreadcrumbs={<div>{t('all')}</div>}
                    />
                    <Space.Compact
                        className="broken-rounds-search__wrapper"
                        style={{ marginBottom: '8px', display: 'flex', width: '100%' }}
                    >
                        <Button
                            icon={<SyncOutlined />}
                            onClick={reloadData}
                            className="broken-rounds-search__refresh"
                        />
                        <Select
                            style={{ width: '160px' }}
                            defaultValue={t('roundUuid')}
                            className="broken-rounds-search__select"
                            onChange={onSearchKeyChange}
                            popupMatchSelectWidth={true}
                        >
                            <Option value="roundUuid">{t('roundUuid')}</Option>
                            <Option value="externalPlayerId">{t('externalPlayerId')}</Option>
                            <Option value="playerSession">{t('playerSession')}</Option>
                            <Option value="sessionId">{t('sessionId')}</Option>
                            <Option value="brandName">{t('brandName')}</Option>
                        </Select>
                        <RangePicker
                            className="broken-rounds-search__datepicker"
                            onChange={(e: any) => onDateChange(e)}
                        />
                        <Select
                            style={{ width: '160px' }}
                            className="broken-rounds-search__status"
                            defaultValue={t('all')}
                            onChange={onStatusFilterChange}
                            popupMatchSelectWidth={true}
                        >
                            <Option value="all">{t('all')}</Option>
                            <Option value="ON_RETRY">{t('on_retry')}</Option>
                            <Option value="BLOCKED">{t('blocked')}</Option>
                            <Option value="RESOLVED">{t('resolved')}</Option>
                            <Option value="MANUALLY_RESOLVED">{t('manually_resolved')}</Option>
                            <Option value="NOT_BLOCKED">{t('not_blocked')}</Option>
                        </Select>
                        <Search
                            value={searchValue}
                            onChange={searchOnChange}
                            suffix={searchFieldSuffix()}
                            className="broken-rounds-search__search-field"
                            placeholder={
                                searchKey === 'sessionId'
                                    ? t('input_a_number')
                                    : t('enter_search_value')
                            }
                            onSearch={onSearch}
                            enterButton
                            style={{ flex: 1 }}
                        />
                    </Space.Compact>
                </>
            }
            innerContent={
                data ? (
                    data.length ? (
                        <>
                            <SimplifiedCustomTable
                                dataSource={getSupplementedData()}
                                columns={brokenRoundsData}
                            />
                            <Pagination
                                className="table-pagination"
                                onChange={onPageChange}
                                total={metadata.totalNumberOfElements}
                                current={metadata.pagingInfo.pageNumber}
                                pageSize={metadata.pagingInfo.pageSize}
                                pageSizeOptions={PAGE_SIZE_ARRAY}
                                showSizeChanger
                                onShowSizeChange={onShowSizeChange}
                            />
                            <Modal
                                className="broken-round-info__modal"
                                title={t('info')}
                                open={roundInfoPopupVisible}
                                onOk={() => changeInfoPopupVisibility(false)}
                                onCancel={() => changeInfoPopupVisibility(false)}
                                closable={false}
                                footer={[
                                    roundInfo.roundStatus.includes('BLOCKED') && (
                                        <SecondaryButton key={'retry'} onClick={onRetry}>
                                            <>
                                                {loadingRetry ? (
                                                    <LoadingOutlined
                                                        style={{
                                                            marginRight: '8px',
                                                        }}
                                                    />
                                                ) : null}
                                                {t('retry')}
                                            </>
                                        </SecondaryButton>
                                    ),
                                    roundInfo.roundStatus.includes('ON_RETRY') && (
                                        <SecondaryButton key={'block'} onClick={onBlock}>
                                            <>
                                                {loadingRetry ? (
                                                    <LoadingOutlined
                                                        style={{
                                                            marginRight: '8px',
                                                        }}
                                                    />
                                                ) : null}
                                                {t('block')}
                                            </>
                                        </SecondaryButton>
                                    ),
                                    <SecondaryButton
                                        key={'resolve_session'}
                                        onClick={onResolve}
                                        disabled={roundInfo.roundStatus.includes('RESOLVED')}
                                    >
                                        <>
                                            {loadingResolve ? (
                                                <LoadingOutlined
                                                    style={{
                                                        marginRight: '8px',
                                                    }}
                                                />
                                            ) : null}
                                            {t('resolve_session')}
                                        </>
                                    </SecondaryButton>,
                                    <MainButton
                                        key={'ok'}
                                        onClick={() => changeInfoPopupVisibility(false)}
                                    >
                                        {t('ok')}
                                    </MainButton>,
                                ]}
                            >
                                <div className="broken-round-info">{getInfo()}</div>
                            </Modal>
                            <Overlay
                                isVisible={roundInfoPopupVisible}
                                switchState={changeInfoPopupVisibility}
                            />
                        </>
                    ) : (
                        <Empty description={t('no_data')} />
                    )
                ) : (
                    <Loader style={{ height: '90vh' }} />
                )
            }
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getBrokenRoundsData(state),
    metadata: getBrokenRoundsMetadata(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getBrokenRounds: (data: IBrokenRoundRequestBody) => dispatch(getBrokenRounds(data)),
    resolveSession: (data: number) => dispatch(resolveSession(data)),
    retrySession: (data: string) => dispatch(retrySession(data)),
    blockSession: (data: string) => dispatch(blockSession(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrokenRoundsPage);
