import { createSelector } from 'reselect';

const getAddOperatorStep = (state: any) => state.addOperatorReducer.step;
const isLoadingDataOnAddingOperator = (state: any) =>
    state.addOperatorReducer.loading;
const isOperatorFullyLoaded = (state: any) => state.addOperatorReducer.loaded;

export const getCurrentAddOperatorStep = createSelector(
    getAddOperatorStep,
    (state) => state,
);
export const isLoadingOnAddingOperator = createSelector(
    isLoadingDataOnAddingOperator,
    (state) => state,
);
export const isOperatorLoaded = createSelector(
    isOperatorFullyLoaded,
    (state) => state,
);
