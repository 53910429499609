import {
    IAction,
    IPromotionData,
    IPromotionPlayer,
    IPromotionSummary,
} from '../../../helpers/interfaces';
import { PromotionTypes } from '../../actions/backoffice/promotions-actions';
import { errorHandler } from '../../../helpers/errorHandler';
import { dataFormatterUtils, Messages } from '../../../utils';
import i18next from 'i18next';

interface IPromotionsState {
    allCampaigns: IPromotionData[] | null;
    allCampaignsTotal: number | null;
    csv: any;
    campaignData: IPromotionData | null;
    campaignSummary: IPromotionSummary | null;
    isLoading: boolean;
    error: string | null;
    campaignPlayers: IPromotionPlayer[] | null;
    campaignPlayersTotal: number | null;
    isUpdateData: boolean;
}

const promotionsInitialState: IPromotionsState = {
    allCampaigns: null,
    allCampaignsTotal: null,
    isLoading: false,
    error: null,
    csv: null,
    campaignData: null,
    campaignSummary: null,
    campaignPlayers: null,
    campaignPlayersTotal: null,
    isUpdateData: false,
};

const promotionsReducer = (state = promotionsInitialState, actions: IAction) => {
    switch (actions.type) {
        case PromotionTypes.GET_PROMOTIONS_REQUEST: {
            return { ...state, isLoading: true, isUpdateData: false, error: null };
        }
        case PromotionTypes.GET_PROMOTIONS_SUCCESS: {
            const { content, total } = actions.payload;

            return { ...state, allCampaigns: content, allCampaignsTotal: total, isLoading: false };
        }
        case PromotionTypes.GET_PROMOTIONS_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            return { ...state, error, isLoading: false };
        }

        case PromotionTypes.CREATE_PROMOTION_WITH_CSV_REQUEST:
        case PromotionTypes.CREATE_PROMOTION_REQUEST: {
            return { ...state, isUpdateData: false };
        }
        case PromotionTypes.UPLOAD_CSV_SUCCESS:
        case PromotionTypes.CREATE_PROMOTION_SUCCESS: {
            Messages.success(i18next.t('new_campaign_has_been_created'));

            return { ...state, isUpdateData: true };
        }
        case PromotionTypes.CREATE_PROMOTION_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            Messages.error(error);

            return { ...state, error };
        }

        case PromotionTypes.GET_CAMPAIGN_REQUEST: {
            return { ...state, isUpdateData: false };
        }
        case PromotionTypes.GET_CAMPAIGN_SUCCESS: {
            const { campaignData, summary, players, csv } = actions.payload;

            return {
                ...state,
                csv,
                campaignData,
                campaignSummary: summary,
                campaignPlayers: dataFormatterUtils.getFormattedData(
                    players.content,
                    false,
                    campaignData.currencyCode,
                ),
                campaignPlayersTotal: players.total,
            };
        }
        case PromotionTypes.GET_CAMPAIGN_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            return { ...state, error };
        }

        case PromotionTypes.CLEAR_CAMPAIGN_DATA_REQUEST: {
            return {
                ...state,
                csv: null,
                campaignData: null,
                campaignSummary: null,
                campaignPlayers: null,
                campaignPlayersTotal: null,
            };
        }

        case PromotionTypes.UPLOAD_CSV_FAILED: {
            Messages.error(i18next.t('file_could_not_be_uploaded'));

            return { ...state };
        }

        case PromotionTypes.DELETE_CAMPAIGN_REQUEST: {
            return { ...state };
        }
        case PromotionTypes.DELETE_CAMPAIGN_SUCCESS: {
            Messages.success(i18next.t('campaign_has_been_deleted'));

            return { ...state };
        }
        case PromotionTypes.DELETE_CAMPAIGN_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            Messages.error(error);

            return { ...state };
        }

        case PromotionTypes.TERMINATE_CAMPAIGN_REQUEST: {
            return { ...state };
        }
        case PromotionTypes.TERMINATE_CAMPAIGN_SUCCESS: {
            Messages.success(i18next.t('campaign_status_has_been_changed_successfully'));

            return { ...state };
        }
        case PromotionTypes.TERMINATE_CAMPAIGN_FAILED: {
            const error = errorHandler.getErrorText(actions.payload);

            Messages.error(error);

            return { ...state };
        }

        case PromotionTypes.EDIT_PROMOTION_SUCCESS: {
            return { ...state, isUpdateData: true };
        }

        default:
            return state;
    }
};

export default promotionsReducer;
