export interface ITableData {
    title: string;
    tooltip: string;
    dataIndex: string;
    key: string;
    alignment: string;
    className?: string;
    withCurrency?: boolean;
    canBeFiltered?: boolean;
    canBeSorted?: boolean;
    stickyPosition?: number;
    totalLineOnly?: boolean;
    frData?: boolean;
    bfData?: boolean;
}

export const casinoReportData: ITableData[] = [
    {
        title: 'casino_report_from_date',
        tooltip: 'casino_report_from_date_tooltip',
        dataIndex: 'from',
        key: 'from',
        alignment: 'center',
        canBeSorted: true,
        stickyPosition: 0,
    },
    {
        title: 'casino_report_to_date',
        tooltip: 'casino_report_to_date_tooltip',
        dataIndex: 'to',
        key: 'to',
        alignment: 'center',
        canBeSorted: true,
        stickyPosition: 1,
    },
    {
        title: 'spins',
        tooltip: 'casino_report_total_spins_tooltip',
        dataIndex: 'spins',
        key: 'spins',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'currency_filter',
        tooltip: 'player_currency_tooltip',
        dataIndex: 'currencyId',
        key: 'currencyId',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'bet',
        tooltip: 'casino_report_total_bets_tooltip',
        dataIndex: 'bet',
        key: 'bet',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'win',
        tooltip: 'casino_report_payout_tooltip',
        dataIndex: 'win',
        key: 'win',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'net',
        tooltip: 'net_tooltip',
        dataIndex: 'net',
        key: 'net',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'rtp',
        tooltip: 'casino_report_rtp_tooltip',
        dataIndex: 'rtp',
        key: 'rtp',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'players',
        tooltip: 'casino_report_unique_players_tooltip',
        dataIndex: 'players',
        key: 'players',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'newPlayers',
        tooltip: 'casino_report_new_players_tooltip',
        dataIndex: 'newPlayers',
        key: 'newPlayers',
        alignment: 'right',
        canBeFiltered: false,
        canBeSorted: true,
    },
    {
        title: 'free_round_spins',
        tooltip: 'free_round_spins_tooltip',
        dataIndex: 'freeRoundsSpins',
        key: 'freeRoundsSpins',
        alignment: 'right',
        frData: true,
    },
    {
        title: 'free_round_bet',
        tooltip: 'free_round_bet_tooltip',
        dataIndex: 'freeRoundsBet',
        key: 'freeRoundsBet',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'free_round_net',
        tooltip: 'free_round_net_tooltip',
        dataIndex: 'freeRoundsNet',
        key: 'freeRoundsNet',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'free_round_rtp',
        tooltip: 'free_round_rtp_tooltip',
        dataIndex: 'freeRoundsRtp',
        key: 'freeRoundsRtp',
        alignment: 'right',
        frData: true,
    },
    {
        title: 'free_round_win',
        tooltip: 'free_round_win_tooltip',
        dataIndex: 'freeRoundsWin',
        key: 'freeRoundsWin',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_spins',
        tooltip: 'buy_feature_spins_tooltip',
        dataIndex: 'buyFeatureSpins',
        key: 'buyFeatureSpins',
        alignment: 'right',
        bfData: true,
    },
    {
        title: 'buy_feature_bet',
        tooltip: 'buy_feature_bet_tooltip',
        dataIndex: 'buyFeatureBet',
        key: 'buyFeatureBet',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_net',
        tooltip: 'buy_feature_net_tooltip',
        dataIndex: 'buyFeatureNet',
        key: 'buyFeatureNet',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_rtp',
        tooltip: 'buy_feature_rtp_tooltip',
        dataIndex: 'buyFeatureRtp',
        key: 'buyFeatureRtp',
        alignment: 'right',
        bfData: true,
    },
    {
        title: 'buy_feature_win',
        tooltip: 'buy_feature_win_tooltip',
        dataIndex: 'buyFeatureWin',
        key: 'buyFeatureWin',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
];

export const gamesReportData: ITableData[] = [
    {
        title: 'games_report_game_name',
        tooltip: 'games_report_game_name_tooltip',
        dataIndex: 'gameName',
        key: 'gameName',
        alignment: 'left',
        canBeSorted: true,
        stickyPosition: 0,
    },
    {
        title: 'casino_report_from_date',
        tooltip: 'casino_report_from_date_tooltip',
        dataIndex: 'fromDate',
        key: 'fromDate',
        alignment: 'center',
    },
    {
        title: 'casino_report_to_date',
        tooltip: 'casino_report_to_date_tooltip',
        dataIndex: 'toDate',
        key: 'toDate',
        alignment: 'center',
    },
    {
        title: 'players',
        tooltip: 'games_report_unique_players_tooltip',
        dataIndex: 'players',
        key: 'players',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'rounds',
        tooltip: 'casino_report_total_spins_tooltip',
        dataIndex: 'spins',
        key: 'spins',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'device_type',
        tooltip: 'device_tooltip',
        dataIndex: 'gamesDeviceType',
        key: 'gamesDeviceType',
        alignment: 'center',
    },
    {
        title: 'currency_filter',
        tooltip: 'player_currency_tooltip',
        dataIndex: 'currencyId',
        key: 'currencyId',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'bet',
        tooltip: 'games_report_total_bet_tooltip',
        dataIndex: 'bet',
        key: 'bet',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'win',
        tooltip: 'games_report_win_tooltip',
        dataIndex: 'win',
        key: 'win',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'net',
        tooltip: 'net_tooltip',
        dataIndex: 'net',
        key: 'net',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'rtp',
        tooltip: 'casino_report_rtp_tooltip',
        dataIndex: 'rtp',
        key: 'rtp',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'free_round_spins',
        tooltip: 'free_round_spins_tooltip',
        dataIndex: 'freeRoundsSpins',
        key: 'freeRoundsSpins',
        alignment: 'right',
        frData: true,
    },
    {
        title: 'free_round_bet',
        tooltip: 'free_round_bet_tooltip',
        dataIndex: 'freeRoundsBet',
        key: 'freeRoundsBet',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'free_round_net',
        tooltip: 'free_round_net_tooltip',
        dataIndex: 'freeRoundsNet',
        key: 'freeRoundsNet',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'free_round_rtp',
        tooltip: 'free_round_rtp_tooltip',
        dataIndex: 'freeRoundsRtp',
        key: 'freeRoundsRtp',
        alignment: 'right',
        frData: true,
    },
    {
        title: 'free_round_win',
        tooltip: 'free_round_win_tooltip',
        dataIndex: 'freeRoundsWin',
        key: 'freeRoundsWin',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_spins',
        tooltip: 'buy_feature_spins_tooltip',
        dataIndex: 'buyFeatureSpins',
        key: 'buyFeatureSpins',
        alignment: 'right',
        bfData: true,
    },
    {
        title: 'buy_feature_bet',
        tooltip: 'buy_feature_bet_tooltip',
        dataIndex: 'buyFeatureBet',
        key: 'buyFeatureBet',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_net',
        tooltip: 'buy_feature_net_tooltip',
        dataIndex: 'buyFeatureNet',
        key: 'buyFeatureNet',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_rtp',
        tooltip: 'buy_feature_rtp_tooltip',
        dataIndex: 'buyFeatureRtp',
        key: 'buyFeatureRtp',
        alignment: 'right',
        bfData: true,
    },
    {
        title: 'buy_feature_win',
        tooltip: 'buy_feature_win_tooltip',
        dataIndex: 'buyFeatureWin',
        key: 'buyFeatureWin',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
];

export const playersReportData: ITableData[] = [
    {
        title: 'players',
        tooltip: 'games_report_unique_players_tooltip',
        dataIndex: 'players',
        key: 'players',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'externalPlayerId',
        tooltip: 'players_report_player_login_tooltip',
        dataIndex: 'login',
        key: 'login',
        alignment: 'left',
        canBeSorted: true,
        stickyPosition: 0,
    },
    {
        title: 'casino_report_from_date',
        tooltip: 'casino_report_from_date_tooltip',
        dataIndex: 'fromDate',
        key: 'fromDate',
        alignment: 'center',
    },
    {
        title: 'casino_report_to_date',
        tooltip: 'casino_report_to_date_tooltip',
        dataIndex: 'toDate',
        key: 'toDate',
        alignment: 'center',
    },
    {
        title: 'rounds',
        tooltip: 'casino_report_total_spins_tooltip',
        dataIndex: 'spins',
        key: 'spins',
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'currency_filter',
        tooltip: 'player_currency_tooltip',
        dataIndex: 'currencyId',
        key: 'currencyId',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'bet',
        tooltip: 'players_report_total_bets_tooltip',
        dataIndex: 'bet',
        key: 'bet',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'win',
        tooltip: 'players_report_total_player_win_tooltip',
        dataIndex: 'win',
        key: 'win',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'net',
        tooltip: 'net_tooltip',
        dataIndex: 'net',
        key: 'net',
        withCurrency: true,
        canBeFiltered: false,
        alignment: 'right',
        canBeSorted: true,
    },
    {
        title: 'rtp',
        tooltip: 'casino_report_rtp_tooltip',
        dataIndex: 'rtp',
        key: 'rtp',
        alignment: 'right',
        canBeFiltered: false,
        canBeSorted: true,
    },
    {
        title: 'free_round_spins',
        tooltip: 'free_round_spins_tooltip',
        dataIndex: 'freeRoundsSpins',
        key: 'freeRoundsSpins',
        alignment: 'right',
        frData: true,
    },
    {
        title: 'free_round_bet',
        tooltip: 'free_round_bet_tooltip',
        dataIndex: 'freeRoundsBet',
        key: 'freeRoundsBet',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'free_round_net',
        tooltip: 'free_round_net_tooltip',
        dataIndex: 'freeRoundsNet',
        key: 'freeRoundsNet',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'free_round_rtp',
        tooltip: 'free_round_rtp_tooltip',
        dataIndex: 'freeRoundsRtp',
        key: 'freeRoundsRtp',
        alignment: 'right',
        frData: true,
    },
    {
        title: 'free_round_win',
        tooltip: 'free_round_win_tooltip',
        dataIndex: 'freeRoundsWin',
        key: 'freeRoundsWin',
        alignment: 'right',
        frData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_spins',
        tooltip: 'buy_feature_spins_tooltip',
        dataIndex: 'buyFeatureSpins',
        key: 'buyFeatureSpins',
        alignment: 'right',
        bfData: true,
    },
    {
        title: 'buy_feature_bet',
        tooltip: 'buy_feature_bet_tooltip',
        dataIndex: 'buyFeatureBet',
        key: 'buyFeatureBet',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_net',
        tooltip: 'buy_feature_net_tooltip',
        dataIndex: 'buyFeatureNet',
        key: 'buyFeatureNet',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
    {
        title: 'buy_feature_rtp',
        tooltip: 'buy_feature_rtp_tooltip',
        dataIndex: 'buyFeatureRtp',
        key: 'buyFeatureRtp',
        alignment: 'right',
        bfData: true,
    },
    {
        title: 'buy_feature_win',
        tooltip: 'buy_feature_win_tooltip',
        dataIndex: 'buyFeatureWin',
        key: 'buyFeatureWin',
        alignment: 'right',
        bfData: true,
        withCurrency: true,
    },
];

export const playerReportData: ITableData[] = [
    {
        title: 'game_name',
        tooltip: 'game_name_tooltip',
        dataIndex: 'game_name_with_id',
        key: 'game_name_with_id',
        alignment: 'left',
        canBeSorted: true,
        canBeFiltered: false,
    },
    {
        title: 'roundId',
        tooltip: 'roundUuid',
        dataIndex: 'roundId',
        key: 'roundId',
        alignment: 'left',
    },
    {
        title: 'game_session_id',
        tooltip: 'game_session_id_tooltip',
        dataIndex: 'gameSessionId',
        key: 'gameSessionId',
        alignment: 'center',
        canBeFiltered: false,
        canBeSorted: true,
    },
    {
        title: 'country_city',
        tooltip: 'country_tooltip',
        dataIndex: 'country',
        key: 'country',
        alignment: 'left',
        canBeFiltered: false,
        canBeSorted: true,
    },
    {
        title: 'played_at',
        tooltip: 'played_at_tooltip',
        dataIndex: 'endTime',
        key: 'endTime',
        alignment: 'center',
        canBeSorted: true,
    },
    {
        title: 'device_type',
        tooltip: 'device_tooltip',
        dataIndex: 'deviceType',
        key: 'deviceType',
        alignment: 'center',
    },
    {
        title: 'type',
        tooltip: 'round_type_tooltip',
        dataIndex: 'type',
        key: 'type',
        alignment: 'center',
        frData: true,
    },
    {
        title: 'Status',
        tooltip: '',
        dataIndex: 'finished',
        key: 'finished',
        alignment: 'center',
    },
    {
        title: 'promotion',
        tooltip: 'promotion_tooltip',
        dataIndex: 'promotionId',
        key: 'promotionId',
        alignment: 'center',
        frData: true,
    },
    {
        title: 'bet',
        tooltip: 'bet_tooltip',
        dataIndex: 'bet',
        key: 'bet',
        alignment: 'right',
        withCurrency: true,
        canBeSorted: true,
        canBeFiltered: true,
    },
    {
        title: 'win',
        tooltip: 'payout_tooltip',
        dataIndex: 'win',
        key: 'win',
        alignment: 'right',
        withCurrency: true,
        canBeSorted: true,
        canBeFiltered: true,
    },
    {
        title: 'net',
        tooltip: 'net_tooltip',
        dataIndex: 'net',
        key: 'net',
        alignment: 'right',
        withCurrency: true,
        canBeSorted: true,
        canBeFiltered: true,
    },
    {
        title: 'start_balance',
        tooltip: 'start_balance_tooltip',
        dataIndex: 'startBalance',
        key: 'startBalance',
        alignment: 'right',
        withCurrency: true,
        canBeSorted: true,
        canBeFiltered: true,
    },
    {
        title: 'end_balance',
        tooltip: 'end_balance_tooltip',
        dataIndex: 'balance',
        key: 'balance',
        alignment: 'right',
        withCurrency: true,
        canBeSorted: true,
        canBeFiltered: true,
    },
    {
        title: 'spins',
        tooltip: 'casino_report_total_spins_tooltip',
        dataIndex: 'spins',
        key: 'spins',
        alignment: 'right',
        totalLineOnly: true,
    },
    {
        title: 'rtp',
        tooltip: 'casino_report_rtp_tooltip',
        dataIndex: 'rtp',
        key: 'rtp',
        alignment: 'right',
        totalLineOnly: true,
    },
    {
        title: 'visual_log',
        tooltip: 'visual_log_tooltip',
        dataIndex: 'callback',
        key: 'callback',
        alignment: 'center',
    },
];

export const usersData: ITableData[] = [
    {
        title: 'name',
        tooltip: '',
        dataIndex: 'name',
        key: 'name',
        alignment: 'left',
        stickyPosition: 0,
    },
    {
        title: 'login_email',
        tooltip: '',
        dataIndex: 'email',
        key: 'email',
        alignment: 'left',
    },
    {
        title: 'role',
        tooltip: '',
        dataIndex: 'role',
        key: 'role',
        alignment: 'left',
    },
    {
        title: 'account_status',
        tooltip: '',
        dataIndex: 'blocked',
        key: 'blocked',
        alignment: 'left',
    },
    {
        title: 'actions',
        tooltip: '',
        dataIndex: 'actions',
        key: 'actions',
        alignment: 'center',
    },
];

export const CP_OperatorsData: ITableData[] = [
    {
        title: 'serial_number_title',
        tooltip: 'serial_number_tooltip',
        dataIndex: 'index',
        key: 'index',
        alignment: 'center',
        stickyPosition: 0,
    },
    {
        title: 'name',
        tooltip: '',
        dataIndex: 'name',
        key: 'name',
        alignment: 'left',
        stickyPosition: 1,
        canBeSorted: true,
    },
    {
        title: 'id',
        tooltip: '',
        dataIndex: 'id',
        key: 'id',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'status',
        tooltip: '',
        dataIndex: 'status',
        key: 'status',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'actions',
        tooltip: '',
        dataIndex: 'actions',
        key: 'actions',
        alignment: 'center',
    },
];

export const CP_OperatorCurrenciesData: ITableData[] = [
    {
        title: 'serial_number_title',
        tooltip: 'serial_number_tooltip',
        dataIndex: 'index',
        key: 'index',
        alignment: 'center',
        stickyPosition: 0,
    },
    {
        title: 'currency_name',
        tooltip: '',
        dataIndex: 'name',
        key: 'name',
        alignment: 'left',
        stickyPosition: 1,
        canBeSorted: true,
    },
    {
        title: 'code',
        tooltip: '',
        dataIndex: 'code',
        key: 'code',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'id',
        tooltip: '',
        dataIndex: 'id',
        key: 'id',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'subunit',
        tooltip: '',
        dataIndex: 'subunit',
        key: 'subunit',
        alignment: 'left',
    },
];

export const CP_OperatorGamesData: ITableData[] = [
    {
        title: 'serial_number_title',
        tooltip: 'serial_number_tooltip',
        dataIndex: 'index',
        key: 'index',
        alignment: 'center',
        stickyPosition: 0,
    },
    {
        title: 'game_name',
        tooltip: '',
        dataIndex: 'gameName',
        key: 'gameName',
        alignment: 'left',
        stickyPosition: 1,
        canBeSorted: true,
    },
    {
        title: 'id',
        tooltip: '',
        dataIndex: 'gameId',
        key: 'gameId',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'free_rounds',
        tooltip: '',
        dataIndex: 'freeRoundsEnabled',
        key: 'freeRoundsEnabled',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'buy_feature',
        tooltip: '',
        dataIndex: 'buyFeatureEnabled',
        key: 'buyFeatureEnabled',
        alignment: 'left',
        canBeSorted: true,
    },
];

export const CP_OperatorBrandsData: ITableData[] = [
    {
        title: 'serial_number_title',
        tooltip: 'serial_number_tooltip',
        dataIndex: 'index',
        key: 'index',
        alignment: 'center',
        stickyPosition: 0,
    },
    {
        title: 'brand_name',
        tooltip: '',
        dataIndex: 'name',
        key: 'name',
        alignment: 'left',
        stickyPosition: 1,
        canBeSorted: true,
    },
    {
        title: 'id',
        tooltip: '',
        dataIndex: 'id',
        key: 'id',
        alignment: 'left',
        canBeSorted: true,
    },
    {
        title: 'status',
        tooltip: '',
        dataIndex: 'status',
        key: 'status',
        alignment: 'left',
    },
    {
        title: 'service_url',
        tooltip: '',
        dataIndex: 'serviceUrl',
        key: 'serviceUrl',
        alignment: 'left',
    },
    {
        title: 'service_login',
        tooltip: '',
        dataIndex: 'serviceLogin',
        key: 'serviceLogin',
        alignment: 'left',
    },
    {
        title: 'service_password',
        tooltip: '',
        dataIndex: 'servicePassword',
        key: 'servicePassword',
        alignment: 'left',
    },
    {
        title: 'admin_logins',
        tooltip: '',
        dataIndex: 'accounts',
        key: 'accounts',
        alignment: 'left',
    },
    {
        title: 'actions',
        tooltip: '',
        dataIndex: 'actions',
        key: 'actions',
        alignment: 'left',
    },
];

export const promotionPageData: ITableData[] = [
    {
        title: 'round',
        tooltip: '',
        dataIndex: 'round',
        key: 'round',
        alignment: 'left',
    },
    {
        title: 'startedAt',
        tooltip: '',
        dataIndex: 'startedAt',
        key: 'startedAt',
        alignment: 'left',
    },
    {
        title: 'gameName',
        tooltip: '',
        dataIndex: 'gameName',
        key: 'gameName',
        alignment: 'left',
    },
    {
        title: 'gameId',
        tooltip: '',
        dataIndex: 'gameId',
        key: 'gameId',
        alignment: 'left',
    },
    {
        title: 'win',
        tooltip: '',
        dataIndex: 'win',
        key: 'win',
        alignment: 'left',
        withCurrency: true,
    },
];

export const brokenRoundsData: ITableData[] = [
    {
        title: 'start_time',
        tooltip: '',
        dataIndex: 'roundStartedAt',
        key: 'roundStartedAt',
        alignment: 'left',
    },
    {
        title: 'end_time',
        tooltip: '',
        dataIndex: 'roundFailedAt',
        key: 'roundFailedAt',
        alignment: 'left',
    },
    {
        title: 'amount',
        tooltip: '',
        dataIndex: 'amount',
        key: 'amount',
        alignment: 'left',
    },
    {
        title: 'roundUuid',
        tooltip: '',
        dataIndex: 'roundUuid',
        key: 'roundUuid',
        alignment: 'left',
    },
    {
        title: 'transactionUuid',
        tooltip: '',
        dataIndex: 'transactionUuid',
        key: 'transactionUuid',
        alignment: 'left',
    },
    {
        title: 'brandName',
        tooltip: '',
        dataIndex: 'brandName',
        key: 'brandName',
        alignment: 'left',
    },
    {
        title: 'sessionId',
        tooltip: '',
        dataIndex: 'sessionId',
        key: 'sessionId',
        alignment: 'left',
    },
    {
        title: 'externalPlayerId',
        tooltip: '',
        dataIndex: 'externalPlayerId',
        key: 'externalPlayerId',
        alignment: 'left',
    },
    {
        title: 'transaction_type_status',
        tooltip: '',
        dataIndex: 'roundStatus',
        key: 'roundStatus',
        alignment: 'left',
    },
    {
        title: 'info',
        tooltip: '',
        dataIndex: 'info',
        key: 'info',
        alignment: 'center',
    },
];

export const CP_OperatorManageGamesData: ITableData[] = [
    {
        title: '№',
        tooltip: '',
        dataIndex: 'num',
        key: 'num',
        alignment: 'left',
    },
    {
        title: 'name',
        tooltip: '',
        dataIndex: 'name',
        key: 'name',
        alignment: 'left',
    },
    {
        title: 'id',
        tooltip: '',
        dataIndex: 'id',
        key: 'id',
        alignment: 'left',
    },
    {
        title: 'game_status',
        tooltip: '',
        dataIndex: 'status',
        key: 'status',
        alignment: 'left',
    },
    {
        title: 'free_rounds_status',
        tooltip: '',
        dataIndex: 'freeRoundsEnabled',
        key: 'freeRoundsEnabled',
        alignment: 'left',
    },
    {
        title: 'buy_feature_status',
        tooltip: '',
        dataIndex: 'buyFeatureEnabled',
        key: 'buyFeatureEnabled',
        alignment: 'left',
    },
];

export const CriticalChecksumData: ITableData[] = [
    {
        title: 'gameId',
        tooltip: '',
        dataIndex: 'gameId',
        key: 'fileId',
        alignment: 'left',
    },
    {
        title: 'fileName',
        tooltip: '',
        dataIndex: 'fileNameVersion',
        key: 'fileNameVersion',
        alignment: 'left',
    },
    {
        title: 'status',
        tooltip: '',
        dataIndex: 'success',
        key: 'success',
        alignment: 'left',
    },
];

export const unfinishedRoundsData: ITableData[] = [
    {
        title: '',
        tooltip: '',
        dataIndex: 'checkbox',
        key: 'checkbox',
        alignment: 'center',
    },
    {
        title: 'startedAt',
        tooltip: '',
        dataIndex: 'startedAt',
        key: 'startedAt',
        alignment: 'left',
    },
    {
        title: 'brandId',
        tooltip: '',
        dataIndex: 'brandId',
        key: 'brandId',
        alignment: 'left',
    },
    {
        title: 'externalPlayerId',
        tooltip: '',
        dataIndex: 'externalPlayerId',
        key: 'externalPlayerId',
        alignment: 'left',
    },
    {
        title: 'gameId',
        tooltip: '',
        dataIndex: 'gameId',
        key: 'gameId',
        alignment: 'left',
    },
    {
        title: 'roundUuid',
        tooltip: '',
        dataIndex: 'roundUuid',
        key: 'roundUuid',
        alignment: 'left',
    },
    {
        title: 'transactionUuid',
        tooltip: '',
        dataIndex: 'transactionUuid',
        key: 'transactionUuid',
        alignment: 'left',
    },
    {
        title: 'sessionId',
        tooltip: '',
        dataIndex: 'sessionId',
        key: 'sessionId',
        alignment: 'left',
    },
    {
        title: 'startId',
        tooltip: '',
        dataIndex: 'startId',
        key: 'startId',
        alignment: 'left',
    },
];
