import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, Location, useParams } from 'react-router-dom';

type WithRouterProps = {
    navigate: ReturnType<typeof useNavigate>;
    location: Location;
    history: {
        push: ReturnType<typeof useNavigate>;
        listen: (callback: (location: Location) => void) => () => void;
    };
};

export const withRouter = (Component: React.ComponentType<any & WithRouterProps>) => {
    const WithRouterWrapper = (props: any) => {
        const params = useParams();
        const navigate = useNavigate();
        const location = useLocation();
        const listeners = useRef<Array<(location: Location) => void>>([]);

        // Custom listen method
        const listen = (callback: (location: Location) => void) => {
            listeners.current.push(callback);

            // Return a function that will allow users to unsubscribe
            return () => {
                listeners.current = listeners.current.filter((l) => l !== callback);
            };
        };

        useEffect(() => {
            // Notify all listeners about the location change
            listeners.current.forEach((callback) => callback(location));
        }, [location]);

        const history = {
            push: navigate,
            listen,
        };

        return (
            <Component
                {...props}
                navigate={navigate}
                location={location}
                history={history}
                params={params}
            />
        );
    };

    // This is the key part to set the display name
    WithRouterWrapper.displayName = `WithRouter(${getDisplayName(Component)})`;

    return WithRouterWrapper;
};

// Helper function to get the display name of a component
function getDisplayName(Component: React.ComponentType<any>): string {
    return Component.displayName || Component.name || 'Component';
}
