import dotProp from 'dot-prop-immutable';
import {
    localStorageHelper,
    Messages,
    sort,
    sortCPCurrenciesArray,
} from 'utils';
import i18next from 'i18next';
import { OperatorsTypes } from 'redux/actions/configProvider/operators-actions';
import { IAction, ICurrencyData } from 'helpers/interfaces';

interface IOperatorsCP {
    operatorsCP: any;
    sortedOperatorsCP: any;
    changingOperatorStatusData: any;
    currentOperatorGameConfigs: any;
    currentOperatorCurrencies: any;
    operatorCurrenciesCP: any;
    sortedOperatorCurrenciesCP: any;
    currentOperatorGames: any;
    operatorGamesCP: any;
    sortedOperatorGamesCP: any;
    isStatusChanged: boolean | null;
    isGameConfigUpdated: any;
    isOperatorGamesUpdated: any;
    isOperatorCurrenciesUpdated: any;
    loading: boolean;
}

const defaultState: IOperatorsCP = {
    operatorsCP: null,
    sortedOperatorsCP: null,
    changingOperatorStatusData: null,
    isStatusChanged: null,
    currentOperatorGameConfigs: null,
    currentOperatorCurrencies: null,
    operatorCurrenciesCP: null,
    sortedOperatorCurrenciesCP: null,
    currentOperatorGames: null,
    operatorGamesCP: null,
    sortedOperatorGamesCP: null,
    isGameConfigUpdated: null,
    isOperatorGamesUpdated: null,
    isOperatorCurrenciesUpdated: null,
    loading: false,
};

const operatorsReducer = (state = defaultState, actions: IAction) => {
    const { operatorsCP, changingOperatorStatusData } = state;

    switch (actions.type) {
        case OperatorsTypes.GET_ALL_OPERATORS_REQUEST: {
            return { ...defaultState };
        }

        case OperatorsTypes.GET_ALL_OPERATORS_SUCCESS: {
            return dotProp.set(state, 'operatorsCP', actions.payload);
        }

        case OperatorsTypes.GET_ALL_OPERATORS_FAILED: {
            return state;
        }

        case OperatorsTypes.SORT_ALL_OPERATORS: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedOperatorsCP: sortState
                    ? sort(actions.payload, state.operatorsCP)
                    : [],
            };
        }

        case OperatorsTypes.CHANGE_OPERATOR_STATUS_REQUEST: {
            return {
                ...state,
                isStatusChanged: null,
                changingOperatorStatusData: actions.payload,
            };
        }

        case OperatorsTypes.CHANGE_OPERATOR_STATUS_SUCCESS: {
            operatorsCP &&
                operatorsCP.forEach((operator: any, i: number) => {
                    if (operator.id === changingOperatorStatusData.id) {
                        operatorsCP[i].enabled =
                            changingOperatorStatusData.enabled;
                    }
                });

            Messages.success(
                i18next.t('operators_status_has_been_successfully_updated'),
            );

            return dotProp.set(state, 'isStatusChanged', true);
        }

        case OperatorsTypes.CHANGE_OPERATOR_STATUS_FAILED: {
            Messages.error(
                i18next.t([
                    'operators_status_wasnt_updated',
                    'please_try_again',
                ]),
            );

            return state;
        }

        case OperatorsTypes.REFRESH_SERVERS_SUCCESS: {
            Messages.success(i18next.t('servers_refreshed_successfully'));

            return state;
        }

        case OperatorsTypes.REFRESH_SERVERS_FAILED: {
            return state;
        }

        case OperatorsTypes.GET_OPERATOR_GAME_CONFIGS_SUCCESS: {
            return dotProp.set(
                state,
                'currentOperatorGameConfigs',
                actions.payload,
            );
        }

        case OperatorsTypes.UPDATE_OPERATOR_GAME_CONFIGS_SUCCESS: {
            Messages.success(i18next.t('changes_successfully_saved'));

            return { ...state, isGameConfigUpdated: true };
        }

        case OperatorsTypes.UPDATE_OPERATOR_GAME_CONFIGS_FAILED: {
            Messages.error(i18next.t('changes_could_not_saved'));

            return state;
        }

        case OperatorsTypes.GET_OPERATORS_CURRENCIES_SUCCESS: {
            return {
                ...state,
                operatorCurrenciesCP: sortCPCurrenciesArray(
                    localStorageHelper.getChainedValue('user.allCurrencies'),
                    'code',
                    'asc',
                    false,
                ).filter((storedCurrency: ICurrencyData) =>
                    actions.payload.find(
                        (currency: any) =>
                            storedCurrency.id === currency.currencyId,
                    ),
                ),
                currentOperatorCurrencies: actions.payload,
            };
        }

        case OperatorsTypes.SORT_OPERATORS_CURRENCIES: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedOperatorCurrenciesCP: sortState
                    ? sort(actions.payload, state.operatorCurrenciesCP)
                    : [],
            };
        }

        case OperatorsTypes.GET_OPERATORS_GAMES_SUCCESS: {
            return {
                ...state,
                operatorGamesCP: actions.payload,
                currentOperatorGames: actions.payload,
            };
        }

        case OperatorsTypes.SORT_OPERATORS_GAMES: {
            const { sortState } = actions.payload;

            return {
                ...state,
                sortedOperatorGamesCP: sortState
                    ? sort(actions.payload, state.operatorGamesCP)
                    : [],
            };
        }

        case OperatorsTypes.UPDATE_OPERATORS_GAMES_SUCCESS: {
            Messages.success(i18next.t('changes_successfully_saved'));

            return dotProp.set(
                { ...state, isOperatorGamesUpdated: true },
                'currentOperatorGames',
                actions.payload,
            );
        }

        case OperatorsTypes.UPDATE_OPERATORS_GAMES_FAILED: {
            return state;
        }

        case OperatorsTypes.UPDATE_OPERATORS_CURRENCIES_SUCCESS: {
            Messages.success(i18next.t('changes_successfully_saved'));

            return { ...state, isOperatorCurrenciesUpdated: true };
        }

        case OperatorsTypes.UPDATE_OPERATORS_CURRENCIES_FAILED: {
            return state;
        }

        case OperatorsTypes.LOGOUT_ALL_OPERATOR_SESSIONS_REQUEST: {
            return { ...state, loading: true };
        }

        case OperatorsTypes.LOGOUT_ALL_OPERATOR_SESSIONS_SUCCESS: {
            Messages.success(
                i18next.t('sessions_have_been_successfully_suspended'),
            );

            return { ...state, loading: false };
        }

        case OperatorsTypes.LOGOUT_ALL_OPERATOR_SESSIONS_FAILED: {
            return { ...state, loading: false };
        }

        default:
            return state;
    }
};

export default operatorsReducer;
