import * as React from 'react';
import { connect } from 'react-redux';
import '../../commonCPStyles.scss';
import './commonCPGamesStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { Button, Dropdown, MenuProps } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import { IConfig, IGameItemData } from 'helpers/interfaces';
import { HeaderTitleComponent } from 'Components/ContentComponent/HeaderTitleComponent';
import {
    CaretDownOutlined,
    CaretUpOutlined,
    EditOutlined,
    EllipsisOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons/lib';
import { Link } from 'react-router-dom';
import { localStorageHelper } from 'utils';
import { getDefaultGameConfig } from 'redux/actions/configProvider/games-actions';
import { getDefaultConfig } from 'redux/selectors/configProvider/games-selectors';
import { getAllGames } from '../../../redux/actions/configProvider/general-actions';

interface IProps {
    configs: IConfig[];
    getAllGames: Function;
    getDefaultConfigs: Function;
    error: string;
}

type DataKeysForSorting = 'id' | 'name' | 'currencies';

interface IState {
    keyToSort: DataKeysForSorting;
    sortState: 'asc' | 'desc';
}

interface IGameItemFullData extends IGameItemData {
    currencies: number;
}

const COLUMNS: string[] = [
    'counter_column',
    'name',
    'id',
    'currencies',
    'free_rounds',
    'buy_feature',
    'actions',
];

class Games extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        keyToSort: 'id',
        sortState: 'desc',
    };

    getActions = (game: IGameItemFullData) => (
        <Dropdown menu={{ items: this.getActionsMenuItems(game) }} trigger={['click']}>
            <Button className={'cp-actions'}>
                <EllipsisOutlined />
            </Button>
        </Dropdown>
    );

    getActionsMenuItems = (game: IGameItemFullData): MenuProps['items'] => {
        const { id } = game;
        const { t } = this.props;

        return [
            {
                key: id,
                label: (
                    <Link to={`/config-provider/games/${id}/game-configuration`}>
                        <EditOutlined />
                        {t('edit_game_configurations')}
                    </Link>
                ),
            },
        ];
    };

    sortStateHandler = (key: DataKeysForSorting) => {
        const { keyToSort, sortState } = this.state;

        if (key === keyToSort) {
            if (sortState === 'asc') {
                this.setState({ sortState: 'desc' });
            } else {
                this.setState({ sortState: 'asc' });
            }
        } else {
            this.setState({ keyToSort: key, sortState: 'asc' });
        }
    };

    getTable = () => {
        const data = this.getData();
        const { keyToSort, sortState } = this.state;
        const { t } = this.props;

        return (
            <ItemComponentWrapper customClass="cp-table__wrapper">
                <table className="cp-table">
                    <thead className="cp-table__header">
                        <tr>
                            {COLUMNS.map((columnTitle: string) => (
                                <th key={columnTitle} className="cp-table-cell">
                                    <div className="cell-inner">
                                        <div className="cell-inner__content">{t(columnTitle)}</div>
                                        {columnTitle === 'id' ||
                                        columnTitle === 'name' ||
                                        columnTitle === 'currencies' ? (
                                            <div
                                                className={`sorter${
                                                    keyToSort && keyToSort === columnTitle
                                                        ? ` ${sortState}`
                                                        : ''
                                                }`}
                                                onClick={() => this.sortStateHandler(columnTitle)}
                                            >
                                                <CaretUpOutlined />
                                                <CaretDownOutlined />
                                            </div>
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="cp-table__body">
                        {data.map((game: IGameItemFullData, i: number) => (
                            <tr key={game.name}>
                                <td className="cp-table__cell cp-table__cell__number">{i + 1}</td>
                                <td className="cp-table__cell cp-table__cell__name">{game.name}</td>
                                <td className="cp-table__cell cp-table__cell__id">{game.id}</td>
                                <td className="cp-table__cell cp-table__cell__currencies">
                                    {game.currencies}
                                </td>
                                <td className="cp-table__cell cp-table__cell__fr">
                                    <span
                                        className={`account-status${
                                            game.freeRoundsSupported ? '' : ' blocked'
                                        }`}
                                    >
                                        {t(game.freeRoundsSupported ? 'active' : 'disabled')}
                                    </span>
                                </td>
                                <td className="cp-table__cell cp-table__cell__fr">
                                    <span
                                        className={`account-status${
                                            game.buyFeatureSupported ? '' : ' blocked'
                                        }`}
                                    >
                                        {t(game.buyFeatureSupported ? 'active' : 'disabled')}
                                    </span>
                                </td>
                                <td className="cp-table__cell cp-table__cell__action">
                                    {this.getActions(game)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ItemComponentWrapper>
        );
    };

    getData = () => {
        const { configs } = this.props;
        const { keyToSort, sortState } = this.state;
        const games = localStorageHelper.getChainedValue('user.allGames');

        games.forEach((game: IGameItemFullData) => {
            let currencies = 0;

            configs.forEach((config: IConfig) => config.gameId === game.id && currencies++);

            game.currencies = currencies;
        });

        return this.getSortedData(games, keyToSort, sortState);
    };

    getSortedData = (data: IGameItemFullData[], key: DataKeysForSorting, state: 'asc' | 'desc') =>
        data.sort((a: IGameItemFullData, b: IGameItemFullData) => {
            if (a[key] > b[key]) {
                return state === 'asc' ? 1 : -1;
            } else {
                if (b[key] > a[key]) {
                    return state === 'asc' ? -1 : 1;
                } else {
                    return 0;
                }
            }
        });

    componentDidMount = () => {
        this.props.getAllGames();
        this.props.getDefaultConfigs([]);
    };

    render = () => {
        const { t, configs } = this.props;

        return (
            <ContentComponent
                header={
                    <div className="header-line cp-header-line">
                        <HeaderTitleComponent
                            title={t('games')}
                            customBreadcrumbs={<div>{t('all')}</div>}
                        />
                        <div className="cp-header-buttons">
                            <div className="cp-header-buttons__button">
                                <Link
                                    id="cp-header-button__wrapper"
                                    to={`/config-provider/games/add-game`}
                                >
                                    <Button shape="round" type="primary">
                                        <PlusCircleOutlined /> {t('add_game')}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                innerContent={
                    configs && configs.length ? (
                        this.getTable()
                    ) : (
                        <Loader style={{ height: '90vh' }} />
                    )
                }
            />
        );
    };
}

const mapStateToProps = (state: any) => ({
    configs: getDefaultConfig(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getDefaultConfigs: () => dispatch(getDefaultGameConfig()),
    getAllGames: () => dispatch(getAllGames()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Games));
