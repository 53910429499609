import * as React from 'react';
import './ReportsMenuItem.scss';
import './ReportsMenuItemMedia.scss';
import { IReportType } from 'helpers/interfaces';
import * as i18next from 'i18next';
import { withRouter } from '../../../helpers/HOCs/withRouter';

interface IProps {
    t: i18next.TFunction;
    properties: IReportType;
    isEnabled: boolean;
    navigate: Function;
}

const ReportsMenuItem = (props: IProps) => {
    const { t, properties, isEnabled, navigate } = props;
    const { icon, description, link } = properties;

    return (
        <div
            className={`plates-container__item reports-plate ${isEnabled ? '' : 'disabled'}`}
            onClick={() => navigate(link)}
        >
            {icon}
            <p className={'reports-plate__title'}>{t(description)}</p>
        </div>
    );
};

export default withRouter(ReportsMenuItem);
