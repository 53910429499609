import * as React from 'react';
import { Form, Input, Modal } from 'antd';
import './UsersComponent.scss';
import * as i18next from 'i18next';
import { FormInstance } from 'antd/lib/form';

interface IProps {
    name: string;
    visible: boolean;
    onCancel: Function;
    onSubmit: Function;
    t: i18next.TFunction;
}

export class ChangeUserNamePopupComponent extends React.Component<IProps> {
    form: React.RefObject<FormInstance> = React.createRef<FormInstance>();

    onCancel = () => {
        this.form.current && this.form.current.resetFields();
        this.props.onCancel();
    };

    onSubmit = () => {
        const { current } = this.form;

        if (current) {
            current
                .validateFields()
                .then((values: any) => {
                    current.resetFields();
                    this.props.onSubmit(values);
                })
                .catch();
        }
    };

    validateName = (obj: any, value: string) => {
        const { current } = this.form;

        if (current) {
            const { validateFields, getFieldsValue } = current;

            if (value) {
                value = value.trim();
            }

            if (!!value && value.length > 1 && value.length < 65) {
                getFieldsValue().password && validateFields(['password']);

                return Promise.resolve();
            }
        }

        return Promise.reject('error');
    };

    render = () => {
        const { visible, name, t } = this.props;

        return (
            <Modal
                className="changeUserName"
                open={visible}
                title={t('edit_user_name')}
                okText={t('save')}
                onCancel={this.onCancel}
                onOk={this.onSubmit}
            >
                <Form layout="vertical" ref={this.form}>
                    <Form.Item
                        label={t('user_name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('name_must_be_between_2_and_64_characters'),
                                validator: this.validateName,
                            },
                        ]}
                    >
                        <Input type="text" size="large" placeholder={name} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };
}
