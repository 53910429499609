import { IAction } from 'helpers/interfaces';
import { AddOperatorsTypes } from 'redux/actions/configProvider/add-operator-actions';
import { localStorageHelper, Messages } from 'utils';
import i18next from 'i18next';

interface IAddOperatorData {
    step: number;
    loading?: boolean;
    error?: string;
    loaded?: boolean;
    addedConfig?: any;
}

export const addOperatorInitialState: IAddOperatorData = {
    step: 0,
};

const addOperatorReducer = (state = addOperatorInitialState, actions: IAction) => {
    switch (actions.type) {
        case AddOperatorsTypes.ADD_OPERATOR_NAME_REQUEST: {
            return { ...addOperatorInitialState, loading: true };
        }

        case AddOperatorsTypes.ADD_OPERATOR_GAMES_REQUEST:
        case AddOperatorsTypes.ADD_OPERATOR_CURRENCIES_REQUEST:
        case AddOperatorsTypes.ADD_BRANDS_REQUEST: {
            return { ...state, loading: true };
        }

        case AddOperatorsTypes.ADD_OPERATOR_NAME_FAILED:
        case AddOperatorsTypes.ADD_OPERATOR_GAMES_FAILED:
        case AddOperatorsTypes.ADD_OPERATOR_CURRENCIES_FAILED:
        case AddOperatorsTypes.ADD_GAME_CONFIGURATIONS_FAILED: {
            return { ...state, loading: false };
        }

        case AddOperatorsTypes.ADD_OPERATOR_NAME_SUCCESS: {
            const { name, id } = actions.payload;

            localStorageHelper
                .setChainedValue('user.newOperator.name', name)
                .setChainedValue('user.newOperator.id', id)
                .setChainedValue('user.newOperator.step', 1);

            return { step: 1 };
        }

        case AddOperatorsTypes.ADD_OPERATOR_GAMES_SUCCESS: {
            const gameIds: number[] = [];

            actions.payload.forEach((game: any) => gameIds.push(game.gameId));

            localStorageHelper
                .setChainedValue('user.newOperator.gameIds', gameIds)
                .setChainedValue('user.newOperator.step', 2);

            return { step: 2 };
        }

        case AddOperatorsTypes.ADD_OPERATOR_CURRENCIES_SUCCESS: {
            localStorageHelper
                .setChainedValue(
                    'user.newOperator.currencyIds',
                    actions.payload.map((currency: any) => currency.currencyId),
                )
                .setChainedValue('user.newOperator.step', 3);

            return { step: 3 };
        }

        case AddOperatorsTypes.ADD_GAME_CONFIGURATIONS_REQUEST: {
            return {
                ...state,
                loading: true,
                addedConfig: actions.payload.storedConfigurations,
            };
        }

        case AddOperatorsTypes.ADD_GAME_CONFIGURATIONS_SUCCESS: {
            localStorageHelper.setChainedValue('user.newOperator.step', 4);
            localStorageHelper.setChainedValue(
                'user.newOperator.configurations',
                state.addedConfig,
            );

            return { step: 4 };
        }

        case AddOperatorsTypes.ADD_BRANDS_SUCCESS: {
            const brands = actions.payload.response;

            localStorageHelper.setChainedValue('user.newOperator', null);

            Messages.success(i18next.t('new_operator_successfully_added'));
            brands &&
                Messages.success(
                    i18next.t(
                        brands.length === 1
                            ? 'brand_admin_account_was_successfully_created'
                            : 'brand_admin_accounts_were_successfully_created',
                    ),
                );

            return { step: 0, loaded: true };
        }

        case AddOperatorsTypes.ADD_BRANDS_FAILED: {
            Messages.error(i18next.t('operator_could_not_be_added'));

            return { ...state, loading: false };
        }

        case AddOperatorsTypes.BACK_STEP_ACTION: {
            return { step: state.step > 0 ? state.step - 1 : 0 };
        }

        default:
            return state;
    }
};

export default addOperatorReducer;
