import { createSelector } from 'reselect';

const getGameHistoryData = (state: any) => state.gameHistoryReducer.gameHistory;

const getGameHistoryURL = (state: any) => state.gameHistoryReducer.url;

export const getGameHistory = createSelector(
    getGameHistoryData,
    (state) => state,
);

export const getHistoryUrl = createSelector(
    getGameHistoryURL,
    (state) => state,
);