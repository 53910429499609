import * as React from 'react';
import { Form, Input, Modal } from 'antd';
import 'Containers/Users/UsersComponent.scss';
import * as i18next from 'i18next';
import { EyeOutlined } from '@ant-design/icons/lib';
import { localStorageHelper, passwordUtil } from 'utils';
import { FormInstance, Rule } from 'antd/lib/form';

interface IProps {
    onCancel: Function;
    onSubmit: Function;
    t: i18next.TFunction;
}

interface IState {
    oldPassword: string;
    newPassword: string;
    repeatedPassword: string;
    isCurrentPasswordVisible: boolean;
    isPasswordVisible: boolean;
    isRepeatedPasswordVisible: boolean;
}

const defaultState: IState = {
    oldPassword: '',
    newPassword: '',
    repeatedPassword: '',
    isCurrentPasswordVisible: false,
    isPasswordVisible: false,
    isRepeatedPasswordVisible: false,
};

export class ChangePasswordPopupComponent extends React.Component<IProps, IState> {
    form: React.RefObject<FormInstance> = React.createRef<FormInstance>();

    state: IState = defaultState;

    onCancel = () => {
        this.setState({ ...defaultState });
        this.props.onCancel();
    };

    onSubmit = () => {
        const { current } = this.form;

        if (current) {
            current
                .validateFields()
                .then((values: any) => {
                    current.resetFields();
                    this.props.onSubmit(values);
                })
                .catch();
        }
    };

    validateCurrentPassword = (rule: Rule, value: string) => {
        if (value) {
            value = value.trim();
        }

        this.setState({ oldPassword: value });

        if (!!value && value.length > 0) {
            return Promise.resolve();
        }

        return Promise.reject('error');
    };

    validatePassword = (rule: Rule, value: string) => {
        if (value) {
            value = value.trim();
        }

        const { name, email } = localStorageHelper.getChainedValue('user');

        this.setState({ newPassword: value });

        if (passwordUtil.validatePassword(value) && value !== name && value !== email) {
            return Promise.resolve();
        }

        return Promise.reject(this.props.t(passwordUtil.getValidationMessage(value, name, email)));
    };

    validateRepeatedPassword = (obj: any, value: string) => {
        if (value) {
            value = value.trim();
        }

        this.setState({ repeatedPassword: value });

        if (value === this.state.newPassword) {
            return Promise.resolve();
        }

        return Promise.reject('error');
    };

    generatePassword = () => {
        const { current } = this.form;

        if (current) {
            const password = passwordUtil.generatePassword();

            current.setFieldsValue({ password });
            current.validateFields(['password']);
            current.resetFields(['confirm_password']);
        }
    };

    copyPassword = () => {
        const { current } = this.form;

        if (current) {
            const { newPassword } = this.state;

            current.setFieldsValue({ confirm_password: newPassword });
            current.validateFields(['confirm_password']);
            navigator.clipboard.writeText(newPassword);
        }
    };

    toggleCurrentPasswordVisibility = () =>
        this.setState((state) => ({
            isCurrentPasswordVisible: !state.isCurrentPasswordVisible,
        }));

    togglePasswordVisibility = () =>
        this.setState((state) => ({
            isPasswordVisible: !state.isPasswordVisible,
        }));

    toggleRepeatedPasswordVisibility = () =>
        this.setState((state) => ({
            isRepeatedPasswordVisible: !state.isRepeatedPasswordVisible,
        }));

    render = () => {
        const { t } = this.props;
        const {
            oldPassword,
            newPassword,
            repeatedPassword,
            isCurrentPasswordVisible,
            isPasswordVisible,
            isRepeatedPasswordVisible,
        } = this.state;

        return (
            <Modal
                open
                className="changePassword"
                title={t('change_password')}
                okText={t('save')}
                onCancel={this.onCancel}
                onOk={this.onSubmit}
            >
                <Form layout="vertical" ref={this.form}>
                    <Form.Item
                        label={t('current_password')}
                        name="current_password"
                        rules={[
                            {
                                required: true,
                                message: t('this_field_cannot_be_empty'),
                                validator: this.validateCurrentPassword,
                            },
                        ]}
                    >
                        <div>
                            <Input
                                value={oldPassword}
                                type={isCurrentPasswordVisible ? 'text' : 'password'}
                                size="large"
                            />
                            <div
                                className="input-container__icon"
                                onClick={this.toggleCurrentPasswordVisibility}
                            >
                                <EyeOutlined />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={t('input_new_password')}
                        name="password"
                        rules={[
                            {
                                required: true,
                                validator: this.validatePassword,
                            },
                        ]}
                    >
                        <div>
                            <Input
                                value={newPassword}
                                type={isPasswordVisible ? 'text' : 'password'}
                                size="large"
                            />
                            <div
                                className="input-container__icon"
                                onClick={this.togglePasswordVisibility}
                            >
                                <EyeOutlined />
                            </div>
                            <div className="additional-field" onClick={this.generatePassword}>
                                {t('generate_password')}
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={t('confirm_password')}
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: t('password_mismatch_v_2'),
                                validator: this.validateRepeatedPassword,
                            },
                        ]}
                    >
                        <div>
                            <Input
                                value={repeatedPassword}
                                type={isRepeatedPasswordVisible ? 'text' : 'password'}
                                size="large"
                            />
                            <div
                                className="input-container__icon"
                                onClick={this.toggleRepeatedPasswordVisibility}
                            >
                                <EyeOutlined />
                            </div>
                            <div
                                className={`additional-field ${newPassword ? '' : ' disabled'}`}
                                onClick={this.copyPassword}
                            >
                                {t('copy_password')}
                            </div>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };
}
