import * as React from 'react';
import { Form, Input, Modal } from 'antd';
import './UsersComponent.scss';
import * as i18next from 'i18next';
import { FormInstance, Rule } from 'antd/lib/form';
import { IUser } from 'Containers/Users/UsersComponent';
import { passwordUtil } from 'utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SecondaryButton } from '../../Components/CustomButtons/CustomButtons';

interface IProps {
    editingUser: IUser;
    visible: boolean;
    onCancel: Function;
    onSubmit: Function;
    t: i18next.TFunction;
}

interface IState {
    newPassword: string;
    repeatedPassword: string;
}

const defaultState: IState = {
    newPassword: '',
    repeatedPassword: '',
};

export class ChangeUserPasswordPopupComponent extends React.Component<IProps, IState> {
    form: React.RefObject<FormInstance> = React.createRef<FormInstance>();

    state: IState = defaultState;

    onCancel = () => {
        this.setState({ ...defaultState });
        this.props.onCancel();
    };

    onSubmit = () => {
        const { current } = this.form;

        if (current) {
            current
                .validateFields()
                .then((values: any) => {
                    current.resetFields();
                    this.props.onSubmit(values);
                })
                .catch();
        }
    };

    validatePassword = (rule: Rule, value: string) => {
        if (value) {
            value = value.trim();
        }

        const { t, editingUser } = this.props;
        const { name, email } = editingUser;

        this.setState({ newPassword: value });

        if (passwordUtil.validatePassword(value) && value !== email && value !== name) {
            return Promise.resolve();
        }

        return Promise.reject(t(passwordUtil.getValidationMessage(value, name, email)));
    };

    validateRepeatedPassword = (obj: any, value: string) => {
        let password = value;

        if (password) {
            password = password.trim();
        }

        this.setState({ repeatedPassword: password });

        if (password === this.state.newPassword) {
            return Promise.resolve();
        }

        return Promise.reject('error');
    };

    generatePassword = () => {
        const { current } = this.form;

        if (current) {
            const password = passwordUtil.generatePassword();

            current.setFieldsValue({ password });
            current.validateFields(['password']);
            current.resetFields(['confirm_password']);
        }
    };

    copyPassword = () => {
        const { current } = this.form;

        if (current) {
            const { newPassword } = this.state;

            current.setFieldsValue({ confirm_password: newPassword });
            current.validateFields(['confirm_password']);
            navigator.clipboard.writeText(newPassword);
        }
    };

    render = () => {
        const { editingUser, visible, t } = this.props;
        const { newPassword, repeatedPassword } = this.state;

        return (
            <Modal
                className="changePassword"
                open={visible}
                title={t('change_password')}
                okText={t('save')}
                onCancel={this.onCancel}
                onOk={this.onSubmit}
            >
                <Form
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    ref={this.form}
                >
                    <Form.Item className="email" label={t('login_email')}>
                        <Input type="email" size="large" disabled placeholder={editingUser.email} />
                    </Form.Item>
                    <Form.Item
                        label={t('input_new_password')}
                        name="password"
                        rules={[
                            {
                                required: true,
                                validator: this.validatePassword,
                            },
                        ]}
                    >
                        <Input.Password value={newPassword} size="large" />
                    </Form.Item>
                    <Form.Item
                        label={t('confirm_password')}
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: t('password_mismatch'),
                                validator: this.validateRepeatedPassword,
                            },
                        ]}
                    >
                        <Input.Password value={repeatedPassword} size="large" />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ marginRight: '8px' }}>
                            <SecondaryButton onClick={this.generatePassword}>
                                {t('generate_password')}
                            </SecondaryButton>
                        </div>
                        <div>
                            <SecondaryButton disabled={!newPassword} onClick={this.copyPassword}>
                                {t('copy_password')}
                            </SecondaryButton>
                        </div>
                    </div>
                    <div className="login-form__newPass-message-wrapper">
                        <InfoCircleOutlined />
                        <p className="login-form__newPass-message">
                            {t('user_change_default_password')}
                        </p>
                    </div>
                </Form>
            </Modal>
        );
    };
}
