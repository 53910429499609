import { createSelector } from 'reselect';
import { dataFormatterUtils } from '../../../utils';

const getBrokenRounds = (state: any) => state.brokenRoundsReducer.data;
const getBrokenRoundsMeta = (state: any) => state.brokenRoundsReducer.metadata;
const getStatusHistory = (state: any) =>
    state.brokenRoundsReducer.statusHistory;

export const getBrokenRoundsData = createSelector(
    getBrokenRounds,
    (state) => state && dataFormatterUtils.getFormattedDataForCP(state),
);
export const getBrokenRoundsMetadata = createSelector(
    getBrokenRoundsMeta,
    (state) => state,
);
export const getStatusHistoryData = createSelector(
    getStatusHistory,
    (state) => state && dataFormatterUtils.getFormattedDataForCP(state),
);
