import { createSelector } from 'reselect';
import { dataFormatterUtils, sort } from 'utils';

const getPlayerData = (state: any) => state.playerReportReducer.playerData;
const playerCurrencyId = (state: any) => state.playerReportReducer.currencyId;
const getPlayerTotalLineData = (state: any) => state.playerReportReducer.total;
const getSortedPlayerData = (state: any) => state.playerReportReducer.sortedPlayerData;
const getPromotionData = (state: any) => state.playerReportReducer.promotionData;
const getCampaignsData = (state: any) => state.playerReportReducer.campaignsData;
const getPlayerInternalIdData = (state: any) => state.playerReportReducer.internalPlayerId;

export const getPlayerRawData = createSelector(getPlayerData, (state) => state);
export const getPlayer = createSelector(
    getPlayerData,
    (state) =>
        state &&
        sort(
            {
                keyToSort: 'endTime',
                sortState: 'desc',
            },
            dataFormatterUtils.getFormattedData(state, false),
        ),
);
export const getPromotion = createSelector(getPromotionData, (state) => state);
export const getPlayerCampaigns = createSelector(getCampaignsData, (state) => state);
export const getPlayerCurrencyId = createSelector(playerCurrencyId, (state) => state);
export const getPlayerTotalLine = createSelector(getPlayerTotalLineData, (state) => state);
export const getSortedPlayer = createSelector(getSortedPlayerData, (state) => state);
export const getPlayerInternalId = createSelector(getPlayerInternalIdData, (state) => state);
