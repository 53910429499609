export const CurrencyTypes = {
    GET_GAME_CONFIGURED_CURRENCIES_REQUEST:
        'GET_GAME_CONFIGURED_CURRENCIES_REQUEST',
    GET_GAME_CONFIGURED_CURRENCIES_SUCCESS:
        'GET_GAME_CONFIGURED_CURRENCIES_SUCCESS',
    GET_GAME_CONFIGURED_CURRENCIES_FAILED:
        'GET_GAME_CONFIGURED_CURRENCIES_FAILED',

    GET_GAME_UNCONFIGURED_CURRENCIES_REQUEST:
        'GET_GAME_UNCONFIGURED_CURRENCIES_REQUEST',
    GET_GAME_UNCONFIGURED_CURRENCIES_SUCCESS:
        'GET_GAME_UNCONFIGURED_CURRENCIES_SUCCESS',
    GET_GAME_UNCONFIGURED_CURRENCIES_FAILED:
        'GET_GAME_UNCONFIGURED_CURRENCIES_FAILED',

    ADD_CURRENCY_DEFAULT_CONFIG_REQUEST: 'ADD_CURRENCY_DEFAULT_CONFIG_REQUEST',
    ADD_CURRENCY_DEFAULT_CONFIG_SUCCESS: 'ADD_CURRENCY_DEFAULT_CONFIG_SUCCESS',
    ADD_CURRENCY_DEFAULT_CONFIG_FAILED: 'ADD_CURRENCY_DEFAULT_CONFIG_FAILED',

    GET_CURRENCY_CONFIGURATION_REQUEST: 'GET_CURRENCY_CONFIGURATION_REQUEST',
    GET_CURRENCY_CONFIGURATION_SUCCESS: 'GET_CURRENCY_CONFIGURATION_SUCCESS',
    GET_CURRENCY_CONFIGURATION_FAILED: 'GET_CURRENCY_CONFIGURATION_FAILED',

    UPDATE_CURRENCY_CONFIGURATION_REQUEST:
        'UPDATE_CURRENCY_CONFIGURATION_REQUEST',
    UPDATE_CURRENCY_CONFIGURATION_SUCCESS:
        'UPDATE_CURRENCY_CONFIGURATION_SUCCESS',
    UPDATE_CURRENCY_CONFIGURATION_FAILED:
        'UPDATE_CURRENCY_CONFIGURATION_FAILED',

    SORT_ALL_CURRENCIES: 'SORT_ALL_CURRENCIES',
};

export const getGameConfiguredCurrenciesRequest = (payload?: any) => ({
    type: CurrencyTypes.GET_GAME_CONFIGURED_CURRENCIES_REQUEST,
    payload,
});
export const getGameConfiguredCurrenciesSuccess = (payload?: any) => ({
    type: CurrencyTypes.GET_GAME_CONFIGURED_CURRENCIES_SUCCESS,
    payload,
});
export const sortAllCurrencies = (payload?: any) => ({
    type: CurrencyTypes.SORT_ALL_CURRENCIES,
    payload,
});
export const getGameUnconfiguredCurrenciesRequest = (payload?: any) => ({
    type: CurrencyTypes.GET_GAME_UNCONFIGURED_CURRENCIES_REQUEST,
    payload,
});
export const getGameUnconfiguredCurrenciesSuccess = (payload?: any) => ({
    type: CurrencyTypes.GET_GAME_UNCONFIGURED_CURRENCIES_SUCCESS,
    payload,
});
export const addCurrencyDefaultConfigRequest = (payload?: any) => ({
    type: CurrencyTypes.ADD_CURRENCY_DEFAULT_CONFIG_REQUEST,
    payload,
});
export const addCurrencyDefaultConfigSuccess = (payload?: any) => ({
    type: CurrencyTypes.ADD_CURRENCY_DEFAULT_CONFIG_SUCCESS,
    payload,
});
export const getCurrencyConfiguration = (payload?: any) => ({
    type: CurrencyTypes.GET_CURRENCY_CONFIGURATION_REQUEST,
    payload,
});
export const getCurrencyConfigurationSuccess = (payload?: any) => ({
    type: CurrencyTypes.GET_CURRENCY_CONFIGURATION_SUCCESS,
    payload,
});
export const updateCurrencyConfiguration = (payload?: any) => ({
    type: CurrencyTypes.UPDATE_CURRENCY_CONFIGURATION_REQUEST,
    payload,
});
export const updateCurrencyConfigurationSuccess = (payload?: any) => ({
    type: CurrencyTypes.UPDATE_CURRENCY_CONFIGURATION_SUCCESS,
    payload,
});
