import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
    getPlayerPromotionsDataSuccess,
    playerReportSuccess,
    PlayerReportTypes,
    promotionDataSuccess,
} from '../../actions/backoffice/player-report-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { dateFormatter, getLoginRequestHeader, localStorageHelper } from '../../../utils';
import { of } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const playerReportEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PlayerReportTypes.PLAYER_REPORT_REQUEST),
        mergeMap((action) => {
            const { playerId, login, fromDate, toDate } = action.payload;
            const isToSendHours = localStorageHelper.getChainedValue('user.reports.isToSendHours');
            const fromDateStr = isToSendHours
                ? dateFormatter.getFormattedStartDateWithHours(fromDate)
                : dateFormatter.getFormattedStartDate(fromDate);
            const toDateStr = isToSendHours
                ? dateFormatter.getFormattedEndDateWithHours(toDate)
                : dateFormatter.getFormattedEndDate(toDate);

            localStorageHelper.setChainedValue('user.reports.isToSendHours', false);

            const headers = getLoginRequestHeader();
            const fromPromise = from(
                axios.get(
                    `${SERVER_URL}/game-history/list?${
                        login ? `externalPlayerId=${login}` : `playerId=${playerId}`
                    }&start=${fromDateStr}&end=${toDateStr}`,
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) => playerReportSuccess(response.data)),
                catchError((error) => of(apiError(error, PlayerReportTypes.PLAYER_REPORT_REQUEST))),
            );
        }),
    );

export const promotionDataEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PlayerReportTypes.PROMOTION_DATA_REQUEST),
        mergeMap((action) => {
            const { playerId, gameSessionId, promotionId } = action.payload;

            const headers = getLoginRequestHeader();
            const fromPromise = from(
                axios.get(
                    `${SERVER_URL}/game-history/promotion-detail?playerId=${playerId}&gameSessionId=${gameSessionId}&promotionId=${promotionId}`,
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) => promotionDataSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PlayerReportTypes.PROMOTION_DATA_REQUEST)),
                ),
            );
        }),
    );

export const playerCampaignsData = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_REQUEST),
        mergeMap((action) => {
            const { fromDate, toDate, login } = action.payload;

            const headers = getLoginRequestHeader();
            const fromPromise = from(
                axios.get(
                    `${SERVER_URL}/api/v1/brand/current/campaign-participant/${login}/summary?startDate=${dateFormatter.getFormattedStartDate(
                        fromDate,
                    )}&endDate=${dateFormatter.getFormattedEndDate(toDate)}`,
                    { headers },
                ),
            );

            return fromPromise.pipe(
                map((response) => getPlayerPromotionsDataSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, PlayerReportTypes.PLAYER_CAMPAIGNS_DATA_REQUEST)),
                ),
            );
        }),
    );
