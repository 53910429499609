import * as React from 'react';
import './SettingsComponentStyles.scss';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import { WithTranslation, withTranslation } from 'react-i18next';
import Settings from './Settings';
import { Context } from 'App';

class SettingsComponent extends React.Component<WithTranslation> {
    render = () => {
        const { t } = this.props;

        return (
            <ContentComponent
                customBreadcrumbs={<div>{t('profile')}</div>}
                innerContent={
                    <Context.Consumer>
                        {(context) => <Settings context={context} />}
                    </Context.Consumer>
                }
                title={t('settings-title')}
            />
        );
    };
}

export default withTranslation()(SettingsComponent);
