import { useState, useCallback, useEffect } from 'react';
import { localStorageHelper } from 'utils';
import { IFilters } from '../../helpers/interfaces';

const defaultFiltersValues = {
    currencies: [],
    currenciesBreakdown: false,
    deviceType: 'all',
    games: [],
};

export const useFilters = (reportKey: string, getReport: () => void) => {
    const [applyingFilters, setApplyingFilters] = useState<IFilters>(defaultFiltersValues);
    const [appliedFilters, setAppliedFilters] = useState<IFilters>(defaultFiltersValues);

    const applyFilters = useCallback(() => {
        localStorageHelper.setChainedValue('user.reports.filters', applyingFilters);
        setAppliedFilters(applyingFilters);
        getReport();
    }, [applyingFilters, getReport]);

    const cancelFilters = useCallback(() => {
        setApplyingFilters({
            currencies: localStorageHelper.getChainedValue('user.reports.filters.currencies') || [],
            currenciesBreakdown:
                localStorageHelper.getChainedValue('user.reports.filters.currenciesBreakdown') ||
                false,
            deviceType:
                localStorageHelper.getChainedValue('user.reports.filters.deviceType') || 'all',
            games: localStorageHelper.getChainedValue('user.reports.filters.games') || [],
        });
    }, []);

    const resetFilters = useCallback(() => {
        const newFilters: IFilters = {
            currencies: [],
            currenciesBreakdown: false,
            deviceType: 'all',
            games: [],
        };
        localStorageHelper.setChainedValue('user.reports.filters', newFilters);
        setApplyingFilters(newFilters);
        setAppliedFilters(newFilters);
        getReport();
    }, [getReport]);

    const onFiltersChangeCallback = useCallback(
        (changedField: keyof IFilters, value: any, isToApplyFilters = false) => {
            setApplyingFilters((prevFilters) => {
                const filters: IFilters = { ...prevFilters, [changedField]: value };

                if (isToApplyFilters) {
                    localStorageHelper.setChainedValue('user.reports.filters', filters);
                    setAppliedFilters(filters);
                    getReport();
                }

                return filters;
            });
        },
        [getReport],
    );

    useEffect(() => {
        const filters =
            localStorageHelper.getChainedValue('user.reports.filters') || defaultFiltersValues;
        if (reportKey === 'playerProfile') {
            filters.currencies = [];
            filters.currenciesBreakdown = false;
            filters.deviceType = 'all';
        } else {
            filters.games = [];
        }
        setApplyingFilters(filters);
        setAppliedFilters(filters);
    }, [reportKey]);

    return {
        applyingFilters,
        appliedFilters,
        setApplyingFilters,
        applyFilters,
        cancelFilters,
        resetFilters,
        onFiltersChangeCallback,
    };
};
