import { createSelector } from 'reselect';

const getOperatorsData = (state: any) => state.operatorsReducer.operatorsCP;
const getSortedOperatorsData = (state: any) =>
    state.operatorsReducer.sortedOperatorsCP;
const isOperatorStatusChanged = (state: any) =>
    state.operatorsReducer.isStatusChanged;
const currentOperatorGameConfigs = (state: any) =>
    state.operatorsReducer.currentOperatorGameConfigs;
const currentOperatorCurrencies = (state: any) =>
    state.operatorsReducer.currentOperatorCurrencies;
const operatorCurrenciesCP = (state: any) =>
    state.operatorsReducer.operatorCurrenciesCP;
const sortedOperatorCurrenciesCP = (state: any) =>
    state.operatorsReducer.sortedOperatorCurrenciesCP;

const currentOperatorGames = (state: any) =>
    state.operatorsReducer.currentOperatorGames;
const operatorGamesCP = (state: any) => state.operatorsReducer.operatorGamesCP;
const sortedOperatorGamesCP = (state: any) =>
    state.operatorsReducer.sortedOperatorGamesCP;
const gameConfigUpdateStatus = (state: any) =>
    state.operatorsReducer.isGameConfigUpdated;
const operatorGamesUpdateStatus = (state: any) =>
    state.operatorsReducer.isOperatorGamesUpdated;
const operatorCurrenciesUpdateStatus = (state: any) =>
    state.operatorsReducer.isOperatorCurrenciesUpdated;
const loading = (state: any) => state.operatorsReducer.loading;

export const getOperators = createSelector(getOperatorsData, (state) => state);
export const getSortedOperators = createSelector(
    getSortedOperatorsData,
    (state) => state,
);
export const isStatusChanged = createSelector(
    isOperatorStatusChanged,
    (state) => state,
);
export const getCurrentOperatorGameConfigs = createSelector(
    currentOperatorGameConfigs,
    (state) => state,
);
export const getCurrentOperatorCurrencies = createSelector(
    currentOperatorCurrencies,
    (state) => state,
);
export const getOperatorCurrenciesCP = createSelector(
    operatorCurrenciesCP,
    (state) => state,
);
export const getSortedOperatorCurrenciesCP = createSelector(
    sortedOperatorCurrenciesCP,
    (state) => state,
);
export const getCurrentOperatorGames = createSelector(
    currentOperatorGames,
    (state) => state,
);
export const getOperatorGamesCP = createSelector(
    operatorGamesCP,
    (state) => state,
);
export const getSortedOperatorGamesCP = createSelector(
    sortedOperatorGamesCP,
    (state) => state,
);
export const getGameConfigUpdateStatus = createSelector(
    gameConfigUpdateStatus,
    (state) => state,
);
export const getOperatorGamesUpdateStatus = createSelector(
    operatorGamesUpdateStatus,
    (state) => state,
);
export const getOperatorCurrenciesUpdateStatus = createSelector(
    operatorCurrenciesUpdateStatus,
    (state) => state,
);
export const getLoadingStatus = createSelector(loading, (state) => state);
