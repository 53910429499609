import * as React from 'react';
import './ContentComponent.scss';
import { BreadcrumbComponent } from '../BreadcrumbComponent/BreadcrumbComponent';
import { dateFormatter } from '../../utils';

interface IProps {
    title: any;
    content?: JSX.Element;
    customBreadcrumbs?: JSX.Element;
    dateOfLastRefresh?: Date;
}

export const HeaderTitleComponent = (props: IProps) => {
    const { title, content, customBreadcrumbs, dateOfLastRefresh } = props;

    return (
        <>
            <h1 className="content-title" id="content_title">
                <span className="title-span">
                    {<BreadcrumbComponent customBreadcrumbs={customBreadcrumbs} />}
                </span>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {title}
                    {dateOfLastRefresh && (
                        <span style={{ fontSize: '12px', color: '#999', fontWeight: '500' }}>
                            Data updated{' '}
                            {dateFormatter.getFormattedDateOfLastRefresh(dateOfLastRefresh)}
                        </span>
                    )}
                </div>
            </h1>
            {content && <div className="usersList__buttons">{content}</div>}
        </>
    );
};
