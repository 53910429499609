import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { CheckOutlined } from '@ant-design/icons/lib';
import 'Components/CustomStepper/StepperStyles.scss';

interface IProps {
    currentStep: number;
    steps: string[];
}

class Stepper extends React.Component<IProps & WithTranslation> {
    getSteps = () => {
        const { t, currentStep, steps } = this.props;

        return steps.map((step: string, index: number) => {
            const stepClass =
                index < currentStep
                    ? 'before'
                    : index > currentStep
                    ? 'after'
                    : 'current';
            const stepIndex =
                index < currentStep ? <CheckOutlined /> : index + 1;

            return (
                <div
                    className={`step ${stepClass}`}
                    style={{ width: 100 / steps.length + '%' }}
                    key={step}
                >
                    <div className="index">{stepIndex}</div>
                    <div className="name">{t(step)}</div>
                </div>
            );
        });
    };

    render = () => {
        const { currentStep, steps } = this.props;
        const indent = 50 / steps.length;
        const step = 100 / steps.length;

        return (
            <ItemComponentWrapper>
                <div className="stepper">
                    <div className="strip">
                        <div className="grey" />
                        <div
                            className="green"
                            style={{ width: indent + currentStep * step + '%' }}
                        />
                    </div>
                    <div className="steps">{this.getSteps()}</div>
                </div>
            </ItemComponentWrapper>
        );
    };
}

export default connect(null, null)(withTranslation()(Stepper));
