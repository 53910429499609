import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { IAction } from '../../../helpers/interfaces';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from '../../../utils';
import { of } from 'rxjs';
import {
    getRoundInfoSuccess,
    RoundInfoTypes,
} from '../../actions/configProvider/round-info-actions';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const roundInfoEpicEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(RoundInfoTypes.ROUND_INFO_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            return from(
                axios.get(`${SERVER_URL}/technical-reports/game-round?uuid=${action.payload}`, {
                    headers,
                }),
            ).pipe(
                map(({ data }) => getRoundInfoSuccess(data)),
                catchError((error) => of(apiError(error, RoundInfoTypes.ROUND_INFO_REQUEST))),
            );
        }),
    );
