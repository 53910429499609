export const UserTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGOUT: 'LOGOUT',
    INFO_REQUEST: 'INFO_REQUEST',
    INFO_SUCCESS: 'INFO_SUCCESS',
    INFO_FAILED: 'INFO_FAILED',
    GET_ALL_CURRENCIES_REQUEST: 'GET_ALL_CURRENCIES_REQUEST',
    ALL_CURRENCY_SUCCESS: 'ALL_CURRENCY_SUCCESS',
    ALL_CURRENCY_FAILED: 'ALL_CURRENCY_FAILED',
    GET_ALL_CURRENCIES_BY_BRAND_REQUEST: 'GET_ALL_CURRENCIES_BY_BRAND_REQUEST',
    GET_ALL_CURRENCIES_BY_BRAND_SUCCESS: 'GET_ALL_CURRENCIES_BY_BRAND_SUCCESS',
    GET_ALL_CURRENCIES_BY_BRAND_FAILED: 'GET_ALL_CURRENCIES_BY_BRAND_FAILED',
    GAMES_BY_OPERATOR_REQUEST: 'GAMES_BY_OPERATOR_REQUEST',
    GAMES_BY_OPERATOR_SUCCESS: 'GAMES_BY_OPERATOR_SUCCESS',
    GAMES_BY_OPERATOR_FAILED: 'GAMES_BY_OPERATOR_FAILED',
    TIME_ZONE_REQUEST: 'TIME_ZONE_REQUEST',
    TIME_ZONE_SUCCESS: 'TIME_ZONE_SUCCESS',
    TIMEZONE_FAILED: 'TIMEZONE_FAILED',
    GET_ALL_COUNTRIES_REQUEST: 'GET_ALL_COUNTRIES_REQUEST',
    GET_ALL_COUNTRIES_SUCCESS: 'GET_ALL_COUNTRIES_SUCCESS',
    GET_ALL_COUNTRIES_FAILED: 'GET_ALL_COUNTRIES_FAILED',
    UPDATE_PREFERENCES_REQUEST: 'UPDATE_PREFERENCES_REQUEST',
    UPDATE_PREFERENCES_SUCCESS: 'UPDATE_PREFERENCES_SUCCESS',
    UPDATE_PREFERENCES_FAILED: 'UPDATE_PREFERENCES_FAILED',
    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',
    CHANGE_DEFAULT_PASSWORD_REQUEST: 'CHANGE_DEFAULT_PASSWORD_REQUEST',
    CHANGE_DEFAULT_PASSWORD_SUCCESS: 'CHANGE_DEFAULT_PASSWORD_SUCCESS',
    CHANGE_DEFAULT_PASSWORD_FAILED: 'CHANGE_DEFAULT_PASSWORD_FAILED',

    CHANGE_NAME_REQUEST: 'CHANGE_NAME_REQUEST',
    CHANGE_NAME_SUCCESS: 'CHANGE_NAME_SUCCESS',
    CHANGE_NAME_FAILED: 'CHANGE_NAME_FAILED',
    REMOVE_IS_ERROR_STATE: 'REMOVE_IS_ERROR_STATE',
    CHANGE_STATE_DISPLAYING_TEST_DATA: 'CHANGE_STATE_DISPLAYING_TEST_DATA',
    DEFAULT_PASSWORD_CHANGE_REQUEST: 'DEFAULT_PASSWORD_CHANGE_REQUEST',
    DEFAULT_PASSWORD_CHANGE_SUCCESS: 'DEFAULT_PASSWORD_CHANGE_SUCCESS',
    API_ERROR: 'API_ERROR',
    SHOW_ERROR_NOTIFICATION: 'SHOW_ERROR_NOTIFICATION',
    DOWNLOAD_REPORT_REQUEST: 'DOWNLOAD_REPORT_REQUEST',
    DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
    DATE_OF_LAST_REFRESH_REQUEST: 'DATE_OF_LAST_REFRESH_REQUEST',
    DATE_OF_LAST_REFRESH_SUCCESS: 'DATE_OF_LAST_REFRESH_SUCCESS',
    DATE_OF_LAST_REFRESH_FAILED: 'DATE_OF_LAST_REFRESH_FAILED',
    GET_ALL_GAMES_BY_USER_REQUEST: 'GET_ALL_GAMES_BY_USER_REQUEST',
    GET_ALL_GAMES_BY_USER_SUCCESS: 'GET_ALL_GAMES_BY_USER_SUCCESS',
    GET_ALL_GAMES_BY_USER_FAILED: 'GET_ALL_GAMES_BY_USER_FAILED',
    GET_ALL_GAME_CONFIGURATIONS_BY_USER_REQUEST: 'GET_ALL_GAME_CONFIGURATIONS_BY_USER_REQUEST',
    GET_ALL_GAME_CONFIGURATIONS_BY_USER_SUCCESS: 'GET_ALL_GAME_CONFIGURATIONS_BY_USER_SUCCESS',
    GET_ALL_GAME_CONFIGURATIONS_BY_USER_FAILED: 'GET_ALL_GAME_CONFIGURATIONS_BY_USER_FAILED',
};

export const login = (payload?: any) => ({
    type: UserTypes.LOGIN_REQUEST,
    payload,
});
export const loginSuccess = (payload?: any) => ({
    type: UserTypes.LOGIN_SUCCESS,
    payload,
});
export const logout = (payload?: any) => ({ type: UserTypes.LOGOUT, payload });
export const allCurrenciesSuccess = (payload?: any) => ({
    type: UserTypes.ALL_CURRENCY_SUCCESS,
    payload,
});
export const allCurrenciesByBrandSuccess = (payload?: any) => ({
    type: UserTypes.GET_ALL_CURRENCIES_BY_BRAND_SUCCESS,
    payload,
});
export const allCurrenciesByBrandFailed = (payload?: any) => ({
    type: UserTypes.GET_ALL_CURRENCIES_BY_BRAND_FAILED,
    payload,
});
export const gamesByOperatorSuccess = (payload?: any) => ({
    type: UserTypes.GAMES_BY_OPERATOR_SUCCESS,
    payload,
});
export const timezoneSuccess = (payload?: any) => ({
    type: UserTypes.TIME_ZONE_SUCCESS,
    payload,
});
export const updatePreferences = (payload?: any) => ({
    type: UserTypes.UPDATE_PREFERENCES_REQUEST,
    payload,
});
export const updatePreferencesSuccess = (payload?: any) => ({
    type: UserTypes.UPDATE_PREFERENCES_SUCCESS,
    payload,
});
export const changePassword = (payload?: any) => ({
    type: UserTypes.CHANGE_PASSWORD_REQUEST,
    payload,
});
export const changePasswordSuccess = (payload?: any) => ({
    type: UserTypes.CHANGE_PASSWORD_SUCCESS,
    payload,
});
export const changeDefaultPassword = (payload?: any) => ({
    type: UserTypes.CHANGE_DEFAULT_PASSWORD_REQUEST,
    payload,
});
export const changeDefaultPasswordSuccess = (payload?: any) => ({
    type: UserTypes.CHANGE_DEFAULT_PASSWORD_SUCCESS,
    payload,
});
export const changeName = (payload?: any) => ({
    type: UserTypes.CHANGE_NAME_REQUEST,
    payload,
});
export const changeNameSuccess = (payload?: any) => ({
    type: UserTypes.CHANGE_NAME_SUCCESS,
    payload,
});
export const changeStateDisplayingTestData = (payload?: any) => ({
    type: UserTypes.CHANGE_STATE_DISPLAYING_TEST_DATA,
    payload,
});
export const apiError = (error: any, sourceActionType: any) => ({
    type: UserTypes.API_ERROR,
    payload: error,
    meta: { sourceActionType },
});
export const showErrorNotification = (payload?: any) => ({
    type: UserTypes.SHOW_ERROR_NOTIFICATION,
    payload,
});

export const getAllCountries = (payload?: any) => ({
    type: UserTypes.GET_ALL_COUNTRIES_REQUEST,
    payload,
});
export const getAllCountriesSuccess = (payload?: any) => ({
    type: UserTypes.GET_ALL_COUNTRIES_SUCCESS,
    payload,
});

export const downloadReport = (payload?: any) => ({
    type: UserTypes.DOWNLOAD_REPORT_REQUEST,
    payload,
});
export const downloadReportSuccess = (payload?: any) => ({
    type: UserTypes.DOWNLOAD_REPORT_SUCCESS,
    payload,
});

export const DateOfLastRefrasheSuccessfull = (payload?: any) => ({
    type: UserTypes.DATE_OF_LAST_REFRESH_SUCCESS,
    payload,
});

export const getAllGamesByUser = (payload?: any) => ({
    type: UserTypes.GET_ALL_GAMES_BY_USER_REQUEST,
    payload,
});
export const getAllGamesByUserSuccess = (payload?: any) => ({
    type: UserTypes.GET_ALL_GAMES_BY_USER_SUCCESS,
    payload,
});

export const getAllGameConfigurationsByUser = (payload?: any) => ({
    type: UserTypes.GET_ALL_GAME_CONFIGURATIONS_BY_USER_REQUEST,
    payload,
});
export const getAllGameConfigurationsByUserSuccess = (payload?: any) => ({
    type: UserTypes.GET_ALL_GAME_CONFIGURATIONS_BY_USER_SUCCESS,
    payload,
});
