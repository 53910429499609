import { createSelector } from 'reselect';
import { dataFormatterUtils } from '../../../utils';

const getPromotions = (state: any) => state.promotionsReducer.allCampaigns;
const getTotalPromotionsAmount = (state: any) => state.promotionsReducer.allCampaignsTotal;
const getCampaign = (state: any) => state.promotionsReducer.campaignData;
const getCampaignSummary = (state: any) => state.promotionsReducer.campaignSummary;
const getCampaignPlayers = (state: any) => state.promotionsReducer.campaignPlayers;
const getCampaignPlayersCSV = (state: any) => state.promotionsReducer.csv;

const getCampaignPlayersTotal = (state: any) => state.promotionsReducer.campaignPlayersTotal;

const getPromotionsLoadingState = (state: any) => state.promotionsReducer.isLoading;
const getPromotionsError = (state: any) => state.promotionsReducer.error;
const getInvokeCallbackState = (state: any) => state.promotionsReducer.isUpdateData;

export const getPromotionsData = createSelector(
    getPromotions,
    (state) => state && dataFormatterUtils.getFormattedData(state),
);
export const getTotalPromotionsAmountData = createSelector(
    getTotalPromotionsAmount,
    (state) => state,
);
export const getPromotionsLoadingStateData = createSelector(
    getPromotionsLoadingState,
    (state) => state,
);
export const getPromotionsErrorData = createSelector(getPromotionsError, (state) => state);
export const isUpdateData = createSelector(getInvokeCallbackState, (state) => state);
export const getCampaignData = createSelector(getCampaign, (state) => state);
export const getCampaignSummaryData = createSelector(getCampaignSummary, (state) => state);
export const getCampaignPlayersTotalData = createSelector(
    getCampaignPlayersTotal,
    (state) => state,
);
export const getCampaignPlayersData = createSelector(getCampaignPlayers, (state) => state);
export const getCampaignPlayersDataCSV = createSelector(getCampaignPlayersCSV, (state) => state);
