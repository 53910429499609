import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ErrorComponent } from 'Containers/Errors/ErrorComponent';
import { Empty } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import * as i18next from 'i18next';
import { currencyUtils, getAbbreviatedValue } from 'utils';
import { DownOutlined } from '@ant-design/icons/lib';
import { periodKeys } from 'mobile/CalendarMobileComponent';
import { MobileChartConstants } from 'mobile/MobileChartConstants';
import { IGameData } from 'redux/reducers/backoffice/games-report-reducer';
import { getGamesReport } from 'redux/actions/backoffice/games-report-actions';
import { getRawGamesData } from 'redux/selectors/backoffice/games-report-selectors';
import { getServerError } from 'redux/selectors/backoffice/error-selectors';
import { ChangeEvent } from 'react';

interface IProps {
    period: periodKeys;
    t: i18next.TFunction;
    getGamesData: Function;
    data: IGameData[];
    error: string;
}

type gameKey = 'bet' | 'win' | 'net' | 'spins' | 'rtp' | 'players';

const gameKeys: gameKey[] = ['bet', 'win', 'net', 'spins', 'rtp', 'players'];
//  sum of margins around bar and width of value column
const guttersValue = 20;

interface IState {
    activeKey: gameKey;
}

interface IGamesData {
    name: string;
    width: number;
    value: string;
}

class TopGamesMobileComponent extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        activeKey: 'bet',
    };

    getGamesList = () => {
        const { t, data } = this.props;
        const { activeKey } = this.state;
        const isMoneyValue = ['bet', 'win', 'net'].includes(activeKey);
        const sortedData = this.getSortedByKeyData(data, activeKey);
        const maxKeyValue = sortedData[0][activeKey];
        const gamesData: IGamesData[] = [];

        sortedData.forEach((game: IGameData, index: number) => {
            let sign = '';

            if (isMoneyValue) {
                sign = ' ' + currencyUtils.getActiveCurrencyData().code;
            } else if (activeKey === 'rtp') {
                sign = ' %';
            }

            gamesData.push({
                name: `${index + 1}. ${game.gameName ? game.gameName : 'rp-' + game.gameId}`,
                width: maxKeyValue < 0 ? 0 : +(game[activeKey] / maxKeyValue).toFixed(2),
                value:
                    (getAbbreviatedValue(game[activeKey], 'USD', isMoneyValue, false) as string) +
                        ('' + sign) || '',
            });
        });

        const gameNameColumnWidth = this.getMaxGameNameWidth(gamesData);
        const valueColumnWidth = this.getMaxValueColumnWidth(gamesData);

        return (
            <>
                <h2>{t('games_performance_by')}</h2>
                <div className="selected_key">{t(activeKey)}</div>
                <select
                    className="selector"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => this.onChangeGameKey(e)}
                >
                    {gameKeys.map((key: gameKey) => (
                        <option key={key} value={key}>
                            {t(key)}
                        </option>
                    ))}
                </select>
                <DownOutlined />
                <div className="list">
                    {gamesData.map((game: IGamesData) => {
                        const { name, value, width } = game;
                        const othersColumnWidth =
                            gameNameColumnWidth + guttersValue + valueColumnWidth;

                        return (
                            <div key={name} className="list_item">
                                <div
                                    className="name"
                                    style={{
                                        width: gameNameColumnWidth + 'px',
                                    }}
                                >
                                    {name}
                                </div>
                                <div
                                    className="strip"
                                    style={{
                                        width: `calc(${width} * (100% - ${othersColumnWidth}px))`,
                                    }}
                                />
                                <div className="value" style={{ width: valueColumnWidth + 'px' }}>
                                    {value}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    getMaxValueColumnWidth = (gamesData: IGamesData[]) => {
        const oneLetterWidth = 9;
        let maxLength = 0;

        gamesData.forEach(
            (data: IGamesData) => (maxLength = Math.max(maxLength, data.value.length)),
        );

        return maxLength * oneLetterWidth;
    };

    getMaxGameNameWidth = (gamesData: IGamesData[]) => {
        const { outerWidth, outerHeight } = window;
        const isPortrait = outerHeight / outerWidth > 1;
        const oneLetterWidth = 8;
        let maxLength = 0;

        // use fixed width for game's name column in portrait mode on phones
        if (isPortrait) {
            const getIndexOfThirdWordInName = (str: string, matchStr: string, index: number) =>
                str.split(matchStr, index).join(matchStr).length;

            gamesData.forEach((data: IGamesData) => {
                maxLength = Math.max(maxLength, getIndexOfThirdWordInName(data.name, ' ', 3));
            });
        } else {
            gamesData.forEach(
                (data: IGamesData) => (maxLength = Math.max(maxLength, data.name.length)),
            );
        }

        return maxLength * oneLetterWidth;
    };

    onChangeGameKey = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();

        const activeKey = e.target.value as gameKey;

        this.setState({ activeKey });
    };

    getSortedByKeyData = (data: any, activeKey: string) =>
        [...data].sort((a, b) => b[activeKey] - a[activeKey]);

    getReport = () => {
        const { period, getGamesData } = this.props;

        getGamesData(MobileChartConstants.getDates(period));
    };

    componentDidUpdate = (prevProps: IProps) => {
        if (this.props.period !== prevProps.period) {
            this.getReport();
        }
    };

    componentDidMount = () => {
        window.addEventListener('orientationchange', this.getReport, true);

        this.getReport();
    };

    componentWillUnmount = () => {
        window.removeEventListener('orientationchange', this.getReport);
    };

    render = () => {
        const { t, data, error } = this.props;

        return (
            <div className="dashboard__item-wrapper">
                <div className="dashboard__item top_games">
                    {error ? (
                        <ErrorComponent error={error} />
                    ) : data ? (
                        data.length ? (
                            this.getGamesList()
                        ) : (
                            <Empty description={t('no_data')} />
                        )
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state: any) => ({
    data: getRawGamesData(state),
    error: getServerError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getGamesData: (date: any) => dispatch(getGamesReport(date)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(TopGamesMobileComponent));
