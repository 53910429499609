import * as React from 'react';
import './ReportsMenu.scss';
import './ReportsMenuMedia.scss';
import ReportsMenuItem from '../ReportsMenuItem/ReportsMenuItem';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Map } from 'helpers/types/Map';
import { IReportType } from 'helpers/interfaces';
import { localStorageHelper } from 'utils';
import { FileTextOutlined, RocketOutlined, TeamOutlined } from '@ant-design/icons/lib';
import { IssuesCloseOutlined } from '@ant-design/icons';

class ReportsMenu extends React.Component<WithTranslation> {
    convertToComponents = () => {
        const { t } = this.props;
        const authorities = localStorageHelper.getChainedValue('user.authorities') || [];
        const convertingMap: Map<IReportType> = {
            REPORT_PLAYER_GROUP_REVENUE: {
                icon: <FileTextOutlined />,
                description: 'casino_performance',
                link: `/casino/reports/casino-performance`,
                id: 'casino_performance_plate',
            },
            REPORT_PLAYER_REVENUE: {
                icon: <TeamOutlined />,
                description: 'players_performance',
                link: `/casino/reports/players-performance`,
                id: 'players_performance_plate',
            },
            REPORT_GAME_REVENUE: {
                icon: <RocketOutlined />,
                description: 'games_performance',
                link: `/casino/reports/games-performance`,
                id: 'games_performance_plate',
            },
            CRITICAL_FILES_CHECK_HISTORY: {
                icon: <IssuesCloseOutlined />,
                description: 'critical_checksum',
                link: `/casino/reports/critical-checksum`,
                id: 'critical_checksum_reports_item',
            },
            REPORT_MONTHLY_BILLING: {
                icon: <FileTextOutlined />,
                description: 'monthly_billing_report',
                link: `/casino/reports/monthly-billing-report`,
                id: 'monthly_billing_plate',
            },
        };
        const result = [];

        for (const reportType in convertingMap) {
            if (Object.prototype.hasOwnProperty.call(convertingMap, reportType)) {
                const isEnabled = authorities.indexOf(reportType) >= 0;

                isEnabled &&
                    result.push(
                        <ReportsMenuItem
                            key={reportType}
                            t={t}
                            isEnabled={isEnabled}
                            properties={convertingMap[reportType]}
                        />,
                    );
            }
        }

        return result;
    };

    render = () => <div className="plates-container">{this.convertToComponents()}</div>;
}

export default withTranslation()(ReportsMenu);
