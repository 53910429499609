import * as React from 'react';
import { Form, Input, Modal, Radio, Select, Tooltip } from 'antd';
import './UsersComponent.scss';
import * as i18next from 'i18next';
import { localStorageHelper, passwordUtil } from 'utils';
import { InfoCircleOutlined } from '@ant-design/icons/lib';
import { ICurrencyData, ITimeZoneListItem } from 'helpers/interfaces';
import { FormInstance } from 'antd/lib/form';
import { EyeOutlined } from '@ant-design/icons';

interface IModalProps {
    visible: boolean;
    onCancel: Function;
    onSubmit: Function;
    t: i18next.TFunction;
}

interface IState {
    isCurrencyListVisible: boolean;
    isTimezoneListVisible: boolean;
    isPasswordVisible: boolean;
}

export class AddUserPopupComponent extends React.Component<IModalProps, IState> {
    form: React.RefObject<FormInstance> = React.createRef<FormInstance>();

    state: IState = {
        isCurrencyListVisible: false,
        isTimezoneListVisible: false,
        isPasswordVisible: false,
    };

    onCancel = () => this.props.onCancel();

    onSubmit = () => {
        const { current } = this.form;

        if (current) {
            current
                .validateFields()
                .then((values: any) => {
                    current.resetFields();
                    this.props.onSubmit(values);
                })
                .catch();
        }
    };

    handleOnChange = async () => {
        const { current } = this.form;

        if (current) {
            const { validateFields, getFieldsValue } = current;
            getFieldsValue().password && validateFields(['password']).catch(() => {});
        }
    };

    validateEmail = (obj: any, value: string) => {
        const { current } = this.form;

        if (current) {
            const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;

            if (value) {
                value = value.trim();
            }

            if (emailRegExp.test(value)) {
                return Promise.resolve();
            }
        }

        return Promise.reject('error');
    };

    validateName = (obj: any, value: string) => {
        const { current } = this.form;

        if (current) {
            if (value) {
                value = value.trim();
            }

            if (!!value && value.length > 1 && value.length < 65) {
                return Promise.resolve();
            }
        }

        return Promise.reject('error');
    };

    validatePassword = (obj: any, value: string) => {
        const { current } = this.form;

        if (current) {
            const { name, email } = current.getFieldsValue();

            if (value) {
                value = value.trim();
            }

            if (passwordUtil.validatePassword(value) && value !== name && value !== email) {
                return Promise.resolve();
            } else {
                return Promise.reject(
                    this.props.t(passwordUtil.getValidationMessage(value, name, email)),
                );
            }
        }

        return Promise.reject('error');
    };

    togglePasswordVisibility = () =>
        this.setState((state) => ({
            isPasswordVisible: !state.isPasswordVisible,
        }));

    render = () => {
        const { visible, t } = this.props;
        const { isPasswordVisible } = this.state;
        const { Option } = Select;
        const defaultCurrency = localStorageHelper.getChainedValue('user.preferences.currency');

        return (
            <Modal
                className="addUser"
                open={visible}
                title={t('new_user')}
                okText={t('save')}
                onCancel={this.onCancel}
                onOk={this.onSubmit}
            >
                <Form ref={this.form} onValuesChange={this.handleOnChange}>
                    <Form.Item
                        label={t('login_email')}
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('email_error_validation'),
                                validator: this.validateEmail,
                            },
                        ]}
                    >
                        <Input type="email" size="large" placeholder={t('email_placeholder')} />
                    </Form.Item>
                    <Form.Item
                        label={t('name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t('name_must_be_between_2_and_64_characters'),
                                validator: this.validateName,
                            },
                        ]}
                    >
                        <Input type="text" size="large" placeholder={t('name_placeholder')} />
                    </Form.Item>
                    <Form.Item
                        label={t('login_password')}
                        name="password"
                        rules={[
                            {
                                required: true,
                                validator: this.validatePassword,
                            },
                        ]}
                    >
                        <div>
                            <Input type={isPasswordVisible ? 'text' : 'password'} size="large" />
                            <div
                                className="input-container__icon"
                                onClick={this.togglePasswordVisibility}
                            >
                                <EyeOutlined />
                            </div>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label={t('role')}
                        className="role"
                        name="role"
                        rules={[{ required: true }]}
                        initialValue="user"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Radio.Group>
                            <Radio value="admin">{t('admin')}</Radio>
                            <Radio value="manager">{t('manager')}</Radio>
                            <Radio value="user">{t('user')}</Radio>
                            <Radio value="account_manager">{t('account_manager')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t('timezone')}
                        name="timezone"
                        rules={[{ required: true }]}
                        initialValue={localStorageHelper.getChainedValue(
                            'user.preferences.timeZone',
                        )}
                    >
                        <Select size={'large'} showSearch notFoundContent={t('no_results')}>
                            {localStorageHelper
                                .getChainedValue('user.timezoneList')
                                .map((elem: ITimeZoneListItem) => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.id.replace(/_/g, ' ')}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <>
                                {t('currency')}
                                <Tooltip title={t('currency-hint')}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </>
                        }
                        name="currency"
                        rules={[{ required: true }]}
                        initialValue={defaultCurrency}
                    >
                        <Select size={'large'} showSearch notFoundContent={t('no_results')}>
                            {localStorageHelper
                                .getChainedValue('user.allCurrencies')
                                .map((elem: ICurrencyData) => (
                                    <Option key={elem.code} value={elem.code}>
                                        {elem.code}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };
}
