import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Space } from 'antd';
import { ContentComponent } from '../../Components/ContentComponent/ContentComponent';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IRoundInfoData } from '../../helpers/interfaces';
import { HeaderTitleComponent } from '../../Components/ContentComponent/HeaderTitleComponent';
import { Input, Select } from 'antd';
import { getRoundsInfoData } from '../../redux/selectors/configProvider/round-info-selectors';
import { getRoundInfo } from '../../redux/actions/configProvider/round-info-actions';
import ItemComponentWrapper from '../../Components/ContentComponent/ItemComponentWrapper';
import './RoundInfoStyles.scss';

const { Option } = Select;
const { Search } = Input;

interface IProps {
    data: IRoundInfoData[];
    getRoundInfo: Function;
}

const RoundInfoPage = (props: IProps) => {
    const { t } = useTranslation();
    const { data, getRoundInfo } = props;
    const [searchKey, setSearchKey] = useState('roundUuid');

    const onSearchKeyChange = (key: string) => {
        setSearchKey(key);
    };

    const onSearch = (value: string) => {
        if (value.length > 0) {
            getRoundInfo(value);
        }
    };

    const getTable = () => {
        const elements: any = [];
        for (const key in data) {
            elements.push(
                <div className="round-info-item">
                    <span>{t(key)}:</span>
                </div>,
            );
            elements.push(
                <div className="round-info-item">
                    <span className="round-info-item__value">{String(data[key])}</span>
                </div>,
            );
        }

        return (
            <ItemComponentWrapper>
                <div className="round-info-grid">{elements}</div>
            </ItemComponentWrapper>
        );
    };

    useEffect(() => {}, [data, getRoundInfo]);

    return (
        <ContentComponent
            header={
                <>
                    <HeaderTitleComponent title={t('round_info')} />
                    <Space.Compact
                        className="broken-rounds-search__wrapper"
                        style={{ marginBottom: '8px', width: '100%' }}
                    >
                        <Select
                            defaultValue={t('roundUuid')}
                            className="broken-rounds-search__select"
                            onChange={onSearchKeyChange}
                        >
                            <Option value="roundUuid">{t('roundUuid')}</Option>
                        </Select>
                        <Search
                            className="broken-rounds-search__search-field"
                            placeholder={
                                searchKey === 'sessionId'
                                    ? t('input_a_number')
                                    : t('enter_search_value')
                            }
                            onSearch={onSearch}
                            enterButton
                        />
                    </Space.Compact>
                </>
            }
            innerContent={data ? getTable() : <Empty description={t('enter_valid_round_uuid')} />}
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getRoundsInfoData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getRoundInfo: (data: any) => dispatch(getRoundInfo(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoundInfoPage);
