import * as React from 'react';
import { Result } from 'antd';
import './ErrorStyles.scss';
import { ContentComponent } from 'Components/ContentComponent/ContentComponent';
import i18next from 'i18next';

export const PageNotFoundComponent = () => (
    <ContentComponent
        customBreadcrumbs={<h1>404</h1>}
        innerContent={
            <div className="error-page">
                <Result status="404" title="404" subTitle={i18next.t('wrong_url')} />
            </div>
        }
        title={''}
    />
);
