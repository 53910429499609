export const CasinoPerformanceReportTypes = {
    CASINO_PERFORMANCE_REPORT_REQUEST: 'CASINO_PERFORMANCE_REPORT_REQUEST',
    CASINO_PERFORMANCE_REPORT_SUCCESS: 'CASINO_PERFORMANCE_REPORT_SUCCESS',
    CASINO_REPORT_SORT: 'CASINO_REPORT_SORT',
};

export const getCasinoPerformanceReport = (payload?: any) => ({
    type: CasinoPerformanceReportTypes.CASINO_PERFORMANCE_REPORT_REQUEST,
    payload,
});
export const casinoPerformanceReportSuccess = (payload?: any) => ({
    type: CasinoPerformanceReportTypes.CASINO_PERFORMANCE_REPORT_SUCCESS,
    payload,
});
export const casinoPerformanceReportSort = (payload?: any) => ({
    type: CasinoPerformanceReportTypes.CASINO_REPORT_SORT,
    payload,
});
