import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../Components/Loader/Loader';
import { getStatusHistory } from '../../redux/actions/configProvider/broken-rounds-actions';
import { getStatusHistoryData } from '../../redux/selectors/backoffice/broken-rounds-selector';

interface IProps {
    data: any;
    roundUuid: string;
    getStatusHistory: Function;
}

const StatusHistory = (props: IProps) => {
    const { t } = useTranslation();
    const { data, roundUuid, getStatusHistory } = props;

    const getTimestamps = () => (
        <div className="statusHistory">
            <p>{t('status_history')}:</p>
            {data.map((item: any) => (
                <div key={item.statusUpdated} className="statusHistory__item">
                    <p>{item.brokenRoundStatus}</p>
                    <p>{item.statusUpdated}</p>
                </div>
            ))}
        </div>
    );

    useEffect(() => {
        getStatusHistory(roundUuid);
    }, [getStatusHistory, roundUuid]);

    return <div>{data ? getTimestamps() : <Loader />}</div>;
};

const mapStateToProps = (state: any) => ({
    data: getStatusHistoryData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getStatusHistory: (data: string) => dispatch(getStatusHistory(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusHistory);
