export const GamesReportTypes = {
    GAMES_REPORT_REQUEST: 'GAMES_REPORT_REQUEST',
    GAMES_REPORT_SUCCESS: 'GAMES_REPORT_SUCCESS',
    GAMES_REPORT_FAILED: 'GAMES_REPORT_FAILED',
    GAMES_REPORT_SORT: 'GAMES_REPORT_SORT',
};

export const getGamesReport = (payload?: any) => ({
    type: GamesReportTypes.GAMES_REPORT_REQUEST,
    payload,
});
export const gamesReportSuccess = (payload?: any) => ({
    type: GamesReportTypes.GAMES_REPORT_SUCCESS,
    payload,
});
export const gamesReportSort = (payload?: any) => ({
    type: GamesReportTypes.GAMES_REPORT_SORT,
    payload,
});
