export const AdminActionsTypes = {
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILED: 'GET_USERS_SUCCESS',
    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILED: 'CREATE_USER_FAILED',
    BLOCK_USER_REQUEST: 'BLOCK_USER_REQUEST',
    BLOCK_USER_SUCCESS: 'BLOCK_USER_SUCCESS',
    BLOCK_USER_FAILED: 'BLOCK_USER_FAILED',
    UNBLOCK_USER_REQUEST: 'UNBLOCK_USER_REQUEST',
    UNBLOCK_USER_SUCCESS: 'UNBLOCK_USER_SUCCESS',
    UNBLOCK_USER_FAILED: 'UNBLOCK_USER_FAILED',
    CHANGE_ROLE_REQUEST: 'CHANGE_ROLE_REQUEST',
    CHANGE_ROLE_SUCCESS: 'CHANGE_ROLE_SUCCESS',
    CHANGE_ROLE_FAILED: 'CHANGE_ROLE_FAILED',
    CHANGE_PASSWORD_BY_ADMIN_REQUEST: 'CHANGE_PASSWORD_BY_ADMIN_REQUEST',
    CHANGE_PASSWORD_BY_ADMIN_SUCCESS: 'CHANGE_PASSWORD_BY_ADMIN_SUCCESS',
    CHANGE_PASSWORD_BY_ADMIN_FAILED: 'CHANGE_PASSWORD_BY_ADMIN_FAILED',
    CHANGE_NAME_BY_ADMIN_REQUEST: 'CHANGE_NAME_BY_ADMIN_REQUEST',
    CHANGE_NAME_BY_ADMIN_SUCCESS: 'CHANGE_NAME_BY_ADMIN_SUCCESS',
    CHANGE_NAME_BY_ADMIN_FAILED: 'CHANGE_NAME_BY_ADMIN_FAILED',
};

export const getUsers = (payload?: any) => ({
    type: AdminActionsTypes.GET_USERS_REQUEST,
    payload,
});
export const getUsersSuccess = (payload?: any) => ({
    type: AdminActionsTypes.GET_USERS_SUCCESS,
    payload,
});
export const createUser = (payload?: any) => ({
    type: AdminActionsTypes.CREATE_USER_REQUEST,
    payload,
});
export const createUserSuccess = (payload?: any) => ({
    type: AdminActionsTypes.CREATE_USER_SUCCESS,
    payload,
});
export const blockUser = (payload?: any) => ({
    type: AdminActionsTypes.BLOCK_USER_REQUEST,
    payload,
});
export const blockUserSuccess = (payload?: any) => ({
    type: AdminActionsTypes.BLOCK_USER_SUCCESS,
    payload,
});
export const unblockUser = (payload?: any) => ({
    type: AdminActionsTypes.UNBLOCK_USER_REQUEST,
    payload,
});
export const unblockUserSuccess = (payload?: any) => ({
    type: AdminActionsTypes.UNBLOCK_USER_SUCCESS,
    payload,
});
export const changeUserRole = (payload?: any) => ({
    type: AdminActionsTypes.CHANGE_ROLE_REQUEST,
    payload,
});
export const changeUserRoleSuccess = (payload?: any) => ({
    type: AdminActionsTypes.CHANGE_ROLE_SUCCESS,
    payload,
});
export const changePasswordByAdmin = (payload?: any) => ({
    type: AdminActionsTypes.CHANGE_PASSWORD_BY_ADMIN_REQUEST,
    payload,
});
export const changePasswordByAdminSuccess = (payload?: any) => ({
    type: AdminActionsTypes.CHANGE_PASSWORD_BY_ADMIN_SUCCESS,
    payload,
});
export const changeNameByAdmin = (payload?: any) => ({
    type: AdminActionsTypes.CHANGE_NAME_BY_ADMIN_REQUEST,
    payload,
});
export const changeNameByAdminSuccess = (payload?: any) => ({
    type: AdminActionsTypes.CHANGE_NAME_BY_ADMIN_SUCCESS,
    payload,
});
