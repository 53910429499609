import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { Dispatch } from 'redux';
import {
    getOperatorGames,
    sortOperatorGames,
} from 'redux/actions/configProvider/operators-actions';
import { Loader } from 'Components/Loader/Loader';
import { IOperatorGameData } from 'helpers/interfaces';
import {
    getOperatorGamesCP,
    getSortedOperatorGamesCP,
} from 'redux/selectors/configProvider/operators-selectors';
import CustomTable from '../../../Components/Table/CustomTable';
import { CP_OperatorGamesData } from '../../../Containers/Reports/columnsData';
import { Empty } from 'antd';

interface IOperatorData {
    operatorId: number;
}

interface IProps {
    data?: IOperatorData;
    getOperatorGames: Function;
    games: IOperatorGameData[];
    sortedGames: Function;
    sortHandler: Function;
    t: i18next.TFunction;
}

class OperatorGames extends React.Component<IProps & WithTranslation> {
    componentDidMount = () =>
        this.props.getOperatorGames(this.props.data?.operatorId);

    sortHandler = (key: string, sortState: string) =>
        this.props.sortHandler({ keyToSort: key, sortState });

    render = () => {
        const { games, sortedGames, t } = this.props;
        const gamesData =
            sortedGames && sortedGames.length
                ? sortedGames
                : games
                ? games.length
                    ? games
                    : []
                : null;

        return gamesData ? (
            gamesData.length ? (
                <CustomTable
                    reportKey={'CP_OperatorGamesData'}
                    dataSource={gamesData}
                    columns={CP_OperatorGamesData}
                    sortHandler={this.sortHandler}
                    withoutCollapsing
                    hidePagination
                />
            ) : (
                <Empty description={t('no_data')} />
            )
        ) : (
            <Loader />
        );
    };
}

const mapStateToProps = (state: any) => ({
    games: getOperatorGamesCP(state),
    sortedGames: getSortedOperatorGamesCP(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOperatorGames: (data: any) => dispatch(getOperatorGames(data)),
    sortHandler: (data: any) => dispatch(sortOperatorGames(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(OperatorGames));
