import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';

const CampaignColumns = (currencyCode: string) => {
    const { t } = useTranslation();

    return [
        {
            title: t('id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: t('playerId'),
            dataIndex: 'playerId',
            key: 'playerId',
            render: (text: string) => <Link to={`/casino/players/null/${text}`}>{text}</Link>,
        },
        {
            title: t('receivedAt'),
            dataIndex: 'receivedAt',
            key: 'receivedAt',
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('totalBet')}
                    <span>{currencyCode}</span>
                </span>
            ),
            dataIndex: 'totalBet',
            key: 'totalBet',
        },
        {
            title: () => (
                <span className="table-title__span">
                    {t('totalWin')}
                    <span>{currencyCode}</span>
                </span>
            ),
            dataIndex: 'totalWin',
            key: 'totalWin',
        },
        {
            title: t('roundsPlayed'),
            dataIndex: 'roundsPlayed',
            key: 'roundsPlayed',
        },
        {
            title: t('lastRoundDate'),
            dataIndex: 'lastRoundDate',
            key: 'lastRoundDate',
        },
        {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
        },
    ];
};

export default CampaignColumns;
