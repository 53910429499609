import React from 'react';
import { Checkbox, Select } from 'antd';
import { ICurrencyData, IFilters } from 'helpers/interfaces';
import { currencyUtils, localStorageHelper } from 'utils';
import * as i18next from 'i18next';
import { InfoCircleOutlined } from '@ant-design/icons/lib';
const { Option } = Select;

interface IProps {
    t: i18next.TFunction;
    onChangeCallback: Function;
    filters: IFilters;
}

interface IState {
    open: boolean;
    isFunCurrencyActive: boolean;
    operatorCurrenciesList: any[];
    initialTooltipState: boolean;
    isToShowTooltip: boolean;
}

export class CurrencySelector extends React.Component<IProps, IState> {
    select: any = React.createRef();

    state: IState = {
        open: false,
        isFunCurrencyActive: !currencyUtils.getActiveCurrencyData().billable,
        operatorCurrenciesList: localStorageHelper
            .getChainedValue('user.currenciesByBrand')
            .map((elem: ICurrencyData) => elem.code),
        initialTooltipState: !localStorageHelper.getChainedValue('tooltips.doNotShowCurrency'),
        isToShowTooltip: !localStorageHelper.getChainedValue('tooltips.doNotShowCurrency'),
    };

    onChange = (checkedList: any[]) => {
        this.props.onChangeCallback('currencies', checkedList);
        this.select.current.blur();
        this.setState({ open: false });
    };

    handleFocus = () => {
        this.setState({ open: true });
    };

    handleBlur = () => {
        this.setState({ open: false });
    };

    selectAllCurrencies = () => {
        const { operatorCurrenciesList } = this.state;

        this.props.onChangeCallback('currencies', operatorCurrenciesList);
    };

    resetAllCurrencies = () => this.props.onChangeCallback('currencies', []);

    generateOptionsList = () => {
        const billableCurrencies = localStorageHelper
            .getChainedValue('user.currenciesByBrand')
            .filter((currency: any) => currency.billable)
            .map((elem: ICurrencyData) => elem.code);
        const filteredCurrencies = !this.state.isFunCurrencyActive
            ? billableCurrencies
            : this.state.operatorCurrenciesList;

        return filteredCurrencies.map((code: string) => (
            <Option key={code} value={code}>
                {code}
            </Option>
        ));
    };

    handleCurrencyBreakdown = (value: boolean) =>
        this.props.onChangeCallback('currenciesBreakdown', value);

    changeTooltipStatus = () => {
        this.setState((state) => ({ isToShowTooltip: !state.isToShowTooltip }));
    };

    onOk = () => {
        if (!this.state.isToShowTooltip) {
            localStorageHelper.setChainedValue('tooltips.doNotShowCurrency', true);
        }

        this.setState((state) => ({
            initialTooltipState: !state.initialTooltipState,
        }));
    };

    render = () => {
        const { t, filters } = this.props;
        const {
            initialTooltipState,
            isToShowTooltip,
            operatorCurrenciesList,
            isFunCurrencyActive,
        } = this.state;
        const { currencies, currenciesBreakdown } = filters;
        const { pathname } = window.location;
        const isToShowCurrencyBreakdown = pathname !== `/casino/reports/players-performance`;
        const isTooltipVisible = pathname === `/casino/reports/casino-performance`;
        const isResetDisable = currencies.length === 0;
        const isSelectAllDisable = currencies.length === operatorCurrenciesList.length;

        return (
            <>
                <div className="filter-item currency-checkbox-wrapper">
                    <div className="filter-title">{t('currency_filter')}</div>
                    <div className={'filter-content currency-checkbox'}>
                        <Select
                            ref={this.select}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            open={this.state.open}
                            value={filters.currencies}
                            bordered={false}
                            className="games-selector"
                            showSearch
                            placeholder={t('select_currencies')}
                            mode="multiple"
                            onChange={this.onChange}
                            notFoundContent={t('no_data_search')}
                            disabled={isFunCurrencyActive}
                        >
                            {this.generateOptionsList()}
                        </Select>
                        <div className="currency-checkbox-button">
                            <span
                                className={`select-showMore${
                                    isSelectAllDisable || isFunCurrencyActive ? ' disable' : ''
                                }`}
                                onClick={() => {
                                    !isSelectAllDisable &&
                                        !isFunCurrencyActive &&
                                        this.selectAllCurrencies();
                                }}
                            >
                                {t('select-all_currency')}
                            </span>
                            <span
                                className={`reset${
                                    isResetDisable || isFunCurrencyActive ? ' disable' : ''
                                }`}
                                onClick={() => {
                                    !isResetDisable &&
                                        !isFunCurrencyActive &&
                                        this.resetAllCurrencies();
                                }}
                            >
                                {t('reset_currency')}
                            </span>
                        </div>
                    </div>
                </div>
                {isToShowCurrencyBreakdown && !isFunCurrencyActive && (
                    <div className="filter-item currency-checkbox-wrapper">
                        <div className="filter-title">{t('show_currencies')}</div>
                        <div className="filter-content">
                            <div className="switcher__wrapper">
                                <div
                                    className={`switcher__scrolled-block ${
                                        currenciesBreakdown ? ' right' : ''
                                    }`}
                                />
                                <div
                                    className={`switcher__option first ${
                                        currenciesBreakdown ? '' : ' active'
                                    }`}
                                    onClick={() =>
                                        currenciesBreakdown && this.handleCurrencyBreakdown(false)
                                    }
                                >
                                    {t('consolidated')}
                                </div>
                                <div
                                    className={`switcher__option second ${
                                        currenciesBreakdown ? ' active' : ''
                                    }`}
                                    onClick={() =>
                                        !currenciesBreakdown && this.handleCurrencyBreakdown(true)
                                    }
                                >
                                    {t('broken_down')}
                                </div>
                            </div>
                            {isTooltipVisible && initialTooltipState && (
                                <div className="currencies_tooltip">
                                    <div className="container">
                                        <p className="tooltip_sign">
                                            <InfoCircleOutlined />
                                        </p>
                                        <p>
                                            {t('currency_switching_tooltip') + ' '}
                                            <span className="primary_color">
                                                {t('trend_by_performance_indicators')}
                                            </span>
                                            {' ' + t('and') + ' '}
                                            <span className="primary_color">
                                                {t('trend_by_currency')}
                                            </span>
                                            {' ' + t('charts')}
                                        </p>
                                    </div>
                                    <div className="container_btn">
                                        <Checkbox
                                            checked={!isToShowTooltip}
                                            onChange={this.changeTooltipStatus}
                                        >
                                            {t('dont_show_again')}
                                        </Checkbox>
                                        <div className="btn" onClick={this.onOk}>
                                            {t('ok')}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    };
}
