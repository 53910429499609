import { createSelector } from 'reselect';

const getUserData = (state: any) => state.userReducer;
const getIsAuthorisedStatus = (state: any) => state.userReducer.isAuthorised;
const getIsLoggingOutStatus = (state: any) => state.userReducer.isLoggingOut;
const getNameChangeStatus = (state: any) => state.userReducer.isNameChanged;
const getPasswordChangeStatus = (state: any) => state.userReducer.isPasswordChanged;
const getPreferencesChangeStatus = (state: any) => state.userReducer.isPreferencesChanged;
const preferencesUpdateError = (state: any) => state.userReducer.preferencesUpdateError;
const getPasswordChangeRequiredStatus = (state: any) => state.userReducer.passwordChangeRequired;
const getDefaultPasswordChangedStatus = (state: any) => state.userReducer.defaultPasswordChanged;
const getDateOfLastRefreshData = (state: any) => state.userReducer.dateOfLastRefresh;
const getGamesByUserData = (state: any) => state.userReducer.games;
const getGameConfigurationsByUserData = (state: any) => state.userReducer.gameConfigurations;

// User operations
export const userState = createSelector(getUserData, (state) => state);
export const isAuthorised = createSelector(getIsAuthorisedStatus, (state) => state);
export const isLoggingOut = createSelector(getIsLoggingOutStatus, (state) => state);
export const isNameChanged = createSelector(getNameChangeStatus, (state) => state);
export const isPasswordChanged = createSelector(getPasswordChangeStatus, (state) => state);
export const isPreferencesChanged = createSelector(getPreferencesChangeStatus, (state) => state);
export const getPreferencesUpdateError = createSelector(preferencesUpdateError, (state) => state);
export const passwordChangeRequired = createSelector(
    getPasswordChangeRequiredStatus,
    (state) => state,
);
export const defaultPasswordChanged = createSelector(
    getDefaultPasswordChangedStatus,
    (state) => state,
);
export const getDateOfLastRefresh = createSelector(getDateOfLastRefreshData, (state) => state);
export const getGamesByUser = createSelector(getGamesByUserData, (state) => state);
export const getGameConfigurationsByUser = createSelector(
    getGameConfigurationsByUserData,
    (state) => state,
);
