import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { Input, Select, Switch } from 'antd';
import { Loader } from 'Components/Loader/Loader';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import {
    addBrandDataRequest,
    generateFreeRoundsCredentialsRequest,
    saveFreeRoundsCredentialsRequest,
    updateBrandDataRequest,
} from 'redux/actions/configProvider/manage-brand-actions';
import '../../commonCPStyles.scss';
import { BRAND_STEPS } from 'ConfigurationProvider/ManageBrands/AddBrand';
import { Footer } from 'Components/Footer/Footer';
import { MainButton, SecondaryButton } from 'Components/CustomButtons/CustomButtons';
import { IBrandData, IOperatorData } from 'helpers/interfaces';
import {
    getFreeRoundsCredentials,
    getFreeRoundsCredentialsLoadingStatus,
    isBrandDataLoading,
} from 'redux/selectors/configProvider/manage-brand-selectors';
import { Messages } from 'utils';
import { CopyOutlined, LoadingOutlined } from '@ant-design/icons';
const { Option } = Select;

interface IProps {
    operatorData: IOperatorData;
    brandData: IBrandData;
    updateBrandData: Function;
    addBrandInfo: Function;
    updateBrandInfo: Function;
    loading: boolean;
    nextStep: Function;
    isExistingBrand?: boolean;
    t: i18next.TFunction;
    generateFreeRoundsCredentials: Function;
    credentialsLoading: boolean;
    freeRoundsCredentials: any;
    saveFreeRoundsCredentials: Function;
}

interface IValues {
    name: string;
    serviceUrl: string;
    serviceLogin: string;
    servicePassword: string;
    inactivityTimerMinutes: string;
    freeRoundsEnabled: boolean;
    buyFeatureEnabled: boolean;
    dynamicGameConfigurationEnabled: boolean;
    geolocationEnabled: boolean;
    singleSessionModeEnabled: boolean;
    directFreeRoundsSupporting: boolean;
}

interface IState {
    values: IValues;
    errors: any;
    username: string;
}

type brandDataKeys =
    | 'name'
    | 'serviceUrl'
    | 'serviceLogin'
    | 'servicePassword'
    | 'inactivityTimerMinutes'
    | 'freeRoundsEnabled'
    | 'buyFeatureEnabled'
    | 'dynamicGameConfigurationEnabled'
    | 'geolocationEnabled'
    | 'singleSessionModeEnabled'
    | 'directFreeRoundsSupporting';

const defaultValues: IValues = {
    name: '',
    serviceUrl: '',
    serviceLogin: '',
    servicePassword: '',
    inactivityTimerMinutes: '30',
    freeRoundsEnabled: false,
    buyFeatureEnabled: false,
    dynamicGameConfigurationEnabled: false,
    geolocationEnabled: false,
    singleSessionModeEnabled: false,
    directFreeRoundsSupporting: false,
};

class StepManageBrandInfo extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        values: StepManageBrandInfo.getInitialBrandData(this.props),
        errors: { ...defaultValues, inactivityTimerMinutes: '' },
        username: '',
    };

    static getInitialBrandData = (props: IProps) => {
        const { brandData, operatorData, updateBrandData } = props;
        const brandDataFromUrl = operatorData.brands?.filter(
            (brand: IBrandData) => brand.id === brandData.id,
        );

        brandDataFromUrl && brandDataFromUrl.length && updateBrandData(brandDataFromUrl[0]);

        return brandDataFromUrl && brandDataFromUrl.length
            ? { ...brandData, ...brandDataFromUrl[0] }
            : { ...brandData };
    };

    generatePassword = () => {
        const { generateFreeRoundsCredentials, brandData } = this.props;

        generateFreeRoundsCredentials({ brandId: brandData.id, username: this.state.username });
    };

    copyToClipboard = () => {
        Messages.success(this.props.t('copied_to_clipboard'));

        navigator.clipboard.writeText(this.props.freeRoundsCredentials.password);
    };

    savePassword = () => {
        this.props.saveFreeRoundsCredentials(this.props.freeRoundsCredentials.id);
    };

    getInputItems = () => {
        const { t, credentialsLoading, freeRoundsCredentials, brandData, isExistingBrand } =
            this.props;
        const { values, errors } = this.state;
        const inputKeys: brandDataKeys[] = [
            'name',
            'serviceUrl',
            'serviceLogin',
            'servicePassword',
            'inactivityTimerMinutes',
            'freeRoundsEnabled',
            'buyFeatureEnabled',
            'dynamicGameConfigurationEnabled',
            'geolocationEnabled',
            'singleSessionModeEnabled',
        ];

        return inputKeys.map((key: brandDataKeys) => {
            if (key === 'freeRoundsEnabled') {
                return (
                    <div key={key} className="item switcher">
                        <div>
                            <label>{t('free_rounds')}</label>
                            <Switch
                                checked={values[key]}
                                onChange={(value: boolean) => this.onFreeRoundsSupportChange(value)}
                                style={{ marginRight: '16px' }}
                            />
                            {isExistingBrand && (
                                <>
                                    <Input
                                        className="free-rounds-credentials-input"
                                        placeholder="Username"
                                        value={this.state.username}
                                        onChange={(e) =>
                                            this.setState({ username: e.target.value })
                                        }
                                    />
                                    <Input
                                        className="free-rounds-credentials-input"
                                        placeholder="Password"
                                        value={freeRoundsCredentials.password}
                                        disabled
                                    />
                                    {freeRoundsCredentials.password && (
                                        <SecondaryButton onClick={this.copyToClipboard} small>
                                            <CopyOutlined />
                                        </SecondaryButton>
                                    )}
                                    <SecondaryButton
                                        small
                                        disabled={!brandData.id}
                                        onClick={this.generatePassword}
                                    >
                                        <>
                                            Generate{' '}
                                            {credentialsLoading ? <LoadingOutlined /> : null}
                                        </>
                                    </SecondaryButton>
                                    {freeRoundsCredentials.password && (
                                        <MainButton small onClick={this.savePassword}>
                                            Save
                                        </MainButton>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                );
            } else if (key === 'buyFeatureEnabled') {
                return (
                    <div key={key} className="item switcher">
                        <div>
                            <label>{t('buy_feature')}</label>
                            <Switch
                                checked={values[key]}
                                onChange={(value: boolean) => this.onBuyFeatureSupportChange(value)}
                                style={{ marginRight: '16px' }}
                            />
                        </div>
                    </div>
                );
            } else if (key === 'dynamicGameConfigurationEnabled') {
                return (
                    <div key={key} className="item switcher">
                        <div>
                            <label>{t('dynamic_game_configuration')}</label>
                            <Switch
                                checked={values[key]}
                                onChange={(value: boolean) =>
                                    this.onDynamicGameConfigurationChange(value)
                                }
                            />
                        </div>
                    </div>
                );
            } else if (key === 'geolocationEnabled') {
                return (
                    <div key={key} className="item switcher">
                        <div>
                            <label>{t('geolocation')}</label>
                            <Switch
                                checked={values[key]}
                                onChange={(value: boolean) => this.onGeolocationChange(value)}
                            />
                        </div>
                    </div>
                );
            } else if (key === 'singleSessionModeEnabled') {
                return (
                    <div key={key} className="item switcher">
                        <div>
                            <label>{t('single_session_mode')}</label>
                            <Switch
                                checked={values[key]}
                                onChange={(value: boolean) => this.onSingleSessionModeChange(value)}
                            />
                        </div>
                    </div>
                );
            } else if (key === 'inactivityTimerMinutes') {
                return (
                    <div key={key} className="item">
                        <div>
                            <label>
                                <span className="red-color">* </span>
                                {t(key)}
                            </label>
                            <Input
                                type="number"
                                min="1"
                                max="120"
                                value={values[key]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.onInactivityTimerChange(e.target.value)
                                }
                            />
                        </div>
                        <p className="error">{t(errors[key])}</p>
                    </div>
                );
            } else if (key === 'directFreeRoundsSupporting') {
                return null;
            } else {
                return (
                    <div key={key} className="item">
                        <div>
                            <label>
                                <span className="red-color">* </span>
                                {t(key)}
                            </label>
                            <Input
                                value={values[key]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.onChange(e, key)
                                }
                            />
                        </div>
                        <p className="error">{t(errors[key])}</p>
                    </div>
                );
            }
        });
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>, key: brandDataKeys) => {
        const { value } = e.target;

        this.setState((state) => ({
            values: { ...state.values, [key]: value.trim() },
            errors: { ...state.errors, [key]: '' },
        }));
    };

    handleSubmit = () => {
        if (this.isDataReady() && this.isDataValid()) {
            const { values } = this.state;
            const { operatorData, brandData } = this.props;
            const { brands } = operatorData;
            const { id, operatorId } = brandData;
            const { enabled } = brandData;

            this.props.updateBrandData({ ...values });

            if (brands && brands.length) {
                this.props.updateBrandInfo({
                    ...values,
                    id,
                    operatorId,
                    enabled,
                });
            } else {
                this.props.addBrandInfo({
                    operatorId: this.props.brandData.operatorId,
                    ...values,
                });
            }
        }
    };

    isDataValid = () => {
        const errors = this.getValidationErrors();

        return Object.values(errors).every((error: any) => error === '');
    };

    getValidationErrors = () => {
        const { name, serviceUrl, serviceLogin, servicePassword, inactivityTimerMinutes } =
            this.state.values;
        const errors = {
            name:
                name.length > 2 && name.length <= 64
                    ? ''
                    : 'this_field_should_be_between_2_and_64_chars',
            serviceUrl: serviceUrl.length ? '' : 'this_field_cannot_be_empty',
            serviceLogin: serviceLogin.length ? '' : 'this_field_cannot_be_empty',
            servicePassword: servicePassword.length > 2 ? '' : 'password_validation_error_3',
            inactivityTimerMinutes:
                Number(inactivityTimerMinutes) > 0 && Number(inactivityTimerMinutes) < 121
                    ? ''
                    : 'inactivityTimerMinutes_should_be_between_0_and_120',
            freeRoundsEnabled: '',
            buyFeatureEnabled: '',
        };

        this.setState({ errors });

        return errors;
    };

    getBrandSelector = () => {
        const { operatorData, brandData, t } = this.props;
        const { values } = this.state;

        return (
            <div className="item">
                <label>
                    <span className="red-color">* </span>
                    {t('choose_brand')}
                </label>
                <Select
                    placeholder={values.name || t('select_brand')}
                    onChange={this.onChangeBrand}
                >
                    {operatorData.brands?.map((brand: IBrandData) => (
                        <Option
                            key={brand.name}
                            value={brand.name}
                            selected={brand.name === values.name}
                        >
                            {brand.name}
                        </Option>
                    ))}
                </Select>
                <h3>{brandData.name}</h3>
            </div>
        );
    };

    onChangeBrand = (brandName: string) => {
        const brandData = this.props.operatorData.brands?.filter(
            (brand: IBrandData) => brand.name === brandName,
        )[0];

        if (brandData) {
            const {
                name,
                serviceUrl,
                serviceLogin,
                servicePassword,
                inactivityTimerMinutes,
                freeRoundsEnabled,
                buyFeatureEnabled,
                dynamicGameConfigurationEnabled,
                geolocationEnabled,
                singleSessionModeEnabled,
                directFreeRoundsSupporting,
            } = brandData;

            this.setState({
                values: {
                    name,
                    serviceUrl,
                    serviceLogin,
                    servicePassword,
                    inactivityTimerMinutes: inactivityTimerMinutes || '30',
                    freeRoundsEnabled,
                    buyFeatureEnabled,
                    dynamicGameConfigurationEnabled,
                    geolocationEnabled,
                    singleSessionModeEnabled,
                    directFreeRoundsSupporting,
                },
            });
            this.props.updateBrandData(brandData);
        }
    };

    nextStep = () => this.props.nextStep();

    isDataReady = () => {
        const { isExistingBrand, brandData } = this.props;
        const isFieldsNotEmpty = Object.entries(this.state.values).every((value: string[]) => {
            if (typeof value[1] === 'boolean') {
                return true;
            }
            return value[1] !== '';
        });

        return isExistingBrand ? brandData.id && isFieldsNotEmpty : isFieldsNotEmpty;
    };

    onInactivityTimerChange = (value: string) => {
        this.setState((state) => ({
            values: { ...state.values, inactivityTimerMinutes: value },
        }));
    };

    onFreeRoundsSupportChange = (value: boolean) => {
        this.setState((state) => ({
            values: { ...state.values, freeRoundsEnabled: value },
        }));
    };

    onBuyFeatureSupportChange = (value: boolean) => {
        this.setState((state) => ({
            values: { ...state.values, buyFeatureEnabled: value },
        }));
    };

    onDynamicGameConfigurationChange = (value: boolean) => {
        this.setState((state) => ({
            values: { ...state.values, dynamicGameConfigurationEnabled: value },
        }));
    };

    onGeolocationChange = (value: boolean) => {
        this.setState((state) => ({
            values: { ...state.values, geolocationEnabled: value },
        }));
    };

    onSingleSessionModeChange = (value: boolean) => {
        this.setState((state) => ({
            values: { ...state.values, singleSessionModeEnabled: value },
        }));
    };

    render = () => {
        const { t, isExistingBrand, brandData } = this.props;

        return (
            <>
                <div className="adding-form">
                    {brandData ? (
                        <>
                            <ItemComponentWrapper customClass="items" titleKey={BRAND_STEPS[0]}>
                                {isExistingBrand && this.getBrandSelector()}
                                {this.getInputItems()}
                            </ItemComponentWrapper>
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
                <Footer>
                    {isExistingBrand ? (
                        <SecondaryButton disabled={!brandData.id} onClick={this.nextStep}>
                            {t('next')}
                        </SecondaryButton>
                    ) : (
                        <></>
                    )}
                    <MainButton disabled={!this.isDataReady()} onClick={this.handleSubmit}>
                        {t('continue_save')}
                    </MainButton>
                </Footer>
            </>
        );
    };
}

const mapStateToProps = (state: any) => ({
    loading: isBrandDataLoading(state),
    freeRoundsCredentials: getFreeRoundsCredentials(state),
    credentialsLoading: getFreeRoundsCredentialsLoadingStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addBrandInfo: (data: IValues) => dispatch(addBrandDataRequest(data)),
    updateBrandInfo: (data: IValues) => dispatch(updateBrandDataRequest(data)),
    generateFreeRoundsCredentials: (data: any) =>
        dispatch(generateFreeRoundsCredentialsRequest(data)),
    saveFreeRoundsCredentials: (data: any) => dispatch(saveFreeRoundsCredentialsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StepManageBrandInfo));
