import { createSelector } from 'reselect';

const isBrandLoading = (state: any) => state.manageBrandReducer.loading;
const getManageBrandStep = (state: any) => state.manageBrandReducer.step;
const getBrands = (state: any) => state.manageBrandReducer.brands;
const getOperatorBrandsData = (state: any) =>
    state.manageBrandReducer.operatorBrandsCP;
const getSortedOperatorBrandsData = (state: any) =>
    state.manageBrandReducer.sortedOperatorBrandsCP;
const getOperatorGames = (state: any) =>
    state.manageBrandReducer.operatorGamesData;
const operatorCurrencyIds = (state: any) =>
    state.manageBrandReducer.operatorCurrencyIds;
const brandGames = (state: any) => state.manageBrandReducer.games;
const brandCurrencies = (state: any) => state.manageBrandReducer.currencies;
const brandConfigs = (state: any) => state.manageBrandReducer.gameConfig;
const brandConfigUpdateStatus = (state: any) => state.manageBrandReducer.isBrandConfigUpdated;
const freeRoundsCredentials = (state: any) => state.manageBrandReducer.freeRoundsCredentials;
const freeRoundsCredentialsLoading = (state: any) => state.manageBrandReducer.credentialsLoading;


export const getOperatorBrands = createSelector(getBrands, (state) => state);
export const getOperatorBrandsCP = createSelector(
    getOperatorBrandsData,
    (state) => state,
);
export const getSortedOperatorBrandsCP = createSelector(
    getSortedOperatorBrandsData,
    (state) => state,
);
export const getOperatorGamesData = createSelector(
    getOperatorGames,
    (state) => state,
);
export const getOperatorCurrenciesIds = createSelector(
    operatorCurrencyIds,
    (state) => state,
);
export const isBrandDataLoading = createSelector(
    isBrandLoading,
    (state) => state,
);
export const getCurrentManageBrandStep = createSelector(
    getManageBrandStep,
    (state) => state,
);
export const getBrandGames = createSelector(brandGames, (state) => state);
export const getBrandCurrencies = createSelector(
    brandCurrencies,
    (state) => state,
);
export const getBrandConfigs = createSelector(brandConfigs, (state) => state);
export const getBrandConfigUpdateStatus = createSelector(
    brandConfigUpdateStatus,
    (state) => state,
);
export const getFreeRoundsCredentials = createSelector(freeRoundsCredentials, state => state);
export const getFreeRoundsCredentialsLoadingStatus = createSelector(freeRoundsCredentialsLoading, state => state);


