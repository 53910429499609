import * as React from 'react';
import './CustomTableStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { ITableData } from 'Containers/Reports/columnsData';
import { currencyUtils, localStorageHelper } from 'utils';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons/lib';

interface IProps {
    columns: ITableData[];
    currencyId?: number;
    sortStateHandler?: Function;
    keyToSort: string | null;
    sortState: string | null;
    columnFilters?: any;
}

class TableHeader extends React.Component<IProps & WithTranslation> {
    getTitles = () => {
        const { t, columns, currencyId, keyToSort, sortState, sortStateHandler, columnFilters } =
            this.props;

        const shouldDisplayFreeRoundsData = localStorageHelper.getChainedValue(
            'user.brandFeatures.supportsFreeRounds',
        );
        const shouldDisplayBuyFeatureData = localStorageHelper.getChainedValue(
            'user.brandFeatures.supportsBuyFeature',
        );
        const currencyCode = currencyId
            ? currencyUtils.getCurrencyDataById(currencyId).code
            : currencyUtils.getActiveCurrencyCode();

        return columns.map((column: ITableData, i: number) => {
            if (!column.totalLineOnly) {
                if (
                    (!column.frData && !column.bfData) ||
                    (column.frData && shouldDisplayFreeRoundsData) ||
                    (column.bfData && shouldDisplayBuyFeatureData)
                ) {
                    if ((columnFilters && columnFilters[column.key]) || !columnFilters) {
                        return (
                            <th
                                key={i}
                                className={`app-table__column-title app-table__cell
                            ${
                                column.stickyPosition !== undefined
                                    ? ` sticky-title sticky-title_${column.stickyPosition}`
                                    : ''
                            }
                            ${column.key === 'index' ? ' index-column' : ''}
                        `}
                            >
                                <div className={`cell_wrapper alignment-${column.alignment}`}>
                                    {column.canBeSorted &&
                                        column.alignment !== 'left' &&
                                        column.alignment !== 'center' && (
                                            <div
                                                className={`sorter${
                                                    keyToSort && keyToSort === column.key
                                                        ? ` ${sortState}`
                                                        : ''
                                                }`}
                                                onClick={() =>
                                                    sortStateHandler && sortStateHandler(column.key)
                                                }
                                                data-key={column.key}
                                            >
                                                <CaretUpOutlined />
                                                <CaretDownOutlined />
                                            </div>
                                        )}
                                    <div className="cell-flex-wrapper">
                                        <span
                                            id={'column_title_' + (i + 1)}
                                            className="app-table__span"
                                        >
                                            {this.setTooltipToTitle(
                                                `${t(`${column.title}`)}`,
                                                `${t(`${column.tooltip}`)}`,
                                                i + 1,
                                            )}
                                        </span>
                                        {column.withCurrency && (
                                            <span className="app-table__currency">
                                                {currencyCode}
                                            </span>
                                        )}
                                        {['rtp', 'freeRoundsRtp', 'buyFeatureRtp'].includes(
                                            column.key,
                                        ) && <span className="app-table__currency">%</span>}
                                    </div>
                                    {column.canBeSorted && column.alignment !== 'right' && (
                                        <div
                                            className={`sorter${
                                                keyToSort && keyToSort === column.key
                                                    ? ` ${sortState}`
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                sortStateHandler && sortStateHandler(column.key)
                                            }
                                            data-key={column.key}
                                        >
                                            <CaretUpOutlined />
                                            <CaretDownOutlined />
                                        </div>
                                    )}
                                </div>
                            </th>
                        );
                    }
                }
            }

            return null;
        });
    };

    setTooltipToTitle = (title: string, tooltip: string, index: number) => (
        <Tooltip title={tooltip} id={`tooltip_${index}`}>
            <span>{title}</span>
        </Tooltip>
    );

    render = () => (
        <thead>
            <tr className="app-table__header">{this.getTitles()}</tr>
        </thead>
    );
}

export default withTranslation()(TableHeader);
