import * as React from 'react';
import './FooterStyles.scss';

interface IProps {
    children: JSX.Element | JSX.Element[] | null;
    className?: string;
}

export class Footer extends React.Component<IProps> {
    render = () => {
        const { children, className } = this.props;

        return (
            <div
                className={`content-footer${className ? ` ${className}` : ''}`}
            >
                {children}
            </div>
        );
    };
}
