import { Link } from 'react-router-dom';
import {
    CopyOutlined,
    DesktopOutlined,
    EllipsisOutlined,
    MobileOutlined,
    RightOutlined,
    TabletOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons/lib';
import { currencyNameConverter } from '../../utils/Currency/currencyNameConverter';
import { dateFormatter, localStorageHelper, Messages } from '../../utils';
import * as React from 'react';
import i18next from 'i18next';
import { Tag } from 'antd';
import { ITimeZoneListItem } from '../../helpers/interfaces';

export const copyData = (data: any) => {
    Messages.success(`${i18next.t('copied_to_clipboard')}: ${data}`, 1);
    navigator.clipboard.writeText(data);
};

export const isNotClickableElem = (target: HTMLElement): boolean => {
    const className = target.className as unknown;
    const { parentElement } = target;
    const notClickableElemClasses = ['copy-icon', 'menu-icon'];

    if (
        typeof className === 'string' &&
        notClickableElemClasses.some((name) => className.indexOf(name) >= 0)
    ) {
        return true;
    }

    return parentElement ? isNotClickableElem(parentElement) : false;
};

export const handleRowClick = (
    props: any,
    data: any,
    e: React.MouseEvent<HTMLTableRowElement>,
    navigate: Function,
) => {
    const target = e.target as HTMLTableRowElement;
    const isRowClick =
        target.classList.contains('app-table__span') ||
        target.classList.contains('app-table__cell') ||
        target.tagName === 'svg' ||
        target.tagName === 'path';

    if (isNotClickableElem(target)) {
        return;
    }

    if (props.clickableRow && isRowClick) {
        if (data.link) {
            navigate(data.link);
        } else if (data.callback) {
            data.callback();
        }
    }
};

export const getInnerCellContent = (
    props: any,
    data: any,
    dataIndex: string,
    index: number,
    key: string = '',
) => {
    const { t, gmtOffset, context, actionsMenu } = props;
    const { login, playerId, blocked, link, onClick, enabled, testPlayer } = data;
    const value = data[key] === '' || data[key] === null ? 'n/a' : data[key];

    if (dataIndex === 'link')
        return (
            <Link to={link}>
                <RightOutlined />
            </Link>
        );
    if (dataIndex === 'finished')
        return <span>{data.finished ? t('finished') : t('unfinished')}</span>;
    if (dataIndex === 'amount') return <span>{`${value} ${data.currencyCode}`}</span>;
    if (dataIndex === 'roundStatus') return <span>{`${data.transactionType}/${value}`}</span>;
    if (dataIndex === 'callback') return <RightOutlined className="callback-arrow" />;
    if (dataIndex === 'info') return <InfoCircleOutlined onClick={() => data.callback(data)} />;
    if (dataIndex === 'blocked')
        return (
            <span className={`account-status${blocked ? ' blocked' : ''}`}>
                {t(blocked ? 'blocked' : 'active')}
            </span>
        );
    if (dataIndex === 'status')
        return (
            <span className={`account-status${enabled ? '' : ' blocked'}`}>
                {t(enabled ? 'active' : 'blocked')}
            </span>
        );
    if (dataIndex === 'campaign_status')
        if (data[key] === 'DISABLED') return <Tag color="red">{t('disabled')}</Tag>;
    if (data[key] === 'ONGOING') return <Tag color="green">{t('ongoing')}</Tag>;
    if (data[key] === 'UPCOMING') return <Tag color="blue">{t('upcoming')}</Tag>;
    if (data[key] === 'ENDED') return <Tag color="gold">{t('ended')}</Tag>;
    if (dataIndex === 'actions' && !data.disabled)
        return actionsMenu ? (
            actionsMenu(data)
        ) : (
            <EllipsisOutlined className="actions" onClick={onClick} />
        );
    if (dataIndex === 'login')
        return (
            <>
                <Link className="login" to={`/casino/players/${playerId}/${login}`}>
                    {`${value} ${testPlayer ? ' [T]' : ''}`}
                </Link>
                <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
            </>
        );
    if (dataIndex === 'promotionId')
        if (props.reportKey === 'promotion-page')
            return (
                <>
                    <span>{value}</span>
                    <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
                </>
            );
        else {
            const { playerId, login, currencyId } = props.customProps;
            const queryParams = `promotion-${playerId}+${data.promotionId}+${data.gameSessionId}+${currencyId}+${login}`;

            return data[key] ? (
                <>
                    <Link className="login" to={`/casino/promotion/${queryParams}`}>
                        {value}
                    </Link>
                    <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
                </>
            ) : (
                <span>{t('n_a')}</span>
            );
        }
    if (dataIndex === 'currencyId') {
        const currency =
            data[key] === 0 ? t('all_consolidated') : currencyNameConverter.idToCode(`${value}`);

        return (
            <>
                <span>{currency}</span>
                <CopyOutlined onClick={() => copyData(currency)} className="copy-icon" />
            </>
        );
    }
    if (dataIndex === 'game_name_with_id') {
        const gameNameWithId = data.gameName + ' | ID: ' + data.gameId;

        return (
            <>
                <span>{gameNameWithId}</span>
                <CopyOutlined onClick={() => copyData(gameNameWithId)} className="copy-icon" />
            </>
        );
    }
    if (dataIndex === 'from' || dataIndex === 'to')
        return (
            <>
                <span>{dateFormatter.getFormattedForReportDate(value)}</span>
                {gmtOffset ? <span>{context.timezone.offset}</span> : null}
                <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
            </>
        );
    if (dataIndex === 'endTime' || dataIndex === 'startTime' || dataIndex === 'expire')
        return (
            <>
                <span>{value}</span>
                <br />
                {gmtOffset ? <span className="gmtOffset">UTC{context.timezone.offset}</span> : null}
                <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
            </>
        );
    if (dataIndex === 'deviceType' || (dataIndex === 'gamesDeviceType' && value !== 'all')) {
        if (value === 'desktop') return <DesktopOutlined className="device-type" />;
        if (value === 'tablet') return <TabletOutlined className="device-type" />;
        else return <MobileOutlined className="device-type" />;
    }
    if (dataIndex === 'gamesDeviceType' && value === 'all')
        return <span>{t('all').toUpperCase()}</span>;
    if (dataIndex === 'role') return <span>{t(`${value}`)}</span>;
    if (dataIndex === 'index') return <span>{index + 1}</span>;
    if (dataIndex === 'servicePassword') return <span>{value.replace(/./g, '*').slice(0, 7)}</span>;
    if (dataIndex === 'roundId') {
        if (props.reportKey === 'playerProfile') {
            return (
                <span className={'shaped-roundId'}>
                    {value}
                    <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
                </span>
            );
        }
        return <span>{value}</span>;
    }
    if (dataIndex === 'freeRoundsEnabled') {
        return (
            <span className={`account-status${data[key] ? '' : ' blocked'}`}>
                {t(value ? 'active' : 'disabled')}
            </span>
        );
    }
    if (dataIndex === 'buyFeatureEnabled') {
        return (
            <span className={`account-status${data[key] ? '' : ' blocked'}`}>
                {t(value ? 'active' : 'disabled')}
            </span>
        );
    }
    if (dataIndex === 'accounts')
        return (
            data[key] &&
            data[key].length &&
            data[key].map((account: string) => <p key={account}>{account}</p>)
        );
    if (dataIndex === 'country')
        return (
            <>
                <span>{data[key] ? `${data.country} | ${data.city}` : t('unknown')}</span>
                {data[key] ? (
                    <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
                ) : null}
            </>
        );
    if (dataIndex === 'success')
        return (
            <span className={`account-status${data.success ? '' : ' blocked'}`}>
                {t(data.success ? 'success' : 'failed')}
            </span>
        );
    if (dataIndex === 'checkbox') {
        return (
            <input type="checkbox" onChange={(e) => props.onRowSelect(data, e.target.checked)} />
        );
    }

    if (dataIndex === 'timezone')
        return (
            <span>
                {value}{' '}
                <span className="gmtOffset">
                    {
                        localStorageHelper
                            .getChainedValue('user.timezoneList')
                            .find((timezone: ITimeZoneListItem) => timezone.id === value).offset
                    }
                </span>
            </span>
        );
    else {
        return (
            <>
                <span>{value}</span>
                {data[key] ? (
                    <CopyOutlined onClick={() => copyData(value)} className="copy-icon" />
                ) : null}
            </>
        );
    }
};

export const addRowClasses = (stickyPosition?: number) =>
    `app-table__cell${stickyPosition !== undefined ? ' sticky' : ''}`;
