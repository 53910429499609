import * as React from 'react';
import '../ChartStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactEcharts from 'echarts-for-react';
import { currencyUtils, dateFormatter, localStorageHelper } from 'utils';
import { Checkbox } from 'antd';
import { IEChartSeries } from 'helpers/interfaces';
import { ChartConstants, chartKey } from '../ChartConstants';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type groupKey = 'financial' | 'other';

interface IProps {
    data: any[];
}

interface IState {
    activeGroup: groupKey;
    activeCharts: chartKey[];
}

const otherCharts: chartKey[] = ['spins', 'rtp', 'newPlayers', 'players'];
const financialCharts: chartKey[] = ['bet', 'win', 'net'];

const defaultOtherCharts: chartKey[] = ['spins', 'rtp'];
const defaultState: IState = {
    activeGroup: 'financial',
    activeCharts: financialCharts,
};
const chartColors = {
    bet: '#00d9a0',
    win: '#3579ff',
    net: '#ffb600',
    spins: '#cf0920',
    rtp: '#1c366a',
    newPlayers: '#ff837d',
    players: '#ff7700',
};

class CasinoConsolidatedDataChart extends React.Component<IProps & WithTranslation, IState> {
    state: IState = defaultState;

    getOptions = () =>
        this.props.data && {
            color: this.getChartColors(),
            xAxis: this.getXAxis(),
            yAxis: this.getYAxis(),
            series: this.getSeries(),
            tooltip: this.getTooltips(),
            ...ChartConstants.commonOptions,
        };

    getChartColors = () => this.state.activeCharts.map((key: chartKey) => chartColors[key]);

    getXAxis = () => [{ ...ChartConstants.commonAxisXStyles, data: this.getDates() }];

    getDates = () => {
        const dates: string[] = [];
        const isAggregationPeriodDay =
            localStorageHelper.getChainedValue('user.reports.aggregationPeriod') === 'day';

        this.props.data.forEach((item) => {
            const from = dateFormatter.getFormattedForReportDate(item.from);
            const to = dateFormatter.getFormattedForReportDate(item.to);
            const date = isAggregationPeriodDay ? from : `${from} - ${to}`;

            dates.push(date);
        });

        return dates;
    };

    getYAxis = () => {
        const yAxis: any = [];
        let isMoneyAxisAdded = false;

        this.state.activeCharts.forEach((key: chartKey, index: number) => {
            const isMoneyChart = financialCharts.includes(key);
            const position = index === 0 ? 'left' : 'right';
            const name = financialCharts.includes(key)
                ? currencyUtils.getActiveCurrencyCode()
                : key === 'rtp'
                ? '%'
                : '';

            if (isMoneyChart && !isMoneyAxisAdded) {
                yAxis.push({
                    name,
                    position,
                    ...ChartConstants.commonAxisYStyles,
                });
                isMoneyAxisAdded = true;
            } else if (!isMoneyChart) {
                yAxis.push({
                    name,
                    position,
                    ...ChartConstants.commonAxisYStyles,
                });
            }
        });

        return yAxis;
    };

    getChartDataByKey = (key: chartKey) => {
        const chartData: (number | null)[] = [];
        const { subunit } = currencyUtils.getActiveCurrencyData();
        const isMoneyChart = financialCharts.includes(key);

        this.props.data.forEach((item) => {
            const formattedValue = isMoneyChart ? item[key] / subunit : item[key];

            chartData.push(formattedValue);
        });

        return chartData;
    };

    getSingleSeries = (key: chartKey, axisIndex: number) => ({
        name: this.props.t(key),
        type: 'line',
        smooth: true,
        yAxisIndex: axisIndex,
        data: this.getChartDataByKey(key),
    });

    getSeries = () => {
        const { activeGroup, activeCharts } = this.state;
        const isMoneyChart = activeGroup === 'financial';
        const series: IEChartSeries[] = [];

        activeCharts.forEach((key: chartKey, index: number) => {
            series.push(this.getSingleSeries(key, isMoneyChart ? 0 : index));
        });

        return series;
    };

    getTooltips = () => ({
        ...ChartConstants.tooltipCommonOptions,
        ...ChartConstants.tooltipConsolidatedData,
    });

    onChartSelectChange = (e: CheckboxChangeEvent) => {
        const { activeGroup, activeCharts } = this.state;
        const isMoneyChart = activeGroup === 'financial';
        const { checked, value } = e.target;
        const currentCharts = [...activeCharts];

        // should be at least one selector active
        if (!checked && activeCharts.length === 1) {
            e.preventDefault();

            return;
        }

        if (checked) {
            if (isMoneyChart || activeCharts.length < 2) {
                currentCharts.push(value);
            } else {
                currentCharts[currentCharts.length - 1] = value;
            }
        } else {
            currentCharts.splice(currentCharts.indexOf(value), 1);
        }

        this.setState(() => ({ activeCharts: currentCharts }));
    };

    getChartSelectors = () => {
        const { activeGroup, activeCharts } = this.state;
        const charts = activeGroup === 'financial' ? financialCharts : otherCharts;

        return (
            <div className="checkboxes">
                {charts.map((key: chartKey) => (
                    <Checkbox
                        className={key}
                        key={key}
                        value={key}
                        checked={activeCharts.includes(key)}
                        onChange={(e: CheckboxChangeEvent) => this.onChartSelectChange(e)}
                    >
                        {this.props.t(key)}
                    </Checkbox>
                ))}
            </div>
        );
    };

    getAxisNames = () => {
        const { activeGroup, activeCharts } = this.state;
        const { t } = this.props;
        const isMoneyChart = activeGroup === 'financial';
        const axisNames: string[] = [];

        activeCharts.forEach((key: chartKey) => axisNames.push(t(key)));

        return (
            <div className="axis-names">
                {isMoneyChart ? (
                    <div>{axisNames.join(', ')}</div>
                ) : (
                    axisNames.map((key: string) => <div key={key}>{key}</div>)
                )}
            </div>
        );
    };

    onGroupChange = (isOtherGroupActive: boolean) => {
        this.setState(() => ({
            activeGroup: isOtherGroupActive ? 'other' : 'financial',
            activeCharts: isOtherGroupActive ? defaultOtherCharts : financialCharts,
        }));
    };

    render = () => {
        const { t } = this.props;
        const isOtherGroupActive = this.state.activeGroup === 'other';

        return (
            <ItemComponentWrapper titleKey={'trend_by_performance_indicators'} collapsible>
                <div className="chart consolidated">
                    <div className="switcher__wrapper">
                        <div
                            className={`switcher__scrolled-block ${
                                isOtherGroupActive ? ' right' : ''
                            }`}
                        />
                        <div
                            className={`switcher__option first ${
                                isOtherGroupActive ? '' : ' active'
                            }`}
                            onClick={() => this.onGroupChange(false)}
                        >
                            {t('financial')}
                        </div>
                        <div
                            className={`switcher__option second ${
                                isOtherGroupActive ? ' active' : ''
                            }`}
                            onClick={() => this.onGroupChange(true)}
                        >
                            {t('other')}
                        </div>
                    </div>
                    {this.getAxisNames()}
                    <ReactEcharts notMerge option={this.getOptions()} style={{ height: '300px' }} />
                    {this.getChartSelectors()}
                </div>
            </ItemComponentWrapper>
        );
    };
}

export default withTranslation()(CasinoConsolidatedDataChart);
