import { ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import {
    AdminActionsTypes,
    blockUserSuccess,
    changeNameByAdminSuccess,
    changePasswordByAdminSuccess,
    changeUserRoleSuccess,
    createUserSuccess,
    getUsersSuccess,
    unblockUserSuccess,
} from '../../actions/backoffice/casino-admin-actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getLoginRequestHeader } from 'utils';
import { of } from 'rxjs';
import { IAction } from 'helpers/interfaces';
import { apiError } from '../../actions/user-actions';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/config';

export const getUsersEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AdminActionsTypes.GET_USERS_REQUEST),
        mergeMap(() => {
            const fromPromise = from(
                axios.get(`${SERVER_URL}/management/account/by-operator`, {
                    headers: getLoginRequestHeader(),
                }),
            );
            return fromPromise.pipe(
                map((response) => getUsersSuccess(response.data)),
                catchError((error) => of(apiError(error, AdminActionsTypes.GET_USERS_REQUEST))),
            );
        }),
    );

export const createUserEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AdminActionsTypes.CREATE_USER_REQUEST),
        mergeMap((action) => {
            const headers = getLoginRequestHeader('application/json');
            const fromPromise = from(
                axios.post(`${SERVER_URL}/management/account/create`, action.payload, { headers }),
            );
            return fromPromise.pipe(
                map((response) => createUserSuccess(response.data)),
                catchError((error) => of(apiError(error, AdminActionsTypes.CREATE_USER_REQUEST))),
            );
        }),
    );

export const blockUserEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AdminActionsTypes.BLOCK_USER_REQUEST),
        mergeMap((action) =>
            from(
                axios.get(`${SERVER_URL}/management/account/block?email=${action.payload.email}`, {
                    headers: getLoginRequestHeader('application/json'),
                }),
            ).pipe(
                map((response) => blockUserSuccess(response.data)),
                catchError((error) => of(apiError(error, AdminActionsTypes.BLOCK_USER_REQUEST))),
            ),
        ),
    );

// Unblock User Epic
export const unblockUserEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AdminActionsTypes.UNBLOCK_USER_REQUEST),
        mergeMap((action) =>
            from(
                axios.get(
                    `${SERVER_URL}/management/account/unblock?email=${action.payload.email}`,
                    {
                        headers: getLoginRequestHeader('application/json'),
                    },
                ),
            ).pipe(
                map((response) => unblockUserSuccess(response.data)),
                catchError((error) => of(apiError(error, AdminActionsTypes.UNBLOCK_USER_REQUEST))),
            ),
        ),
    );

// Change Role User Epic
export const changeRoleUserEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AdminActionsTypes.CHANGE_ROLE_REQUEST),
        mergeMap((action) =>
            from(
                axios.get(
                    `${SERVER_URL}/management/account/change-role?email=${action.payload.email}&role=${action.payload.role}`,
                    {
                        headers: getLoginRequestHeader('application/json'),
                    },
                ),
            ).pipe(
                map((response) => changeUserRoleSuccess(response.data)),
                catchError((error) => of(apiError(error, AdminActionsTypes.CHANGE_ROLE_REQUEST))),
            ),
        ),
    );

// Change Name by Admin Epic
export const changeNameByAdminEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AdminActionsTypes.CHANGE_NAME_BY_ADMIN_REQUEST),
        mergeMap((action) =>
            from(
                axios.post(`${SERVER_URL}/management/account/change-name`, action.payload, {
                    headers: getLoginRequestHeader('application/json'),
                }),
            ).pipe(
                map((response) => changeNameByAdminSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, AdminActionsTypes.CHANGE_NAME_BY_ADMIN_REQUEST)),
                ),
            ),
        ),
    );

// Change Password by Admin Epic
export const changePasswordByAdminEpic = (action$: Observable<IAction>) =>
    action$.pipe(
        ofType(AdminActionsTypes.CHANGE_PASSWORD_BY_ADMIN_REQUEST),
        mergeMap((action) =>
            from(
                axios.post(`${SERVER_URL}/management/account/reset-password`, action.payload, {
                    headers: getLoginRequestHeader('application/json'),
                }),
            ).pipe(
                map((response) => changePasswordByAdminSuccess(response.data)),
                catchError((error) =>
                    of(apiError(error, AdminActionsTypes.CHANGE_PASSWORD_BY_ADMIN_REQUEST)),
                ),
            ),
        ),
    );
